import { Input, Tag } from "antd";
// import { TweenOneGroup } from "rc-tween-one";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import { FormOutlined } from "@ant-design/icons";

const SendMessage = () => {
  const [tags, setTags] = useState([""]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
    }
  }, []);

  const handleClose = (removedTag: {} | null | undefined) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }

    setInputVisible(false);
    setInputValue("");
  };

  const forMap = (tag: any | null | undefined) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return <span>{tagElem}</span>;
  };

  const tagChild = tags.map(forMap);
  return (
    <>
      <div className="container send_message">
        <Row>
          <Col span={8}>
            <div className="flex items-center justify-between">
              <h4>Messages</h4>
              <FormOutlined />
            </div>
          </Col>
          <Col span={16}>
            <h1 className="ml-6">New Message</h1>
            <div
              style={{
                marginBottom: 16,
              }}
            >
            {/* <TweenOneGroup
              enter={{
                scale: 0.8,
                opacity: 0,
                type: "from",
                duration: 100,
              }}
              onEnd={(e) => {
                if (e.type === "appear" || e.type === "enter") {
                }
              }}
              leave={{
                opacity: 0,
                width: 0,
                scale: 0,
                duration: 200,
              }}
              appear={false}
            >
              {tagChild}
            </TweenOneGroup> */}
            </div>
            {inputVisible && (
              <Input
                ref={inputRef}
                type="text"
                size="small"
                style={{
                  width: 78,
                }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            )}
            {!inputVisible && (
              <a
                onClick={showInput}
                className="w-full"
                placeholder="Type names of one or more people"
              >
                <input
                  placeholder="Type names of one or more people"
                  className="w-full"
                />
              </a>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SendMessage;
