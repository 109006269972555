import React, { Fragment, useState, useRef } from "react";
import { Image } from "antd";
import { VideoCameraOutlined, CloseOutlined } from "@ant-design/icons";
import Tips from "./MiniCard/Tips";
import ToolsList from "./tools/ToolsList";
import Todolist from "./MiniCard/Todolist";
import ChangingBody from "./MiniCard/ChangingBody";
import Symptoms from "./MiniCard/Symptomps";
import ReactHlsPlayer, { HlsPlayerProps } from "react-hls-player";

interface Props {
  weekData: any;
}
const WeekData: React.FC<Props> = ({ weekData }) => {
  const playerRef = useRef<HTMLVideoElement>(null);
  const [videoShow, setVideoShow] = useState(false);

  const handleVideoPlay = () => {
    setVideoShow(true);
  };

  const handleVideoClose = () => {
    setVideoShow(false);
  };

  const [showTips, setShowTips] = useState(false);
  const [showToolsList, setShowToolsList] = useState(false);
  const [showTodoList, setShowTodoList] = useState(false);
  const [showChangeBody, setShowChangeBody] = useState(false);
  const [showSymptoms, setShowSymptoms] = useState(false);
  const [actionParamsId, setactionParamsId] = useState("");
  const handleBabyDevlopment = (e: any, val: any) => {
    if (val?.action === "tips") {
      setactionParamsId(val?.actionParams?.id);
      setShowTips(true);
      setShowToolsList(false);
      setShowTodoList(false);
      setShowChangeBody(false);
      setShowSymptoms(false);
    } else if (val?.action === "tools") {
      setShowToolsList(true);
      setShowTips(false);
      setShowTodoList(false);
      setShowChangeBody(false);
      setShowSymptoms(false);
    } else if (val?.action === "toDoList") {
      setShowTodoList(true);
      setShowToolsList(false);
      setShowTips(false);
      setShowChangeBody(false);
      setShowSymptoms(false);
    } else if (val?.action === "yourBody") {
      setShowChangeBody(true);
      setShowTodoList(false);
      setShowToolsList(false);
      setShowTips(false);
      setShowSymptoms(false);
      setactionParamsId(val?.actionParams?.id);
    } else if (val?.action === "symptoms") {
      setactionParamsId(val?.actionParams?.id);
      setShowSymptoms(true);
      setShowChangeBody(false);
      setShowTodoList(false);
      setShowToolsList(false);
      setShowTips(false);
    }
  };

  return (
    <div className="mb-20">
      {videoShow === false ? (
        <div className="week-banner-card" style={{ backgroundColor: `${weekData?.data?.weekData?.style?.bgColor}` }}>
          <div className="week-ban-inner-card">
            <p className="week-text-p" style={{ color: `${weekData?.data?.weekData?.style?.fontColor}` }}>
              Your baby in week {weekData?.data?.week}
            </p>
            <Image
              src={weekData?.data?.weekData?.imageLink}
              preview={false}
              width={250}
              height={250}
              className="your-b-img text-center "/>
            <div
              className="flex s-between p-10">
              <div>
                <p className="week-c-text">Size</p>
                <p className="week-c-num">
                  <span className="week-c-span">{weekData?.data?.weekData?.size}</span>
                  cm
                </p>
              </div>
              <div>
                <p className="week-c-text">Weight</p>
                <p className="week-c-num">
                  <span className="week-c-span">{weekData?.data?.weekData?.weight}</span>
                  gm
                </p>
              </div>
            </div>
          </div>
          {videoShow === false && (
            <button
              onClick={handleVideoPlay}
              className="mt-5 week-c-btn"
              style={{
                backgroundColor: `${weekData?.data?.weekData?.style?.fontColor}`,
              }}>
              <VideoCameraOutlined /> Watch how your baby is growing
            </button>
          )}
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            width: "100%",
          }}>
          <ReactHlsPlayer
            playerRef={playerRef}
            src={weekData?.data?.weekData?.videoLink}
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
          />
        </div>
      )}

      {videoShow == true && (
        <button
          onClick={handleVideoClose}
          className="mt-5 week-c-btn"
          style={{
            backgroundColor: `${weekData?.data?.weekData?.style?.fontColor}`,
          }}>
          <CloseOutlined /> Close Video
        </button>
      )}

      <div className="mb-7 mini-card-container" >
        <div className="flex items-center overflow-x-auto mt-7 horizontal-scroll-container-minicard">
          {weekData?.data?.widgets?.map((val: any, ind: number) => {
            return (
              <Fragment key={ind}>
                {val.type === "miniCards" && (
                  <Fragment>
                    {val?.miniCards?.map((mini: any, index: number) => {
                      return (
                        <Fragment key={index}>
                          <div className="mr-5 cursor-pointer" onClick={e => handleBabyDevlopment(e, mini)}>
                            <div
                              className="week-mini-card"
                              style={{
                                backgroundColor: `${mini?.style?.bgColor}`,
                              }}>
                              <p
                                className="week-mini-title"
                                style={{
                                  color: `${mini?.style?.fontColor}`,
                                }}>
                                {mini.title}
                              </p>
                              <p className="week-mini-subtitle">{mini?.subTitle}</p>
                              <img src={mini.icon} alt="" loading="lazy" className="week-mini-img" />
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                  </Fragment>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      {showTips == true && <Tips setShowTips={setShowTips} actionParamsId={actionParamsId} />}
      {showToolsList === true && <ToolsList setShowToolsList={setShowToolsList} />}
      {showTodoList === true && <Todolist setShowTodoList={setShowTodoList} />}
      {showChangeBody === true && <ChangingBody setShowChangeBody={setShowChangeBody} actionParamsId={actionParamsId} />}
      {showSymptoms === true && <Symptoms setShowSymptoms={setShowSymptoms} actionParamsId={actionParamsId} />}

      {weekData?.data?.widgets?.map((cardVal: any, ind: number) => {
        return (
          <>
            {cardVal?.type == "card" && (
              <Fragment>
                <div
                  className="week-key-card"
                  style={{
                    backgroundColor: `${cardVal?.card?.style?.bgColor}`,
                  }}>
                  <h3 style={{ color: `${cardVal?.card?.style?.fontColor}` }}>{cardVal?.card?.title}</h3>
                  <div className="mt-3">
                    <p>{cardVal?.card?.description}</p>
                  </div>
                </div>
              </Fragment>
            )}
          </>
        );
      })}

      {weekData?.data?.widgets?.some((cardVal: any) => cardVal?.type === "postCards") && (
        <div className="mt-3">
          <p className="week-c-text">FOOD GUIDE FOR THIS WEEK</p>
          <div className="weekcard-scroll" >
          <div className="flex items-center horizontal-scroll-container-minicard">
              {weekData?.data?.widgets?.map((cardVal: any, ind: number) => {
                return (
                  <>
                    {cardVal?.type == "postCards" && (
                      <Fragment>
                        {cardVal?.postCards?.map((postCar: any, iny: number) => {
                          return (
                            <div
                              className="text-center mr-3 post-cards mt-4"
                              style={{
                                backgroundColor: `${postCar?.style?.bgColor}`}}>
                              <h3>{postCar?.title}</h3>
                              <img
                                src={postCar?.imageUrl}
                                alt=""
                                loading="lazy"
                              />
                              <p className="p-card-description"
                                style={{
                                  color: `${postCar?.style?.fontColor}`,
                                }}>
                                {postCar?.description}
                              </p>
                            </div>
                          );
                        })}
                      </Fragment>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeekData;
