import React, { useRef } from 'react';
import { Modal, Button,Input } from 'antd';
import {
    LikeOutlined,
    MessageOutlined,
    LikeFilled,
    MoreOutlined,
    CopyOutlined
  } from "@ant-design/icons";
interface Props {
  shareOpenModal: boolean;
  setShareOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  shareLink:string
}

const Share: React.FC<Props> = ({ shareOpenModal, setShareOpenModal ,shareLink}) => {

  console.log("shareLink");

  const handleOk = () => {
    setShareOpenModal(false);
  };

  const handleCancel = () => {
    setShareOpenModal(false);
  };

  const textAreaRef = useRef<HTMLTextAreaElement>(null);


  const contentToCopy = shareLink;

  const copyToClipboard = () => {
    textAreaRef.current?.select();
    document.execCommand('copy');
    // alert('Copied to clipboard!');
    setShareOpenModal(false);
  };

  return (
    <Modal  visible={shareOpenModal}
    onOk={handleOk}
    onCancel={handleCancel}
    footer={null}
    closable={false}
    >

<div style={{ position: 'relative' }}>
      <textarea
        ref={textAreaRef}
        readOnly
        value={contentToCopy}
        style={{ position: 'absolute', left: '-9999px' }}
      />
      <div style={{ position: 'relative' }}>
        <Input
          type="text"
          value={contentToCopy}
          readOnly
          style={{ cursor: 'not-allowed', paddingRight: '40px', width:"90%" }} // Adjust padding for the button
        />
        <Button
          onClick={copyToClipboard}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            width: '80px',
          }}
        >
         < CopyOutlined  style={{fontSize:"18px"}}/>
        </Button>
      </div>
    </div>
    </Modal>
  );
};

export default Share;
