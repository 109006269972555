import React, { useEffect, useState } from "react";
// import 'antd/dist/antd.css';
// import './index.css';
import { Avatar, Badge, Col, Layout, Modal, Row, Tooltip, Upload } from "antd";
import Sidenav from "views/MainHeader/SideNav";
import Headersection from "views/MainHeader/Header";
import Profile_bg from "../../images/profile_cover.png";
import Profile_img from "../../images/profile_img.png";
import Edit_icon from "../../images/edit_icon.png";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewprofile, TUserDetailRequest } from "actions/viewprofile";
import { TRootState } from "reducers";
import { Tabs } from "antd";
import { Button } from "antd";
import DetailsPersonal from "./DetailsPersonal";
import Timeline from "./Timeline";

import type { RadioChangeEvent } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import Webcam from "react-webcam";
import { FaRegUserCircle, FaImage } from "react-icons/fa";
import Dragdropsection from "../EditProfile/Dragdropsection";
import Connection from "./Connection";
import Groups from "./Groups";
import Videos from "./Videos";
import Photos from "./Photos";
import Forums from "./Forums";
import Documents from "./Documents";
import { useLocation } from "react-router";
import { getviewprofileConnectionReq } from "apis/ViewProfile";
import { getviewprofileFollowingReq } from "apis/ViewProfile";
import { getviewprofileFollowersReq } from "apis/ViewProfile";
import { viewProfileApi } from "apis/btpViewProfile";
import { useQuery } from "react-query";
import moment from "moment";

const { TabPane } = Tabs;

const { Header, Content, Footer, Sider } = Layout;

type TabPosition = "left" | "right" | "top" | "bottom";
const ViewProfile = () => {
  const [myconnections, setMyconnections] = useState()
  const [Myfollowing, setMyfollowing] = useState()
  const [Myfollowers, setMyfollowers] = useState()
  const [Myfollowersveiw, setMyfollowersveiw] = useState<any>([])
  const [Myfollowingveiw, setMyfollowingveiw] = useState<any>([])


  const [modal2Open, setModal2Open] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);

  


  const authInfo = useSelector((state: TRootState) => state.auth);

  const location = useLocation();
  // const data = location.state;
  const [tabPosition, setTabPosition] = useState<TabPosition>("left");
  const { Accesstoken, userId } = useSelector(
    (state: TRootState) => state.auth
  );
  const userdata = useSelector((state: TRootState) => state.userprofile);
  const dispatch = useDispatch();
  let reqBody = { user_id: userId };

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabPosition(e.target.value);
  };



  // const profileData =
  //   useSelector((state: TRootState) => state.userprofile);


  const { isLoading, data,refetch } = useQuery('viewProfile', () => 
  viewProfileApi({ user_id: "" ,enabled: false},
  ))
  const profileData = data;

  // const childrenList = userdata.child_info.map((item, index) => {
  //   return (
  //     <Row>
  //       <Col span={4}>
  //         <h5>Child Name</h5>
  //       </Col>
  //       <Col span={4}>
  //         <p>{item.child_name}</p>
  //       </Col>
  //       <Col span={4}>
  //         <h5>Child gender</h5>
  //       </Col>
  //       <Col span={4}>
  //         <p>{item.gender}</p>
  //       </Col>
  //       <Col span={4}>
  //         <h5>Child dob</h5>
  //       </Col>
  //       <Col span={4}>
  //         <p>{item.dob}</p>
  //       </Col>
  //       <Col span={4}>
  //         <h5>Child sk_stage_id</h5>
  //       </Col>
  //       <Col span={4}>
  //         <p>{item.sk_stage_id}</p>
  //       </Col>
  //       <Col span={4}>
  //         <h5>Child nick_name</h5>
  //       </Col>
  //       <Col span={4}>
  //         <p>{item.nick_name}</p>
  //       </Col>
  //       <Col span={4}>
  //         <h5>Child child_profile_pic</h5>
  //       </Col>
  //       <Col span={4}>
  //         <p>{item.child_profile_pic}</p>
  //       </Col>
  //     </Row>
  //   );
  // });

  const childrenList = userdata.child_info.map((item, index) => {
    if (item) { // Check if item is not null or undefined
      return (
        <Row key={index}>
          <Col span={4}>
            <h5>Child Name</h5>
          </Col>
          <Col span={4}>
            <p>{item.child_name}</p>
          </Col>
          <Col span={4}>
            <h5>Child gender</h5>
          </Col>
          <Col span={4}>
            <p>{item.gender}</p>
          </Col>
          <Col span={4}>
            <h5>Child dob</h5>
          </Col>
          <Col span={4}>
            <p>{item.dob}</p>
          </Col>
          <Col span={4}>
            <h5>Child sk_stage_id</h5>
          </Col>
          <Col span={4}>
            <p>{item.sk_stage_id}</p>
          </Col>
          <Col span={4}>
            <h5>Child nick_name</h5>
          </Col>
          <Col span={4}>
            <p>{item.nick_name}</p>
          </Col>
          <Col span={4}>
            <h5>Child child_profile_pic</h5>
          </Col>
          <Col span={4}>
            <p>{item.child_profile_pic}</p>
          </Col>
        </Row>
      );
    } else {
      // Handle the case where item is null or undefined
      return null; // or display a message, or handle it as needed
    }
  });
  

  useEffect(() => {
    if (Accesstoken !== "" && Accesstoken !== null)
      dispatch(viewprofile(reqBody as TUserDetailRequest, Accesstoken));
  }, [Accesstoken]);

  const [tabkeyval, setTabkeyval] = useState("View");
  const [defaultActiveSub, setDefaultActiveSub] = useState("my_connections");
  const [defaultActiveGroup, setDefaultActiveGroup] = useState("groups");
  const [defaultActivePhotos, setDefaultActivePhotos] = useState("my_photos");
  const [defaultActiveForums, setDefaultActiveForums] =
    useState("my_Discusssions");
  const router: any = useRouteMatch();
  useEffect(() => {
    if (Object.keys(router.params).length > 0) {
      if (typeof router.params["slug1"] !== "undefined") {
        setTabkeyval(router.params["slug1"]);
        setDefaultActiveSub("my_connections");
        setDefaultActiveGroup("groups");
        setDefaultActivePhotos("my_photos");
        setDefaultActiveForums("my_Discusssions");
      }
      if (
        typeof router.params["slug1"] !== "undefined" &&
        typeof router.params["slug2"] !== "undefined"
      ) {
        setTabkeyval(router.params["slug1"]);
        setDefaultActiveSub(router.params["slug2"]);
        setDefaultActiveGroup(router.params["slug2"]);
        setDefaultActivePhotos(router.params["slug2"]);
        setDefaultActiveForums(router.params["slug2"]);
      }
    }
  }, [router]);


  const handleTabClick = (key: any) => {
    setTabkeyval(key);
  };

  useEffect(() => {
    getviewprofileConnectionReq(Accesstoken).then((res) => {
      const connectioncount = res.data.length;
      setMyconnections(connectioncount);
    });
  }, []);
  useEffect(() => {
    getviewprofileFollowingReq(Accesstoken).then((res) => {
      const connectioncount_section1 = res.data;
      const connectioncount_section = res.data.length;
      setMyfollowing(connectioncount_section);
      setMyfollowingveiw(connectioncount_section1);

    });
  }, []);
  useEffect(() => {
    getviewprofileFollowersReq(Accesstoken).then((res) => {
      const connectioncount_section2 = res.data;
      const connectioncount_section = res.data.length;
      setMyfollowers(connectioncount_section);
      setMyfollowersveiw(connectioncount_section2);
    });
  }, []);
  

  // console.log('authInfo', authInfo, profileData);

  return (
    
    <Layout className="h-screen">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
        }}
        onCollapse={(collapsed, type) => {
        }}
      >
        <div className="logo" />
        <Sidenav />
      </Sider>
      
      <Header
        className=""
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <Headersection />
      </Header>
      <Layout className="Content-body container mt-20">
        <div className="bg-white mt-16">
          <Content className="profike_imgs">
            <div className="profile_bg">


              {/* <div className="edit-icon">
                <figure>
                  <Link
                    className="mb-4"
                    to={{ pathname: "/editprofile/coverPhoto" }}
                  >
                    {userdata.cover_photo !== '' ? 
                      <img
                        src={userdata.cover_photo}
                        alt="prf_img"
                        className="cover_bg object-cover w-full"
                      />:
                      <img
                        src={Profile_bg}
                        alt="prf_img"
                        className="cover_bg object-cover w-full"
                      />
                    }
                  </Link>
                </figure>
                <Tooltip title="change cover image">
                  <div className="cover_bg_upload">
                  <Link
                    to={{ pathname: "/editprofile/coverPhoto" }}
                  >
                    <img
                      src={Edit_icon}
                      alt="edit-icon"
                      width={40}
                      height={20}
                    />
                    </Link>
                  </div>
                </Tooltip>
              </div> */}

              
              <div className="profile_sec">
                <figure>
                {profileData?.profile_pic !== "" ? 
                  <img
                    src={profileData?.profile_pic}
                    alt="profile-img"
                    max-width={"100%"}
                    height={"auto"}
                  />: 
                  <Avatar src ={authInfo.parent_info?.gender_pic}/>
                }
                </figure>


                <div className="flex flex-col md:mt-8 lg:items-start md:items-center profile_name">
                  <div className="md:flex flex-row items-center gap-2 ml-4 mt-5">
                    <h2 className="profile-head-name">{profileData?.full_name}</h2>
                    <p
                      className="mx-auto md:mx-0 text-center font-semibold text-xs profile-head-tag"
                    >
                      {profileData?.stage_name}
                    </p>
                  </div>
                  <div className="flex">
                    <div>
                      <h6 style={{paddingRight:"0px"}}> @{profileData?.full_name} <span className="px-2">•</span></h6>
                    </div>
                    <div>
                    <p>{moment(profileData?.created_date_time).format('DD-MM-YYYY')}</p>
                    </div>
                  </div>
                  <div className="flex mx-3">
                  <Button style={{border:"0px",padding:"0px",marginRight:"10px"}} onClick={() => setModal2Open(true)}>
                    <p className="follower-profile" ><span className="black_text">{Myfollowers}</span> followers .  |</p>  
                  </Button>
                  <Button style={{border:"0px",padding:"0px"}} onClick={() => setModal1Open(true)}>
                  <p className="follower-profile" ><span className="black_text">{Myfollowing}</span> Following .</p>  
                  </Button>
                  </div>
                </div>

                
              </div>
            </div>
          </Content>
          <div className="Content-body1 md:mt-5">
            <Content className="menu-bar1">
              <Tabs
                tabPosition={tabPosition}
                onChange={handleTabClick}
                activeKey={tabkeyval}
                className="main-tab"
              >
                <TabPane tab="Profile" key="View">
                  <div className="tab-style">
                    <DetailsPersonal />
                  </div>
                </TabPane>

                <TabPane tab="Timeline" key="timeline">
                  <div className="tab-style">
                    <Timeline />
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span className="badge">
                      Connections
                      {/* <p className="badge-style">{myconnections}</p>  */}
                      <Badge className="badge-style" count={
                        <>
                        {myconnections}
                        </>
                      } />
                    </span>
                  }
                  key="my_connections"
                >
                  <div className="tab-style ">
                    <Connection
                      setDefaultActiveSub={(aa: string) =>
                        setDefaultActiveSub(aa)
                      }
                      defaultActive={defaultActiveSub}
                    />
                  </div>
                </TabPane>
                {/* <TabPane
                  tab={
                    <span className="badge">
                      Groups
                      <Badge
                        style={{ backgroundColor: "lightgray" }}
                        className="badge-style2"
                        count={9}
                      />
                    </span>
                  }
                  key="groups"
                >
                  <div className="tab-style">
                    <Groups
                      setDefaultActiveGroup={(aa: string) =>
                        setDefaultActiveGroup(aa)
                      }
                      defaultActiveGroup={defaultActiveGroup}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Videos" key="5">
                  <div className="tab-style">
                    <Videos />
                  </div>
                </TabPane>
                <TabPane tab="Photos" key="my_photos">
                  <div className="tab-style">
                    <Photos
                      setDefaultActivePhotos={(aa: string) =>
                        setDefaultActivePhotos(aa)
                      }
                      defaultActivePhotos={defaultActivePhotos}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Forums" key="my_Discusssions">
                  <div className="tab-style">
                    <Forums
                      setDefaultActiveForums={(aa: string) =>
                        setDefaultActiveForums(aa)
                      }
                      defaultActiveForums={defaultActiveForums}
                    />
                  </div>
                </TabPane>
                <TabPane tab="Documents" key="8">
                  <div className="tab-style">
                    <Documents />
                  </div>
                </TabPane> */}
              </Tabs>
            </Content>
          </div>
          {/* Modal Section */}
            <Modal
              title="Vertically centered modal dialog"
              centered
              visible={modal2Open}
              onOk={() => setModal2Open(false)}
              onCancel={() => setModal2Open(false)}
            >
              <div className="h_400">
                {Myfollowersveiw.map((movie:any) => {
                  return(
                  <>
                  {movie.follower_details.map((sub:any) =>
                    <div>
                      <div className="flex mb-2">
                        <div className="mr-3">
                          {sub.profile_pic  ? 
                          <>
                          <div style={{padding:"5px"}}>
                            <img src={sub.profile_pic} alt=""  style={{width:"30px",height:"30px",borderRadius:"50%"}}/> 
                            </div>
                          </>
                          :
                          <img src={authInfo.parent_info?.gender_pic} alt=""  style={{width:"40px",height:"40px",borderRadius:"50%"}}/>
                          }
                        </div>
                        <div>
                          <h2 className="mb-0">{sub.full_name}</h2>
                          <div className="flex">
                            <div className="mr-2">
                              <p><span className="pr-1">{sub.connection}</span> connection</p>
                            </div>
                            <div>
                              <p><span className="pr-1">{sub.follow}</span>follow </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   )}
                   
                  </>
                  )
                })}
              </div>
             {/* {Myfollowersveiw.map((movie:any) => {
              return(
               <>
               <div>
                <ul>
                  {movie.follower_details.map((sub:any) => 
                  <li>
                    {sub.full_name}
                  </li>
                )}
                </ul>
               </div>
               </>
              )
             })} */}
            </Modal>
            <Modal
              title={
                <>
                <h1>Following</h1>
                </>
              }
              centered
              visible={modal1Open}
              onOk={() => setModal1Open(false)}
              onCancel={() => setModal1Open(false)}
              footer={false}
            >
              <div className="h_400">
                {Myfollowingveiw.map((movie:any) => {
                  return(
                  <>
                  <div>
                    <div className="flex mb-2">
                      <div className="mr-3">
                        {movie.profile_pic  ? 
                        <>
                        <div style={{padding:"5px"}}>
                          <img src={movie.profile_pic} alt=""  style={{width:"30px",height:"30px",borderRadius:"50%"}}/> 
                          </div>
                        </>
                        :
                        <img src={authInfo.parent_info?.gender_pic} alt=""  style={{width:"40px",height:"40px",borderRadius:"50%"}}/>
                        }
                      </div>
                      <div>
                        <h2 className="mb-0">{movie.full_name}</h2>
                        <span>{movie.connection} connection</span> <span>{movie.follow} follow</span>
                      </div>
                    </div>
                  </div>
                  </>
                  )
                })}
              </div>
            </Modal>
            
        </div>
      </Layout>
    </Layout>
    
  );
};


export default ViewProfile;
