import React, { useEffect, useState, Fragment } from "react";
import { Card, Button, Modal, Select, Avatar, Badge, Space, Form, Pagination, Upload, Dropdown, Menu, Image } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Headersection from "views/MainHeader/Header";
import { Layout, Tabs } from "antd";
const { Header, Content, Sider } = Layout;
import Sidenav from "views/MainHeader/SideNav";
import { PaginationProps } from "antd";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { TRootState } from "reducers";
import { mediaUpload } from "apis/UpdateProfile";
import { createPostCore, grpPostUpdate } from "views/Groups/btpGroupApi";
import GroupComments from "./GroupComments";
import { MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import Send from "../../images/send.svg";
import CommentImg from "../../images/comment.svg";
import type { UploadProps } from "antd";
import ImgCrop from "antd-img-crop";
import {
  groupById,
  joinGroupApi,
  groupMemberById,
  leaveGroup,
  groupPostByID,
  deleteGroup,
  updateGroup,
  savePostGroupApi,
} from "views/Groups/btpGroupApi";
import { AiOutlineCamera, AiOutlineVideoCamera } from "react-icons/ai";
import { postReportGroup } from "views/Groups/GroupApi";
import { useQuery } from "react-query";
import Swal from "sweetalert2";
import { viewProfileApi } from "apis/btpViewProfile";
import { useSelector } from "react-redux";
import { LikeOutlined, LikeFilled } from "@ant-design/icons";
import { grouplikeApi, deleteMyPost } from "views/Groups/btpGroupApi";
import SaveImage from "../../images/SavedIcon.svg";
import Save from "../../images/save.svg";
import SendModal from "views/Posts/SendModal";
import Slider from "react-slick";
import Connect from "components/global/buttonsection/Connect";
import Followbutton from "components/global/buttonsection/Followbutton";
const { Meta } = Card;
const like_stylus = "border-like_stylus0 text-red-600 hover:text-red-300";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import PinnedIcon from "../../images/pinned.svg"

let settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  arrows: false,
};

const GroupProfile: React.FC = (props: any) => {
  const [activeTab, setActiveTab] = useState("1");
  const [openReportModal, setOpenReportModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [groupData, setGroupData] = useState<any>({});
  const [selectedReason, setSelectedReason] = useState(null);
  const [postData, setPostdata] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPagesGPost, setTotalPagesGPost] = useState(1);
  const authInfo = useSelector((state: TRootState) => state.auth);
  const [groupMemberListData, setGroupMemberListData] = useState<any>([]);
  const [inputVal, setInputVal] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postId, setPostID] = useState<any>("");
  const [isMoreVisible, setIsMoreVisible] = useState(true);
  const history = useHistory();

  const [liked, setLiked] = useState(false);

  const [pageInfo, setPageInfo] = useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [pageInfoGPost, setPageInfoGPost] = useState<any>({
    current: 1,
    pageSize: 10,
  });
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (current, pageSize) => {
    setPageInfo({ current: current, pageSize: pageSize });
    setPageInfoGPost({ current: current, pageSize: pageSize });
  };
  const [form] = Form.useForm();
  const { id } = useParams<any>();
  const { data, isLoading } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));
  const profileData = data;

  function handleChange(tab: any) {
    setActiveTab(tab);
  }
  const showModal = () => {
    setOpen(true);
  };
  const reportModal = () => {
    setOpenReportModal(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleReport = () => {
    setOpenReportModal(false);
  };

  const handleChangeReport = (value: any) => {
    setSelectedReason(value);
  };

  const groupByIDApi = (id: any) => {
    groupById(id)
      .then(res => {
        setGroupData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const groupPosts = async (id: any, pageInfoGPost: any) => {
    await groupPostByID(id, pageInfoGPost)
      .then(res => {
        setTotalPagesGPost(res.data.pageInfo.totalCount);
        setPostdata(res.data.posts);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const showModalComment = (e: any, values: any) => {
    setPostID(values._id);
    setIsModalOpen(true);
  };

  const refetch = () => {
    groupMemberById(id, pageInfo)
      .then(res => {
        setGroupMemberListData(res.data.members);
        setTotalPages(res.data.pageInfo.totalCount);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    refetch();
    groupPosts(id, pageInfoGPost);
  }, [id, pageInfo]);

  useEffect(() => {
    groupByIDApi(id);
  }, [id]);

  const handleClickJoin = (e: any, value: any) => {
    const data = {
      groupId: value.groupId,
      isModerator: false,
      isAdmin: false,
      memberStatus: "active",
    };
    joinGroupApi(data)
      .then(res => {
        groupByIDApi(id);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleOk = () => {
    leaveGroup(id)
      .then(res => {
        groupByIDApi(id);
        setOpen(false);
        setActiveTab("1");
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleReportSubmit = () => {
    const data = {
      type: "groups",
      content_id: id,
      reason: selectedReason,
    };
    postReportGroup(data)
      .then(res => {
        groupByIDApi(id);
        setOpenReportModal(false);
        setSelectedReason(null);
        Swal.fire({
          icon: "success",
          title: "Report has been sent successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleMute = () => {
    const data = {
      groupId: id,
      isModerator: false,
      isAdmin: false,
      isSearchable: false,
      memberStatus: "active",
      isMuted: true,
      id: groupData.memberId,
    };
    joinGroupApi(data)
      .then(res => {
        groupByIDApi(id);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleUnMute = () => {
    const data = {
      groupId: id,
      isModerator: false,
      isAdmin: false,
      isSearchable: false,
      memberStatus: "active",
      isMuted: false,
      id: groupData.memberId,
    };
    joinGroupApi(data)
      .then(res => {
        groupByIDApi(id);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickLike = async (value: any) => {
    setLiked(!liked);
    await grouplikeApi(value)
      .then(res => {})
      .catch(err => {
        console.log(err);
      });
    groupPosts(id, pageInfoGPost);
  };

  const toggleDisplay = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  const handleDeleteClick = (e: any, list: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Post.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteMyPost(list)
          .then(res => {
            groupPosts(id, pageInfoGPost);
          })
          .catch(err => {
            console.log(err);
          });
        toggleDisplay();
        Swal.fire("Deleted!", "Your post has been deleted.", "success");
      }
    });
  };

  useEffect(() => {
    if (groupData.memberStatus !== "active") {
      setActiveTab("2");
    } else {
      setActiveTab("1");
    }
  }, []);

  const handleDeleteGroup = (e: any, val: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't to delete this group!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteGroup(val._id)
          .then(res => {
            history.push("/groups");
            Swal.fire("Deleted!", "Your group has been deleted.", "success");
          })
          .catch(err => {
            console.log(err);
          });
      }
    });
  };

  const handleDeactivate = (e: any, val: any) => {
    const data = {
      groupStatus: val,
      id: id,
    };
    setActiveTab("4");
    updateGroup(data)
      .then(res => {
        groupByIDApi(id);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleActivate = (e: any, val: any) => {
    const data = {
      groupStatus: val,
      id: id,
    };
    setActiveTab("4");
    updateGroup(data)
      .then(res => {
        groupByIDApi(id);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const hanldeSavePost = async (e: any, val: any) => {
    await savePostGroupApi(val._id)
      .then(res => {
        groupPosts(id, pageInfoGPost);
        if (val.totalSavedPosts == 0) {
          groupPosts(id, pageInfoGPost);
          Swal.fire({
            icon: "success",
            title: "Feed saved",
            showConfirmButton: false,
            timer: 900,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickEditGroup = (e: any, value: any) => {
    history.push("/groups", { state: value });
  };

  const [artcileData, setArticleData] = useState<any>({});
  const [type, setType] = useState<any>("blog");
  const [isSendShow, setIsSendShow] = useState(false);
  const handleSendBtn = (permalink: any) => {
    setIsSendShow(true);
    setArticleData(permalink);
    setType("group_post");
  };
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [postedFiles, setPostedFiles] = useState<any>([]);
  const [errText, setErrText] = useState("");
  const onSubmit = async (values: any) => {
    // if (!inputVal || uploadedFiles.length !== 0) {
    //   setErrText("Please add some content or media to post");
    //   return;
    // }
    // console.log("imgSrc", values);
    let data = JSON.stringify({
      postTitle: values.postTitle,
      postPrivacy: "public",
      postDescription: inputVal,
      groupId: id,
      postType: "groupPost",
      postMedia: uploadedFiles,
    });
    // setErrText("")
    await createPostCore(data).then(res => {
      if (res.status === true) {
        form.resetFields();
        setInputVal("");
        setUploadedFiles([]);
        setPostedFiles([]);
        groupByIDApi(id);
        groupPosts(id, pageInfoGPost);
        Swal.fire({
          icon: "success",
          title: "Posted successfully",
          showConfirmButton: false,
          timer: 900,
        });
      }
    });
  };

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputVal(e.target.value);
    setErrText("");
  };

  // New Code
  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    Modal.info({
      title: "Image Preview",
      content: <img src={src} style={{ maxWidth: "100%" }} />,
    });
  };

  const onVideoPreview = async (file: UploadFile<any>) => {
    Modal.info({
      title: file.name,
      content: (
        <video controls style={{ maxWidth: "100%" }}>
          <source src={file.url || URL.createObjectURL(file.originFileObj as Blob)} type={file.type} />
          Your browser does not support the video tag.
        </video>
      ),
      onOk() {},
    });
  };

  const videoprops: UploadProps = {
    name: "file",
    accept: "image/*,video/*",
    multiple: true,
    onRemove: async file => {
      const updatedUploadedFiles = uploadedFiles.filter((item: any) => item.uid !== file.uid);
      setUploadedFiles(updatedUploadedFiles);
      const updatedPostedFiles = postedFiles.filter((item: any) => item.uid !== file.uid);
      setPostedFiles(updatedPostedFiles);
      if (file.response && file.response.imageUrl) {
        try {
          console.log("File removed from API successfully.");
        } catch (error) {
          console.error("Error while removing file from API:", error);
        }
      } else {
        console.error("File response or imageUrl is undefined.");
      }
    },
    beforeUpload: async (file: any) => {
      mediaUpload(file)
        .then((res: any) => {
          const mediaObject = {
            mediaType: "mp4",
            mediaKey: res.imageUrl,
            uid: file.uid,
          };
          setErrText("");
          setUploadedFiles([...uploadedFiles, mediaObject]);
        })
        .catch(error => {
          console.log(error);
        });

      return false;
    },
    onChange(info: any) {
      if (info.file.status === "done") {
        form.setFieldsValue({ image: info?.file?.originFileObj });
      }
    },
  };

  const imageProp: UploadProps = {
    name: "file",
    accept: "image/*,video/*",
    multiple: true,
    onRemove: async file => {
      const updatedUploadedFiles = uploadedFiles.filter((item: any) => item.uid !== file.uid);
      setUploadedFiles(updatedUploadedFiles);
      const updatedPostedFiles = postedFiles.filter((item: any) => item.uid !== file.uid);
      setPostedFiles(updatedPostedFiles);
      if (file.response && file.response.imageUrl) {
        try {
          console.log("File removed from API successfully.");
        } catch (error) {
          console.error("Error while removing file from API:", error);
        }
      } else {
        console.error("File response or imageUrl is undefined.");
      }
    },
    beforeUpload: async (file: any) => {
      mediaUpload(file)
        .then((res: any) => {
          const mediaObject = {
            mediaType: "image",
            mediaKey: res.imageUrl,
            uid: file.uid,
          };
          setErrText("");
          setUploadedFiles([...uploadedFiles, mediaObject]);
        })
        .catch(error => {
          console.log(error);
        });

      return false;
    },
    onChange(info: any) {
      if (info.file.status === "done") {
        form.setFieldsValue({ image: info?.file?.originFileObj });
      }
    },
  };

  const handlePinPost = async (e: any, val: any) => {
    console.log("val", val);
    let data = JSON.stringify({
      isPinned: true
    });
    await grpPostUpdate(data, val)
      .then((res: any) => {
        console.log(res);
        groupPosts(id, pageInfoGPost);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleUnPinPost= async(e: any, val: any)=>{
    console.log("val", val);
    let data = JSON.stringify({
      isPinned: false
    });
    await grpPostUpdate(data, val)
      .then((res: any) => {
        console.log(res);
        groupPosts(id, pageInfoGPost);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  return (
    <>
      {isSendShow && (
        <SendModal
          setIsSendShow={setIsSendShow}
          isSendShow={isSendShow}
          artcileData={artcileData}
          type={type}
          setArticleData={setArticleData}
        />
      )}
      {isModalOpen && (
        <GroupComments
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          postId={postId}
          groupPosts={groupPosts}
          groupData={groupData.groupName}
          setPostID={setPostID}
          id={id}
          pageInfoGPost={pageInfoGPost}
        />
      )}
      <Layout className="h-screen">
        <Sider breakpoint="lg" collapsedWidth="0" onBreakpoint={broken => {}}>
          <div className="logo" />
          <Sidenav />
        </Sider>
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection {...props} />
        </Header>
        <Layout className="container">
          <div className="bg-white mt-16">
            <Content className="moms_like_content mt-16">
              <Card
                className="profile_group"
                style={{ width: "100%", marginBottom: "100px" }}
                cover={
                  <div style={{ height: "400px" }}>
                    <img
                      alt="Group Image"
                      src={groupData.imageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                }>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  <div className="group_profile_left">
                    <h1 style={{ flex: 1 }}>{groupData.groupName}</h1>
                    <p>
                      {groupData.focus}
                      <span>
                        <Space>
                          <Badge status="default" />
                        </Space>{" "}
                        {groupData.type}
                      </span>
                    </p>
                    <p>{groupData.membersCount} Member</p>
                  </div>
                  {groupData.memberStatus !== "active" && (
                    <Button type="primary" onClick={e => handleClickJoin(e, groupData)}>
                      Join Group
                    </Button>
                  )}
                </div>
                <Tabs onChange={handleChange} activeKey={activeTab} defaultActiveKey={"1"}>
                  {groupData.memberStatus === "active" && (
                    <Tabs.TabPane
                      tab={
                        <>
                          <h4>Group Feed</h4>
                        </>
                      }
                      key={"1"}>
                      {groupData?.groupStatus != "deaactivated" && (
                        <>
                          <Card className="mb-3" style={{ marginBottom: "10px" }}>
                            <p>Share your parenting moments</p>

                            <div className="text-area-input flex items-center mt-3 ">
                              <div className="p-5">
                                {profileData?.profile_pic ? (
                                  <img
                                    src={profileData?.profile_pic}
                                    alt=""
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      objectFit: "cover",
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <Avatar src={authInfo.parent_info?.gender_pic} />
                                )}
                              </div>
                              <div>
                                <p className="mb-2">{profileData?.full_name}</p>
                              </div>
                            </div>

                            <div className="" style={{ marginBottom: "-33px", width: "100%" }}>
                              <Form layout="vertical" form={form} onFinish={onSubmit}>
                                <Form.Item name="postDescription">
                                  <TextArea
                                    placeholder="What is on your mind ?"
                                    name="postDescription"
                                    rows={6}
                                    style={{
                                      height: "80px",
                                      border: "none",
                                      resize: "none",
                                      boxShadow: "none",
                                    }}
                                    className="text-area-placeholder"
                                    value={inputVal}
                                    onChange={handleChangeTextArea}
                                  />
                                </Form.Item>

                                <div className="flex post-control-form px-3">
                                  <Form.Item name="postMedia" valuePropName="fileList" className="flex">
                                    <div className="flex">
                                      <div style={{ marginTop: "-2%" }}>
                                        <ImgCrop>
                                          <Upload
                                            {...imageProp}
                                            onPreview={onPreview}
                                            listType="picture"
                                            // className="upload-list-inline user-post"
                                            accept="image/*,">
                                            <div>
                                              <AiOutlineCamera className="post-icon mr-3 " size={30} />
                                            </div>
                                          </Upload>
                                        </ImgCrop>
                                      </div>
                                      <div style={{ marginTop: "-2%" }}>
                                        <Upload
                                          {...videoprops}
                                          onPreview={onVideoPreview}
                                          listType="picture"
                                          // className="upload-list-inline user-post"
                                          accept="video/*">
                                          <div>
                                            <AiOutlineVideoCamera className="post-icon " size={30} />
                                          </div>
                                        </Upload>
                                      </div>
                                    </div>
                                  </Form.Item>
                                  <Form.Item>
                                    <Button
                                      htmlType="submit"
                                      className="float-right mt-1"
                                      style={{
                                        background: "rgb(255, 90, 95)",
                                        color: "#fff",
                                        borderRadius: "5px",
                                        padding: "5px 40px",
                                      }}>
                                      Post
                                    </Button>
                                  </Form.Item>
                                </div>
                              </Form>
                            </div>
                            <div className="text-center">
                              <p className="post-form-text-danger mr-3">{errText}</p>
                            </div>
                          </Card>
                        </>
                      )}
                      {postData &&
                        postData.map((list: any) => {
                          return (
                            <>
                              {list.isPinned && (
        <div className="pinned-container">
          <Image className="pinned-icon" width={15} src={PinnedIcon} />&nbsp;
          <p className="pinned-text">Pinned</p>
        </div>
      )}
                              <Card
                                key={list._id}
                                className="Liked_post_section"
                                style={{ marginBottom: "25px" }}
                                title={
                                  <>
                                    <div className="flex mb-5">
                                      <div className="mr-3">
                                        <img
                                          src={list.profilePic}
                                          alt=""
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                          }}
                                        />
                                      </div>
                                      <div style={{ flex: 1 }}>
                                        <h2 style={{ marginBottom: "0px" }}>{list.fullName}</h2>
                                        {list.createdDate != null && <span>{moment(list.createdDate).format("DD-MM-YYYY")} </span>}
                                      </div>

                                      <div style={{ flexShrink: 0 }}>
                                        {list.userId === profileData?.sk_user_id && (
                                          <div className="custom-dropdown">
                                            <Dropdown
                                              className="cursor-pointer"
                                              overlay={
                                                <Menu>
                                                  <Menu.Item onClick={e => handleDeleteClick(e, list._id)}>Delete Post</Menu.Item>
                                                  {list.isPinned == false && (
                                                    <Menu.Item onClick={e => handlePinPost(e, list._id)}>Pin Post</Menu.Item>
                                                  )}
                                                  {list.isPinned == true && (
                                                    <Menu.Item onClick={e => handleUnPinPost(e, list._id)}>Unpin Post</Menu.Item>
                                                  )}
                                                </Menu>
                                              }
                                              placement="bottomLeft"
                                              arrow>
                                              <MoreOutlined />
                                            </Dropdown>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                }>
                                <p className="mb-2">
                                  {list.postDescription !== "undefined" ? (
                                    <p className="white-space-preline" style={{ textAlign: "justify" }}>
                                      {list?.postDescription}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <Slider {...settings}>
                                  {list.postMedia.map((media_item: any, index: number) => {
                                    let srcUrl = media_item.mediaKey;
                                    if (!srcUrl.startsWith("http://") && !srcUrl.startsWith("https://")) {
                                      srcUrl = "https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=" + srcUrl;
                                    }
                                    return (
                                      <div className="grp-post-img-my" key={index}>
                                        {media_item.mediaType === "image" ||
                                        media_item.mediaType === "png" ||
                                        media_item.mediaType === "jpg" ? (
                                          <img
                                            src={srcUrl}
                                            alt=""
                                            style={{
                                              maxWidth: "100%",
                                              maxHeight: "100%",
                                            }}
                                          />
                                        ) : (
                                          (media_item.mediaType === "video" || media_item.mediaType === "mp4") && (
                                            <video src={media_item.mediaKey} controls autoPlay style={{ width: "100%", height: "100%" }} />
                                          )
                                        )}
                                      </div>
                                    );
                                  })}
                                </Slider>
                                <hr className="mt-3" />
                                <div
                                  style={{
                                    justifyContent: "space-between",
                                  }}
                                  className="mt-3 flex text-center">
                                  <div className="flex text-center cursor-pointer">
                                    {list.totalLikes !== 0 && (
                                      <span
                                        className="mt-1"
                                        style={{
                                          fontWeight: "600px",
                                          fontSize: ".9375rem",
                                        }}>
                                        {list.totalLikes !== 0 && list.totalLikes} Like
                                      </span>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "16px",
                                    }}>
                                    {list.totalComments !== 0 && (
                                      <span
                                        className="mt-1"
                                        style={{
                                          fontWeight: "600px",
                                          fontSize: ".9375rem",
                                          cursor: "pointer",
                                        }}>
                                        {list.totalComments !== 0 && list.totalComments} Comment
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="flex text-center">
                                  <div className="mt-3 flex text-center">
                                    <div className="flex text-center cursor-pointer" onClick={() => handleClickLike(list._id)}>
                                      {list.likeStatus == 1 ? (
                                        <span className="mt-1 mr-8">
                                          <LikeFilled style={{ fontSize: "21px" }} />
                                        </span>
                                      ) : (
                                        <span className="mt-1 mr-8">
                                          <LikeOutlined style={{ fontSize: "21px" }} className="mt-1" />
                                        </span>
                                      )}
                                    </div>
                                    <div className="flex text-center">
                                      <span className="mt-2  mr-8">
                                        <img src={CommentImg} className="cursor-pointer" onClick={e => showModalComment(e, list)} />
                                      </span>

                                      <span className="mt-2  mr-8">
                                        <img src={Send} className="cursor-pointer" onClick={() => handleSendBtn(list)} />
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex text-center"
                                    style={{
                                      marginLeft: "auto",
                                    }}>
                                    <div className="flex text-center">
                                      <span className="mr-8 mt-8">
                                        {list.totalSavedPosts == 0 && (
                                          <img src={Save} className="cursor-pointer" onClick={e => hanldeSavePost(e, list)} />
                                        )}

                                        {list.totalSavedPosts == 1 && (
                                          <img src={SaveImage} className="cursor-pointer" onClick={e => hanldeSavePost(e, list)} />
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </>
                          );
                        })}

                      {postData.length !== 0 && (
                        <div style={{ float: "right" }}>
                          <br />
                          <Pagination defaultCurrent={1} total={totalPagesGPost} onChange={onShowSizeChange} />
                        </div>
                      )}
                    </Tabs.TabPane>
                  )}

                  <Tabs.TabPane
                    tab={
                      <>
                        <h4>About This Group</h4>
                      </>
                    }
                    key={"2"}>
                    <div>
                      <p>{groupData.description}</p>
                    </div>
                  </Tabs.TabPane>

                  {groupData.memberStatus === "active" && (
                    <Tabs.TabPane
                      tab={
                        <>
                          <h4>Rules</h4>
                        </>
                      }
                      key={"3"}>
                      <div>
                        <p>{groupData.groupRule}</p>
                      </div>
                    </Tabs.TabPane>
                  )}
                  {groupData.memberStatus === "active" && (
                    <Tabs.TabPane
                      tab={
                        <>
                          <h4>Settings</h4>
                        </>
                      }
                      key={"4"}>
                      <div className="group_action">
                        <ol>
                          {groupData.isMuted !== true ? <li onClick={handleMute}>Mute Group</li> : <li onClick={handleUnMute}>Unmute</li>}

                          <li onClick={reportModal}>Report Group</li>

                          {groupData.groupStatus === "deactivated" && (
                            <li>
                              <button className="grp-btn" onClick={e => handleActivate(e, "activated")}>
                                Activate
                              </button>
                            </li>
                          )}
                          {groupData.groupStatus === "activated" && (
                            <li>
                              <button className="grp-btn" onClick={e => handleDeactivate(e, "deactivated")}>
                                Deactivate
                              </button>
                            </li>
                          )}

                          {groupData?.createdBy === profileData?.sk_user_id && (
                            <li>
                              <button
                                className="grp-btn"
                                onClick={e => {
                                  handleClickEditGroup(e, groupData);
                                }}>
                                Group Settings
                              </button>
                              <button>{groupData.userId}</button>
                            </li>
                          )}

                          {groupData?.createdBy === profileData?.sk_user_id && (
                            <li>
                              <button className="grp-btn" onClick={e => handleDeleteGroup(e, groupData)}>
                                Delete Group
                              </button>
                            </li>
                          )}

                          {groupData?.isAdmin === false && (
                            <li style={{ color: "red" }}>
                              <button className="grp-btn" onClick={showModal}>
                                Leave Group
                              </button>
                            </li>
                          )}
                        </ol>
                      </div>
                      <Modal
                        title="Report Group"
                        open={openReportModal}
                        okButtonProps={{
                          children: "Custom OK",
                        }}
                        onCancel={handleReport}
                        okText="Report"
                        onOk={handleReportSubmit}>
                        <p>Please select a reason</p>
                        <Select
                          style={{ width: "100%" }}
                          className="mt-5"
                          placeholder="Select a reason"
                          onChange={handleChangeReport}
                          value={selectedReason}>
                          <Select.Option value="Spreading Hate Speech">Spreading Hate Speech</Select.Option>
                          <Select.Option value="Fake Group">Fake Group</Select.Option>
                          <Select.Option value="Fake name">Fake name</Select.Option>
                          <Select.Option value="Posting inappropriate things">Posting inappropriate things</Select.Option>
                          <Select.Option value="Harassment or bullying">Harassment or bullying</Select.Option>
                          <Select.Option value="Nudity or sexual activity">Nudity or sexual activity</Select.Option>
                        </Select>
                      </Modal>

                      <Modal
                        title="Leave the group"
                        open={open}
                        okButtonProps={{
                          children: "Custom OK",
                        }}
                        onCancel={handleCancel}
                        okText="Confirm"
                        onOk={handleOk}>
                        <Form>
                          <p>Are You sure you want to leave this group?</p>
                        </Form>
                      </Modal>
                    </Tabs.TabPane>
                  )}

                  {groupData.memberStatus === "active" && (
                    <Tabs.TabPane
                      tab={
                        <>
                          <h4>Group Members</h4>
                        </>
                      }
                      key={"5"}>
                      <div>
                        <p>Admins and Moderators</p>
                        {groupMemberListData?.map((val: any, key: number) => {
                          return (
                            <Fragment key={key}>
                              {val.isAdmin === true && (
                                <>
                                  <div
                                    className="mt-3"
                                    key={key}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                    }}>
                                    <Card
                                      className="Card-moms"
                                      style={{ width: 250 }}
                                      cover={
                                        <img
                                          alt={val.full_name}
                                          src={val.profile_pic}
                                          className="image_logo object-center mt-4"
                                          style={{
                                            borderRadius: "50%",
                                          }}
                                        />
                                      }>
                                      <Link to={"/momslikelistprofile/" + val.userId}>
                                        <Meta title={val.full_name} description={val.stage} />
                                      </Link>
                                      <div className="text-center pt-2">
                                        <p>Mutual {val.mutualConnection}</p>
                                      </div>

                                      <div className="p-tag flex items-center">
                                        <p className=""></p>
                                        <div></div>
                                      </div>
                                    </Card>
                                  </div>
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                        <hr className="mt-5" />
                        <p className="mt-3">Members</p>
                        <div className="mt-3" style={{ display: "flex", flexWrap: "wrap" }}>
                          {groupMemberListData?.map((mem: any, ind: number) => {
                            return (
                              <>
                                {mem.isAdmin === false && (
                                  <div
                                    key={ind}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "30px",
                                      marginBottom: "10px",
                                    }}>
                                    <Card
                                      className="Card-moms"
                                      style={{ width: 250 }}
                                      cover={
                                        <img
                                          alt={mem.full_name}
                                          src={mem.profile_pic}
                                          className="image_logo object-center mt-4"
                                          style={{
                                            borderRadius: "50%",
                                          }}
                                        />
                                      }>
                                      <Link to={"/momslikelistprofile/" + mem.userId}>
                                        <Meta title={mem.full_name} description={mem.stage} />
                                      </Link>
                                      <div className="text-center pt-2">
                                        <p>Mutual {mem.mutualConnection}</p>
                                      </div>

                                      <div className="p-tag flex items-center">
                                        <p className="">
                                          <Connect {...mem} class={like_stylus} getConnectionList={refetch} />
                                        </p>
                                        <div>
                                          <Button
                                            className="follow_section"
                                            style={{
                                              backgroundColor: "#ED4D41",
                                              border: "2px",
                                              borderRadius: "0.375rem",
                                              width: "6rem",
                                              borderWidth: "2px",
                                              paddingTop: "0.25rem",
                                              paddingBottom: "0.25rem",
                                            }}>
                                            <Followbutton {...mem} class={like_stylus} getConnectionList={refetch} />
                                          </Button>
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                      <div style={{ float: "right" }}>
                        <br />
                        <Pagination defaultCurrent={1} total={totalPages} onChange={onShowSizeChange} />
                      </div>
                    </Tabs.TabPane>
                  )}
                </Tabs>
              </Card>
            </Content>
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default GroupProfile;
