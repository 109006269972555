import {
  BellOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Menu, Popover,  Tooltip, Drawer, message } from "antd";
import React, { useState, useEffect,} from "react";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import Logo from "../../../images/logo.svg";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { AiOutlineInbox } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CgMenuLeft } from "react-icons/cg";
import Searchsection from "components/global/Searchsection";
import { useDispatch } from "react-redux";
import { getFeed } from "actions/getfeed";
import { environmentConfig } from "config";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import { userDelete } from "../../../apis/ViewProfile";
import Swal from "sweetalert2";
import { getNotifications } from "../../../apis/btpNotify";
import moment from "moment";
import { getChatRoom } from "apis/btpFallowUnfallow";

const Headersection = () => {
  const authInfo = useSelector((state: TRootState) => state.auth);
  const dispatch = useDispatch();
  const { isLoading, data } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: true }));
  const profileData = data && data;
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const handleLogout = async () => {
    await localStorage.clear();
    window.location.replace(environmentConfig.BLOG_URL + "/signin-api/");
  };
  const [chatRooms, setChatRooms] = useState<any>({});
  let val = chatRooms.unreadMessages?.reduce((total: any, message: any) => total + message.count, 0);

  const getChat = () => {
    getChatRoom()
      .then(res => {
        setChatRooms(res);
      })
      .catch(err => {
        message.error(err);
      });
  };

  useEffect(() => {
    getChat();
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (authInfo.Accesstoken !== "" && authInfo.Accesstoken !== null) dispatch(getFeed(authInfo.Accesstoken));
  }, [authInfo.Accesstoken]);

  const [notoficationData, setNotificationData] = useState<any>([]);
  useEffect(() => {
    const requestData = {
      start_date: "2020-10-13",
      end_date: moment().format("YYYY-MM-DD"),
    };
    getNotifications(requestData)
      .then(res => {
        setNotificationData(res?.notifications);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const handleClickDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        userDelete("")
          .then(async res => {
            if (res.message === "User deleted successfully") {
              await localStorage.clear();
              window.location.replace(environmentConfig.BLOG_URL + "/signin-api/");
            }
          })
          .catch(err => {
            console.log(err);
          });
        Swal.fire("Deleted!", "Your account has been deleted.", "success");
      }
    });
  };

  const readmore = (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
        }}>
        <div>
          <ul>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/planning-for-a-baby/preconception-health/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Preconception Health
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/planning-for-a-baby/preconception-diet/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Diet
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/planning-for-a-baby/preconception-ovulation/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Ovulation
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/planning-for-a-baby/preparing-for-the-pregnancy/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Preparing for Pregnancy
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/planning-for-a-baby/finance/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Finances
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/assisted-pregnancy/" target="_blank" className="text-black !hover:text-red-500">
                Assisted Pregnancy
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/planning-for-a-baby/about-adoption/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Adoption
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/second-pregnancy/" target="_blank" className="text-black !hover:text-red-500">
                Second Pregnancy
              </a>
            </li>
          </ul>
        </div>

        <div className="ml-5">
          <ul>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/already-pregnant/pregnancy-week-by-week/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Pregnancy Week by Week
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/already-pregnant/health-and-safety/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Health & Safety
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/already-pregnant/live-life-better/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Live life better
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/already-pregnant/preparing-for-the-baby/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Preparing for the baby
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/already-pregnant/your-body-pregnancy/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Your Body
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/already-pregnant/pregnancy-diet-fitness/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Pregnancy Diet & Fitness
              </a>
            </li>
          </ul>
        </div>

        <div className="ml-5">
          <ul>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-baby/baby-milestones/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Baby Milestones
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/have-a-baby/baby-health/" target="_blank" className="text-black !hover:text-red-500">
                Health
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/have-a-baby/baby-feeding/" target="_blank" className="text-black !hover:text-red-500">
                Feeding
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/have-a-baby/baby-basic/" target="_blank" className="text-black !hover:text-red-500">
                Baby Basics
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/have-a-baby/mother-care/" target="_blank" className="text-black !hover:text-red-500">
                Mother Care
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-baby/activity-fun-with-babies/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Activity & Fun
              </a>
            </li>
          </ul>
        </div>

        <div className="ml-5">
          <ul>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/toddler-milestones/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Toddler Mile Stones
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/toddler-basic/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Toddler Basics
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/have-a-toddler/learning/" target="_blank" className="text-black !hover:text-red-500">
                Learning
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/food-feeding/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Food & Feeding
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/child-safety-and-health/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Child Safety & Health
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/games-playtime/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Games & Playtime
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/special-children/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Special Child
              </a>
            </li>
          </ul>
        </div>

        <div className="ml-5">
          <ul>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/health-hygiene/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Health & Hyginie
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/learning-development/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Learning and Development
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/family-basic/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Family Basics
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/have-a-toddler/child-safety-and-health/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Child Safety
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/special-children-care/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Special child Care
              </a>
            </li>

            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/teens/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Teens
              </a>
            </li>

            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/planning-for-the-future/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Planning for Future
              </a>
            </li>
            <li className="mb-2">
              <a
                href="https://www.beingtheparent.com/all-about-positive-parenting/fun-and-activity/"
                target="_blank"
                className="text-black !hover:text-red-500">
                Play, Games, activity & Fun
              </a>
            </li>
          </ul>
        </div>

        <div className="ml-5" style={{ paddingRight: "10px" }}>
          <ul>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/child-safety/" target="_blank" className="text-black !hover:text-red-500">
                Child Safety
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/cord-blood/" target="_blank" className="text-black !hover:text-red-500">
                Cord Blood
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/life-skills/" target="_blank" className="text-black !hover:text-red-500">
                Life Skills
              </a>
            </li>
            <li className="mb-2">
              <a href="https://www.beingtheparent.com/premature-baby/" target="_blank" className="text-black !hover:text-red-500">
                Premature Baby
              </a>
            </li>

            <li className="mb-2">
              <a href="https://www.beingtheparent.com/twins/" target="_blank" className="text-black !hover:text-red-500">
                Twins
              </a>
            </li>

            <li className="mb-2">
              <a href="https://www.beingtheparent.com/baby-products/" target="_blank" className="text-black !hover:text-red-500">
                Baby Products
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );

  const text1 = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        textAlign: "center",
      }}
      className="read-more-header">
      <p className="ml-5" style={{ float: "left" }}>
        <a href="https://www.beingtheparent.com/planning-for-a-baby/" target="_blank">
          Planning for a baby?
        </a>
      </p>
      <p className="ml-5" style={{ float: "left" }}>
        <a href="https://www.beingtheparent.com/already-pregnant/" target="_blank">
          Already Pregnant?
        </a>
      </p>
      <p className="ml-5" style={{ float: "left" }}>
        <a href="https://www.beingtheparent.com/have-a-baby/" target="_blank">
          Have a Baby?
        </a>
      </p>
      <p className="ml-5" style={{ float: "left" }}>
        <a href="https://www.beingtheparent.com/have-a-toddler/" target="_blank">
          Have a Toddler?
        </a>
      </p>
      <p className="ml-5" style={{ float: "left" }}>
        <a href="https://www.beingtheparent.com/all-about-positive-parenting/" target="_blank">
          Positive Parenting
        </a>
      </p>
      <p className="ml-5" style={{ float: "left" }}>
        <a href="https://www.beingtheparent.com/blog/" target="_blank">
          Baby Names
        </a>
      </p>
    </div>
  );
  const content1 = (
    <div>
      <Link className="mb-4" to="/viewprofile">
        {" "}
        <Menu.Item>View</Menu.Item>
      </Link>
      <Link className="mb-4" to={{ pathname: "/editprofile/edit" }}>
        {" "}
        <Menu.Item>Edit</Menu.Item>
      </Link>
      <Link className="mb-4" to={{ pathname: "/editprofile/profilePhoto" }}>
        {" "}
        <Menu.Item>Profile Photo</Menu.Item>
      </Link>
      <Link className="mb-4" to={{ pathname: "/editprofile/coverPhoto" }}>
        <Menu.Item>Cover Photo</Menu.Item>
      </Link>
    </div>
  );
  const content2 = (
    <div>
      <Link to={{ pathname: "/accountsettings", hash: "#LoginInformation" }}>
        <Menu.Item>Login Information</Menu.Item>
      </Link>
      <Link to={{ pathname: "/accountsettings", hash: "#EmailPreferences" }}>
        <Menu.Item>Email Preferences</Menu.Item>
      </Link>
      <Link to={{ pathname: "/accountsettings", hash: "#Privacy" }}>
        <Menu.Item>Privacy</Menu.Item>
      </Link>
      <Link to={{ pathname: "/accountsettings", hash: "#ExportData" }}>
        <Menu.Item>Export Data</Menu.Item>
      </Link>
      <Link to={{ pathname: "/accountsettings", hash: "#DeleteAccount" }}>
        {" "}
        <Menu.Item>Delete Account</Menu.Item>
      </Link>
    </div>
  );
  const MomsLikeMe = (
    <div>
      <Link className="mb-4" to="/momslikeyou">
        {" "}
        <Menu.Item>momslikeyou</Menu.Item>
      </Link>
    </div>
  );
  const content3 = (
    <div>
      <Link to={{ pathname: "/viewprofile/timeline" }}>
        <Menu.Item>Posts</Menu.Item>
      </Link>
    </div>
  );
  const content4 = (
    <div>
      <Link to={{ pathname: "/notification", hash: "#Unread" }}>
        <Menu.Item>Unread</Menu.Item>
      </Link>
      <Link to={{ pathname: "/notification", hash: "#Read" }}>
        <Menu.Item>Read</Menu.Item>
      </Link>
    </div>
  );
  // const content5 = (
  //   <div>
  //     <Menu.Item onClick={() => history.push("/sendmessage")}>Messages</Menu.Item>
  //     <Menu.Item onClick={() => history.push("/sendmessage")}>New Message</Menu.Item>
  //   </div>
  // );
  const content6 = (
    <div>
      <Link to={{ pathname: "/viewprofile/my_connections" }}>
        <Menu.Item>My Connections</Menu.Item>
      </Link>
      <Link to={{ pathname: "/viewprofile/my_connections/pending_requests" }}>
        <Menu.Item>Pending Requests</Menu.Item>
      </Link>
    </div>
  );

  const content7 = (
    <div>
      <Link to={{ pathname: "/groups" }}>
        <Menu.Item>My Groups</Menu.Item>
      </Link>
      {/* <Link to={{pathname: "/viewprofile/groups/pending_invites"}}><Menu.Item>No Pending Invites</Menu.Item></Link> */}
      {/* <Link to={{pathname: "/viewprofile/groups/create_group"}}><Menu.Item>Create Group</Menu.Item></Link> */}
    </div>
  );
  const content8 = (
    <div>
      <Link to={{ pathname: "/viewprofile/my_Discusssions" }}>
        {" "}
        <Menu.Item>My Discusssions</Menu.Item>
      </Link>
      <Link to={{ pathname: "/viewprofile/my_Discusssions/my_replies" }}>
        {" "}
        <Menu.Item>My Replies</Menu.Item>
      </Link>
      <Link to={{ pathname: "/viewprofile/my_Discusssions/my_favorites" }}>
        {" "}
        <Menu.Item>My Favorites</Menu.Item>
      </Link>
      <Link to={{ pathname: "/viewprofile/my_Discusssions/subscriptions" }}>
        {" "}
        <Menu.Item>Subscriptions</Menu.Item>
      </Link>
    </div>
  );
  const content9 = (
    <div>
      <Link to={{ pathname: "/viewprofile/my_photos" }}>
        {" "}
        <Menu.Item>My Photos</Menu.Item>
      </Link>
      <Link to={{ pathname: "/viewprofile/my_photos/albums" }}>
        {" "}
        <Menu.Item>My Albums</Menu.Item>
      </Link>
    </div>
  );
  const content10 = (
    <div>
      <Menu.Item>Send Invites</Menu.Item>
      <Menu.Item>Sent Invites</Menu.Item>
    </div>
  );
  if (isLoading || !profileData) {
    return null;
  }

  const content = (
    <div>
      <p>Content</p>
      <p>Content</p>
    </div>
  );
  const buttonWidth = 70;

  return (
    <div>
      {/* mobile version */}

      <div className="sm-menu">
        <div className="flex justify-between mt-3">
          <div className="flex items-center">
            <div className="mr-5" onClick={showDrawer}>
              <CgMenuLeft size={30} />
            </div>
            <div>
              <Link to="/">
                <img src={Logo} />
              </Link>
            </div>
          </div>
          <div className="flex items-center">
            <div className="mr-6">
              <Tooltip placement="bottom" title="Search" className="cursor-pointer">
                <FiSearch style={{ fontSize: "21px" }} />
              </Tooltip>
            </div>
            <div className="mr-6">
              <Tooltip placement="bottom" title="Messaging" className="cursor-pointer">
                <AiOutlineInbox style={{ fontSize: "21px" }} />
              </Tooltip>
            </div>
            <div className="mr-6">
              <Tooltip placement="bottom" title="Notification" className="cursor-pointer">
                <Badge size="small" count={5}>
                  <Link to="/notification">
                    <BellOutlined style={{ fontSize: "21px", color: "black" }} />
                  </Link>
                </Badge>
              </Tooltip>
            </div>
          </div>
        </div>
        <Drawer
        className="mt-7"
          title={
            <>
              <div className="flex">
                <div className="mr-4">
                  {profileData.profile_pic ? (
                    <img
                      src={profileData.profile_pic}
                      alt=""
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <Avatar shape="circle" size={50} />
                  )}
                </div>

                <div>
                  <h1 className="mb-0">{profileData && profileData.full_name}</h1>
                  <p>My Account</p>
                </div>
              </div>
            </>
          }
          placement="left"
          onClose={onClose}
          open={open}>
          {/* <Collapse
            className="panel_close "
            onChange={onChange}
            expandIconPosition="right"
          >
            <Panel
              header={
                <>
                  <Link to={""} className=" name6">
                    Read
                  </Link>
                </>
              }
              key="1"
              className="name6"
            >
              {readmore}
            </Panel>
          </Collapse> */}

          <div className="mb-6">
            <a href="https://www.beingtheparent.com/mom-cafe/" target="_blank" className="name6">
              Mom’s Cafe
            </a>
          </div>
          <div className="mb-6">
            <a href="https://thekidscircle.com/" target="_blank" className="name6">
              Shop
            </a>
          </div>
          <div className="mb-6">
            <a href="https://thekidslocal.com/" target="_blank" className="name6">
              Local
            </a>
          </div>
          <div className="mb-6">
            <Link to="/groups" className="name6">
              Groups
            </Link>
          </div>
          <Button className="mb-6 pink_button" onClick={handleLogout}>
            Logout
          </Button>
        </Drawer>
      </div>
      {/* mobile version END */}

      {/* Desktop version*/}

      <div className=" container lg-menu px-7">
        <div className="flex justify-between items-center ">
          <div>
            <div className="flex items-center">
              <div className="logo cursor-pointer mr-10">
                <Link to="/">
                  <img src={Logo} />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex items-center mr-4">
              <p>
                <div
                  style={{
                    marginLeft: buttonWidth,
                    clear: "both",
                    whiteSpace: "nowrap",
                  }}>
                  <Popover placement="bottom" title={text1} content={readmore}>
                    <p className="cursor-pointer">Read More</p>
                  </Popover>
                </div>
              </p>
            </div>

            <div className="flex items-center mr-4">
              <a href="https://www.beingtheparent.com/mom-cafe/" target="_blank">
                <p>Mom's Cafe</p>
              </a>
            </div>
            <div className="flex items-center mr-4">
              <a href="https://thekidscircle.com/" target="_blank">
                <p>Shop</p>
              </a>
            </div>
            <div className="flex items-center mr-4">
              <a href="https://thekidslocal.com/" target="_blank">
                <p>Local</p>
              </a>
            </div>
            <div className="flex items-center mr-4">
              <Link to="/groups">
                <p>Groups</p>
              </Link>
            </div>

            <div className="mr-6">
              <Popover
                content={
                  <>
                    <Menu
                      className="w-full overflow-y-scroll !max-w-lg header_main_menu"
                      // style={{ height: "200px" }}
                    >
                      <Popover content={content1} placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Profile" />
                      </Popover>

                      {/* <Link className="mb-4" to="/momslikeyou"> <Menu.Item >Moms Like You</Menu.Item></Link> */}

                      {/* Comment line */}

                      {/* <Popover
                        content={content7}
                        placement="leftTop"
                        trigger="hover"
                      >
                        <Menu.SubMenu title="Groups" />
                      </Popover> */}

                      {/* to-do */}
                      {/* <Popover content={content2}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Account"  />
                      </Popover>
                      <Popover content={content3}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Timeline"  />
                      </Popover>
                      <Popover content={content4}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Notifications"  />
                      </Popover> */}
                      {/* <Popover  placement="leftTop" trigger="hover"> */}
                      {/* <Menu.SubMenu onTitleClick={() => history.push("/messages")} title="Messages"  /> */}
                      {/* </Popover> */}
                      {/* <Popover content={content6}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Connections"  />
                      </Popover>
                      <Popover content={content7}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Groups"  />
                      </Popover>
                      <Popover content={content8}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Forums"  />
                      </Popover>
                      <Popover content={content9}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Photos"  />
                      </Popover>
                      <Popover content={content10}  placement="leftTop" trigger="hover">
                        <Menu.SubMenu title="Email Invites"  />
                      </Popover>
                      <Link to={{pathname: "/savedpost"}}>
                      <Menu.SubMenu title="Saved post"  />
                      </Link> */}
                      {/* <Menu.SubMenu title="Notifications">
                      <Link to={{pathname: "/notification", hash: "#Unread"}}><Menu.Item>Unread</Menu.Item></Link>
                       <Link to={{pathname: "/notification", hash: "#Read"}}><Menu.Item>Read</Menu.Item></Link>
                      </Menu.SubMenu> */}
                      {/* <Menu.SubMenu title="Messages">
                        <Menu.Item onClick={() => history.push("/sendmessage")}>Messages</Menu.Item>
                        <Menu.Item onClick={() => history.push("/sendmessage")}>New Message</Menu.Item>
                      </Menu.SubMenu> */}
                      {/* <Menu.SubMenu title="Connections">
                         <Link to={{pathname: "/viewprofile/my_connections"}}><Menu.Item>My Connections</Menu.Item></Link>
                         <Link to={{pathname: "/viewprofile/my_connections/pending_requests"}}><Menu.Item>Pending Requests</Menu.Item></Link>
                      </Menu.SubMenu> */}
                      {/* <Menu.SubMenu title="Groups">
                      <Link to={{pathname: "/viewprofile/groups"}}><Menu.Item>My Groups</Menu.Item></Link>
                      <Link to={{pathname: "/viewprofile/groups/pending_invites"}}><Menu.Item>No Pending Invites</Menu.Item></Link>
                      <Link to={{pathname: "/viewprofile/groups/create_group"}}><Menu.Item>Create Group</Menu.Item></Link>
                      </Menu.SubMenu> */}
                      {/* <Menu.SubMenu title="Forums">
                      <Link to={{pathname: "/viewprofile/my_Discusssions"}}> <Menu.Item>My Discusssions</Menu.Item></Link>
                      <Link to={{pathname: "/viewprofile/my_Discusssions/my_replies"}}>  <Menu.Item>My Replies</Menu.Item></Link>
                      <Link to={{pathname: "/viewprofile/my_Discusssions/my_favorites"}}>   <Menu.Item>My Favorites</Menu.Item></Link>
                      <Link to={{pathname: "/viewprofile/my_Discusssions/subscriptions"}}>  <Menu.Item>Subscriptions</Menu.Item></Link>
                      </Menu.SubMenu> */}
                      {/* <Menu.SubMenu title="Photos">
                        <Link to={{pathname: "/viewprofile/my_photos"}}> <Menu.Item>My Photos</Menu.Item></Link>
                        <Link to={{pathname: "/viewprofile/my_photos/albums"}}> <Menu.Item>My Albums</Menu.Item></Link>
                      </Menu.SubMenu> */}
                      {/* <Menu.SubMenu title="Email Invites">
                        <Menu.Item>Send Invites</Menu.Item>
                        <Menu.Item>Sent Invites</Menu.Item>
                      </Menu.SubMenu> */}
                      <Menu.Item>
                        <Link to="/save-items/">Saved Items</Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to="/change-password">Password</Link>
                      </Menu.Item>

                      <Menu.Item onClick={handleClickDelete}>Delete Account</Menu.Item>

                      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
                    </Menu>
                  </>
                }
                placement="bottom"
                title={
                  <>
                    <div className="flex items-center">
                      <div className="mr-2">
                        {profileData.profile_pic ? (
                          <img
                            src={profileData.profile_pic}
                            alt=""
                            style={{
                              width: "30px",
                              height: "30px",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                          />
                        ) : (
                          <Avatar src={authInfo.parent_info?.gender_pic} />
                        )}
                      </div>
                      <div>
                        <p className="mb-0">{profileData.full_name}</p>
                        {profileData.username_momnexus != null || "" ? (
                          <p className="gray_color">@{profileData.username_momnexus}</p>
                        ) : null}
                      </div>
                    </div>
                  </>
                }
                trigger="click"
                open={visible}
                onOpenChange={handleVisibleChange}>
                <div className="flex items-center cursor-pointer ">
                  <div className="mr-0">
                    <p className="name">{profileData.full_name}</p>
                  </div>
                  <div>
                    <MdKeyboardArrowDown style={{ fontSize: "20px" }} />
                  </div>
                </div>
              </Popover>
            </div>

            <div className="mr-6 flex flex-row items-center justify-between gap-4">
              {profileData.profile_pic ? (
                <img
                  src={profileData.profile_pic}
                  alt=""
                  style={{
                    width: "30px",
                    height: "30px",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <Avatar src={authInfo.parent_info?.gender_pic} />
              )}
              <div className="head-profile-pic"></div>
            </div>

            <div className="mr-6">
              <Badge size="small">
                <Searchsection />
              </Badge>
            </div>

            {/* {chatRooms?.usersData?.length !== 0 ? (
              <div className="mr-6">
                <Tooltip placement="bottom" title="Messaging" className="cursor-pointer">
                  <Link to="/messages">
                    <Badge size="small" count={val && val}>
                      <AiOutlineInbox style={{ fontSize: "21px", color: "black" }} />
                    </Badge>
                  </Link>
                </Tooltip>
              </div>
            ) : null} */}

            <div className="mr-6">
              <Tooltip placement="bottom" title="Notification" className="cursor-pointer">
                <Link to="/notification">
                  <Badge size="small" count={notoficationData.length}>
                    <BellOutlined style={{ fontSize: "21px", color: "black" }} />
                  </Badge>
                </Link>
              </Tooltip>
            </div>

          </div>
        </div>
      </div>
      {/* Desktop version END*/}
    </div>
  );
};

export default Headersection;
