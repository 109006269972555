import {
    Card, Carousel, Skeleton, Tabs, Input, Collapse, Button, Modal, Badge,
    DatePicker, Space,
    Form
} from "antd";
import React, { useState, useEffect } from 'react';
// import Calendar from 'react-calendar';
import Calendar, { TileDisabledFunc } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getBannerApi, getLikesapi, getListsApi, getFeedsApi, postCalendar, TCalendarData } from "apis/GetFeeds"
// import BarChart from 'react-bar-chart';
// import { Bar } from 'react-chartjs-2';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line } from 'recharts';


// const CustomBar = (props: any) => {
//     const { x, y, width, height, fill } = props;
//     const borderRadius = 23;

//     return (
//         <g>
//             <path d={`M${x},${y + borderRadius} 
//                   L${x},${y + height} 
//                   L${x + width},${y + height} 
//                   L${x + width},${y + borderRadius} 
//                   Q${x + width},${y} ${x + width - borderRadius},${y} 
//                   L${x + borderRadius},${y} 
//                   Q${x},${y} ${x},${y + borderRadius}`}
//                 fill={fill} />
//         </g>
//     );
// };

// const CustomBar = (props: any) => {
//     const { x, y, width, height, fill } = props;
//     const borderRadius = 23;
//     const adjustedY = props.reversed ? y - height : y; // Adjust y coordinate for reversed y-axis
  
//     return (
//       <g>
//         <path
//           d={`M${x},${adjustedY + borderRadius} 
//               L${x},${adjustedY + height} 
//               L${x + width},${adjustedY + height} 
//               L${x + width},${adjustedY + borderRadius} 
//               Q${x + width},${adjustedY} ${x + width - borderRadius},${adjustedY} 
//               L${x + borderRadius},${adjustedY} 
//               Q${x},${adjustedY} ${x},${adjustedY + borderRadius}`}
//           fill={fill}
//         />
//       </g>
//     );
//   };


// const CustomBar = (props: any) => {
//     const { x, y, width, height, fill } = props;
//     const borderRadius = 23;
//     const adjustedY = props.reversed ? y : y + height; // Adjust y coordinate for reversed y-axis
  
//     return (
//       <g>
//         <path
//           d={`M${x},${adjustedY - borderRadius} 
//               L${x},${adjustedY - height} 
//               L${x + width},${adjustedY - height} 
//               L${x + width},${adjustedY - borderRadius} 
//               Q${x + width},${adjustedY} ${x + width - borderRadius},${adjustedY} 
//               L${x + borderRadius},${adjustedY} 
//               Q${x},${adjustedY} ${x},${adjustedY - borderRadius}`}
//           fill={fill}
//         />
//       </g>
//     );
//   };


// const CustomBar = (props: any) => {
//     const { x, y, width, height, fill } = props;
//     const borderRadius = 23;
  
//     return (
//       <g>
//         <path
//           d={`M${x},${y} 
//               L${x},${y + height} 
//               L${x + width},${y + height} 
//               L${x + width},${y} 
//               Q${x + width},${y} ${x + width - borderRadius},${y} 
//               L${x + borderRadius},${y} 
//               Q${x},${y} ${x},${y}`}
//           fill={fill}
//         />
//       </g>
//     );
//   };


const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const borderRadius =7;
  
    return (
      <g>
        {/* Draw the bar with rounded top using <rect> */}
        <rect x={x} y={y} width={width} height={height} rx={borderRadius} ry={borderRadius} fill={fill} />
      </g>
    );
  };
  
  
  
  

interface ModalProps {
    data: {
        window: {
            title: string,
            fertility: [
                data: [
                    {
                        date: number,
                        day: string,
                        value: number
                    }
                ],
                month: string
            ]
        }

    }
    openFertileModal: boolean;
    setOpenFertileModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FertilityData {
    month: string;
    data: [
        date: number,
        day: string,
        value: string
    ]
    // Add other properties of the data object if needed
}



// openFertileModal,setOpenFertileModal
const Fertile = ({ openFertileModal, setOpenFertileModal, data }: ModalProps) => {
    console.log(data && data);
    // console.log(data && data.window);
    // console.log(data && data.window.title);




    const showModal = () => {
        setOpenFertileModal(true);
    };

    const handleOk = () => {
        setOpenFertileModal(false);
    };

    const handleCancel = () => {
        setOpenFertileModal(false);
    };


    const [selectedButton, setSelectedButton] = useState(0);
    const selectedDataIndex = data.window.fertility.findIndex((val, i) => i === selectedButton);
    // const selectedData = selectedDataIndex !== -1 ? data.window.fertility[selectedDataIndex] : null;

    const selectedData = selectedDataIndex !== -1 ? data.window.fertility[selectedDataIndex] : null;


    console.log(selectedData);

    function isFertilityData(obj: any): obj is FertilityData {
        return obj && typeof obj === 'object' && 'month' in obj;
    }
    let globalData: { name: string; value: number }[] = [];
    if (selectedData !== null && isFertilityData(selectedData)) {
        console.log(selectedData.month);
        console.log(selectedData.data);
        selectedData.data.map((val: any) => {
            console.log(val.date + val.day.charAt(0));
            console.log(val.value);
            globalData.push({
                name: val.date + val.day.charAt(0),
                value: val.value,
            });

            console.log(globalData);


        })
    }


    const handleButtonClick = (e: any) => {
        setSelectedButton(e);
    };

    // category==date
    //  value==value

    const data1 = [
        { name: 'Category A', value: 30 },
        { name: 'Category B', value: 45 },
        { name: 'Category C', value: 20 },
        { name: 'Category D', value: 60 },
        { name: 'Category E', value: 35 },
    ];









    return (
        <>
            <Button type="primary" onClick={showModal}>
                Open Modal
            </Button>
            <Modal title="Set Up Tracker" open={openFertileModal} onOk={handleOk} onCancel={
                handleCancel}
                footer={null}
            >
                <div className="react_calendar_card" style={{
                    backgroundColor: 'rgb(255, 244, 215)',

                }}>
                    <div style={{
                        padding: '10px',
                    }}>
                        <h6>{data && data.window.title}</h6>
                    </div>
                    <Form>
                        <div className="react_calendar_card_in">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {data &&
                                    data.window.fertility.map((val: any, i: number) => (
                                        <div key={i} style={{ display: 'flex', justifyContent: 'left', margin: '5px' }}>
                                            <button
                                                onClick={() => handleButtonClick(i)}
                                                style={{
                                                    padding: '10px',
                                                    borderRadius: '23px',
                                                    backgroundColor: selectedButton === i ? '#F0F0F0' : 'transparent',
                                                    color: selectedButton === i ? 'black' : 'black',
                                                }}
                                            >
                                                {val.month}
                                            </button>
                                        </div>
                                    ))}
                            </div>
     
                            {selectedData && isFertilityData(selectedData) && (
                                <div style={{ margin: '20px' }}>
                                    <>
                                        {/* <h2>Bar Chart 1</h2> */}
                                        <style>{`.recharts-yAxis .recharts-cartesian-grid line { display: none; }`}</style>
                                        <BarChart width={400} height={300} data={globalData} >
                                            <CartesianGrid strokeDasharray="0 0" vertical={false} />
                                            <XAxis dataKey="name" type="category" />
                                            <YAxis axisLine={false} />
                                            <Bar dataKey="value" shape={<CustomBar />} fill="#D3F4EA" barSize={40} />
                                        </BarChart>

                                        
                                    </>

                                </div>
                            )}
                        </div>





                    </Form>
                    <div>
                    </div>
                </div>

                <div>

                </div>


            </Modal>
        </>
    )
}

export default Fertile