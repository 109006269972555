import React, { useState, useEffect, useRef } from "react";
import { Layout, Card, message, Input, Form, Table, Tooltip } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import CreateNameBaby from "../babyname/svg/CreateBabyNameSvg.png";
import { godList, serachNameMeaning, getFavName, serachExactNameMeaning, addFavName } from "../../../apis/babyName";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { viewProfileApi } from "../../../apis/btpViewProfile";
import { HeartOutlined, HeartFilled, RightOutlined, LeftOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import Footer from "views/MainFooter/Footer";

const BabyNames = () => {
  const [godNameList, setGodNameList] = useState<any>([]);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    viewProfileApi("")
      .then(res => {
        setData(res);
      })
      .catch(err => {
        message.error(err);
      });
  }, []);

  const [favNameData, setFavNameData] = useState<any>([]);
  const getFavNameList = () => {
    getFavName(data && data?.sk_user_id)
      .then(res => {
        setFavNameData(res?.matchedData);
      })
      .catch(err => {
        message.error(err);
      });
  };

  const [rashiList, setRashiList] = useState<any>([]);
  const [nakshatraList, setNakshatraList] = useState<any>([]);
  const [numerologyList, setNumerologyList] = useState<any>([]);
  const [religionList,setReligionList]=useState<any>([])

  const getGodDataList = (name: any) => {
    godList(name)
      .then(res => {
        if (name === "gods") {
          setGodNameList(res);
        } else if (name === "rashi") {
          setRashiList(res);
        } else if (name == "nakshatra") {
          setNakshatraList(res);
        } else if (name == "numerology") {
          setNumerologyList(res);
        } else if(name=="religion"){
          setReligionList(res)
        }
      })
      .catch(err => {
        message.error(err);
      });
  };

  useEffect(() => {
    getGodDataList("gods");
    getGodDataList("rashi");
    getGodDataList("nakshatra");
    getGodDataList("numerology");
    getGodDataList("religion");
  }, []);

  useEffect(() => {
    getFavNameList();
  }, [data && data?.sk_user_id]);

  const [finalName, setFinalName] = useState<any>([]);
  const onFinish = (values: any) => {
    serachExactNameMeaning(values.name)
      .then(res => {
        setFinalName(res.matchedNames);
      })
      .catch(err => {
        message.error(err);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [matchName, setMatchedName] = useState<any>([]);
  const handleChange = (e: any) => {
    const value = e.target.value;
    if (value.length >= 3) {
      setInputValue(value);
      serachNameMeaning(value)
        .then(res => {
          setMatchedName(res.matchedNames);
        })
        .catch(err => {
          message.error(err);
        });
    }
  };

  const handleClickBack = () => {
    setFinalName([]);
    setMatchedName([]);
  };

  const [inputValue, setInputValue] = useState("");
  const handleNameClick = (name: any) => {
    setInputValue(name);
    onFinish({ name });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
    {
      title: "Numerology",
      dataIndex: "numerology",
      key: "numerology",
    },
    {
      title: "Religion",
      dataIndex: "religion",
      key: "religion",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Fav",
      dataIndex: "fav",
      key: "fav",
      render: (fav: any, record: any) => {
        const isFav = favNameData?.some((getFavNameData: any) => getFavNameData.name == record?.name);
        return isFav ? <HeartFilled className="darkFav-icon" /> : <HeartOutlined className="outlineFav-icon" />;
      },
      onCell: (record: any) => ({
        onClick: () => {
          handleFavClick(record);
        },
      }),
    },
  ];

  const handleFavClick = (record: any) => {
    if (record && record.name) {
      let data1 = JSON.stringify({
        userId: data?.sk_user_id,
        name: record.name,
      });
      addFavName(data1)
        .then(res => {
          getFavNameList();
          Swal.fire({
            icon: "success",
            title: "Name added to your favourite list !",
            showConfirmButton: false,
            timer: 900,
          });
        })
        .catch(err => {
          message.error(err);
        });
    }
  };

  const renderTooltipContent = (val: any) => (
    <div className="name-table">
      {val?.meaning && (
        <div className="name-table-row">
          <div className="name-table-data">Meaning</div>
          <div className="name-table-data">{val?.meaning}</div>
        </div>
      )}
      {val?.nakshatra && (
        <div className="name-table-row">
          <div className="name-table-data">Nakshatra</div>
          <div className="name-table-data">{val?.nakshatra}</div>
        </div>
      )}
      {val?.numerology && (
        <div className="name-table-row">
          <div className="name-table-data">Numerology</div>
          <div className="name-table-data">{val?.numerology}</div>
        </div>
      )}
      {val?.rashi && (
        <div className="name-table-row">
          <div className="name-table-data">Rashi</div>
          <div className="name-table-data">{val?.rashi}</div>
        </div>
      )}
      {val?.religion && (
        <div className="name-table-row">
          <div className="name-table-data">Religion</div>
          <div className="name-table-data">{val?.religion}</div>
        </div>
      )}
      {val?.type && (
        <div className="name-table-row">
          <div className="name-table-data">Type</div>
          <div className="name-table-data">{val?.type}</div>
        </div>
      )}
    </div>
  );

  const [showScrollButtonGods, setShowScrollButtonGods] = useState(false);
  const [showScrollButtonReligious, setShowScrollButtonReligious] = useState(false);
  const [showScrollButtonNakshatras, setShowScrollButtonNakshatras] = useState(false);
  const [showScrollButtonNumerology, setShowScrollButtonNumerology] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollRefGods = useRef<any>(null);
  const scrollRefReligious = useRef<any>(null);
  const scrollRefRashi = useRef<any>(null);
  const scrollRefNakshatras = useRef<any>(null);
  const scrollRefNumerology = useRef<any>(null);

  const handleScrollGods = (scrollOffset: any) => {
    if (scrollRefGods.current) {
      scrollRefGods.current.scrollLeft += scrollOffset;
    }
  };
  const handleScrollReligious = (scrollOffset: any) => {
    if (scrollRefReligious.current) {
      scrollRefReligious.current.scrollLeft += scrollOffset;
    }
  };

  const handleScrollRashi = (scrollOffset: any) => {
    if (scrollRefRashi.current) {
      scrollRefRashi.current.scrollLeft += scrollOffset;
    }
  };

  const handleScrollNakshatras = (scrollOffset: any) => {
    if (scrollRefNakshatras.current) {
      scrollRefNakshatras.current.scrollLeft += scrollOffset;
    }
  };

  const handleScrollNumerology = (scrollOffset: any) => {
    if (scrollRefNumerology.current) {
      scrollRefNumerology.current.scrollLeft += scrollOffset;
    }
  };

  const handleResizeReligious = () => {
    const scrollContainer = scrollRefReligious.current;
    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
      setShowScrollButtonReligious(true);
    } else {
      setShowScrollButtonReligious(false);
    }
  };

  const handleResizeGods = () => {
    const scrollContainer = scrollRefGods.current;
    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
      setShowScrollButtonGods(true);
    } else {
      setShowScrollButtonGods(false);
    }
  };

  const handleResizeRashi = () => {
    const scrollContainer = scrollRefRashi.current;
    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const handleResizeNakshatras = () => {
    const scrollContainer = scrollRefNakshatras.current;
    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
      setShowScrollButtonNakshatras(true);
    } else {
      setShowScrollButtonNakshatras(false);
    }
  };

  const handleResizeNumerology = () => {
    const scrollContainer = scrollRefNumerology.current;
    if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
      setShowScrollButtonNumerology(true);
    } else {
      setShowScrollButtonNumerology(false);
    }
  };

  useEffect(() => {
    const handleBothResizes = () => {
      handleResizeGods();
      handleResizeRashi();
      handleResizeReligious();
      handleResizeNakshatras();
      handleResizeNumerology();
    };
    handleBothResizes();
    window.addEventListener("resize", handleBothResizes);
    return () => {
      window.removeEventListener("resize", handleBothResizes);
    };
  });

  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-20">
          <Content
            className=" container "
            style={{
              margin: "24px auto",
            }}>
            <div>
              <div>
                <div className="Artical_card3 relative mt-10">
                  <div
                    style={{
                      display: "flex",
                    }}></div>
                  <div className="mt-10">
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <div className="babay_gen_left" style={{ flex: 1, paddingRight: "20px" }}>
                        {finalName?.length == 0 ? (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                              }}>
                              <p
                                style={{
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                  color: "#333",
                                }}>
                                Search name for meaning...
                              </p>
                            </div>

                            <Card
                              style={{
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                height: "170px",
                                overflowY: "auto",
                              }}>
                              <Form
                                style={{
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                  paddingTop: "20px",
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off">
                                <Form.Item name="name">
                                  <Input
                                    placeholder="Enter a name to search for its meaning"
                                    name="name"
                                    onChange={handleChange}
                                    value={inputValue}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  />
                                </Form.Item>
                              </Form>
                              <div style={{ paddingLeft: "20px" }}>
                                {matchName.map((val: any, ind: number) => {
                                  return (
                                    <>
                                      <p className="cursor-pointer px-1" onClick={() => handleNameClick(val.name)}>
                                        {val.name}
                                      </p>
                                    </>
                                  );
                                })}
                              </div>
                            </Card>
                          </div>
                        ) : (
                          <div>
                            <div
                              style={{
                                textAlign: "center",
                                marginBottom: "20px",
                              }}>
                              <p
                                style={{
                                  fontSize: "28px",
                                  fontWeight: "bold",
                                  color: "#333",
                                }}>
                                <ArrowLeftOutlined onClick={handleClickBack} /> Your name meaning is here...
                              </p>
                            </div>
                            <Card
                              style={{
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                height: "170px",
                                overflowY: "auto",
                              }}>
                              <Table dataSource={finalName} columns={columns} />
                            </Card>
                          </div>
                        )}
                        <br />
                        <Card
                          className="mt-5"
                          style={{
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            borderRadius: "8px",
                            height: "170px",
                            textAlign: "center",
                          }}>
                          <div className="text-center" style={{ width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingRight: "20px",
                                paddingLeft: "20px",
                              }}>
                              <p className="mt-2">Your Favourite Name</p>
                              {favNameData?.length !== 0 && (
                                <Link to="/fav_names">
                                  <p className="mt-2 danger">View All</p>
                                </Link>
                              )}
                            </div>

                            <div style={{ padding: "20px" }}>
                              <table style={{ width: "100%", cursor: "pointer" }}>
                                <div style={{ height: "100px", overflowY: "auto" }}>
                                  <table style={{ width: "100%" }}>
                                    <tbody>
                                      <tr>
                                        {favNameData?.map((val: any, index: number) => (
                                          <Link to="/fav_names" key={index}>
                                            <td
                                              style={{
                                                borderRight: "1px solid #ccc",
                                                padding: "8px",
                                              }}
                                              key={index}>
                                              <Tooltip title={() => renderTooltipContent(val)}>{val?.name}</Tooltip>
                                            </td>
                                          </Link>
                                        ))}
                                        {favNameData?.length === 0 && (
                                          <td
                                            style={{
                                              borderRight: "1px solid #ccc",
                                              padding: "8px",
                                            }}>
                                            <p>No Favorite Names Found</p>
                                          </td>
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </table>
                            </div>
                          </div>
                        </Card>
                      </div>

                      <div className="babay_gen_right">
                        <Link to={"/generate_names"}>
                          <img src={CreateNameBaby} alt="" style={{ maxWidth: "100%", height: "auto" }} />
                        </Link>
                      </div>
                    </div>

                    <div className="mt-10">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div
                          style={{
                            fontSize: "20px",
                            letterSpacing: "1.05px",
                          }}>
                          <h3>Religious Names</h3>
                        </div>
                        {showScrollButtonReligious && (
                          <div>
                            <button onClick={() => handleScrollReligious(-100)} className="babyname-left-arrow">
                              <LeftOutlined />
                            </button>
                            <button onClick={() => handleScrollReligious(100)} className="babyname-right-arrow">
                              <RightOutlined />
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                        className="baby-scroll"
                        ref={scrollRefReligious}>
                        {religionList?.map((reg: any, ind: number) => {
                          return (
                            <>
                              <Link to={`/babynames/${reg.name}`}>
                                <div
                                  key={ind}
                                  style={{
                                    textAlign: "center",
                                    marginLeft: ind === 0 ? "0" : "30px",
                                  }}
                                  className="mb-10">
                                  <Card
                                    className="mt-5"
                                    hoverable
                                    style={{
                                      width: 150,
                                      marginTop: "8px",
                                      backgroundColor: "#CEE9FF",
                                      borderRadius: "8px",
                                      border: "none",
                                    }}
                                    cover={<img alt={reg?.imageUrl} src={reg?.imageUrl} style={{ padding: "20px" }} />}></Card>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginTop: "8px",
                                      letterSpacing: "1.05px",
                                    }}>
                                    {reg.name}
                                  </p>
                                </div>
                              </Link>{" "}
                            </>
                          );
                        })}


                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div
                          style={{
                            fontSize: "20px",
                            letterSpacing: "1.05px",
                          }}>
                          <h3>Gods Names</h3>
                        </div>
                        {showScrollButtonGods && (
                          <div>
                            <button onClick={() => handleScrollGods(-100)} className="babyname-left-arrow">
                              <LeftOutlined />
                            </button>
                            <button onClick={() => handleScrollGods(100)} className="babyname-right-arrow">
                              <RightOutlined />
                            </button>
                          </div>
                        )}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                          }}
                          className="baby-scroll"
                          ref={scrollRefGods}>
                          {godNameList?.map((god: any, index: number) => {
                            return (
                              <>
                                <Link to={`/god/${god?.name}`}>
                                  <div
                                    key={index}
                                    style={{
                                      textAlign: "center",
                                      marginLeft: index === 0 ? "0" : "30px",
                                    }}
                                    className="mb-8">
                                    <Card
                                      hoverable
                                      style={{
                                        width: 150,
                                        height: 150,
                                        marginTop: "8px",
                                        backgroundColor: "#CEE9FF",
                                        borderRadius: "8px",
                                        border: "none",
                                        textAlign: "center",
                                      }}
                                      cover={
                                        <img
                                          alt="example"
                                          src={god?.imageUrl}
                                          style={{
                                            height: "140px",
                                            width: "114px",
                                            objectFit: "fill",
                                            padding: "5px",
                                            marginLeft: "15px",
                                          }}
                                        />
                                      }></Card>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                        marginTop: "8px",
                                        letterSpacing: "1.05px",
                                      }}>
                                      {god.name}
                                    </p>
                                  </div>
                                </Link>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div
                          style={{
                            fontSize: "20px",
                            letterSpacing: "1.05px",
                          }}>
                          <h3>Rashi Names</h3>
                        </div>
                        {showScrollButton && (
                          <div>
                            <button onClick={() => handleScrollRashi(-100)} className="babyname-left-arrow">
                              <LeftOutlined />
                            </button>
                            <button onClick={() => handleScrollRashi(100)} className="babyname-right-arrow">
                              <RightOutlined />
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                        className="baby-scroll"
                        ref={scrollRefRashi}>
                        {rashiList?.map((god: any, index: number) => {
                          return (
                            <>
                              <Link to={`/rashi/${god.name}`}>
                                <div
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    marginLeft: index === 0 ? "0" : "30px",
                                  }}
                                  className="mb-8">
                                  <Card
                                    hoverable
                                    style={{
                                      width: 150,
                                      height: 150,
                                      marginTop: "8px",
                                      backgroundColor: "#CEE9FF",
                                      borderRadius: "8px",
                                      border: "none",
                                    }}
                                    cover={<img alt="example" src={god?.imageUrl} style={{ padding: "20px" }} />}></Card>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginTop: "8px",
                                      letterSpacing: "1.05px",
                                    }}>
                                    {god.name}
                                  </p>
                                </div>
                              </Link>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    <div className="mt-5">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}>
                        <div
                          style={{
                            fontSize: "20px",
                            letterSpacing: "1.05px",
                          }}>
                          <h3>Nakshatras Names</h3>
                        </div>
                        {showScrollButtonNakshatras && (
                          <div>
                            <button onClick={() => handleScrollNakshatras(-100)} className="babyname-left-arrow">
                              <LeftOutlined />
                            </button>
                            <button onClick={() => handleScrollNakshatras(100)} className="babyname-right-arrow">
                              <RightOutlined />
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                        className="baby-scroll"
                        ref={scrollRefNakshatras}>
                        {nakshatraList?.map((god: any, index: number) => {
                          return (
                            <>
                              <Link to={`/nakshatra/${god.name}`}>
                                <div
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    marginLeft: index === 0 ? "0" : "30px",
                                  }}
                                  className="mb-8">
                                  <Card
                                    hoverable
                                    style={{
                                      width: 150,
                                      height: 150,
                                      marginTop: "8px",
                                      backgroundColor: "#CEE9FF",
                                      borderRadius: "8px",
                                      border: "none",
                                    }}
                                    cover={<img alt="example" src={god?.imageUrl} style={{ padding: "20px" }} />}></Card>
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginTop: "8px",
                                      letterSpacing: "1.05px",
                                    }}>
                                    {god.name}
                                  </p>
                                </div>
                              </Link>
                            </>
                          );
                        })}
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        <div
                          style={{
                            fontSize: "20px",
                            letterSpacing: "1.05px",
                          }}
                          className="mt-5">
                          <h3>Numerology Name</h3>
                        </div>
                        {showScrollButtonNumerology && (
                          <div>
                            <button onClick={() => handleScrollNumerology(-100)} className="babyname-left-arrow">
                              <LeftOutlined />
                            </button>
                            <button onClick={() => handleScrollNumerology(100)} className="babyname-right-arrow">
                              <RightOutlined />
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          whiteSpace: "nowrap",
                        }}
                        className="baby-scroll"
                        ref={scrollRefNumerology}>
                        {numerologyList.map((val: any, index: number) => {
                          return (
                            <>
                              <Link to={`/numerology/${val.name}`}>
                                <div
                                  key={index}
                                  style={{
                                    textAlign: "center",
                                    marginLeft: index === 0 ? "0" : "30px",
                                  }}
                                  className="mb-10">
                                  <Card
                                    hoverable
                                    style={{
                                      width: 80,
                                      height: 80,
                                      marginTop: "30px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "24px",
                                      fontWeight: "600",
                                    }}>
                                    <p>{val.name}</p>
                                  </Card>
                                </div>
                              </Link>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
        <Footer />
      </Layout>
    </>
  );
};

export default BabyNames;
