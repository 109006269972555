import React, { useState, useEffect } from "react";
import { Form, Input, Radio, Button, Select } from "antd";
import type { UploadProps } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  updateprofile,
  TUpdateProfileRequest_personal,
} from "actions/updateprofile";
import { TRootState } from "reducers";
import Pregnant from "../../images/pregnant.jpg";
import Conceive from "../../images/conceive.jpg";
import FrameP from "../../images/Frame6179.png";
import Group from "../../images/Group1073.jpg";
import Frame1 from "../../images/Frame 6178.png";
import { viewProfileApi } from "apis/btpViewProfile";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
const PersonalDetails = (callback: any) => {
  const { isLoading, data, refetch } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );
  const profileData = data;
  const [base64, setBase64] = useState<string>();
  const [radioval, setRadioval] = useState<any>();
  const [value, setValue] = useState<number>(0);
  const history = useHistory();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { Accesstoken } = useSelector((state: TRootState) => state.auth);

  useEffect(() => {
    const email = profileData?.email;
    const full_name = profileData?.full_name;
    const username_momnexus = profileData?.email;
    const country_id = profileData?.c_code;
    const pincode = profileData?.pincode;
    const i_am = profileData?.i_am;
    const mobile = profileData?.mobile;
    const user_bio=profileData?.user_bio;
    const gender=profileData?.gender;
    form.setFieldsValue({
      name: full_name,
      username: username_momnexus,
      country_name: country_id,
      pincode: pincode,
      i_am: i_am,
      mobile: mobile,
      user_bio:user_bio,
      gender:gender
    });
    if (i_am !== undefined) {
      setValue(parseInt(i_am));
    }
  }, [profileData, form]);
  const onFinish = async (values: any) => {
    console.log("values", values);

    let reqBody = form.validateFields() as any;
    (reqBody.sk_user_id = profileData?.sk_user_id), (reqBody.file_image = "");
    reqBody.full_name = values.name;
    reqBody.pincode = values.pincode;
    if (typeof radioval !== "undefined") {
      reqBody.i_am = radioval.toString();
    }
    reqBody.file_image = reqBody.profile_pic;
    reqBody.mobile = values.mobile;
    reqBody.user_bio=values.user_bio
    reqBody.gender=values.gender;
    delete reqBody.firstname;
    delete reqBody.lastname;
    delete reqBody.name;
    delete reqBody.username;
    reqBody.type = "personal";
    await refetch();
    Swal.fire({
      icon: "success",
      showConfirmButton: false,
      timer: 900,
    });
    let updateProfile = dispatch(
      updateprofile(
        reqBody as TUpdateProfileRequest_personal,
        Accesstoken,
        (res) => handleCallback(res)
      )
    );
    await refetch();
  };

  const handleCallback = (res: any) => {
    if (res.i_am.toString() === "2") {
      history.push("/editprofile/edit/pregrancyInformation");
    } else if (res.i_am.toString() === "3") {
      history.push("/editprofile/edit/parentInformation");
    } else if (res.i_am.toString() === "5") {
      history.push("/editprofile/edit/pregrancyInformation");
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const _handleReaderLoaded = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    setBase64(binaryString);
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded;
      reader.readAsDataURL(file);
      return false;
    },
  };

  const radioHandler = (e: any) => {
    setValue(e.target.value);
  };
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
  );
  return (
    <div>
      <Form
        name="personal"
        form={form}
        layout="vertical"
        autoComplete="off"
        className="input-sty"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[a-zA-Z ]*$/),
              message: "Please enter your Full Name!",
            },
          ]}
        >
          <Input id="firstname" />
        </Form.Item>
        <Form.Item name="username" label="Email">
          <Input id="username" disabled />
        </Form.Item>
        <Form.Item name="country_name" label="Country">
          <Input id="country_name" disabled />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Phone Number"
          rules={[
            { required: true, message: "Please enter your Phone number!" },
            {
              message: "Please enter valid  mobile number",
              pattern: /^[6-9][0-9]{9}$/,
            },
          ]}
        >
          <Input id="mobile" placeholder="Please Enter your number" />
        </Form.Item>

        <div className="Pincode-sty md:flex flex-row justify-between min-w-full xs:flex-wrap ">
          <Form.Item
            name="pincode"
            label="Zip Code"
            rules={[
              {
                required: true,
                pattern: new RegExp(/^[0-9]*$/),
                message: "Please enter your Zip Code/Pin Code!",
              },
            ]}
          >
            <Input id="pincode" />
          </Form.Item>


        </div>
        <Form.Item label="Gender" name="gender">
            <Select
              defaultValue="Male"
              allowClear
              options={[
                {
                  value: "Male",
                  label: "Male",
                },
                {
                  value: "Female",
                  label: "Female",
                },
              ]}
            />
          </Form.Item>

          <Form.Item label="Bio" name="user_bio">
              <Input placeholder="Enter Bio"/>
          </Form.Item>

        <Form.Item
          name="i_am"
          label="Lifestage"
          rules={[{ required: true, message: "Please select your Lifestage!" }]}
          valuePropName="checked"
        >
          <Radio.Group
            className="radio-custom"
            name="i_am"
            value={value}
            onChange={(e) => {
              setRadioval(e.target.value);
              setValue(e.target.value);
            }}
          >
            <Radio.Button value={1}>
              <span className="flex flex-row justify-around items-center gap-1 hover:text-red-500">
                <img
                  alt=" "
                  width={55}
                  height={55}
                  src={Conceive}
                  className="object-contain"
                />
                <p className="text-sm hover:text-red-500">
                  I’m trying to conceive
                </p>
              </span>
            </Radio.Button>

            <Radio.Button value={2}>
              <span className="flex flex-row justify-around items-center gap-1">
                <img
                  alt=" "
                  width={55}
                  height={55}
                  src={Pregnant}
                  className="object-contain"
                />
                <p className="text-sm hover:text-red-500">I’m pregnant</p>
              </span>
            </Radio.Button>
            <Radio.Button value={3}>
              <span className="flex flex-row justify-around items-center gap-1">
                <img
                  alt=" "
                  width={55}
                  height={55}
                  src={Frame1}
                  className="object-contain"
                />
                <p className="text-sm hover:text-red-500">I’m parent</p>
              </span>
            </Radio.Button>
            <Radio.Button value={4}>
              <span className="flex flex-row justify-around items-center gap-1">
                <img
                  alt=" "
                  width={55}
                  height={55}
                  src={Group}
                  className="object-contain"
                />
                <p className="text-sm hover:text-red-500">
                  I’m parent & trying to conceive
                </p>
              </span>
            </Radio.Button>
            <Radio.Button value={5}>
              <span className="flex flex-row justify-around items-center gap-1">
                <img
                  alt=" "
                  width={55}
                  height={55}
                  src={FrameP}
                  className="object-contain"
                />
                <p className="text-sm hover:text-red-500">
                  I’m Pregnant & Parent
                </p>
              </span>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="button-sty float-right"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default PersonalDetails;
