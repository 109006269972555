
import { Card, Row, Col, Space, Button, Carousel, Skeleton } from "antd";
import { MoreOutlined } from '@ant-design/icons'
import React, { useState, useRef, useEffect } from "react";
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import Arrow from "../../../src/images/arrow.svg";
import Arrow from "../../../../src/images/arrow.svg"


interface MyCarouselProps {
  dots?: boolean | { className?: string };
}
const Product = (props: any,) => {
  // console.log(props);

  let deatil = props.data;
  const truncateDescription = (description: any, wordLimit: any) => {
    const words = description.split(' ');
    const truncatedWords = words.slice(0, wordLimit);
    if (words.length > wordLimit) {
        return truncatedWords.join(' ') + ' ...';
    }
    return truncatedWords.join(' ');
};




  // var settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1
  // };
  const SampleNextArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img
          src={Arrow}
          width={30}
          height={30}
          alt=""
          style={{ transform: "rotate(180deg)" }}
          loading="lazy"
        />
      </div>
    );
  };
  
  const SamplePrevArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src={Arrow} width={30} height={30} alt="" loading="lazy" />
      </div>
    );
  };



  const settings = {
    initialSlide: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };






  return (
    <>
      <div className='line' >
        <div className="mb-5">
          <Row align="middle" justify="space-between" >
            <Col span={20}>
              <Space align="center">
                <div>
                  <h3 style={{
                    color: 'color',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '22px'
                  }}>{deatil.title}</h3>
                </div>
              </Space>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              {/* <MoreOutlined /> */}
            </Col>
            <Col span={24} className='mt-1 mb-3'>
              <p style={{
                fontSize: '14px',
                color: '#666',
                fontWeight: 'bold'
              }}>{deatil.description}</p>
            </Col>
          </Row>

          <div className="s-card-scroll">
          {/* <Carousel fade={true} arrows {...settings}> */}
            {deatil.recomondedProducts.map((value: any) => (
              <div
                key={value.id}
                className="p-card-container card"
                style={{
                  // border: 'none',
                  display: 'inline-block',
                  width: '100%',
                  border:'1px solid #F0F0F0',
                  gap:"20px",
                  marginRight:'10px',
                  // margin: '0 0px',
                }}
              >
                <div
                  className="p-card-img"
                  style={{
                    width: '100%', 
                    height: '500px', 
                    overflow: 'hidden', 
                  }}
                >
                  <img
                    src={value.productImage}
                    alt=""
                    // className="object-cover w-full"
                    style={{
                      width: '100%',
                      height: '100%',
                      // objectFit: 'contain', 
                    }}
                    loading="lazy"
                  />
                </div>
                <div
                  className="mb-3 mt-3"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                  }}
                >
                  <div>
                    <h3 className="ellipsis-title">{truncateDescription(value.productTitle,8)}</h3>
                    <p className="ellipsis-text">{truncateDescription(value.productDescription, 8)}</p>
                  </div>
                  <div>
                    <a href={value.productLink.url} target={value.productLink.target}>
                      <button>Buy</button>
                    </a>
                  </div>
                </div>
              </div>
            ))}
            {/* </Carousel> */}
          </div>
        </div>



        {/* <div className="container">
          <Slider {...settings}>


            <div>
              <img src="http://placekitten.com/g/400/200" />
            </div>
            <div>
              <img src="http://placekitten.com/g/400/200" />
            </div>
            <div>
              <img src="http://placekitten.com/g/400/200" />
            </div>
            <div>
              <img src="http://placekitten.com/g/400/200" />
            </div>

          </Slider>
        </div> */}





      </div>
    </>
  )
}

export default Product