import { Card, Skeleton, Tabs, Image, message } from "antd";
import Arrow from "../../../src/images/arrow.svg";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import Post from "components/modal/Post";
import Newpost from "./Newpost";
import { viewProfileApi } from "apis/btpViewProfile";
import { getBannerApi, getFeedsApi, customizeFeed } from "apis/GetFeeds";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import UserPost from "views/Posts/UserPost";
import Product from "./feedlist/Product";
import MomsLikeMe from "./feedlist/MomsLikeMe";
import MomsNearMe from "./feedlist/MomsNearMe";
import SuggestedGroups from "./groups/SuggestedGroups";
import Tools from "./tools/Tools";
import Ads from "./advertisement/Ads";
import Service from "./service/Service";
import SetUpTracker from "./calendar/SetUpTracker";
import CalendarDetail from "./calendar/CalendarDetail";
import { getUserPost, grouplikeApi, savePostGroupApi } from "views/Groups/btpGroupApi";
import moment from "moment";
import SaveImg from "../../images/SavedIcon.svg";
import Save from "../../images/save.svg";
import { LikeOutlined, LikeFilled, MoreOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import PostComment from "components/modal/PostComment";
import DueDate from "components/modal/DueDate";
import { deleteMyPost } from "views/Groups/btpGroupApi";
import InvalidDate from "components/modal/InvalidDate";
import CommentImg from "../../images/comment.svg";
import Send from "../../images/send.svg";
import SendModal from "views/Posts/SendModal";
import WeekData from "./WeekData";
import Slider from "react-slick";

const SampleNextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={Arrow} width={30} height={30} alt="" style={{ transform: "rotate(180deg)" }} loading="lazy" />
    </div>
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src={Arrow} width={30} height={30} alt="" loading="lazy" />
    </div>
  );
};

const Articalsection3 = () => {
  const [isOpenTracker, setIsOpenTracker] = useState(false);
  const [post, setPost] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postId, setPostID] = useState<any>("");
  const [isMoreVisible, setIsMoreVisible] = useState(true);
  const [dueDateModal, setDueDateModal] = useState(false);
  const [showInvalidDate, setShowInvalidDate] = useState(false);

  let { data, isLoading, isError } = useQuery("getBanner", getBannerApi);

  const {
    isLoading: loading,
    data: userData,
    refetch: refres,
  } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));

  let {
    data: feedData,
    isLoading: isFeedLoading,
    isError: isFeedError,
    fetchNextPage,
    refetch: refresh,
  } = useInfiniteQuery("getFeed", getFeedsApi, {
    enabled: false,
    getNextPageParam: (lastPage, pages) => {
      if (pages.length && lastPage.hasNext !== false) return pages.length + 1;
      return undefined;
    },
  });

  const customRefresh = (pageNumber: number) => {
    fetchNextPage({ pageParam: pageNumber });
  };
  useEffect(() => {
    customRefresh(1);
  }, []);

  let {
    data: postData,
    isLoading: isDataLoading,
    isError: isDataError,
    refetch,
  } = useQuery("getUserPost", getUserPost, {
    enabled: false,
  });

  let { data: weekData, isLoading: weekDataLoading, isError: isWeekDataError } = useQuery("customizeFeed", customizeFeed);

  const handleTracker = () => {
    setIsOpenTracker(true);
  };

  const queryClient = useQueryClient();
  const handleClickLike = async (value: any) => {
    await grouplikeApi(value)
      .then(res => {})
      .catch(err => {
        message.error(err);
      });
    await queryClient.invalidateQueries("getUserPost");
  };

  const showModalComment = (e: any, values: any) => {
    setPostID(values);
    setIsModalOpen(true);
  };

  const hanldeSavePost = async (e: any, val: any) => {
    await savePostGroupApi(val._id)
      .then(res => {
        refetch();
        if (val.savedPostStatus == 0) {
          Swal.fire({
            icon: "success",
            title: "Post saved",
            showConfirmButton: false,
            timer: 900,
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
    await queryClient.invalidateQueries("getUserPost");
    refetch();
  };

  useEffect(() => {
    if ((userData?.i_am === "2" || userData?.i_am == "4") && userData?.due_date_parent == null) {
      setDueDateModal(true);
      refres();
    } else if (userData?.due_date_parent == "0000-00-00") {
      setDueDateModal(true);
      refres();
    } else {
      setDueDateModal(false);
    }
  });

  useEffect(() => {
    const currentDate = moment();
    const dueDate = moment(userData?.due_date_parent);
    if (dueDate.isValid() && dueDate.isAfter(currentDate.clone().add(9, "months"))) {
      setShowInvalidDate(true);
      refres();
    } else {
      setShowInvalidDate(false);
      refres();
    }
  }, []);

  const toggleDisplay = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  const handleDeleteClick = (e: any, list: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Post.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteMyPost(list)
          .then(res => {
            refetch();
          })
          .catch(err => {
            message.error(err);
          });
        toggleDisplay();
        Swal.fire("Deleted!", "Your post has been deleted.", "success");
      }
    });
  };

  const [isSendShow, setIsSendShow] = useState(false);
  const [artcileData, setArticleData] = useState<any>({});
  const [type, setType] = useState<any>("");

  const handleSendBtn = (permalink: any) => {
    setIsSendShow(true);
    setArticleData(permalink);
    setType("my_post");
  };

  let settingss = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    arrows: false,
  };
  return (
    <>
      {artcileData && (
        <SendModal
          setIsSendShow={setIsSendShow}
          isSendShow={isSendShow}
          artcileData={artcileData}
          type={type}
          setArticleData={setArticleData}
        />
      )}
      {showInvalidDate && <InvalidDate showInvalidDate={showInvalidDate} setShowInvalidDate={setShowInvalidDate} />}
      {dueDateModal && <DueDate dueDateModal={dueDateModal} setDueDateModal={setDueDateModal} />}
      {isModalOpen && <PostComment setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} postId={postId} setPostID={setPostID} />}
      {isOpenTracker && <SetUpTracker isOpenTracker={isOpenTracker} setIsOpenTracker={setIsOpenTracker} />}
      <div className="Artical_card3 relative">
        <div className="border mb-3 rounded-md">
          <Post setpost={setPost} />
        </div>
        {data && data.data.image ? (
          <div
            style={{
              cursor: "pointer",
            }}>
            {isLoading ? <Skeleton active /> : <img onClick={handleTracker} src={data && data.data.image} alt={data && data.data.image} />}
          </div>
        ) : null}
        {data && data.data.type == "calendar" ? <CalendarDetail /> : null}
        {(userData?.i_am === "2" || userData?.i_am == "4") && <WeekData weekData={weekData} />}
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="All Posts" key="1">
            <div>
              <InfiniteScroll
                dataLength={feedData ? feedData.pages.length : 0}
                next={fetchNextPage}
                hasMore={feedData ? feedData.pages[feedData.pages.length - 1].hasNext !== false : true}
                loader={<h4>Loading...</h4>}
                endMessage={<p style={{ textAlign: "center" }}>❄ ❄ ❄ ❄ ❄ ❄ ❄ ❄</p>}>
                {feedData &&
                  feedData.pages.map((page: any, i: number) => {
                    return page.data.map((data: any) => {
                      switch (data?.type) {
                        case "article":
                          return <UserPost data={data.article} key={data.id} refresh={refresh} />;
                        case "post":
                          return <Newpost data={data.post} key={data.id} refresh={refresh} />;
                        case "product":
                          return <Product data={data.product} key={data.id} />;
                        case "tools":
                          return <Tools data={data.tools} key={data.id} />;
                        case "advertisements":
                          return <Ads data={data.advertisements} />;
                        case "service":
                          return <Service data={data.service} key={data.id} />;
                        case "groups":
                          return <SuggestedGroups data={data.groups} key={data.id} />;
                        case "momsnearme":
                          return <MomsNearMe data={data.count} key={data.id} />;
                        case "momslikeme":
                          return <MomsLikeMe data={data.count} key={data.id} />;
                        default:
                          return null;
                      }
                    });
                  })}
              </InfiniteScroll>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="My Posts" key="2">
            <div>
              <>
                {postData &&
                  postData.data.posts &&
                  postData.data.posts.map((list: any) => {
                    return (
                      <Card
                        className="Liked_post_section"
                        style={{ marginBottom: "45px" }}
                        title={
                          <>
                            <div className="flex mb-5">
                              <div className="mr-3">
                                <img src={list.profilePic} alt="" style={{ width: "40px", height: "40px" }} />
                              </div>

                              <div style={{ flex: 1 }}>
                                <h2 style={{ marginBottom: "0px" }}>{list.fullName}</h2>
                                <span>{moment(list.createdDate).format("DD-MM-YYYY")}</span>
                              </div>

                              <div>
                                {list.userId === userData?.sk_user_id && (
                                  <p>
                                    {isMoreVisible ? (
                                      <MoreOutlined className={`more-link cursor-pointer`} onClick={toggleDisplay} />
                                    ) : (
                                      <div className="dropdown-menu">
                                        <a onClick={e => handleDeleteClick(e, list._id)} className="delete-link">
                                          Delete
                                        </a>
                                      </div>
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        }>
                        <p className="white-space-preline">{list?.postDescription}</p>

                        <Slider {...settingss}>
                          {list.postMedia.map((media_item: any, index: number) => (
                            <div
                              className="grp-post-img"
                              key={index}
                              style={{
                                display: "inline-block",
                                marginRight: 20,
                              }}>
                              {media_item.mediaType === "image" || media_item.mediaType === "png" || media_item.mediaType === "jpg" ? (
                                <img
                                  src={media_item.mediaKey}
                                  alt=""
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                  }}
                                />
                              ) : (
                                (media_item.mediaType === "video" || media_item.mediaType === "mp4") && (
                                  <video src={media_item.mediaKey} controls autoPlay style={{ width: "100%", height: "100%" }} />
                                )
                              )}
                            </div>
                          ))}
                        </Slider>
                        <hr className="mt-3" />
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}>
                            {list.totalLikes != 0 && (
                              <span
                                className="mt-1"
                                style={{
                                  fontWeight: "600px",
                                  fontSize: ".9375rem",
                                }}>
                                {list.totalLikes != 0 && list.totalLikes} Like
                              </span>
                            )}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "16px",
                            }}>
                            {list.totalComments != 0 && (
                              <span
                                className="mt-1 cursor-pointer"
                                style={{
                                  fontWeight: "600px",
                                  fontSize: ".9375rem",
                                }}>
                                {list.totalComments != 0 && list.totalComments} Comment
                              </span>
                            )}
                          </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div style={{ display: "flex", alignItems: "center" }} className="mt-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => handleClickLike(list._id)}>
                              {list.likeStatus == 1 ? (
                                <span style={{ marginRight: "8px" }} className="mt-1">
                                  <LikeFilled style={{ fontSize: "20px" }} className="ml-1" />
                                </span>
                              ) : (
                                <span style={{ marginRight: "8px" }} className="mt-1">
                                  <LikeOutlined style={{ fontSize: "20px" }} className="ml-1" />
                                </span>
                              )}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "16px",
                              }}>
                              <span className="mt-2 mr-8">
                                <Image
                                  src={CommentImg}
                                  preview={false}
                                  className="cursor-pointer"
                                  onClick={e => showModalComment(e, list._id)}
                                />
                              </span>
                              <span className="mt-2 mr-8 cursor-pointer">
                                <Image src={Send} preview={false} className="cursor-pointer" onClick={() => handleSendBtn(list)} />
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <span style={{ marginRight: "8px" }}>
                                {list.savedPostStatus != 1 ? (
                                  <Image
                                    src={Save}
                                    preview={false}
                                    className="cursor-pointer mt-1"
                                    onClick={e => hanldeSavePost(e, list)}
                                  />
                                ) : (
                                  <Image
                                    src={SaveImg}
                                    preview={false}
                                    className="cursor-pointer mt-1"
                                    onClick={e => hanldeSavePost(e, list)}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  })}
                {postData?.data.posts && Number(postData.data.posts.length) === 0 && <p className="text-center">No Post Found</p>}
              </>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default Articalsection3;
