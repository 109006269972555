import { InfoCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'

const styles = {color: "white",padding:"18px",backgroundColor: "#007cff",}


function ExportData() {
  return (
    <div className='p-4 lg:py-10 lg:px-20 bg-white'>
    <h3 className='text-xl font-light mb-7'>Export Data</h3>
    <div className='flex login_section mb-4'>
      <InfoCircleOutlined style={styles} />
      <p className='py-2.5 px-1.5 m-0' style={{ backgroundColor: "rgb(247 249 252", color: "#4D5C6D", border: "1px solid rgb(188, 201, 215)" }}>You may download a copy of all data you have created on this platform. Click the button below to start a new request. An email will be sent to you to verify the request. Then the site admin will review your request and if approved, a zip file will be generated and emailed to you.</p>
    </div>
    <Button htmlType="submit" className='mt-4' style={{ backgroundColor: "#ff5a5f", borderRadius: "50px", color: "#fff" }}>
        Request Data Export
    </Button>
</div>
  )
}

export default ExportData