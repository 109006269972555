
import { Avatar, Empty, Modal } from "antd";
import { likeandunlikepost, likedlist } from "apis/likeunlikepost";
import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import Like from "../../../images/like.svg";
import Liked from "../../../images/liked.svg";

const Likedsection = (propslikesection: any) => {
  const [likelist, setlikelist] = useState<any>([]);

  const { feed } = useSelector((state: TRootState) => state.feeds);
  const post_id = propslikesection.postid;
  useEffect(() => {
    likedlist(post_id).then((res) => {
      const likedlistsection = res.data;
      setlikelist(likedlistsection);
    });
  }, []);
  const appReducer = (state: any, action: any) => {
    switch (action.type) {
      case "HANDLE_LIKE":
        return {
          ...state,
          likes: state.likes + action.payload,
        };
      case "HANDLE_DISLIKE":
        return {
          ...state,
          dislikes: state.dislikes + action.payload,
        };
      default:
        return state;
    }
  };

  const initialState = {
    likes: propslikesection.postliked,
    dislikes: propslikesection.postdisliked,
  };
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { likes, dislikes } = state;
  const [status, setStatus] = useState<any>(
    propslikesection.postIsLiked == 1 ? true : false
  );
  const [modalVisible, setModalVisible] = useState(false);

  const modalSection = () => {
    setModalVisible(true);
    likedlist(propslikesection.postid).then((res) => {
    });
  };


  const handleClickLike = () => {
    if (status) {
      const apistatus="unlike"
      likeandunlikepost(propslikesection.postid, apistatus).then((res) => {
      });
      setStatus(!status);
      dispatch({
        type: "HANDLE_LIKE",
        payload: -1,
      });
    } else {
      const apistatus="like"
      setStatus(!status);
      likeandunlikepost(propslikesection.postid, apistatus).then((res) => {
      });
      dispatch({
        type: "HANDLE_LIKE",
        payload: 1,
      });
    }
  };
  return (
    <div>
      <p
        className="cursor-pointer underline text-left pl-2"
        onClick={modalSection}
      >
        {likes}
      </p>
      <div className="mr-5 cursor-pointer" onClick={handleClickLike}>
        {status ? <img src={Liked} alt="" /> : <img src={Like} alt="" />}
      </div>
      <Modal
        title="Liked By"
        centered
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
        footer={false}
      >
        {likelist && likelist?.length > 0 ? (
          <div>
            {likelist.map((list: any) => {
              return (
                <>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="flex items-center mb-4">
                        <div className="mr-4">
                          <Avatar shape="circle" src={list.profile_pic} />
                        </div>
                        <div>
                          <p>{list.full_name}</p>
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="mb-4">
                        <p className="text-gray-300">{list.time}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <Empty />
        )}
      </Modal>
    </div>
  );
};

export default Likedsection;
