
import React from 'react';
import 'antd/dist/antd.css';
import { Button, Table } from 'antd';
import { useState, } from 'react';
import 'antd/dist/antd.css';

import { Divider, Select, Space,  } from 'antd';

const columns = [
    {
      title: <h3>Parenting Information</h3>,
      dataIndex: 'name',
    },
    {
      title: <h3 >Visibility</h3>,
      dataIndex: 'address',
    },
  ];

const data = [
    {
      key: '1',
      name: 'Child Name',
      address: <a style={{color:'#ff5a5f'}}>Manage Privacy</a>,
    },
    {
      key: '2',
      name: 'DOB',
      address: <a style={{color:'#ff5a5f'}}>Manage Privacy</a>,
    },
    {
      key: '3',
      name: 'Nick Name',
      address: <a style={{color:'#ff5a5f'}}>Manage Privacy</a>,
    },
    {
        key: '4',
        name: 'Gender',
        address: <a style={{color:'#ff5a5f'}}>Manage Privacy</a>,
      },
  ];
  
  const column = [
    {
      title: <h3>Personal Information</h3>,
      dataIndex: 'name',
    },
    {
      title: <h3>Visibility</h3>,
      dataIndex: 'address',
    },
  ];

  const datas = [
    {
      key: '1',
      name: 'First Name',
      address: <p>Public</p>,
    },
    {
      key: '2',
      name: 'DOB',
      address: <a style={{color:'#ff5a5f'}}>Manage Privacy</a>,
    },
    {
      key: '3',
      name: 'User Name',
      address: <p>Public</p>,
    },
   
  ];


  
function Privacy() {

  const { Option } = Select;

  const [items] = useState(['Public','All Members', 'My Connections' , 'Only Me']);



  const column = [
    {
      title: <h3>Personal Information</h3>,
      dataIndex: 'name',
    },
    {
      title: <h3 className='text-center'>Visibility</h3>,
      dataIndex: 'address',
    },
  ];

  const datas = [
    {
      key: '1',
      name: 'First Name',
      address: <p className='text-center'>Public</p>,
    },
    {
      key: '2',
      name: 'Last Name',
      address:<div className='text-center'>
                <Select
                style={{
                    width: 200,
                }}
                placeholder="Public"
                dropdownRender={(menu) => (
                    <>
                     {menu}
                    </>
                )}
                >
                {items.map((item) => (
                    <Option value={item} key={item}>{item}</Option>
                ))}
                </Select>
              </div>,
    },
    {
      key: '3',
      name: 'User Name',
      address: <p className='text-center'>Public</p>,
    },
   
  ];

  const colum = [
    {
      title: <h3>Contact Information</h3>,
      dataIndex: 'name',
    },
    {
      title: <h3 className='text-center'>Visibility</h3>,
      dataIndex: 'address',
    },
  ];

  const datass = [
    {
      key: '1',
      name: 'Email Address',
      address:<div className='text-center'>
                <Select
                style={{
                    width: 200,
                }}
                placeholder="Public"
                dropdownRender={(menu) => (
                    <>
                     {menu}
                    </>
                )}
                >
                {items.map((item) => (
                    <Option value={item} key={item}>{item}</Option>
                ))}
                </Select>
              </div>,
    },

    {
        key: '2',
        name: 'Pin Code',
        address:<div className='text-center'>
                  <Select
                  style={{
                      width: 200,
                  }}
                  placeholder="Public"
                  dropdownRender={(menu) => (
                      <>
                       {menu}
                      </>
                  )}
                  >
                  {items.map((item) => (
                      <Option value={item} key={item}>{item}</Option>
                  ))}
                  </Select>
                </div>,
      },

      {
        key: '3',
        name: 'Addrees',
        address:<div className='text-center'>
                  <Select
                  style={{
                      width: 200,
                  }}
                  placeholder="Public"
                  dropdownRender={(menu) => (
                      <>
                       {menu}
                      </>
                  )}
                  >
                  {items.map((item) => (
                      <Option value={item} key={item}>{item}</Option>
                  ))}
                  </Select>
                </div>,
      },

      {
        key: '4',
        name: 'Country',
        address:<div className='text-center'>
                  <Select
                  style={{
                      width: 200,
                  }}
                  placeholder="Public"
                  dropdownRender={(menu) => (
                      <>
                       {menu}
                      </>
                  )}
                  >
                  {items.map((item) => (
                      <Option value={item} key={item}>{item}</Option>
                  ))}
                  </Select>
                </div>,
      },
   
  ];
  
  
  return (
    <div className='p-4 lg:py-10 lg:px-20 bg-white'>
        <h3 className='text-xl font-light mb-7'>Profile Visibility Settings</h3>
        <p className='pb-5'>Select who may see your profile details.</p>
        <div className='privacy_table'>
            <Table style={{backgroundColor:"#fff"}} columns={columns} dataSource={data} size="middle" />
            <Table style={{backgroundColor:"#fff"}} columns={column} dataSource={datas} size="middle" />
            <Table style={{backgroundColor:"#fff"}} columns={colum} dataSource={datass} size="middle" />
        </div>
        <Button htmlType="submit" className='mt-10' style={{backgroundColor:"#ff5a5f",borderRadius:"50px",color:"#fff"}}>
              Save Changes
        </Button>
    </div>
  )
}

export default Privacy