import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { rootReducer } from "reducers";
import { createBrowserHistory } from "history";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
export const history = createBrowserHistory({basename:process.env.PUBLIC_URL});
export default function configureStore(preloadedState?: any) {
    const composeEnhancer: typeof compose =
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    
      const persistConfig = {
        key: 'root',
        storage,
      }

    const store = createStore(

      //@ts-ignore
      persistReducer(persistConfig, rootReducer(history)),
      preloadedState,
      composeEnhancer(
        applyMiddleware(
          thunkMiddleware,
          routerMiddleware(history)
        )
      )
    );
  
    return store;
  }