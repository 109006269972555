import React, { useState, useEffect } from 'react'
import { Card, Carousel, Skeleton, Tabs, Input, Collapse, Button } from "antd";
import { LeftOutlined, RightOutlined, EditOutlined } from '@ant-design/icons'
import { CarouselRef } from 'antd/lib/carousel';
import { useRef } from 'react';
import OverView from './OverView';
import Fertile from './Fertile';
import NextPeriod from './NextPeriod';
import { useInfiniteQuery, useQuery } from "react-query";
import { getBannerApi, getLikesapi, getListsApi, getFeedsApi, getBannerByPageNo } from "apis/GetFeeds"
import SetUpTracker from './SetUpTracker';

type CustomCarouselRef = CarouselRef & {};
const Calendar = (props: any) => {
  const carouselRef = useRef<CustomCarouselRef>(null);
  const [openOverview, setOpenoverview] = useState(false)
  const [openFertileModal, setOpenFertileModal] = useState(false)
  const [openNextPeriodModal, setOpenNextPeriodModal] = useState(false)
  const [isOpenTracker, setIsOpenTracker] = useState(false);
  const [currentSlide, setCurrentSlide] = useState<number>(1);
  // console.log(currentSlide);

  const prev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  const next = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };



  const { data, isLoading, isError } = useQuery(
    ['getBanner', { pageParam: currentSlide }],
    () => getBannerByPageNo({ pageParam: currentSlide })
  );

  // console.log(data && data.data);




  const handleTracker = () => {
    setIsOpenTracker(true)
  }


  const handleOpenPopup = (e: any, value: any) => {
    if (value === 'overview') {
      setOpenoverview(true)
    }
    else if (value === "fertile-window") {
      setOpenFertileModal(true)
    }
    else {
      setOpenNextPeriodModal(true)
    }


  }

  return (
    <>
      {
        isOpenTracker &&

        <SetUpTracker setIsOpenTracker={setIsOpenTracker} isOpenTracker={isOpenTracker} />}
      {openNextPeriodModal &&
        <NextPeriod openNextPeriodModal={openNextPeriodModal} setOpenNextPeriodModal={setOpenNextPeriodModal}
          data={data && data.data.calendar}
        />
      }
      {
        openOverview &&
        <OverView setOpenoverview={setOpenoverview} openOverview={openOverview} data={data && data.data.calendar} />}
      {
        openFertileModal &&
        <Fertile setOpenFertileModal={setOpenFertileModal} openFertileModal={openFertileModal}
          data={data && data.data.calendar}
        />}

      {
        data && data.data.calendar ? (
          <div className='line '>
            <div className='mt-3 mb-3' style={{
              borderRadius: '24px'
            }}>

              <Card style={{
                padding: '10px',
                borderRadius: '8px',
                backgroundColor: data && data.data.calendar.color,
              }}>
                <div className="carousel-container">
                  <Carousel ref={carouselRef} dots={false}>
                    <div className="carousel-item">
                      <p style={{
                        color: data && data.data.calendar.color === '#FFFFFF' ? 'black' : 'white',
                        fontSize: "16px"
                      }}>{data && data.data.calendar.date}</p>
                      <p style={{
                        color: data && data.data.calendar.color === '#FFFFFF' ? 'black' : 'white',
                        fontSize: "16px"
                      }}>{data && data.data.calendar.preTitle}</p>
                      <h2 style={{
                        color: data && data.data.calendar.color === '#FFFFFF' ? 'black' : 'white',
                        fontSize: "16px"
                      }}>{data && data.data.calendar.title}</h2>
                      <p
                        style={{
                          color: data && data.data.calendar.subtitleColor
                        }}
                      >{data && data.data.calendar.subtitle}<EditOutlined
                          onClick={handleTracker}
                        /></p>
                    </div>
                  </Carousel>

                  <div className="carousel-navigation">
                    <div className="carousel-button-left">
                      <button onClick={prev} style={{
                        color: data && data.data.calendar.color === '#FFFFFF' ? 'black' : 'white',
                        fontSize: "16px"
                      }}><LeftOutlined
                      /></button>
                    </div>
                    <div className="carousel-button-right">
                      <button onClick={next} style={{
                        color: data && data.data.calendar.color === '#FFFFFF' ? 'black' : 'white',
                        fontSize: "16px"
                      }}><RightOutlined /></button>
                    </div>
                  </div>
                </div>

                <div className='mb-10' style={{
                  display: 'flex',
                  gap: "10px",
                  overflowX: 'auto',
                }}>
                  {data &&
                    data.data.calendar.buttons.map((val: any, i: number) => {
                      return (
                        <div key={i} className='carousel-cal-buttons'>
                          <button style={{ backgroundColor: val.color }}
                            onClick={(e) => handleOpenPopup(e, val.action)}
                          >
                            <span style={{
                              display: 'flex', textAlign: 'center',
                              marginLeft: '20%',
                              gap: '10px',
                              fontWeight: 'bold'
                            }}>
                              <img src={val.icon
                              } alt="val.title" /> {val.title} </span></button>
                        </div>
                      );
                    })}
                </div>

              </Card>
            </div>
          </div>) : (null)
      }
    </>
  )
}




{/* <button onClick={showOverview}>Overview</button>
                  <button onClick={showFertile}>Fertile Window</button>
                  <button onClick={showNextPeriod}>Next Period</button> */}
{/* <div className='carousel-cal-buttons' > */ }

export default Calendar