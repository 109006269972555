import React, { useState, useEffect,} from "react";
import { Layout,  message, Slider, Card, Image, Collapse, Col } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { contraction_tool } from "../../../../apis/btpTools";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Play from "../icon/Play.svg";
import Contraction from "../icon/contraction.svg";
import Pause from "../icon/Pause.svg";
import Reset from "../icon/Reset.svg";
import Start from "../icon/Start.svg";
import Flag from "../icon/Flag.svg";

const { Panel } = Collapse;

const ContractionCalculator = () => {
  const history = useHistory();

  const hndleClickHome = () => {
    history.push("/");
  };

  const panels = [
    {
      key: 1,
      header: "How is contraction calculated?",
      content: (
        <p>
          The duration of a contraction is calculated from the moment it starts until it ends. For example , if a contraction starts at 10
          a.m. ends 30 seconds later, its duration is 30 seconds.
        </p>
      ),
    },
    {
      key: 2,
      header: "What is the 5 5 5 rule for contractions?",
      content: (
        <p>
          5 5 5 rule of contraction refers to when your contraction are five minutes apart and lasting 60 seconds and you've had this
          activity for about an hour.
        </p>
      ),
    },
    {
      key: 3,
      header: "What is the 5-1-1 rule for contractions?",
      content: (
        <>
          {" "}
          <p>
            5-1-1 rule refers to when your contractions are 5 minutes apart, lasting 1 minutes each for an hour consistently and increasing
            in strength/intensity.
          </p>
        </>
      ),
    },
  ];

  const [showContraction, setShowContraction] = useState(false);
  const [showStart, setShowStart] = useState(false);

  const handleClickPlay = () => {
    setShowContraction(true);
    handleStart()
  };

  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [sessionData, setSessionData] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>("");
  const handleStart = () => {
    if (!timer) {
      const interval = setInterval(() => {
        setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
      }, 1000);
      setTimer(interval);
      setShowStart(true);
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = elapsedTime % 60;
      const timerStart = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;


      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const m = String(currentDate.getMinutes()).padStart(2, '0');
      const s = String(currentDate.getSeconds()).padStart(2, '0');
      const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      const formattedDate = `${year}-${month}-${day} ${hours}:${m}:${s}.${milliseconds}`;

      setStartTime(formattedDate);
      setSessionData((prevData: any) => [...prevData, { timeStart: timerStart, Timerpaused: "" }]);
    }
  };

  const handlePause = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
      setShowStart(false);
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = elapsedTime % 60;
      const timerPaused = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const m = String(currentDate.getMinutes()).padStart(2, '0');
      const s = String(currentDate.getSeconds()).padStart(2, '0');
      const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
      const formattedDate = `${year}-${month}-${day} ${hours}:${m}:${s}.${milliseconds}`;
      let data = JSON.stringify({
        startTime: startTime,
        EndTime: formattedDate,
      });
      contraction_tool(data)
        .then(res => {
        })
        .catch(err => {
          message.error(err);
        });

      setSessionData((prevData: any) => {
        const updatedData = [...prevData];
        if (updatedData.length > 0) {
          updatedData[updatedData.length - 1].Timerpaused = timerPaused;
        }
        return updatedData;
      });
    }
  };

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  const minutes = Math.floor(elapsedTime / 60);
  const seconds = elapsedTime % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  const [sliderValue, setSliderValue] = useState(1);

  useEffect(() => {
    setSliderValue(seconds);
  }, [seconds]);

  const handleChange = (e: any) => {
    setSliderValue(seconds);
  };

  const handleReset = () => {
    setElapsedTime(0);
    setSliderValue(1);
    setSessionData([]);
  };
  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-15">
          <Content className="container blood-type-preg">
            <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
              {showContraction == false && (
                <Card style={{ border: "none" }}>
                  <div className="preg-calc-card-res">
                    <div className="rectangle-parent-contraction">
                      <div className="frame-child-blood-contraction">
                        <div className="line-icons-parent">
                          <ArrowLeftOutlined className="line-icons" />
                          <div className="pregnancy-weight-gain">
                            <button onClick={hndleClickHome}>Contaction Calculator</button>
                          </div>
                        </div>

                        <div
                          style={{
                            marginLeft: "30%",
                            marginRight: "30%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}>
                          <div style={{ marginBottom: "20px", marginTop: "90px" }}>
                            <Image alt="" src={Contraction} width={200} height={200} preview={false} style={{ borderRadius: "100%" }} />
                          </div>
                          <p
                            style={{
                              fontSize: "48px",
                              fontWeight: "400",
                              lineHeight: "130%",
                              color: "#fff",
                            }}>
                            00:00
                          </p>
                          <div style={{ marginTop: "20px" }}>
                            <Image
                              alt=""
                              src={Play}
                              width={160}
                              height={160}
                              preview={false}
                              style={{ borderRadius: "100%", cursor: "pointer" }}
                              onClick={handleClickPlay}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="group-wrapper-faq-ques">
                      <div className="group-wrapper-faq">
                        <div className="result-parent-faq">
                          <div className="result">FAQs</div>
                          <div key={1}>
                            <Collapse
                              style={{
                                backgroundColor: "#F0F0F0",
                              }}
                              expandIconPosition="end">
                              {panels?.map((vv: any, index: number) => {
                                return (
                                  <>
                                    <Panel
                                      key={index + 1}
                                      header={`${index + 1}.` + vv?.header}
                                      style={{
                                        marginBottom: "10px",
                                        border: "1px solid #ccc",
                                        padding: "10px",
                                        borderRadius: "8px",
                                        backgroundColor: "#F0F0F0",
                                      }}>
                                      <div>
                                        <p
                                          style={{
                                            backgroundColor: "#F0F0F0",
                                            padding: "16px",
                                          }}>
                                          <span>{vv?.content}</span>
                                        </p>
                                      </div>
                                    </Panel>
                                  </>
                                );
                              })}
                            </Collapse>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              )}

              {showContraction == true && (
                <Card style={{ border: "none" }}>
                  <div className="preg-calc-card-res">
                    <div className="rectangle-parent">
                      <div className="frame-child-blood"></div>
                      <div className="line-icons-parent">
                        <ArrowLeftOutlined className="line-icons" />
                        <div className="pregnancy-weight-gain">
                          <button onClick={hndleClickHome}>Contaction Calculator</button>
                        </div>
                      </div>

                      <div
                        style={{
                          marginLeft: "30%",
                          marginRight: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}>
                        <div style={{ marginBottom: "20px", marginTop: "90px" }}>
                          <Image alt="" src={Contraction} width={200} height={200} preview={false} style={{ borderRadius: "100%" }} />
                        </div>
                      </div>
                    </div>

                    {showStart == false && (
                      <div className="group-wrapper-faq-ques" style={{ height: "auto" }}>
                        <div>
                          <div>
                            <p>{formattedTime}</p>
                          </div>
                          <Col span={24}>
                            <Slider
                              min={1}
                              max={60}
                              value={sliderValue}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                              }}
                              trackStyle={{
                                backgroundColor: "#ED4D41",
                                height: "6px",
                              }}
                              handleStyle={{
                                borderColor: "#ED4D41",
                                height: "18px",
                                width: "18px",
                                marginTop: "-6px",
                                backgroundColor: "#ED4D41",
                              }}
                            />
                          </Col>
                          <div style={{ padding: "5%", textAlign: "center" }}>
                            {sessionData?.length !== 0 && (
                              <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                <div style={{ flex: 1 }}>
                                  <p>
                                    <span>&nbsp;</span>
                                  </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <p>Length</p>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <p>Frequency</p>
                                </div>
                              </div>
                            )}

                            {sessionData
                              .slice()
                              .reverse()
                              .map((val: any, index: number, arr: any[]) => {
                                const timeStringToDate = (time: string): Date => {
                                  const [hours, minutes] = time.split(":").map(Number);
                                  const date = new Date();
                                  date.setHours(hours, minutes, 0, 0);
                                  return date;
                                };

                                const timeStart = val?.timeStart;
                                const timerPaused = val?.Timerpaused;
                                const startDate = timeStringToDate(timeStart);
                                const pausedDate = timeStringToDate(timerPaused);
                                const difference = pausedDate.getTime() - startDate.getTime();
                                const durationHours = Math.floor(difference / (1000 * 60 * 60));
                                const durationMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                                const durationString = `${durationHours.toString().padStart(2, "0")}:${durationMinutes
                                  .toString()
                                  .padStart(2, "0")}`;

                                const adjustedIndex = sessionData.length - index - 0;
                                return (
                                  <>
                                    <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                      <div className="flex" style={{ flex: 1 }}>
                                        <img src={Flag} style={{ width: "14px", height: "14px" }} className="mt-1" />
                                        &nbsp;
                                        <p>{adjustedIndex}</p>
                                      </div>
                                      <div style={{ flex: 1 }}>
                                        <p key={adjustedIndex}>{val?.Timerpaused}</p>
                                      </div>
                                      <div style={{ flex: 1 }}>{val?.timeStart === "00:00" ? <p>First</p> : <p>{durationString}</p>}</div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div className="text-center">
                            <Image src={Start} width={140} height={140} preview={false} className="cursor-pointer" onClick={handleStart} />
                            <p className="cursor-pointer" onClick={handleStart}>
                            {sessionData.length==0 ? <span>Start</span>:<span>Start again</span>}
                            </p>
                          </div>

                          <div className="text-center">
                            <Image src={Reset} width={140} height={140} preview={false} className="cursor-pointer" onClick={handleReset} />
                            <p className="cursor-pointer" onClick={handleReset}>
                              Reset
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {showStart == true && (
                      <div className="group-wrapper-faq-ques" style={{ height: "auto" }}>
                        <div>
                          <div className="frame-parent mt-5">
                            <div className="frame-group">
                              <div className="o" style={{ textAlign: "center" }}>
                                <p style={{ fontSize: "48px", color: "#333333" }}>{formattedTime}</p>
                              </div>
                            </div>
                          </div>

                          <Col span={24}>
                            <Slider
                              min={1}
                              max={60}
                              value={sliderValue}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                              }}
                              trackStyle={{
                                backgroundColor: "#ED4D41",
                                height: "6px",
                              }}
                              handleStyle={{
                                borderColor: "#ED4D41",
                                height: "18px",
                                width: "18px",
                                marginTop: "-6px",
                                backgroundColor: "#ED4D41",
                              }}
                            />
                          </Col>
                        </div>

                        <div className="frame-parent mt-5">
                          <div className="frame-group">
                            <div className="o" style={{ textAlign: "center" }}>
                              <Image
                                src={Pause}
                                width={130}
                                height={130}
                                preview={false}
                                className="cursor-pointer"
                                onClick={handlePause}
                              />
                              <p className="cursor-pointer" onClick={handlePause}>
                                Resume
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              )}
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default ContractionCalculator;
