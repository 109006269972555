import React, { useEffect, useState } from "react";
import {
  Layout,
  Tabs,
  Select,
  Card,
  Avatar,
  Button,
  List,
  Tooltip,
  Badge,
} from "antd";
import {
  EditOutlined,
  FolderOutlined,
  UserOutlined,
  ExclamationOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import { getConnectionReq, parentConnectionDetail } from "apis/ViewProfile";
import { TRootState } from "reducers";
import { useSelector } from "react-redux";
import { TCoonectionReq } from "actions/viewprofile";
import Followbutton from "components/global/buttonsection/Followbutton";

const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;
const { Meta } = Card;

interface profileType {
  image: string;
  name: string;
  description: string;
}
const { Option } = Select;
const Momslikelist = (props: any) => {
  const [cards, setCards] = useState<any>([]);
  const [cardCount, setCardCount] = useState([]);
  const [cardCount1, setCardCount1] = useState([]);
  const [cardCount2, setCardCount2] = useState([]);
  const [cardCount3, setCardCount3] = useState([]);
  const [cardCount4, setCardCount4] = useState([]);

  const [show, setShow] = useState(true);
  const [list, setList] = useState(false);

  const like_stylus = "border-0 text-red-600 hover:text-red-300";
  const handleChange = (value: { value: string; label: React.ReactNode }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
  };

  const handleChange1 = (value: { value: string; label: React.ReactNode }) => {
    console.log(value); // { value: "lucy", key: "lucy", label: "Lucy (101)" }
    if (value.value === "Fathers") {
      setShow(false);
    } else if (value.value === "Pregnants") {
      setShow(true);
    }
  };

  const handleList = () => {
    setList(false);
  };
  const handlegrid = () => {
    setList(true);
  };
  const onChange = (key: string) => {
    console.log(key);
    props.setDefaultActiveSub(key);
  };
  const { Accesstoken } = useSelector((state: TRootState) => state.auth);
  const [activeKey, setActiveKey] = React.useState("all");

  useEffect(() => {
    const reqBody = {
      user_id: Accesstoken,
      type: "momslikeyou",
      filter_type: activeKey,
    };
    // console.log("route macth", Accesstoken);
    if (reqBody.filter_type === "all") {
      parentConnectionDetail(reqBody as TCoonectionReq).then(
        (res) => {
          // setRequestData(res.data)
          console.log(res.data[0])
          if(res.data[0]){
            const allcount = res.data[0].momslikeyou.length;
            setCards(res.data[0].momslikeyou);
            setCardCount(allcount);

          }
        }
      );
    }
    if (reqBody.filter_type === "myConnections") {
      parentConnectionDetail(reqBody as TCoonectionReq).then(
        (res) => {
          // setRequestData(res.data)
          const myConnectionscount = res.data[0].momslikeyou.length;
          setCards(res.data[0].momslikeyou);
          setCardCount1(myConnectionscount);
          console.log("getConnectionReq", res.data);
        }
      );
    }

    else if (reqBody.filter_type === "followers") {
      parentConnectionDetail(reqBody as TCoonectionReq).then(
        (res) => {
          // setRequestData(res.data)
          const followerscount = res.data[0].momslikeyou.length;
          setCards(res.data[0].momslikeyou);
          setCardCount2(followerscount);

          console.log("getConnectionReq", res.data);
        }
      );

    } else if (reqBody.filter_type === "following") {
      parentConnectionDetail(reqBody as TCoonectionReq).then(
        (res) => {
          // setRequestData(res.data)
          const followingcount = res.data[0].momslikeyou.length;
          setCards(res.data[0].momslikeyou);
          console.log("momslikeyou", res.data[0].momslikeyou);

          setCardCount3(followingcount);

          console.log("getConnectionReq", res.data);
        }
      );
    } else if (reqBody.filter_type === "new_member") {
      parentConnectionDetail(reqBody as TCoonectionReq).then(
        (res) => {
          const new_membercount = res.data[0].momslikeyou.length;
          console.log("momslikeyou", res.data[0].momslikeyou);

          setCards(res.data[0].momslikeyou);
          setCardCount4(new_membercount);

          console.log("getConnectionReq", res.data);
        }
      );
    }
  }, [activeKey, Accesstoken]);

  const onKeyChange = (key: any) => {
    setActiveKey(key);
    console.log("first_key", key);
  };

  useEffect(() => {
    console.log("crsasdafdsf", cards);
  }, [cards]);

  return (
    <div>
      <Content style={{ padding: "50px", backgroundColor: "#fafbfd" }}>
        <Tabs
          defaultActiveKey="all"
          className="Moms_Following_list"
          onChange={onKeyChange}
          activeKey={activeKey}
        >
          <Tabs.TabPane
            tab={
              <span className="badge">
                All
                <Badge className="badge-style" />
              </span>
            }
            key="all"
          >
            {list ? (
              <div>
                {show ? (
                  <div>
                    {cards.length > 0 ? (
                      <List className="">
                        {cards.map((item: any) => (
                          <List.Item
                            className="border ml-4 hover:shadow Card-moms"
                          >
                            {item.profile_pic ? (
                              <img
                                alt="example"
                                src={item.profile_pic}
                                className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                              />
                            ) : (
                              <Avatar
                                shape="circle"
                                icon={<UserOutlined size={50} />}
                                size={50}
                                style={{ margin: "0 15px" }}
                              />
                            )}

                            <List.Item.Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag">
                              <p className="px-7">{item.follow}Followers</p>
                              <div>
                            <Followbutton {...item} class={like_stylus} />
                          </div>
                            </div>
                          </List.Item>
                        ))}
                      </List>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {show ? (
                  <div className="card-sty">
                    {cards.map((item: any) => (
                      <Card
                        style={{ width: 200 }}
                        className="Card-moms"
                        cover={
                          <>
                            {item.profile_pic ? (
                              <img
                                alt="example"
                                src={item.profile_pic}
                                className="image_logo  object-center"
                                style={{
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <img
                                alt="example"
                                src={item.gender_pic}
                                className="image_logo  object-center"
                                style={{
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          </>
                        }
                        // actions={[
                        //   <Tooltip title="Cancel Connection Request">
                        //     <UserDeleteOutlined key="user" />,
                        //   </Tooltip>,
                        // ]}
                      >
                        <Meta
                          title={item.full_name}
                          description={item.user_stage_name}
                        />
                        <div className="text-center pt-2">
                          <p>Mutual {item.mutual_connection}</p>
                        </div>
                        <div className="p-tag flex items-center">
                          {/* <p className="py-4">0 followers</p> */}
                          <p className="">
                            {item.follow}{" "}
                            <span className="follow_section">followers</span>{" "}
                          </p>
                          <div>
                            <Followbutton {...item} class={like_stylus} />
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span className="badge">
                My Connections
                <Badge className="badge-style" />
              </span>
            }
            key="myConnections"
          >
            <div className="flex flex-row flex-wrap gap-2 xs:justify-items-start justify-end mb-5">
              <Select
                labelInValue
                defaultValue={{ value: "All Types", label: "All Types" }}
                onChange={handleChange1}
              >
                <Option value="All Types">All Types</Option>
                <Option value="Fathers">Fathers</Option>
                <Option value="Pregnants">Pregnants</Option>
              </Select>
              <Select
                labelInValue
                defaultValue={{
                  value: "Recently Active",
                  label: "Recently Active",
                }}
                onChange={handleChange}
              >
                <Option value="Recently Active">Recently Active</Option>
                <Option value="Newest Members">Newest Members</Option>
                <Option value="Alphabetical">Alphabetical</Option>
              </Select>
              <span className="flex flex-row mr-5  align-middle">
                <Tooltip title="Grid View">
                  <AppstoreOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handleList}
                  />
                </Tooltip>
                <Tooltip title="List View">
                  <UnorderedListOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handlegrid}
                  />
                </Tooltip>
              </span>
            </div>
            {list ? (
              <div>
                {show ? (
                  <div>
                    {cards.length > 0 ? (
                      <List className="">
                        {cards.map((item: any) => (
                          <>
                            {item ? (
                              <List.Item
                                className="border ml-4 hover:shadow"
                                // actions={[
                                //   <UserOutlined key="user" />,
                                //   <FolderOutlined key="message" />,
                                // ]}
                              >
                                {item.profile_pic ? (
                                  <img
                                    alt="example"
                                    src={item.profile_pic}
                                    className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                                  />
                                ) : (
                                  <Avatar
                                    shape="circle"
                                    icon={<UserOutlined size={50} />}
                                    size={50}
                                    style={{ margin: "0 15px" }}
                                  />
                                )}

                                <List.Item.Meta
                                  title={item.full_name}
                                  description={item.user_stage_name}
                                />
                                <div className="text-center pt-2">
                                  <p>Mutual {item.mutual_connection}</p>
                                </div>
                                <div className="p-tag">
                                  <p className="px-7">{item.follow}Followers</p>
                                  <div>
                            <Followbutton {...item} class={like_stylus} />
                          </div>
                                </div>
                              </List.Item>
                            ) : (
                              <p> fkjfkjfuffhufufufh</p>
                            )}
                          </>
                        ))}
                      </List>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {show ? (
                  <div className="card-sty mr-2">
                    {cards.length > 0 ? (
                      <>
                        {cards.map((item: any) => (
                          <Card
                            className="Card-moms"
                            style={{ width: 200 }}
                            cover={
                              <>
                                {item.profile_pic ? (
                                  <img
                                    alt="example"
                                    src={item.profile_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="example"
                                    src={item.gender_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                              </>
                            }
                            // actions={[
                            //   <UserOutlined key="user" />,
                            //   <FolderOutlined key="message" />,
                            // ]}
                          >
                            <Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag flex items-center">
                              {/* <p className="py-4">0 followers</p> */}
                              <p className="">
                                {item.follow}{" "}
                                <span className="follow_section">
                                  followers
                                </span>{" "}
                              </p>
                              <div>
                            <Followbutton {...item} class={like_stylus} />
                          </div>
                            </div>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span className="badge">
                Following
                <Badge className="badge-style" />
              </span>
            }
            key="following"
          >
            <div className="flex flex-row flex-wrap gap-2 xs:justify-items-start justify-end mb-5">
              <Select
                labelInValue
                defaultValue={{ value: "All Types", label: "All Types" }}
                onChange={handleChange1}
              >
                <Option value="All Types">All Types</Option>
                <Option value="Fathers">Fathers</Option>
                <Option value="Pregnants">Pregnants</Option>
              </Select>
              <Select
                labelInValue
                defaultValue={{
                  value: "Recently Active",
                  label: "Recently Active",
                }}
                onChange={handleChange}
              >
                <Option value="Recently Active">Recently Active</Option>
                <Option value="Newest Members">Newest Members</Option>
                <Option value="Alphabetical">Alphabetical</Option>
              </Select>
              <span className="flex flex-row mr-5  align-middle">
                <Tooltip title="Grid View">
                  <AppstoreOutlined
                    style={{ fontSize: "28px" }}
                    className="border"
                    onClick={handleList}
                  />
                </Tooltip>
                <Tooltip title="List View">
                  <UnorderedListOutlined
                    style={{ fontSize: "28px" }}
                    className="border"
                    onClick={handlegrid}
                  />
                </Tooltip>
              </span>
            </div>
            {list ? (
              <div>
                {show ? (
                  <div>
                    {cards.length > 0 ? (
                      <List className="">
                        {cards.map((item: any) => (
                          <List.Item
                            className="border ml-4 hover:shadow"
                            // actions={[
                            //   <UserOutlined key="user" />,
                            //   <FolderOutlined key="message" />,
                            // ]}
                          >
                            {item.profile_pic ? (
                              <img
                                alt="example"
                                src={item.profile_pic}
                                className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                              />
                            ) : (
                              <Avatar
                                shape="circle"
                                icon={<UserOutlined size={50} />}
                                size={50}
                                style={{ margin: "0 15px" }}
                              />
                            )}

                            <List.Item.Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag">
                              <p className="px-7">{item.follow}Followers</p>
                              <div>
                                <Followbutton {...item} class={like_stylus} />
                              </div>
                            </div>
                          </List.Item>
                        ))}
                      </List>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {show ? (
                  <div className="card-sty mr-2">
                    {cards.length > 0 ? (
                      <>
                        {cards.map((item: any) => (
                          <Card
                            className="Card-moms"
                            style={{ width: 200 }}
                            cover={
                              <>
                                {item.profile_pic ? (
                                  <img
                                    alt="example"
                                    src={item.profile_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="example"
                                    src={item.gender_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                              </>
                            }
                            // actions={[
                            //   <UserOutlined key="user" />,
                            //   <FolderOutlined key="message" />,
                            // ]}
                          >
                            <Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag flex items-center">
                              {/* <p className="py-4">0 followers</p> */}
                              <p className="">
                                {item.follow}{" "}
                                <span className="follow_section">
                                  followers
                                </span>{" "}
                              </p>
                              <div>
                                <Followbutton {...item} class={like_stylus}  />
                              </div>
                            </div>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span className="badge">
                Followers
                <Badge className="badge-style"/>
              </span>
            }
            key="followers"
          >
            <div className="flex flex-row flex-wrap gap-2 xs:justify-items-start justify-end mb-5">
              <Select
                labelInValue
                defaultValue={{ value: "All Types", label: "All Types" }}
                onChange={handleChange1}
              >
                <Option value="All Types">All Types</Option>
                <Option value="Fathers">Fathers</Option>
                <Option value="Pregnants">Pregnants</Option>
              </Select>
              <Select
                labelInValue
                defaultValue={{
                  value: "Recently Active",
                  label: "Recently Active",
                }}
                onChange={handleChange}
              >
                <Option value="Recently Active">Recently Active</Option>
                <Option value="Newest Members">Newest Members</Option>
                <Option value="Alphabetical">Alphabetical</Option>
              </Select>
              <span className="flex flex-row mr-5  align-middle">
                <Tooltip title="Grid View">
                  <AppstoreOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handleList}
                  />
                </Tooltip>
                <Tooltip title="List View">
                  <UnorderedListOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handlegrid}
                  />
                </Tooltip>
              </span>
            </div>
            {list ? (
              <div>
                {show ? (
                  <div>
                    {cards.length > 0 ? (
                      <List className="">
                        {cards.map((item: any) => (
                          <List.Item
                            className="border ml-4 hover:shadow"
                            // actions={[
                            //   <UserOutlined key="user" />,
                            //   <FolderOutlined key="message" />,
                            // ]}
                          >
                            {item.profile_pic ? (
                              <img
                                alt="example"
                                src={item.profile_pic}
                                className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                              />
                            ) : (
                              <Avatar
                                shape="circle"
                                icon={<UserOutlined size={50} />}
                                size={50}
                                style={{ margin: "0 15px" }}
                              />
                            )}

                            <List.Item.Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag">
                              <p className="px-7">{item.follow}Followers</p>
                              <div>
                                <Followbutton {...item} class={like_stylus} />
                              </div>
                            </div>
                          </List.Item>
                        ))}
                      </List>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {show ? (
                  <div className="card-sty mr-2">
                    {cards.length > 0 ? (
                      <>
                        {cards.map((item: any) => (
                          <Card
                            className="Card-moms"
                            style={{ width: 200 }}
                            cover={
                              <>
                                {item.profile_pic ? (
                                  <img
                                    alt="example"
                                    src={item.profile_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="example"
                                    src={item.gender_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                              </>
                            }
                            // actions={[
                            //   <UserOutlined key="user" />,
                            //   <FolderOutlined key="message" />,
                            // ]}
                          >
                            <Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag flex items-center">
                              {/* <p className="py-4">0 followers</p> */}
                              <p className="">
                                {item.follow}
                                <span className="follow_section">
                                  followers
                                </span>
                              </p>
                              <div>
                                <Followbutton {...item} class={like_stylus} />
                              </div>
                            </div>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <span className="badge">
                New Members
                <Badge className="badge-style"/>
              </span>
            }
            key="new_member"
          >
            <div className="flex flex-row flex-wrap gap-2 xs:justify-items-start justify-end mb-5">
              <Select
                labelInValue
                defaultValue={{ value: "All Types", label: "All Types" }}
                onChange={handleChange1}
              >
                <Option value="All Types">All Types</Option>
                <Option value="Fathers">Fathers</Option>
                <Option value="Pregnants">Pregnants</Option>
              </Select>
              <Select
                labelInValue
                defaultValue={{
                  value: "Recently Active",
                  label: "Recently Active",
                }}
                onChange={handleChange}
              >
                <Option value="Recently Active">Recently Active</Option>
                <Option value="Newest Members">Newest Members</Option>
                <Option value="Alphabetical">Alphabetical</Option>
              </Select>
              <span className="flex flex-row mr-5  align-middle">
                <Tooltip title="Grid View">
                  <AppstoreOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handleList}
                  />
                </Tooltip>
                <Tooltip title="List View">
                  <UnorderedListOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handlegrid}
                  />
                </Tooltip>
              </span>
            </div>
            {list ? (
              <div>
                {show ? (
                  <div>
                    {cards.length > 0 ? (
                      <List className="">
                        <>
                          {cards.map((item: any) => (
                            <List.Item
                              className="border ml-4 hover:shadow"
                              // actions={[
                              //   <UserOutlined key="user" />,
                              //   <FolderOutlined key="message" />,
                              // ]}
                            >
                              {item.profile_pic ? (
                                <img
                                  alt="example"
                                  src={item.profile_pic}
                                  className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                                />
                              ) : (
                                <Avatar
                                  shape="circle"
                                  icon={<UserOutlined size={50} />}
                                  size={50}
                                  style={{ margin: "0 15px" }}
                                />
                              )}

                              <List.Item.Meta
                                title={item.full_name}
                                description={item.user_stage_name}
                              />
                              <div className="text-center pt-2">
                                <p>Mutual {item.mutual_connection}</p>
                              </div>
                              <div className="p-tag">
                                <p className="px-7">{item.follow} Followers</p>
                                <div>
                                  <Followbutton {...item} class={like_stylus} />
                                </div>
                              </div>
                            </List.Item>
                          ))}
                        </>
                      </List>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {show ? (
                  <div className="card-sty mr-2">
                    {cards.length > 0 ? (
                      <>
                        {cards.map((item: any) => (
                          <Card
                            className="Card-moms"
                            style={{ width: 200 }}
                            cover={
                              <>
                                {item.profile_pic ? (
                                  <img
                                    alt="example"
                                    src={item.profile_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                ) : (
                                  <img
                                    alt="example"
                                    src={item.gender_pic}
                                    className="image_logo  object-center"
                                    style={{
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                              </>
                            }
                            // actions={[
                            //   <UserOutlined key="user" />,
                            //   <FolderOutlined key="message" />,
                            // ]}
                          >
                            <Meta
                              title={item.full_name}
                              description={item.user_stage_name}
                            />
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutual_connection}</p>
                            </div>
                            <div className="p-tag flex items-center">
                              {/* <p className="py-4">0 followers</p> */}
                              <p className="">
                                {item.follow}{" "}
                                <span className="follow_section">
                                  followers
                                </span>{" "}
                              </p>
                              <div>
                                <Followbutton {...item} class={like_stylus} />
                              </div>
                            </div>
                          </Card>
                        ))}
                      </>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Content>
      <div style={{ paddingTop: "20px" }}>
        <span>Viewing 1 - 10 of 10 members</span>
      </div>
    </div>
  );
};

export default Momslikelist;
