import React, { Fragment, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import {Image} from "antd";
import { toolsListsData } from "apis/babyName";

interface Props {
  setShowToolsList: React.Dispatch<React.SetStateAction<boolean>>;
}

const ToolsList: React.FC<Props> = ({ setShowToolsList }) => {
  const [toolsData, setToolsdata] = useState<any>([]);
  const newToolsApi = () => {
    toolsListsData()
      .then(res => {
        setToolsdata(res?.data?.tools);
      })
      .catch(err => {
        console.log("err", err);
      });
  };
  useEffect(()=>{
    newToolsApi()
  },[])

  const handleCloseTips = () => {
    setShowToolsList(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px", textAlign: "center" }}>
      <div
        style={{
          padding: "10px",
          border: "1px solid Gray",
          borderRadius: "8px",
          width: "100%",
        }}>
        <div style={{ float: "right" }}>
          <a>
            <CloseOutlined
              className="cursor-pointer read"
              size={40}
              style={{ fontWeight: "bold", fontSize: "15px" }}
              onClick={handleCloseTips}
            />
          </a>
        </div>
        <br />

        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around" }}>
          {toolsData?.map((tools: any, index: number) => {
            return (
              <Fragment key={index}>
                {(tools.slug == "Baby Name Generator" ||
                  tools.slug == "Pregnancy Weight Calculator" ||
                  tools.slug == "HCG Calculator" ||
                  tools.slug === "Baby Eye Colour Calculator" ||
                  tools.slug === "Contraction Calculator" ||
                  tools.slug === "Baby Kick Counter") && (
                  <div>
                    {tools.slug == "Baby Name Generator" && (
                      <a href="/babynames" target="_blank">
                        <div style={{ margin: "20px" }}>
                          <Image
                            src={tools?.icon}
                            width={200}
                            height={200}
                            preview={false}
                            style={{
                              borderRadius: "8px",
                            }}
                          />
                          <p>{tools?.name}</p>
                        </div>
                      </a>
                    )}

                    {tools.slug == "Pregnancy Weight Calculator" && (
                      <a href="/pregnancy-calculator" target="_blank">
                        <div style={{ margin: "20px" }}>
                          <Image
                            src={tools?.icon}
                            width={200}
                            height={200}
                            preview={false}
                            style={{
                              borderRadius: "8px",
                            }}
                          />
                          <p>{tools?.name}</p>
                        </div>
                      </a>
                    )}

                    {tools.slug == "HCG Calculator" && (
                      <a href="/hcg-calculator" target="_blank">
                        <div style={{ margin: "20px" }}>
                          <Image
                            src={tools?.icon}
                            width={200}
                            height={200}
                            preview={false}
                            style={{
                              borderRadius: "8px",
                            }}
                          />
                          <p>{tools?.name}</p>
                        </div>
                      </a>
                    )}

                    {tools.slug == "Baby Eye Colour Calculator" && (
                      <a href="/baby-eye" target="_blank">
                        <div style={{ margin: "20px" }}>
                          <Image
                            src={tools?.icon}
                            width={200}
                            height={200}
                            preview={false}
                            style={{
                              borderRadius: "8px",
                            }}
                          />
                          <p>{tools?.name}</p>
                        </div>
                      </a>
                    )}

                    {tools.slug == "Contraction Calculator" && (
                      <a href="/contraction" target="_blank">
                        <div style={{ margin: "20px" }}>
                          <Image
                            src={tools?.icon}
                            width={200}
                            height={200}
                            preview={false}
                            style={{
                              borderRadius: "8px",
                            }}
                          />
                          <p>{tools?.name}</p>
                        </div>
                      </a>
                    )}

                    {tools.slug == "Baby Kick Counter" && (
                      <a href="/kick-counts" target="_blank">
                        <div style={{ margin: "20px" }}>
                          <Image
                            src={tools?.icon}
                            width={200}
                            height={200}
                            preview={false}
                            style={{
                              borderRadius: "8px",
                            }}
                          />
                          <p>{tools?.name}</p>
                        </div>
                      </a>
                    )}
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ToolsList;
