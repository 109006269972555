import { useEffect, useState } from "react";
import { Image, message } from "antd";
import { LikeOutlined, LikeFilled } from "@ant-design/icons";
import SaveImgDark from "../../images/SavedIcon.svg";
import Save from "../../images/save.svg";
import CommentImg from "../../images/comment.svg";
import ShareIcon from "../../images/share_icon.svg";
import Share from "views/ShareModal/Share";
import { articleLike, articleSave } from "../../apis/btpArticles";
import UserComment from "./UserComment";
import Send from "../../images/send.svg";
import SendModal from "./SendModal";

export default function UserPost(props: any) {
  let detais = props.data;
  const [shareOpenModal, setShareOpenModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const handleShareBtn = (permalink: any) => {
    setShareOpenModal(true);
    setShareLink(permalink);
  };
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(detais.likeCount || 0);
  const [savePost, setSavePost] = useState(false);

  useEffect(() => {
    if (detais?.liked == true) {
      setLiked(true);
    } else {
      setLiked(false);
    }

    if (detais?.saved == true) {
      setSavePost(true);
    } else {
      setSavePost(false);
    }
  }, [detais]);

  const handleLike = (postId: any) => {
    setLiked(prevLiked => !prevLiked);
    setLikeCount((prevCount: any) => {
      const increment = liked ? -1 : 1;
      return prevCount + increment;
    });
    articleLike(postId)
      .then(res => {
        // props.refresh();
      })
      .catch((err: any) => {
        message.error(err);
      });
  };

  const handleSaveBtn = (postId: any) => {
    setSavePost(!savePost);
    articleSave(postId)
      .then(res => {
        // props.refresh();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [isModalArticleOpen, setISModalArticleOpen] = useState(false);
  const [isSendShow, setIsSendShow] = useState(false);
  const [postId, setPostID] = useState<any>("");

  const handleCommentArticle = (postId: any) => {
    setISModalArticleOpen(true);
    setPostID(postId);
  };

  const [artcileData, setArticleData] = useState<any>({});
  const [type, setType] = useState<any>("blog");
  const handleSendBtn = (permalink: any) => {
    setIsSendShow(true);
    setArticleData(permalink);
    setType("blog");
  };
  return (
    <>
      {isSendShow && (
        <SendModal
          setIsSendShow={setIsSendShow}
          isSendShow={isSendShow}
          artcileData={artcileData}
          type={type}
          setArticleData={setArticleData}
        />
      )}
      {setISModalArticleOpen && <UserComment setISModalArticleOpen={setISModalArticleOpen} isModalArticleOpen={isModalArticleOpen} postId={postId} />}

      {shareOpenModal && <Share setShareOpenModal={setShareOpenModal} shareOpenModal={shareOpenModal} shareLink={shareLink} />}
      <div className="line" key={props.index}>
        <div className="mb-3 mt-3">
          <a href={detais.permalink}>
            <img className="object-cover w-full" src={detais.thumbnailImage} alt="" loading="lazy" />
            <p></p>
            <p style={{ color: "#666666", fontSize: "bold", marginTop: "2px" }}>
              {detais?.count} views &nbsp; &#x2022; {detais?.minutesRead} min read
            </p>
            <h2 className="my-3">{detais.title}</h2>
          </a>
          <p>{detais.content}</p>
        </div>

        <div className="flex" style={{ justifyContent: "space-between" }}>
          <div>
            {detais?.likeCount !== 0 && (
              <span
                className="mt-1 cursor-pointer"
                style={{
                  fontWeight: "600px",
                  fontSize: ".9375rem",
                }}>
                {likeCount} Like
              </span>
            )}
          </div>
          <div>
            {detais?.commentCount !== 0 && (
              <span
                className="mt-1 cursor-pointer"
                style={{
                  fontWeight: "600px",
                  fontSize: ".9375rem",
                }}>
                {detais?.commentCount}&nbsp; Comment
              </span>
            )}
          </div>
        </div>

        <div className="flex text-center mt-3">
          <div className="flex text-center pointer">
            <span className="mt-1 mr-8">
              {liked ? (
                <LikeFilled style={{ fontSize: "20px" }} className="ml-1" onClick={() => handleLike(detais?.postId)} />
              ) : (
                <LikeOutlined style={{ fontSize: "20px" }} className="ml-1" onClick={() => handleLike(detais?.postId)} />
              )}
            </span>
            <span className="mt-1"></span>
          </div>
          <div className="flex text-center">
            <span className="mt-1 mr-8">
              <Image src={CommentImg} preview={false} className="cursor-pointer" onClick={() => handleCommentArticle(detais?.postId)} />
            </span>
            <span className="mt-1 cursor-pointer mr-8">
              <Image src={ShareIcon} preview={false} onClick={() => handleShareBtn(detais?.permalink)} />
            </span>
            <span className="mt-1 cursor-pointer mr-8">
              <Image src={Send} preview={false} onClick={() => handleSendBtn(detais)} />
            </span>
          </div>

          <div
            className="flex text-center"
            style={{
              marginLeft: "auto",
            }}>
            <span className="mr-1">
              {savePost ? (
                <Image src={SaveImgDark} preview={false} className="cursor-pointer mt-1" onClick={() => handleSaveBtn(detais?.postId)} />
              ) : (
                <Image src={Save} preview={false} className="cursor-pointer mt-1" onClick={() => handleSaveBtn(detais?.postId)} />
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
