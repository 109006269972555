import { Link } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import AuthLayout from "layouts/AuthLayout";
import partner_image from "../../images/login_partner.webp";
import { Form } from "antd";
import { Button, Input } from "antd";

const ForgotPassword = () => {
  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ backgroundColor: "rgba(241, 241, 241, 1)" }}>
      <AuthLayout>
        <div>
          <h1 className="text-4xl text-center">Forget</h1>
          <div className="my-2.5">
            <img className="w-32 m-auto" src={partner_image} />
          </div>
          <p className="mt-4">Forgot your password?</p>
          <p className="bg-blue-500 text-base p-3 mt-4" style={{ color: "#fff" }}>
          Enter your email address. You will receive a link to create a new password via email.
          </p>
        </div>
        <Form>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Please enter your Email Id!" }]}
          >
            <Input
              type="text"
              autoComplete="off"
              placeholder="Email Adress"
              className="bg-gray-50 p-2 mb-2 focus:outline-none w-72"
              style={{ border: "1px solid #ddd" }}
            />
          </Form.Item>
          <div className="m-auto">
            <Button
              type="primary"
              htmlType="submit"
              className="w-72 rounded-3xl"
              style={{ backgroundColor: "#ff5a5f" }}
            >
              Request reset link
            </Button>
          </div>
          <Link to="/login" className="flex content-center mb-4 align-middle">
            <div className="m-auto">
              <p className=" text-red-400 text-base ml-1 mt-4">
                Back to sign in
              </p>
            </div>
          </Link>
        </Form>
        <p className="text-center">
          <span style={{ color: "#ff5a5f" }}>Terms Of Service</span> and{" "}
          <span style={{ color: "#ff5a5f" }}>Privacy Policy</span>
        </p>
      </AuthLayout>
    </div>
  );
};

export default ForgotPassword;
