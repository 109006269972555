import React, { useEffect } from "react";
import {
  Modal,
  Form,
  DatePicker,
  Button,
  Spin,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "reducers";
import Swal from "sweetalert2";
import {
  updateprofile,
  TUpdateProfileRequest_parenting_duedate,
} from "actions/updateprofile";
import { LoadingOutlined } from "@ant-design/icons";

interface Props {
  setDueDateModal: any;
  dueDateModal: boolean;
}

const DueDate: React.FC<Props> = ({ setDueDateModal, dueDateModal }) => {
  const { isLoading, data, refetch } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );
  const profileData = data;
  const dispatch = useDispatch();
  const { Accesstoken, userId } = useSelector(
    (state: TRootState) => state.auth
  );
  const [form] = Form.useForm();
  const pregnacyInfo = useSelector((state: TRootState) => state.userprofile);
  useEffect(() => {
    const { due_date_parent, i_am } = pregnacyInfo;
    if (i_am !== "" && i_am !== null && i_am) {
      form.setFieldsValue({
        i_am: i_am,
      });
    }
    if (due_date_parent !== "" && due_date_parent !== null && due_date_parent) {
      form.setFieldsValue({
        due_date: moment(due_date_parent, "DD-MM-YYYY"),
      });
    }
  }, [pregnacyInfo, form]);

  const handleCancel = () => {
    setDueDateModal(!dueDateModal);
  };

  const onFinish = async (values: any) => {
    if (form.getFieldValue("due_date") !== undefined) {
      let reqBody = (await form.validateFields()) as any;
      delete reqBody.datepicker;
      reqBody.type = "parenting_due_date";
      reqBody.sk_user_id = profileData?.sk_user_id;
      reqBody.due_date_parent = moment(form.getFieldValue("due_date")).format(
        "YYYY-MM-DD"
      );
      refetch()
      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        timer: 900,
      });
      await dispatch(
        updateprofile(
          reqBody as TUpdateProfileRequest_parenting_duedate,
          Accesstoken,
          (res) => handleCallback(res)
        )
      );
    } else{
        console.log("err");
    }
  };

  const handleCallback = (res: any) => {
    // console.log("Received values of form: ", res);
  };
  const onFinishFailed = () => {
    // console.log("Failed:");
  };

  function onChange(date: any, dateString: any, abc: any) {
    const value = moment(dateString);
  }

  // const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  //   const dueDateParent = profileData?.due_date_parent;
  //   if (!dueDateParent) {
  //     return current && current < moment().endOf("day");
  //   }
  //   if (current.isSame(dueDateParent, "day")) {
  //     return true;
  //   }
  //   if (current.isBefore(dueDateParent, "day")) {
  //     return true;
  //   }
  //   const nineMonthsAfterDueDate = moment(dueDateParent).add(9, "months");
  //   return (
  //     current &&
  //     (current < moment().endOf("day") || current > nineMonthsAfterDueDate)
  //   );
  // };


  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (!current) {
      return false; // Allow the user to pick a date when the calendar opens
    }
    const currentDate = moment();
    const nineMonthsAfterCurrentDate = currentDate.clone().add(9, "months");
    return current.isBefore(currentDate, "day") || current.isAfter(nineMonthsAfterCurrentDate, "day");
  };







  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 18, color: "white" }}
      className="text-center"
      spin
    />
  );

  return (
    <>
      <Modal
        title="Due Date"
        open={dueDateModal}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
          className="input-sty"
        >
          <Form.Item
            label={"Due Date"}
            name="due_date"
            rules={[
              {
                required: true,
                message: "Please input your due date!",
              },
            ]}
          >
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={disabledDate}
              onChange={(date, dateString) => onChange(date, dateString, 1)}
            />
          </Form.Item>
        </Form>
        <Button type="primary" danger onClick={onFinish}>
          <span> Save</span>
          {isLoading && <Spin indicator={antIcon} />}
        </Button>
      </Modal>
    </>
  );
};

export default DueDate;
