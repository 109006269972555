import React, { useEffect, useState } from "react";
import Table from "antd/es/table";
import { getGroups } from "./GroupApi";
import { deleteGroup } from "./btpGroupApi";
import { getGroupsCore } from "./btpGroupApi";
import { Button, PaginationProps, Space, Popconfirm } from "antd";
import { Pagination, Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
// import Join from "./Join";
// import GroupFormTabs from "./GroupFormTabs";
// import Groups from "views/ViewProfile/Groups";
import moment from "moment";
const GroupsList = (props: any) => {
  const { Meta } = Card;
  const [upadteValue, setUpdateValue] = useState<any>("");
  useEffect(() => {
    if (props.activeTab === "3") {
      setUpdateValue("_id");
    } else if (props.activeTab === "1") {
      setUpdateValue("groupId");
    }
  }, [props.activeTab]);
  const [groupsData, setGroupsData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [pageInfo, setPageInfo] = useState<any>({
    current: 1,
    pageSize: 10,
  });
  const onShowSizeChange: PaginationProps["onShowSizeChange"] = (current, pageSize) => {
    setPageInfo({ current: current, pageSize: pageSize });
  };

  const getGroupsData = async () => {
    let data1 = await getGroupsCore(props, pageInfo);
    setTotalPages(data1?.data?.pageInfo?.totalCount || 0);
    setGroupsData(data1.data.groups);
  };

  useEffect(() => {
    getGroupsData();
  }, [props.activeTab, pageInfo]);

  async function clickDelete(e: any, id: any) {
    let data: any = await deleteGroup(id);
    await getGroupsData();
  }

  return (
    <div>
      <Row gutter={16}>
        {groupsData.map((val: any, index: number) => {
          return (
            <Col xs={24} sm={12} md={8} lg={8} xl={8} className="mb-3" key={val._id || val.groupId}>
              {props.activeTab === "3" && (
                <Link to={`/group-profile/${val._id}`}>
                  <Card
                    hoverable
                    style={{ width: "auto" }}
                    cover={<img style={{ height: "270px" }} alt={val.imageUrl} src={val.imageUrl} />}>
                    <div style={{ justifyContent: "center" }}>
                      <h1>{val.groupName}</h1>
                      <p>
                        {/* {val.groupStatus==="activated"
                        && "Activate"
                        } */}
                      </p>
                      <p className="global_date">{moment(val.lastUpdated).format("DD-MM-YYYY")}</p>
                    </div>
                  </Card>
                </Link>
              )}

              {props.activeTab === "1" && (
                <Link to={`/group-profile/${val.groupId}`}>
                  <Card
                    hoverable
                    style={{ width: "auto" }}
                    cover={<img style={{ height: "270px" }} alt={val.imageUrl} src={val.imageUrl} />}>
                    <div style={{ justifyContent: "center" }}>
                      <h1>{val.groupName}</h1>
                      <p>
                        {/* {val.groupStatus==="activated"
                        && "Activate"
                        } */}
                      </p>
                      <p className="global_date">{moment(val.lastUpdated).format("DD-MM-YYYY")}</p>
                    </div>
                  </Card>
                </Link>
              )}
            </Col>
          );
        })}
        {groupsData.length == 0 && (
          <div className="text-center mb-10 mt-10 ml-10">
            <p>No Groups Found</p>
          </div>
        )}
      </Row>

      {groupsData.length != 0 && <Pagination onChange={onShowSizeChange} defaultCurrent={1} total={totalPages} />}
    </div>
  );
};

export default GroupsList;
