import React, { useState, useEffect } from "react";
import {
  Layout,
  message,
  Input,
  Form,
  Button,
  Select,
  Radio,
  Table,
} from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { generateNames, addFavName, getFavName } from "../../../apis/babyName";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { useQuery } from "react-query";
import { viewProfileApi } from "../../../apis/btpViewProfile";
import NameDetails from "./NameDetails";
import Footer from "views/MainFooter/Footer";

interface YourDataType {
  name: string;
  numerology: string;
  // Other properties...
}

const GenerateName = () => {
  const religions = [
    { value: "Hindu", label: "Hindu" },
    { value: "Sikh", label: "Sikh" },
    { value: "Muslim", label: "Muslim" },
    { value: "Christian", label: "Christian" },
  ];
  const [form] = Form.useForm();

  const rashi = [
    {
      name: "Mesha",
    },
    {
      name: "Vrishabha",
    },
    {
      name: "Mithun",
    },
    {
      name: "Karka",
    },
    {
      name: "Simha",
    },
    {
      name: "Kanya",
    },
    {
      name: "Tula",
    },
    {
      name: "Dhanu",
    },
    {
      name: "Meena",
    },
  ];

  const nakshatras = [
    {
      name: "Chitra",
    },
    {
      name: "Bharani",
    },
    {
      name: "Rohini",
    },
    {
      name: "Punarvasu",
    },
    {
      name: "Ashlesha",
    },
    // {
    //   name: "UttaraPhalguni",
    // },
    {
      name: "Swati",
    },
    {
      name: "Anuradha",
    },
  ];

  const numerology = [
    // {
    //   num: 0,
    // },
    {
      num: 1,
    },
    {
      num: 2,
    },
    {
      num: 3,
    },
    {
      num: 4,
    },
    {
      num: 5,
    },
    {
      num: 6,
    },
    {
      num: 7,
    },
    {
      num: 8,
    },
    {
      num: 9,
    },
    // {
    //   num: 10,
    // },
    {
      num: 11,
    },
    {
      num: 22,
    },
  ];

  const [selectedReligion, setSelectedReligion] = useState<any>(null);

  const handleReligionChange = (value: any) => {
    setSelectedReligion(value);
  };

  const [generateNamesData, setGenerateNames] = useState<any>([]);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [getFavNameData, setFavNameData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [propData, setPropData] = useState<any>({});

  const {
    isLoading: loading,
    data: userData,
    refetch,
  } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );
  const onFinish = (values: any) => {
    let data = JSON.stringify({
      father: values?.father,
      mother: values.mother,
      type: values.type,
      religion: values.religion,
      numerology: values.numerology,
      rashi: values.rashi,
      nakshatra: values.nakshatra,
      pageLimit: pageLimit,
      page: 1,
    });
    generateNames(data)
      .then((res: any) => {
        setGenerateNames(res.matchedData);
        // console.log("res.matchedData", res.matchedData.length);
        // console.log("res.matchedData",res.matchedData);
        if (res.matchedData?.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
      })
      .catch((err: any) => {
        message.error(err);
      });
  };

  const [noData, setNoData] = useState<any>(false);
  const handleLoadMore = () => {
    setPageLimit((prevPageLimit) => prevPageLimit + 10);
    let data = JSON.stringify({
      father: form.getFieldValue("father"),
      mother: form.getFieldValue("mother"),
      type: form.getFieldValue("type"),
      religion: form.getFieldValue("religion"),
      numerology: form.getFieldValue("numerology"),
      rashi: form.getFieldValue("rashi"),
      nakshatra: form.getFieldValue("nakshatra"),
      pageLimit: pageLimit + 10,
      page: 1,
    });
    setLoadingMore(true);
    generateNames(data)
      .then((res: any) => {
        setLoadingMore(false);
        if (Array.isArray(res.matchedData)) {
          setGenerateNames(res.matchedData);
        }
      })
      .catch((err: any) => {
        message.error(err);
        setLoadingMore(false);
      });
  };

  const arrowIconStyle = {
    marginRight: "8px",
  };

  const handleNameClick = (record: any) => {
    if (record && record.name) {
      let data = JSON.stringify({
        userId: userData?.sk_user_id,
        name: record.name,
      });
      addFavName(data)
        .then((res) => {
          getFavNameList();
          Swal.fire({
            icon: "success",
            title: "Name added to your favourite list !",
            showConfirmButton: false,
            timer: 900,
          });
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const numerologyValues = generateNamesData.map(
    (item: YourDataType) => item.numerology
  );
  const allZeros: boolean = numerologyValues.every(
    (value: number) => value === 0
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <a>{text}</a>,
      onCell: (record: any) => ({
        onClick: () => {
          handleNClick(record);
        },
      }),
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
    {
      title: allZeros ? "" : "Numerology",
      dataIndex: "numerology",
      key: "numerology",
      render: (numerology: any) => {
        if (numerology === 0) {
          return null;
        }
        return numerology;
      },
      showHeader: false,
    },
    {
      title: "Religion",
      dataIndex: "religion",
      key: "religion",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Fav",
      dataIndex: "fav",
      key: "fav",
      render: (fav: any, record: any) => {
        const isFav = getFavNameData?.some(
          (getFavNameData: any) => getFavNameData._id == record?._id
        );
        return isFav ? (
          <HeartFilled className="darkFav-icon" />
        ) : (
          <HeartOutlined className="outlineFav-icon" />
        );
      },
      onCell: (record: any) => ({
        onClick: () => {
          handleNameClick(record);
        },
      }),
    },
  ];

  const handleNClick = (record: any) => {
    if (record && record.name) {
      setIsModalOpen(true);
      setPropData(record);
    }
  };

  const getFavNameList = () => {
    if (userData && userData.sk_user_id) {
      getFavName(userData.sk_user_id)
        .then((res) => {
          setFavNameData(res?.matchedData);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      message.error("error");
    }
  };

  useEffect(() => {
    getFavNameList();
  }, [userData && userData.sk_user_id]);

  // numerology: form.getFieldValue("numerology"),
  // rashi: form.getFieldValue("rashi"),
  // nakshatra: form.getFieldValue("nakshatra"),

  const handleClearFilter = () => {
    setSelectedReligion(null);
    setGenerateNames([]);
    form.setFieldsValue({
      religion: undefined,
      numerology: undefined,
      nakshatra: undefined,
    });
  };


  return (
    <>
      {isModalOpen && (
        <NameDetails
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          propData={propData}
        />
      )}

      <Layout className="h-screen">
        <Header
          className=""
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Headersection />
        </Header>
        <div className=" bg-white mt-20">
          <Content
            className=" container "
            style={{
              margin: "24px auto",
            }}
          >
            <div className="mb-10">
              <Link to="/babynames">
                <p>
                  <ArrowLeftOutlined style={arrowIconStyle} />
                  Baby Names
                </p>
              </Link>
            </div>
            <div>
              <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                  label="Your Name"
                  name="father"
                  rules={[{ required: true, message: "Please fill your name" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Your spouse's name"
                  name="mother"
                  rules={[
                    {
                      required: true,
                      message: "Please fill your spouse's name",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Baby's gender" name="type">
                  <Radio.Group
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Radio value="Boy">Boy</Radio>
                      <Radio value="Girl">Girl</Radio>
                      <Radio value="Unisex">Unisex</Radio>
                    </div>
                    <Button className="btn-clear" onClick={handleClearFilter}>
                      Clear Filter
                    </Button>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="religion">
                  <Select
                    placeholder="Select religion"
                    onChange={handleReligionChange}
                  >
                    {religions.map((religion) => (
                      <Select.Option
                        key={religion.value}
                        value={religion.value}
                      >
                        {religion.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                {selectedReligion == "Hindu" && (
                  <Form.Item name="rashi">
                    <Select placeholder="Select rashi">
                      {rashi.map((rashi) => (
                        <Select.Option key={rashi.name} value={rashi.name}>
                          {rashi.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {selectedReligion == "Hindu" && (
                  <Form.Item name="nakshatra">
                    <Select placeholder="Select nakshatra">
                      {nakshatras.map((nakshatras) => (
                        <Select.Option
                          key={nakshatras.name}
                          value={nakshatras.name}
                        >
                          {nakshatras.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                {selectedReligion == "Hindu" && (
                  <Form.Item name="numerology">
                    <Select placeholder="Select numerology">
                      {numerology.map((numerology) => (
                        <Select.Option
                          key={numerology.num}
                          value={numerology.num}
                        >
                          {numerology.num}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}

                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  Generate
                </Button>
              </Form>
            </div>
            {generateNamesData.length !== 0 && (
              <>
                <div className="mt-5">
                  <Table
                    columns={columns}
                    dataSource={generateNamesData}
                    pagination={false}
                  />
                </div>
                {generateNames?.length !== 0 && (
                  <Button
                    style={{ width: "100%" }}
                    onClick={handleLoadMore}
                    loading={loadingMore}
                  >
                    Load more
                  </Button>
                )}
              </>
            )}
            {noData && (
              <div className="mt-5">
                <p style={{ textAlign: "center" }}>Sorry ' No Names Found </p>
              </div>
            )}
          </Content>
        </div>
        <Footer/>
      </Layout>
    </>
  );
};

export default GenerateName;
