import { AnyAction, CombinedState, combineReducers } from 'redux';
import {initialState as authInitialState} from "./auth";
import {initialState as feedsInitialState} from "./getfeed";
import {connectRouter as router, RouterState} from "connected-react-router";
import {TViewProfile,viewProfileReducer as userprofile} from "./viewprofile"
import {TFollowUser, followUserReducer as followUser} from "./follow"
import {TConnectUser, connectUserReducer as connectUser} from './connect'
import { TUnfollowUser, unfollowUserReducer as unfollowUser} from "./unfollow";
import { TIndividualChatHistory, individualChatHistoryReducer as messages} from "./message";
import {initialState as userInitialState} from "./viewprofile"
import { initialState as followUserInitialState} from "./follow"
import { initialState as unfollowUserInitialState} from "./unfollow" 
import { initialState as connectUserInitialState} from "./connect"
import { initialState as individualChatHistoryInitialState} from "./message"
import {TAuth, authReducer as auth} from "./auth";
import { History } from "history";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TFeeds,feedReducer as feeds } from "./getfeed";
export interface TRootState {
    auth:TAuth,
    feeds:TFeeds,
    router: RouterState;
    userprofile:TViewProfile;
    followUser: TFollowUser;
    unfollowUser: TUnfollowUser;
    messages: TIndividualChatHistory;
    connectUser:TConnectUser;
  }
  const appReducer = (history: History) => 
combineReducers<TRootState>({
    auth,
    feeds,
    userprofile,
    followUser,
    unfollowUser,
    messages,
    connectUser,
    router: router(history),
})
export const rootReducer = (history: History) => (state: CombinedState<TRootState>, action: AnyAction) => {
    if (action.type === 'users/LOGOUT') {
        state = {
            auth: authInitialState,
            feeds: feedsInitialState,
            userprofile:userInitialState,
            followUser: followUserInitialState,
            unfollowUser: unfollowUserInitialState,
            messages: individualChatHistoryInitialState,
            router: state.router,
            connectUser:connectUserInitialState
        };
    }
    return appReducer(history)(state, action)
}