import { MoreOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Layout, Popover, Row, Image, Button, Empty } from "antd";
import { getsavedposts } from "apis/likeunlikepost";
import Likedsection from "components/global/postlist/Likedsection";
import Savedsection from "components/global/postlist/Savedsection";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Headersection from "views/MainHeader/Header";
import Send from "../../images/send_img.svg";
import Share from "../../images/share_icon.svg";
import Saved from "../../images/saved.svg"
import { unsavedpostlikeandunlike } from 'apis/likeunlikepost'
const { Header, Content, Footer, Sider } = Layout;

function Savedpost(props: any) {
  const [visible, setVisible] = useState(false);

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  const { data: savedPostsList, refetch: refetchPurposecustomer } = useQuery(
    "fetchPurpose",
    () => getsavedposts(),
    {}
  );
  useEffect(() => {
    refetchPurposecustomer();
  }, []);
 const handleClickDelete = (postlistval: any, index:any) => {
  console.log("anil",postlistval)
  unsavedpostlikeandunlike(postlistval.post_id).then((res)=>{
    const updatedReques: any = savedPostsList?.data.map((ele: any, idx:any) => {
      if (index === idx) {
        ele.isReject = true;
      }
      refetchPurposecustomer();
  })
})
 }

 console.log("annnn", savedPostsList)
  return (
    <Layout className="h-screen">
      <Header
        className=""
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <Headersection {...props} />
      </Header>
      <Layout className="container">
        <div className="bg-white mt-16">
          <Content className="profike_imgs m-10">
            <div className="profile_bg ">
              <h1 className="pink_text  weight-800 text-lg p-8 ">
                Saved Posts
              </h1>
              <div>
              {savedPostsList?.data && savedPostsList?.data.length > 0 ? (<div className="mb-6 mx-8 flex flex-wrap gap-4 ">
                  {savedPostsList?.data.map((postlistval: any, index:number) => {
                    return (
                      <Card
                        className="card-SavedPost"
                        title={
                          <>
                            <div className="flex">
                              <div className="mr-3">
                                <img
                                  className="w-10 h-9"
                                  src={postlistval.profile_pic}
                                  alt=""
                                />
                              </div>
                              <div>
                                <p className="mb-0 pb-0 text-sm">
                                  {postlistval.full_name}
                                </p>
                                <span className="text-xs">
                                  {postlistval.post_saved_time}
                                </span>
                              </div>
                            </div>
                          </>
                        }
                        extra={
                          <>
                            <Popover
                              content={<a onClick={hide}>Close</a>}
                              title="Title"
                              trigger="click"
                              visible={visible}
                              onVisibleChange={handleVisibleChange}
                              placement="bottomRight"
                            >
                              <div
                                style={{
                                  transform: "rotate(90deg)",
                                  cursor: "pointer",
                                }}
                              >
                                <MoreOutlined />
                              </div>
                            </Popover>
                          </>
                        }
                      >
                        {postlistval.post_media.map((ele:any)=>(<div className="flex justify-center pt-3">
                          {/* <img className="w-12" src={postlistval.profile_pic} alt="" /> */}
                          <Image
                            width={160}
                            height={57}
                            src={ele.attchment_name}
                            alt=""
                            className="img-card-save"
                          />
                        </div>))
                        }
                        <div className="my-2 p-2">
                          <p className="text-center">
                            {(postlistval.post_description!=="undefined") ? <p>{postlistval?.post_description}</p> : ""  }
                          </p>
                        </div>
                        <div className="Card_footer p-4">
                          <div className="flex justify-between items-end">
                            <div className="flex items-end gap-12">
                              <Likedsection />
                            </div>
                            <div>
                              {postlistval.reject ? null :<a onClick={() => handleClickDelete(postlistval, index)} className="cursor-pointer"><img src={Saved}/></a>}
                            </div>
                          </div>
                        </div>
                      </Card>
                    );
                  }) }
                </div>): <div className="flex item-center justify-center"><Empty/></div>}
              </div>
            </div>
          </Content>
        </div>
      </Layout>
    </Layout>
  );
}

export default Savedpost;
