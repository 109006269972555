
import { Card, Col, Row, Skeleton, } from "antd";
import moment from "moment";
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import {  useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import { toolsListsData } from "apis/babyName";

const Articlesection1 = () => {
  const { isLoading, data } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));
  const profileData = data;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 200);
    return () => clearTimeout(timer);
  });

  const [toolsData, setToolsdata] = useState<any>([]);
  const newToolsApi = () => {
    toolsListsData()
      .then(res => {
        setToolsdata(res?.data?.tools);
      })
      .catch(err => {
        console.log("err", err);
      });
  };

  useEffect(()=>{
    newToolsApi()
  },[])



  return (
    <div className=" ">
      <div className="Artical_card1">
        {profileData?.stage !== "parent" && profileData?.stage !== "planning" && (
          <>
            {profileData?.due_date_parent != "0000-00-00" && (
              <Card title="" className="add_card mb-5 rounded-md border">
                <div className="mb-3">
                  <h2 className="pink_text text-sm font-medium text-left">EXPECTED DUE DATE</h2>
                </div>
                {!loading ? (
                  <Skeleton active />
                ) : (
                  <Row className="">
                    <Col xs={12} sm={12}>
                      <p className="text-center">
                        {moment(profileData?.due_date_parent).format("DD-MM-YYYY")}
                      </p>
                    </Col>
                  </Row>
                )}
                <div className="text-right">
                  <Link
                    to={{
                      pathname: "/editprofile/edit/pregrancyInformation",
                    }}
                    className=" pink_text font-normal cursor-pointer ">
                    Edit
                  </Link>
                </div>
              </Card>
            )}
          </>
        )}


        {profileData?.stage == "parent" && (
          <>
            {profileData?.childDetails.length !== 0 && (
              <Card title="" className="add_card mb-5 rounded-md border">
                <div className="mb-3">
                  <h2 className="pink_text text-sm font-medium text-left">CHILD</h2>
                </div>
                {!loading ? (
                  <Skeleton active />
                ) : (
                  <>
                    {profileData?.childDetails?.map((val: any, index: number) => {
                      return (
                        <>
                          <Row>
                            <Col xs={16} sm={16}>
                              <p>
                                {val.child_name}, &nbsp;
                                {moment(val.dob).format("DD-MM-YYYY")}
                              </p>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </>
                )}
                <div className="text-right">
                  <Link to={{ pathname: "/editprofile/edit/pregrancyInformation" }} className=" pink_text font-normal cursor-pointer ">
                    Edit
                  </Link>
                </div>
              </Card>
            )}
          </>
        )}
        {/* // value.slug === "Child Blood Type Calculator" || */}
        {toolsData?.length !== 0 && (
          <Fragment>
            {toolsData.some(
              (value: any) =>
                value.slug === "Baby Name Generator" ||
                value.slug === "Pregnancy Weight Calculator" ||
                value.slug === "HCG Calculator" ||
                value.slug === "Baby Kick Counter" ||
                value.slug === "Contraction Calculator" ||
                value.slug === "Baby Eye Colour Calculator"
            ) && (
              <Card
                title={
                  <Fragment>
                    {" "}
                    <h2 className="pink_text text-sm font-medium text-left">TOOLS YOU MAY NEED</h2>
                  </Fragment>
                }
                bordered={true}
                className="add_card mb-5">
                <div className="mb-5 w-full" style={{ maxHeight: "100%", overflowY: "auto" }}>
                  {toolsData.map((value: any, ind: number) => {
                    return (
                      <Fragment>
                        {value.slug === "Baby Name Generator" && (
                          <React.Fragment key={ind}>
                            <Link to="/babynames" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}

                        {value.slug === "Pregnancy Weight Calculator" && (
                          <React.Fragment key={ind}>
                            <Link to="/pregnancy-calculator" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}

                        {value.slug === "HCG Calculator" && (
                          <React.Fragment key={ind}>
                            <Link to="/hcg-calculator" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}

                        {/* {value.name === "Child Blood Type Calculator" && (
                          <React.Fragment key={ind}>
                            <Link to="/child-blood-type" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )} */}

                        {value.slug === "Baby Eye Colour Calculator" && (
                          <React.Fragment key={ind}>
                            <Link to="/baby-eye" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}

                        {value.slug === "Contraction Calculator" && (
                          <React.Fragment key={ind}>
                            <Link to="/contraction" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}

                        {value.slug === "Baby Kick Counter" && (
                          <React.Fragment key={ind}>
                            <Link to="/kick-counts" className="cursor-pointer">
                              <div className="flex mb-3 border_align">
                                <div className="mr-3">
                                  {!loading ? <Skeleton active /> : <img src={value.icon} alt="" className="mark_image" loading="lazy" />}
                                </div>
                                <div>{!loading ? <Skeleton active /> : <p className="text-black hover:text-red-300">{value.name}</p>}</div>
                              </div>
                            </Link>
                          </React.Fragment>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </Card>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Articlesection1;
