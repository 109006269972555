import axios, {AxiosRequestConfig} from "axios";
import {getAccessToken} from '../actions/auth';
import { environmentConfig } from "../config";

interface AxiosConfig extends AxiosRequestConfig {
  skipAuthRefresh?: boolean;
}

export const getDefaultAxiosConfig = () => {
  const token = getAccessToken();
  let authorizationHeader;
  if (token) {
    authorizationHeader = {
      "accesstoken": `${token}`
    }
  }
  return {
    baseURL: `${environmentConfig.CORE_URL}`,
    headers: {
      "Content-Type": "application/json",
      ...authorizationHeader
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiGet = async <RES extends any>(
  queryUrl: string,
  axiosOptions?: AxiosConfig
) => {
  const {data} = await axios.get<RES>(queryUrl, {...getDefaultAxiosConfig(), ...axiosOptions});
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiGetResponse = async <RES extends any>(
  queryUrl: string,
  axiosOptions?: AxiosConfig
) => {
  
  const {data} = await axios.get<RES>(queryUrl, {...getDefaultAxiosConfig(), ...axiosOptions})
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPost = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosConfig
) => {
  const {data} = await axios.post<RES>(queryUrl, requestBody, {
    ...getDefaultAxiosConfig(), ...axiosOptions});  
  return data;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPatch = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosConfig
) => {
  const {data} = await axios.patch<RES>(queryUrl, requestBody, {
    ...getDefaultAxiosConfig(), ...axiosOptions,
  });
  return data;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPut = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosConfig
) => {
  const {data} = await axios.put<RES>(queryUrl, requestBody, {
    ...getDefaultAxiosConfig(), ...axiosOptions,
  });
  return data;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiPostFormData = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosConfig
) => {
  const config = {
    ...getDefaultAxiosConfig(),
    headers: {
      ...getDefaultAxiosConfig().headers,
      "Content-Type": "multipart/form-data",
    }
  }

  const {data} = await axios.post<RES>(queryUrl, requestBody, {
    ...config, ...axiosOptions,
  });
  return data;
};

export const apiPatchFormData = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosConfig
) => {
  const config = {
    ...getDefaultAxiosConfig(),
    headers: {
      ...getDefaultAxiosConfig().headers,
      "Content-Type": "multipart/form-data",
    }
  }
  const {data} = await axios.patch<RES>(queryUrl, requestBody, {
    ...config, ...axiosOptions,
  });
  return data;
};


export const apiPutFormData = async <RES extends any, REQ extends any>(
  queryUrl: string,
  requestBody?: REQ,
  axiosOptions?: AxiosConfig
) => {
  const config = {
    ...getDefaultAxiosConfig(),
    headers: {
      ...getDefaultAxiosConfig().headers,
      "Content-Type": "multipart/form-data",
    }
  }
  const {data} = await axios.put<RES>(queryUrl, requestBody, {
    ...config, ...axiosOptions,
  });
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const apiDelete = async <RES extends any>(
  queryUrl: string,
  axiosOptions?: AxiosConfig
) => {
  const {data} = await axios.delete<RES>(queryUrl, {
    ...getDefaultAxiosConfig(), ...axiosOptions,
  });
  return data;
};

export const createFormData = (entries: Record<string, any>) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(entries)) {
    formData.append(key, (value as any));
  }
  return formData;
}
