import React, { useEffect, useState } from "react";
import { Layout, Button, Tabs, Select, Card, Avatar, Tooltip } from "antd";
import { UserOutlined, WifiOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { BiShare } from "react-icons/bi";
import { ExclamationOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";
const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;
const Forums = (props:any) => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const [tabkeyval, setTabkeyval] = useState('');
  const router = useLocation();
  

  const handleTabClick =(key : any) => {
    console.log("Forumkey",key)
    props.setDefaultActiveForums(key)
  }


  return (
    <div>
      <Content>
        <Tabs onChange={handleTabClick} activeKey={props.defaultActiveForums} defaultActiveKey={props.defaultActiveForums}>
          <TabPane tab="My Discussion" key="my_Discusssions">
            <p style={{ fontSize: "20px", paddingBottom: "10px" }}>
              Forum Discussions Started
            </p>
            <div className="discuss">
              <h2 style={{ fontSize: "18px" }}> All Discussions</h2>
            </div>
            <Card
              className="hover:shadow"
              title={
                <>
                  <div className="flex items-center">
                    <Badge
                      count={
                        <>
                          <Tooltip placement="top" title="Subscribe">
                            <a>
                              <WifiOutlined
                                style={{
                                  transform: "rotate(30deg)",
                                  fontSize: "27px",
                                  position: "absolute",
                                  left: "33px",
                                  top: "-7px",
                                }}
                              />
                            </a>
                          </Tooltip>
                        </>
                      }
                    >
                      <Avatar
                        shape="circle"
                        src="http://www.thekid.co.in/wp-content/uploads/avatars/684/61bb482b95922-bpfull.png"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Badge>
                    <div className="flex flex-col ml-4">
                      <a>Adsada</a>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "900",
                          color: "black",
                        }}
                      >
                        <span className="flex flex-row flex-wrap gap-2">
                          <BiShare style={{ fontSize: "20px" }} />
                          <a>Anuja More</a> <span>Replied</span>
                          <a> 3 Months Ago</a>1 Memeber . 1 Reply
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              }
              extra={
                <div className="flex item-center">
                  <a
                    className="a-tag-sty"
                    href="http://www.thekid.co.in/groups/april-2022-moms/forum/april-2022-moms/"
                  >
                    April 2022 MOMS
                  </a>
                </div>
              }
            />
            <p className="mt-5">viewing 1 of 1 discussions</p>
          </TabPane>
          <TabPane tab="My Replies" key="my_replies">
            <p style={{ fontSize: "20px", paddingBottom: "10px" }}>
              Forum Replies Created
            </p>
            <div className="icon">
              <div className="icon2">
                <ExclamationOutlined className="icon-sty" />
              </div>
              <div>
                <p>You have not replied to any discussions.</p>
              </div>
            </div>
          </TabPane>
          <TabPane tab="My Favorites" key="my_favorites">
            <p style={{ fontSize: "20px", paddingBottom: "10px" }}>
              My Favorite Discussions
            </p>
            <div className="icon">
              <div className="icon2">
                <ExclamationOutlined className="icon-sty" />
              </div>
              <div>
                <p>You have not replied to any discussions.</p>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Subscriptions" key="subscriptions">
            <p style={{ fontSize: "20px", paddingBottom: "10px" }}>
              Subscribed Forums
            </p>
            <div className="icon">
              <div className="icon2">
                <ExclamationOutlined className="icon-sty" />
              </div>
              <div>
                <p>You are not currently subscribed to any forums.</p>
              </div>
            </div>{" "}
            <br />
            <div className="discuss">
              <h2 style={{ fontSize: "18px" }}> All Discussions</h2>
            </div>
            <Card
              className="hover:shadow"
              title={
                <>
                  <div className="flex items-center">
                    <Badge
                      count={
                        <>
                          <Tooltip placement="top" title="Subscribe">
                            <a>
                              <WifiOutlined
                                style={{
                                  transform: "rotate(30deg)",
                                  fontSize: "27px",
                                  position: "absolute",
                                  left: "33px",
                                  top: "-7px",
                                }}
                              />
                            </a>
                          </Tooltip>
                        </>
                      }
                    >
                      <Avatar
                        shape="circle"
                        src="http://www.thekid.co.in/wp-content/uploads/avatars/684/61bb482b95922-bpfull.png"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </Badge>
                    <div className="flex flex-col ml-4">
                      <a>Adsada</a>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "900",
                          color: "black",
                        }}
                      >
                        <span className="flex flex-row flex-wrap gap-2">
                          <BiShare style={{ fontSize: "20px" }} />
                          <a>Anuja More</a> <span>Replied</span>
                          <a> 3 Months Ago</a>1 Memeber . 1 Reply
                        </span>
                      </p>
                    </div>
                  </div>
                </>
              }
              extra={
                <div className="flex item-center">
                  <a
                    className="a-tag-sty"
                    href="http://www.thekid.co.in/groups/april-2022-moms/forum/april-2022-moms/"
                  >
                    April 2022 MOMS
                  </a>
                </div>
              }
            />
            <p className="mt-5">viewing 1 of 1 discussions</p>
          </TabPane>
        </Tabs>
      </Content>
    </div>
  );
};

export default Forums;
