import { getAccessToken } from "actions/auth";
import { apiGet, apiPost, apiPut, getDefaultAxiosConfig ,apiPatch} from "./btpApi";
export type TGetFeedRes = {
  status: boolean;
  message: string;
  hasNext: boolean;
  data: any;
  page: number;
};

export type TCalendarData = {
  periodDate: string;
  cycleDays: number;
};

export type TGetBannerRes = {
  status: boolean;
  banner_image: string;
  data: any;
};

export type TGetFollowing = {
  status: boolean;
  message: string;
  data: any;
};
export type TGetnotify = {
  status: boolean;
  message: string;
  data: any;
};

export type TGetWeekData={
  status: boolean;
  message:string;
  data:{
    week:number;
    stage:string;

  }
}

export type TGetToDoList={
  status: boolean;
  message:string;
  data:{
    _id: string,
    createdBy: number,
    week: number,
    title: string,
    stage: string,
    status: string,
    isTickable: boolean,
    updatedAt: string,
    createdAt: string,
    isChecked: boolean
  }
}

export type TGetStoryData={
  status:boolean;
  message:string;
  data:{
    _id:string;
    createdBy:number;
    week:number;
    title:string;
    id:string;
    stage:string;
    status:string;
    slides:[
      {
        title:string;
        description:string;
        imageUrl:string;
        style:{
          bgColor:string
        },
        article: {
          articleId: number,
          articleDetails: {
              type: string,
              article: {
                  _id: string,
                  id: number,
                  likes: number,
                  savedCount: number,
                  commentsCount: number,
                  readTime: number,
                  thumbnailImage:string
                  likedBy: [],
                  savedBy: [],
                  views: number,
                  publishedAt: string,
                  lastUpdatedAt: string,
                  title: string,
                  content: string,
                  author: {
                      id: string,
                      display: boolean,
                      name: string,
                      title: string,
                      photo: string
                  },
                  permalink: string,
                  createdAt: string,
                  updatedAt: string,
                  __v: number,
                  liked: boolean,
                  saved: boolean,
                  likeCount: number,
                  commentCount: number,
                  count: number,
                  minutesRead: number,
                  postId: number
              },
              page: number,
              index: number
          }
      },
        buttons:[
          {
            buttonText: string,
            buttonColor: string,
            actionParams: {
                id: string
            },
            action: string
        }
        ]
      }
    ]
  }
}


export const getBannerApi = async (token: any) => {
  const url = "/api/content/v1/banner";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken(), useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};

export const getBannerByPageNo = async ({ pageParam = 1 }) => {
  const url = "/api/content/v1/banner?page=" + pageParam;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};

export const getListsApi = async (token: any) => {
  const url = "/api/content/v1/lists";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken(), useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};

export const getFeedsApi = async ({ pageParam = 1 }) => {
  const url = "/api/content/v1/feed?page=" + pageParam;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};

export const followUser = async (requestee_id: string) => {
  const url = "/api/users/v1/moms?type=following";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TGetFollowing, { requestee_id: string }>(url, { requestee_id: requestee_id }, { ...newconfig, skipAuthRefresh: true });
};

export const postCalendar = async (req: any) => {
  const url = "/api/content/v1/calendar";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPut<TCalendarData, { requestee_id: string }>(url, req, { ...newconfig, skipAuthRefresh: true });
};

export const unfollowUser = async (requestee_id: string) => {
  const url = "/Connection/unfollow";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TGetFollowing, { requestee_id: string }>(url, { requestee_id: requestee_id }, { ...newconfig, skipAuthRefresh: true });
};

export const getLikesapi = async () => {
  const url = "/api/users/v1/moms?type=like";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};

export const getMomApi = async (filter: any, q: any) => {
  const url = `/api/users/v1/moms?type=${filter.filter_type}&filterType=&q=${q}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};


export const getNearapi = async () => {
  const url = "/api/users/v1/moms?type=near";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetFeedRes>(url, { ...newconfig, skipAuthRefresh: true });
};


export const postMessageSend = async (data: string) => {
  const url = process.env.REACT_APP_CHATROOMS+`/api/messages/v1`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TGetFollowing, {}>(url, data, { ...newconfig, skipAuthRefresh: true });
};


export const customizeFeed = async () => {
  const url = "/api/customize-feed/static-feeds";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetWeekData>(url, { ...newconfig, skipAuthRefresh: true });
};


export const customizestoryStoryById = async ( data: any) => {
  const url = `/api/customize-feed/story?id=${data}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetStoryData>(url, { ...newconfig, skipAuthRefresh: true });
};






// todo list
export const customizeFeedTodoList = async () => {
  const url = "/api/customize-feed/to-do-lists-response";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGetToDoList>(url, { ...newconfig, skipAuthRefresh: true });
};


export const customizeFeedTodoListUpdate = async ( data: any) => {
  const url = "/api/customize-feed/to-do-list-response";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGetWeekData, {}>(url, data, {...newconfig,skipAuthRefresh: true,});
};


