import AuthLayout from "layouts/AuthLayout";
import partner_image from "../../images/login_partner.webp";
import { useDispatch, useSelector } from "react-redux";
import { TUserDetailRequest, viewprofile, viewProfileSuccessAction } from "actions/viewprofile";
import { useEffect } from "react";
import { environmentConfig } from "../../config";
import { useHistory } from "react-router-dom";
import { ACCESS_TOKEN_LS_KEY } from "libs/Constants";

const Login = () => {
   const dispatch = useDispatch();
   const history = useHistory();



  useEffect(() => {

    window.addEventListener("message", async function (event) {
       if (event.data && event.data.type === 'btp-auth') {
      //  let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.IjYyMDki.xhkRozFhkUB4bH38LkFfSFXwR_NVdsi9jFLAJuN5bDM';//event.data.token;
        let token = event.data.token;
        console.log('token', token);

        if (token !== null && token !== undefined) {
          let reqBody = {user_id: ""};
          window.localStorage.setItem(ACCESS_TOKEN_LS_KEY, JSON.stringify({Accesstoken: token}));
          // dispatch(viewprofile(reqBody as TUserDetailRequest, token));
          history.push("/");
        }
        else {
          window.location.replace(environmentConfig.BLOG_URL + '/signin');
        }
     }
    });
  });

  return (
    <div style={{ backgroundColor: "rgba(241, 241, 241, 1)" }}>
      <div className="my-2.5">
        <img className="w-32 m-auto" src={partner_image} />
      </div>
      <iframe id="iframe" title="Login" src={ environmentConfig.BLOG_URL + '/signin-api/?check=sso'}></iframe>
    </div>
  );
};

export default Login;
