import { Col, Row, Select, Tooltip } from "antd";
import { Header } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { notifysection } from "apis/notify";
import { getNotifications } from "apis/btpNotify";

import Headersection from "views/MainHeader/Header";
import { Tabs } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useHistory, useLocation } from "react-router";
import { EyeInvisibleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import { notificationreadAPI } from "apis/likeunlikepost";
import { Link } from "react-router-dom";
import moment from "moment";
import Footer from "views/MainFooter/Footer";

const { TabPane } = Tabs;
const onChange = (key: string) => {
  // console.log(key);
};
interface DataType {
  createdtime: string;
  created_date_time: string;
  updatedtime: string;
  updated_date_time: string;
  sender_name: string;
  profile_pic: string;
  notification_body: string;
}

const Notification = (props: any) => {
  const [notoficationData, setNotificationData] = useState<any>([]);
  useEffect(() => {
    const requestData = {
      start_date: "2020-10-13",
      end_date: moment().format("YYYY-MM-DD"),
    };
    getNotifications(requestData)
      .then(res => {
        setNotificationData(res?.notifications);
        // console.log("res?.notifications", res?.notifications);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const history = useHistory();
  const [notifysec, setnotifysec] = useState([]);
  const [messagesec, setMessagesec] = useState([]);
  const [invitename, setName] = useState([]);
  const [invitedesc, setDesc] = useState([]);
  const [messagename, setMessageName] = useState([]);
  const [messagedesc, setMessageDesc] = useState([]);
  const [Profile, setProfile] = useState<any>([]);
  const [msgpush, setMsgPush] = useState([]);
  const [readreceiversid, setReadReceiversId] = useState<any>([]);
  const [tabkeyval, setTabkeyval] = useState("");
  const router = useLocation();
  const { Accesstoken } = useSelector((state: TRootState) => state.auth);

  useEffect(() => {
    notifysection(Accesstoken).then(res => {
      setMessagesec(
        res.data.length > 0 &&
          res.data
            .filter((getmessage: any) => getmessage.notification_type === "message")
            .map(
              (mmm: any) => (
                setMessageName(mmm.sender_name),
                setMessageDesc(mmm.notification_body),
                setProfile(mmm.profile_pic),
                setReadReceiversId(mmm.sender_access_token)
              )
            )
      );
    });
  }, []);

  useEffect(() => {
    notifysection(Accesstoken).then(res => {
      setnotifysec(
        res.data
          .filter(
            (getmessage: any) => ` ${getmessage.notification_type === "connection"} , ${getmessage.notification_type === "following"}  `
          )
          .map(
            (mmm: any) => (
              setMsgPush(mmm),
              setName(mmm.sender_name),
              setDesc(mmm.notification_body),
              setProfile(mmm.profile_pic),
              setReadReceiversId(mmm.sender_access_token)
            )
          )
      );
    });
  }, []);

  const readnotify = () => {
    notificationreadAPI(readreceiversid).then(res => {
      console.log("Jamesjoy", readreceiversid);
    });
  };

  const columnsmessage: ColumnsType<DataType> = [
    {
      title: () => {
        return <div className="w-44"></div>;
      },
      dataIndex: "name",
      render: (text: string) => (
        <div className="flex flex-row justify-start items-center gap-4">
          <Avatar size="large" src={"https://dev.beingtheparent.com/btp-global-api/assets/btp_female.svg"} />
          <div className="flex flex-col">
            <a
              className="text-black !hover:text-red-400"
              onClick={() => {
                history.push("/messages", {
                  notification_details: readreceiversid,
                });
              }}>
              {messagename}
            </a>
            <p className="text-black !hover:text-red-300">{messagedesc}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        return <div className="float-right"></div>;
      },
      dataIndex: "address",
      render: (text, row) => (
        <span className="flex flex-row float-right gap-4 items-end">
          {/* <Read readid={readreceiversid}/> */}
          <Tooltip placement="top" title="Delete">
            <a className="text-black !hover:text-red-400">
              <DeleteOutlined />
            </a>
          </Tooltip>
        </span>
      ),
    },
  ];

  const columns: ColumnsType<DataType> = [
    {
      title: () => {
        return <div className="w-44"></div>;
      },
      dataIndex: "name",
      render: () => (
        <div className="flex flex-row justify-start items-center gap-4">
          {/* <Avatar
            size="large"
            src={
              "https://dev.beingtheparent.com/btp-global-api/assets/btp_female.svg"
            }
          /> */}
          {Profile ? (
            <>
              <Avatar src={Profile} />
            </>
          ) : (
            <Avatar size="large" src={"https://dev.beingtheparent.com/btp-global-api/assets/btp_female.svg"} />
          )}
          <div className="flex flex-col">
            <a
              className="text-black !hover:text-red-400"
              onClick={() => {
                history.push("", { notification_details: msgpush });
              }}>
              {invitename}
            </a>
            <p className="text-black !hover:text-red-300">{invitedesc}</p>
          </div>
        </div>
      ),
    },
    {
      title: () => {
        return <div className="float-right"></div>;
      },
      dataIndex: "address",
      render: (text, row) => (
        <span className="flex flex-row float-right gap-4 items-end">
          <Tooltip placement="top" title="Mark as Read">
            <div className="" onClick={readnotify}>
              <a className="text-black !hover:text-red-400">
                <EyeInvisibleOutlined />
              </a>
            </div>
          </Tooltip>
        </span>
      ),
    },
  ];

  useEffect(() => {
    let hash = router.hash;
    let activeKey;
    if (hash === "#Read") {
      activeKey = "Read";
      setTabkeyval(activeKey);
    } else if (hash === "#Unread") {
      activeKey = "Unread";
      setTabkeyval(activeKey);
    }
  }, [router]);

  return (
    <>
      <div style={{height:"95vh"}}>
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection {...props} />
        </Header>
        <div className="bg-white pt-24 container">
          <Row justify="end">
            <Col xs={22} xl={22} className="notify-head">
              <div className="flex justify-between">
                <h3 className="text-4xl my-4 notify">Notifications</h3>
                <div className="py-3 mr-8"></div>
              </div>
            </Col>
            <Col xs={23} xl={23}>
              <Row>
                <Col xs={23} xl={23} className="px-3">
                  <div className="notify_tab">
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                      <TabPane tab="Notifications" key="1">
                        <div className="overflow_section max-h-96 h-full overflow-y-scroll">
                          {/* <Table
                        pagination={false}
                        columns={columnsmessage}
                        dataSource={notoficationData}
                        className="sent_table"
                      /> */}
                          <div>
                            {notoficationData?.map((val: any, index: number) => {
                              // console.log("val",val);
                              // console.log(val.data?.groupId);

                              return (
                                <>
                                  {val.target == "/group" && (
                                    <Link to="/groups">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/connection" && (
                                    <Link to="/momslikeyou">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {val.target == "/connection" && (
                                    <Link to="/momslikeyou">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                  {val.target == "/follow" && (
                                    <Link to="/momslikeyou">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/group_pending_request" && (
                                    <Link to={`/group-profile/${val.data?.groupId}`}>
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/recommend_groups" && (
                                    <Link to="/groups">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/my_profile" && (
                                    <Link to="/viewprofile">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/must_read_articles" && (
                                    <Link to="/">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/shop" && (
                                    <Link to="/">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/moms" && (
                                    <Link to="/momslikeyou">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/user_profile" && (
                                    <Link to={"/momslikelistprofile/" + val.data?.userId}>
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/like_post" && (
                                    <Link to="/viewprofile">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}

                                  {val.target == "/comment_post" && (
                                    <Link to="/viewprofile">
                                      <div key={index} className="div-tabel">
                                        <img src={val.icon} alt="" style={{ marginRight: "10px" }} />
                                        <div className="cursor-pointer">
                                          <p>{val.body}</p>
                                          <p id="sub-p">{val.createdAt}</p>
                                        </div>
                                      </div>
                                    </Link>
                                  )}
                                </>
                              );
                            })}

                            {notoficationData?.length == 0 && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  border: "1px solid #ccc",
                                  borderRadius: "5px",
                                  margin: "5px",
                                  padding: "10px",
                                  backgroundColor: "#f0f0f0",
                                }}
                                className="card">
                                <p>No data</p>
                              </div>
                            )}
                          </div>
                        </div>
                      </TabPane>
                      {/* <TabPane tab="User Invites" key="2">
                      <div className="overflow_section max-h-96 h-full overflow-y-scroll">
                        <Table
                          pagination={false}
                          columns={columns}
                          dataSource={notifysec}
                          className="sent_table"
                        />
                      </div>
                    </TabPane> */}
                    </Tabs>
                  </div>
                </Col>
                <Col xs={24} xl={4}></Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Notification;
