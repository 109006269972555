import React, { Fragment, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Checkbox, message } from "antd";
import { customizeFeedTodoList, customizeFeedTodoListUpdate } from "apis/GetFeeds";

interface Props {
  setShowTodoList: React.Dispatch<React.SetStateAction<boolean>>;
}
const Todolist: React.FC<Props> = ({ setShowTodoList }) => {
  const handleCloseTips = () => {
    setShowTodoList(false);
  };

  const [totdoList, setTodolist] = useState<any>([]);
  const getTodoList = () => {
    customizeFeedTodoList()
      .then(res => {
        setTodolist(res.data);
      })
      .catch(err => {
        message.error(err)
      });
  };

  useEffect(() => {
    getTodoList();
  }, []);

  const handleChange = (e: any, value: any) => {
    let data = {
      id: value._id,
      isChecked: value.isChecked == true ? false : true,
    };
    customizeFeedTodoListUpdate(data)
      .then(res => {
        // console.log(res);
        getTodoList();
      })
      .catch(err => {
        message.error(err)
      });
  };

  return (
    <div className="todo-list-card ">
      <div  className="week-card-border-line">
        <div className="sympt-card-close" >
          <a>
            <CloseOutlined
              className="cursor-pointer read close-outline-btn"
              size={40}
              onClick={handleCloseTips}
            />
          </a>
        </div>
        <br />

        <div className="mini-card-todolist mt-3">
          <div className="track-weekly">
            <p >Keep a track of your weekly to-do list</p>
          </div>

          {totdoList?.map((list: any, index: number) => {
            return (
              <Fragment key={index}>
                {list?.isTickable === true && (
                  <>
                    <div className="track-weekly-check-list">
                      <Checkbox defaultChecked={list.isChecked === true} onChange={e => handleChange(e, list)}>
                        {list?.title}
                      </Checkbox>
                    </div>
                    <div className="line"></div>
                  </>
                )}
              </Fragment>
            );
          })}

          <div>
            <p className="more-itme-p " >
              MORE ITEMS TO DO THIS WEEK
            </p>
          </div>
          {totdoList?.map((list: any, index: number) => {
            console.log("list", list);
            return (
              <Fragment key={index}>
                {list?.isTickable === false && (
                  <>
                    <div className="line"></div>
                    <div className="is-tickable-title">
                      <p>{list?.title}</p>
                    </div>
                  </>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Todolist;
