import { Card, Row, Col, Space, Button } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Ads = (props: any) => {
  let addDeatil = props?.data?.collaterals;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
    arrows: false,
  };
  return (
    <>
      <div className="line">
        <Row align="middle" justify="space-between" className="mt-3">
          <Col span={20}>
            <Space align="center">
              <div>
                <h3
                  style={{
                    color: "#999",
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "22px",
                  }}>
                  {props.data?.preText}
                </h3>
              </div>
            </Space>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            {/* <MoreOutlined /> */}
          </Col>
        </Row>

        <Slider {...settings}>
          {addDeatil?.map((value: any) => (
            <div key={value?.id}>
              <div className="a-card-container">
                <Card
                  className="ads-card border-0"
                  style={{
                    marginRight: "10px",
                    border: "1px solid #F0F0F0",
                  }}>
                  <div
                    className="image-container"
                    style={{
                      width: "100%",
                      height: "500px",
                      overflow: "hidden",
                    }}>
                    {value.video && value.video !== "" ? (
                      <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${value.video.split("v=")[1]}?autoplay=1&mute=1`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen></iframe>
                    ) : (
                      <a href={value.url} target="_blank">
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          src={value.image}
                          alt=""
                          className="ad-image"
                        />
                      </a>
                    )}
                  </div>
                  <div className="content-container mb-5">
                    <div className="content-left mt-3">
                      <h3 className="ellipsis-title">{value?.title}</h3>
                      <p className="ellipsis-text">{value?.description}</p>
                    </div>

                    <div className="content-right mt-3">
                      <a href={value?.url} target="_blank">
                        <Button>Visit Now</Button>
                      </a>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Ads;
