import Articalsection2 from "components/global/Articalsection2";
import Articalsection3 from "components/global/Articalsection3";
import Articlesection1 from "components/global/Articlesection1";

import useSticky from "components/global/useSticky";
import { Row, Col, Card, Avatar, Empty, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "reducers";
import { Link } from 'react-router-dom';
import MustRead from "./MustRead";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/ViewProfile";

const Bannersection = () => {
  const { sticky, stickyRef } = useSticky();
  const authInfo = useSelector((state: TRootState) => state.auth);
  const { feed,icon_weekwise } = useSelector((state: TRootState) => state.feeds);
  //const profileData = useSelector((state: TRootState) => state.userprofile);
  // const {data, isLoading, isError} = useQuery(['profile'], () => viewProfileApi({user_id: ''}));
  const {isLoading, data} = useQuery('viewProfile', () => viewProfileApi({user_id: "",}

  ))
  const profileData = data&&data;
  if(!data) {
    return null;
  }

  // const profileData = data?.data[0];
  //const profileData:any = {}
  return (
    <div className="lg:px-5">
      <Row>
        <Col xs={24} xl={6} md={7} className="lg:pr-5 lg:pl-7  md:pr-5">
          <div className="Artical_card1">
            <Card
              title=""
              bordered={true}
              className="add_card mb-5 w-full max-w-[50%] "
              style={{marginLeft:"auto"}}
            >
              <Row className="">
                <Col xs={12} sm={12} className="text-left">
                  <div className="user_logo  pr-0 text_center_right">
                    {/* <Avatar shape="square" size={64} icon={<UserOutlined />} /> */}
                    {profileData?.profile_pic ?
                    <img src={profileData?.profile_pic} alt=""  style={{width:"64px",height:"64px",objectFit:"cover"}}/>
                    :
                    <img src={authInfo.parent_info?.gender_pic} alt="" style={{width:"64px",height:"64px",objectFit:"cover"}}/>
                    // <img src={profileData.profile_pic} alt=""  style={{width:"64px",height:"64px",objectFit:"cover"}}/>

                    }
                    {/* <img src={authInfo.parent_info?.profile_pic_url} alt=""  style={{width:"64px",height:"64px",objectFit:"cover"}} /> */}
                    {/* <img src={profileData.profile_pic} alt=""  style={{width:"64px",height:"64px",objectFit:"cover"}}/>  */}

                    {/* <img src={authInfo.parent_info?.gender_pic} alt="" style={{width:"64px",height:"64px",objectFit:"cover"}}/> */}
                  </div>
                </Col>
                <Col xs={12} sm={12} className="">
                  <p className="break-all text-center text-sm font-normal">{profileData?.full_name}</p>

                  {
                    profileData?.username_momnexus!==null &&
                  <p className="text-center text-sm font-normal">@{profileData?.username_momnexus}</p>}
                </Col>
              </Row>
              <div className="text-right">
                <Link
to={{pathname:"/editprofile/edit"}}
                  className=" pink_text font-normal cursor-pointer "
                >
                  Edit

                </Link>
              </div>
            </Card>
          </div>
          <div className=" sticky" ref={stickyRef} style={{ top: "100px" }} >
            <Articlesection1 />
          </div>
        </Col>
        <Col xs={24} xl={12} md={10} className="lg:pr-5 md:pr-5">
          <Articalsection3 />
        </Col>
        <Col xs={24} xl={6} md={7}  className="lg:pr-5 md:pr-5">
          <Articalsection2 />
          <MustRead />
          {sticky && (
            <div
              style={{
                height: `${stickyRef.current?.clientHeight}px`,
              }}
            />
          )}
        </Col>
      </Row>
      {sticky}
    </div>
  );
};

export default Bannersection;
