import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";
// import { apiGet, apiPatch, apiPost, getDefaultAxiosConfig } from "./btpApi";
import {
  TUserDetailRequest,
  TUserDetailResponse,
  TGetConnectionRes,
  TUserDetailConnection,
  TCoonectionReq,
  TProfileDetailRequest,
} from "actions/viewprofile";
import { getAccessToken } from "actions/auth";





//For Delete Account

export const userDelete = async (req:any) => {
  const url = "/BptglobalUser/user_delete";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TUserDetailConnection, TCoonectionReq>(url, req, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const viewProfileApi = async (req: TUserDetailRequest,) => {
  let accesstoken = getAccessToken();
  const url = "/Btpmobileapi/getuser_details";
  // const url="/api/users/v1/"
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiPost<TUserDetailResponse, TUserDetailRequest>(url, req,{
    ...newconfig,
    skipAuthRefresh: true,
  });
};



export const profileDetailsApi = async (req: TProfileDetailRequest) => {
  const url = "/Connection/user_profile_details";
  let accesstoken = getAccessToken();
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiPost<TUserDetailResponse, TProfileDetailRequest>(url, req, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const parentConnectionDetail = async (
  req: TCoonectionReq
) => {
  const url = "/Momnexus/get_connections";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};

  newconfig.headers = { ...head };
  return apiPost<TUserDetailConnection, TCoonectionReq>(url, req, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getConnectionReq = async (token: any) => {
  const url = "/Connection/get_pending_requests";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getviewprofileConnectionReq = async (token: any) => {
  const url = "Connection/getMyConnections";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getviewprofileFollowingReq = async (token: any) => {
  const url = "Account_settings/following_list";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};
export const getviewprofileFollowersReq = async (token: any) => {
  const url = "Account_settings/my_followers";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

// export const getcountrylistReq = async ( sk_country_id:string, country_status: string) => {
//   const url = "Bptglobalgeo/global-settings-geo-country-view";
//   let newconfig = getDefaultAxiosConfig();
//   const head = {
//     ...newconfig.headers,
//     accesstoken: process.env.REACT_APP_GLOBALTOKEN,
//   };
//   newconfig.headers = { ...head };
//   return apiPost<TGetConnectionRes, {sk_country_id:string, country_status: string}>(url, {sk_country_id:sk_country_id, country_status: country_status}, {
//     ...newconfig,
//     skipAuthRefresh: true,
//   });
// };
