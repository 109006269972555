import React, { useEffect, useState } from "react";
import { Form, Input, Radio, Space, Button } from "antd";
import type { RadioChangeEvent } from "antd";
import {
  updateprofile,
  TUpdateProfileRequest_contact,
} from "actions/updateprofile";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "reducers";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";

const ContactInfo = () => {
  const [isLoadingf, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { isLoading, data,refetch } = useQuery('viewProfile', () => 
  viewProfileApi({ user_id: "" ,enabled: false,},
  
  ))


  const profileData=data
  console.log(profileData);
  

  const { Accesstoken, userId } = useSelector(
    (state: TRootState) => state.auth
  );
  const contactinfo = useSelector((state: TRootState) => state.userprofile);

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    setIsLoading(true);
    let reqBody = (await form.validateFields()) as any;
    console.log(reqBody);
    console.log(values);
    

    reqBody.type = "contact";
    await dispatch(
      updateprofile(
        reqBody as TUpdateProfileRequest_contact,
        Accesstoken,
        (res) => handleCallback(res)
      )
    );
    setIsLoading(false);
    console.log("Received values of form: ", values);
  };


  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const handleCallback = (res: any) => {
    console.log("Received values of form: ", res);
  };

  useEffect(() => {
    // const { email, mobile } = contactinfo;
    const email=profileData?.email
    const mobile=profileData?.mobile
    form.setFieldsValue({
      contact_no: email,
      c_code: mobile,
    });
  }, [contactinfo]);

  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 18, color: "white" }}
      className="text-center"
      spin
    />
  );
  return (
    <div>
      <Form
        name="contact"
        form={form}
        layout="vertical"
        autoComplete="off"
        className="input-sty"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="contact_no"
          label="E-mail"
        >
          <Input id="contact_no"  disabled />
        </Form.Item>
        <Form.Item
          name="mobile"
          label="Phone Number"
          rules={[
            { required: true, message: "Please enter your Phone number!" },
            {
              message: "Please enter valid  mobile number",
              pattern: /^[6-9][0-9]{9}$/,
            },
          ]}
        >
          <Input id="mobile" placeholder="Please Enter your number" />
        </Form.Item>
        <Button type="primary" htmlType="submit" className="button-sty float-right">
        Save Changes
        </Button>
      </Form>
      {/* <Button
        type="primary"
        htmlType="submit"
        className="button-sty float-right"
        onClick={onFinish}
        danger
      >
        <span className="mr-2"> Save Changes</span>
        {isLoading && <Spin indicator={antIcon} />}
      </Button> */}
    </div>
  );
};

export default ContactInfo;
