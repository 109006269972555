
import { Card, Row, Col, Typography, Space, Button } from "antd";
import { Link } from 'react-router-dom';
const { Text } = Typography;

const SuggestedGroups = (props: any) => {
  let deatil = props.data;


  return (
    <div className='line' >
      <div className='mt-3' style={{ marginBottom: '20px' }} >
        <Row align="middle" justify="space-between">
          <Col span={9}>
            <Space align="center">
              <div>
                <Text>Suggested groups</Text>
              </div>
            </Space>
          </Col>
          <Col span={15} style={{ textAlign: 'right', color: '#ED4D41' }}>
            <Link to='/groups' style={{ width: '100%', cursor: 'pointer', color: '#ED4D41' }} >
              View All
            </Link>
          </Col>
        </Row>
        <div className="flex items-center justify-between overflow-x-auto mt-3 horizontal-scroll-container" style={{ width: '100%', gap: '10px' }}>
          {deatil.items.map((val: any, i: number) => {
            // console.log("detail", val);

            return (
              <Card style={{ padding: '10px', width: '400px' }} key={i}>
                <div style={{ height: '300px', overflow: 'hidden' }}>
                  <Card
                    style={{ height: '100%' }}
                    cover={
                      <img
                        src={val.groupImage}
                        style={{ objectFit: 'cover', width: '100%', height: '100%', minWidth: '390px', minHeight: '300px' }}
                        alt="Group"
                      />
                    }
                  >
                  </Card>
                </div>
                <Row>
                  <Col span={24}><h2 style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#333',
                    letterSpacing: '0.8',
                    lineHeight: '18px'

                  }}>{val.title}</h2></Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Text style={{ color: "#666", fontWeight: "bold", fontSize: '14px' }}>{val.type}</Text> <span style={{ color: '#666', fontWeight: "bold", fontSize: '14px' }}> {val.members} followers</span>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {/* <Link to='/groups' style={{ width: '100%', cursor: 'pointer' }}>
                      <button
                        className="full-btn"
                      >
                        Join
                      </button>
                    </Link> */}

                    {/* <Link to='/group-profile' style={{ width: '100%', cursor: 'pointer' }}>
                      <button
                        className="full-btn"
                      >
                        Join
                      </button>
                    </Link> */}

                    <Link to={`/group-profile/${val.id}`} style={{ width: '100%', cursor: 'pointer' }}>
                      <button className="full-btn">View</button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default SuggestedGroups