import React, { Fragment, useState, useEffect } from "react";
import { customizestoryStoryById } from "apis/GetFeeds";
import { CloseOutlined } from "@ant-design/icons";
import { LikeOutlined, LikeFilled } from "@ant-design/icons";
import SaveImgDark from "../../../images/SavedIcon.svg";
import Save from "../../../images/save.svg";
import CommentImg from "../../../images/comment.svg";
import ShareIcon from "../../../images/share_icon.svg";
import Send from "../../../images/send.svg";
import { Image, message } from "antd";
import UserComment from "views/Posts/UserComment";
import Share from "views/ShareModal/Share";
import SendModal from "views/Posts/SendModal";
import { articleLike, articleSave } from "apis/btpArticles";

interface Props {
  setShowChangeBody: React.Dispatch<React.SetStateAction<boolean>>;
  actionParamsId: any;
}

const ChangingBody: React.FC<Props> = ({ setShowChangeBody, actionParamsId }) => {
  const handleCloseTips = () => {
    setShowChangeBody(false);
  };

  const [changingBodyData, setChangingBodyData] = useState<any>([]);
  const [isModalArticleOpen, setISModalArticleOpen] = useState(false);
  const [liked, setLiked] = useState(false);
  const [savePost, setSavePost] = useState(false);
  const [postId, setPostID] = useState<any>("");
  const [shareOpenModal, setShareOpenModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [artcileData, setArticleData] = useState<any>({});
  const [type, setType] = useState<any>("blog");
  const [isSendShow, setIsSendShow] = useState(false);

  const handleCommentArticle = (postId: any) => {
    setISModalArticleOpen(true);
    setPostID(postId);
  };

  const [likeCount, setLikeCount] = useState<any>();
  const getStoryById = () => {
    customizestoryStoryById(actionParamsId)
      .then(res => {
        const likeCount = res?.data?.slides[0]?.article?.articleDetails?.article?.likeCount;
        setChangingBodyData(res?.data?.slides);
        setLikeCount(likeCount);
        if (res?.data?.slides[0]?.article?.articleDetails?.article?.liked == true) {
          setLiked(true);
        } else {
          setLiked(false);
        }
        if (res?.data?.slides[0]?.article?.articleDetails?.article?.saved == true) {
          setSavePost(true);
        } else {
          setSavePost(false);
        }
      })
      .catch(err => {
        message.error(err)
      });
  };

  useEffect(() => {
    getStoryById();
  }, [actionParamsId, isModalArticleOpen]);

  const handleShareBtn = (permalink: any) => {
    setShareOpenModal(true);
    setShareLink(permalink);
  };

  const handleSendBtn = (permalink: any) => {
    setIsSendShow(true);
    setArticleData(permalink);
    setType("blog");
  };

  const handleSaveBtn = (postId: any) => {
    setSavePost(!savePost);
    articleSave(postId)
      .then(res => { })
      .catch(err => {
        message.error(err)
      });
  };

  const handleLike = (postId: any) => {
    setLiked(prevLiked => !prevLiked);
    setLikeCount((prevCount: any) => {
      const increment = liked ? -1 : 1;
      return prevCount + increment;
    });
    articleLike(postId)
      .then(res => { })
      .catch((err: any) => {
        message.error(err);
      });
  };

  return (
    <Fragment>
      {setISModalArticleOpen && (
        <UserComment setISModalArticleOpen={setISModalArticleOpen} isModalArticleOpen={isModalArticleOpen} postId={postId} />
      )}
      {shareOpenModal && <Share setShareOpenModal={setShareOpenModal} shareOpenModal={shareOpenModal} shareLink={shareLink} />}
      {isSendShow && (
        <SendModal
          setIsSendShow={setIsSendShow}
          isSendShow={isSendShow}
          artcileData={artcileData}
          type={type}
          setArticleData={setArticleData}
        />
      )}
      <div
        className="mt-3 week-card-border-line">
        <div className="sympt-card-close">
          <a>
            <CloseOutlined
              className="cursor-pointer read close-outline-btn"
              size={40}
              onClick={handleCloseTips}
            />
          </a>
        </div>
        <br />
        <div className="weekcard-scroll">
          {changingBodyData?.map((cardVal: any, ind: number) => {
            return (
              <>
                <div
                  key={ind}
                  className="mr-3 text-center changing-body"
                  style={{
                    backgroundColor: `${cardVal?.style?.bgColor}`,
                  }}>
                  <p className="sympt-p-2">{cardVal?.description}</p>
                </div>
                <div className="w-100">
                  <h3 className="changing-body-h-tag">
                    Pregnancy symptomps
                  </h3>
                  <p>Few of the symptoms which you may notice at six weeks are:</p>
                </div>

                <div className="flex items-center horizontal-scroll-container-minicard">
                  {cardVal?.subStoryData?.map((sub: any, index: number) => {
                    return (
                      <>
                        <div
                          key={ind}
                          className="mr-3 text-center changing-body-substory-card"
                          style={{
                            backgroundColor: `${sub?.style?.bgColor}`,

                          }}>
                          <p className="sympt-p-1">{sub?.title}</p>
                          <img
                            src={sub?.imageUrl}
                            alt={sub?.imageUrl}
                            loading="lazy" />
                          <p className="sympt-pd">{sub?.description}</p>
                        </div>
                      </>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>

        {changingBodyData?.map((cardVal: any, ind: number) => {
          return (
            <Fragment key={ind}>
              {cardVal?.article?.articleDetails?.type === "article" && (
                <Fragment>
                  <div className="w-100 changing-body-frag">
                    <h3 className="changing-body-h-tag">
                      Related card for changing body
                    </h3>
                    <p>Few of the symptoms which you may notice at six weeks are:</p>
                  </div>
                  <div className="changing-body-artc" style={{ backgroundColor: `${cardVal?.style?.bgColor}`, }}>
                    <div className="mb-3 mt-3">
                      <a href={cardVal?.article?.articleDetails?.article.permalink}>
                        <img
                          className="object-cover w-full"
                          src={cardVal?.article?.articleDetails?.article?.thumbnailImage}
                          loading="lazy"
                        />
                        <p className="changing-body-artc-p" >
                          {cardVal?.article?.articleDetails?.article?.views} views &nbsp; &#x2022;{" "}
                          {cardVal?.article?.articleDetails?.article?.readTime} min read
                        </p>
                        <h2 className="my-3">{cardVal?.article?.articleDetails?.article?.title}</h2>
                      </a>
                      <p>{cardVal?.article?.articleDetails?.article?.content}</p>
                    </div>

                    <div className="flex spc-btn" >
                      <div>
                        {cardVal?.article?.articleDetails?.article?.likeCount !== 0 && (
                          <span
                            className="mt-1 cursor-pointer artc-icon-btn">
                            {likeCount} Like
                          </span>
                        )}
                      </div>
                      <div>
                        {cardVal?.article?.articleDetails?.article?.commentCount != 0 && (
                          <span
                            className="mt-1 cursor-pointer artc-icon-btn">
                            {cardVal?.article?.articleDetails?.article?.commentCount} &nbsp; Comment
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="flex text-center mt-3">
                      <div className="flex text-center pointer">
                        <span className="mt-1 mr-8">
                          {liked ? (
                            <LikeFilled

                              className="ml-1 f-20"
                              onClick={() => handleLike(cardVal?.article?.articleDetails?.article?.postId)}
                            />
                          ) : (
                            <LikeOutlined

                              className="ml-1 f-20"
                              onClick={() => handleLike(cardVal?.article?.articleDetails?.article?.postId)}
                            />
                          )}
                        </span>
                      </div>
                      <div className="flex text-center">
                        <span className="mt-1 mr-8">
                          <Image
                            src={CommentImg}
                            preview={false}
                            className="cursor-pointer"
                            onClick={() => handleCommentArticle(cardVal?.article?.articleDetails?.article?.postId)}
                          />
                        </span>
                        <span className="mt-1 cursor-pointer mr-8">
                          <Image
                            src={ShareIcon}
                            preview={false}
                            onClick={() => handleShareBtn(cardVal?.article?.articleDetails?.article?.permalink)}
                          />
                        </span>
                        <span className="mt-1 cursor-pointer mr-8">
                          <Image src={Send} preview={false} onClick={() => handleSendBtn(cardVal?.article?.articleDetails?.article)} />
                        </span>
                      </div>
                      <div
                        className="flex text-center"
                        style={{
                          marginLeft: "auto",
                        }}>
                        <span className="mr-1">
                          {savePost ? (
                            <Image
                              src={SaveImgDark}
                              preview={false}
                              className="cursor-pointer mt-1"
                              onClick={() => handleSaveBtn(cardVal?.article?.articleDetails?.article?.postId)}
                            />
                          ) : (
                            <Image
                              src={Save}
                              preview={false}
                              className="cursor-pointer mt-1"
                              onClick={() => handleSaveBtn(cardVal?.article?.articleDetails?.article?.postId)}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          );
        })}
      </div>
    </Fragment>
  );
};

export default ChangingBody;
