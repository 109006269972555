import React, { useState, useEffect } from "react";
import { Layout, Card, Table, Collapse, Slider, Switch,Form } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Info from "../icon/Vector.svg";
import Week from "../PregnancyCalculator/PregnancyWeek.json";
import { useLocation, useHistory } from "react-router-dom";
import { Line } from "@ant-design/charts";

const PregnancyCalcResult = () => {
  const location = useLocation();
  const history = useHistory();
  const res = (location.state as any)?.data?.pregnancyInfo;
  const week = (location.state as any)?.data?.week;
  const prePregnancyWeigh = parseInt(
    (location.state as any)?.prePregnancyWeight
  );
  const presentPregnancyWeight = (location.state as any)
    ?.presentPregnancyWeight;

  // console.log("prePregnancyWeigh", prePregnancyWeigh);
  // console.log("presentPregnancyWeight", presentPregnancyWeight);

  const [jsonData, setJsonData] = useState<any[]>([]);
  // console.log(location);

  useEffect(() => {
    setJsonData(Week);
  }, []);

  const { Panel } = Collapse;
  const columns = [
    {
      title: "Week of pregnancy",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Recommended weight range",
      dataIndex: "recomndedWeightRange",
      key: "recomndedWeightRange",
    },
    {
      title: "Recommended weight gain",
      dataIndex: "recomndedWeightGain",
      key: "recomndedWeightGain",
    },
  ];
  const dataSource = jsonData.map((item, index) => {
    return {
      ...item,
      key: index.toString(),
      name: item.week,
      recomndedWeightGain: item.weightGainMin + "-" + item.weightGainMax+" Kgs",
      recomndedWeightRange: `${prePregnancyWeigh + item?.weightGainMin} - ${
        prePregnancyWeigh + item?.weightGainMax
      } Kgs`,
    };
  });

  const handleBack = () => {
    history.push("/pregnancy-calculator");
  };

  const rowClassName = (record: any) => {
    return record.name === week ? "highlighted-row" : "";
  };

  const index = jsonData.findIndex((val: any) => val.week === week);
  const filteredData = jsonData.slice(
    Math.max(0, index - 2),
    Math.min(index + 4, jsonData.length)
  );

  const data = filteredData.map((item) => ({
    weight: ` ${prePregnancyWeigh + item?.weightGainMin}-${
      prePregnancyWeigh + item?.weightGainMax
    }`,
    week: item.week.substring(item.week.indexOf(" ") + 1),
  }));

  // console.log("week", week);

  const config = {
    data,
    xField: "weight",
    yField: "week",
    height: 400,
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      formatter: (datum: any) => {
        const subWeek = week.substring(" " + 4);
        if (parseInt(datum?.week) === parseInt(subWeek)) {
          return `${"Current Weight " + presentPregnancyWeight +"Kgs"}`;
        }
        return "";
      },
      style: {
        textAlign: "center",
        textBaseline: "bottom",
      },
    },
  };

  return (
    <>
      <Layout className="h-screen">
        <Header
          className=""
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Headersection />
        </Header>
        <Content className="container mt-10">
          <div className=" bg-white mt-20">
            <Card style={{ border: "none" }}>
              <div className="preg-calc-card-res">
                <div className="rectangle-parent">
                  <div className="frame-child"></div>
                  <div className="line-icons-parent">
                    <ArrowLeftOutlined
                      className="line-icons"
                      onClick={handleBack}
                    />
                    <div className="pregnancy-weight-gain">
                      <button onClick={handleBack}>
                        Pregnancy Weight Gain
                      </button>
                    </div>
                  </div>
                  <div className="group-wrapper">
                    <div className="result-parent">
                      <div className="result">📉 Result</div>
                      <div className="your-pregnancy-weight-gain-is-parent">
                        <div className="pregnancy-weight-gain">
                          Your pregnancy weight gain is
                        </div>
                        <div className="not-healthy">
                          {res?.pregnancyStatus?.charAt(0).toUpperCase() +
                            res?.pregnancyStatus?.slice(1)}
                        </div>
                      </div>
                      <div className="your-bmi-body-mass-index-parent">
                        <div className="pregnancy-weight-gain">
                          Your BMI (Body Mass Index)
                        </div>
                        <div className="kgm">{res?.bodymassindex} kg/m2</div>
                      </div>
                      <div className="your-target-weight-gain-parent">
                        <div className="pregnancy-weight-gain">
                          Your target weight gain
                        </div>
                        <div className="kgm"><p className="red-text">{res?.targetWeight}&nbsp;Kgs</p></div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="recomnded-weight">
                  <div>
                    <p>
                      Recommended weight range when delivering{" "}
                      <span className="red-text">40th</span>
                    </p>
                    <p className="red-text">{res?.recommendedWeightGain}&nbsp;Kgs</p>
                  </div>

                  <div
                    className="mt-10"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div className="weeks">Weeks</div>
                    <div className="slidecontainer">
                      <Line {...config} />
                      {/* <>
                        {filteredData.map((val: any) => {
                          return (
                            <>
                              <div key={val.week}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      color: val.week === week ? "red" : "#999",
                                    }}
                                  >
                                    {val.week}
                                  </p>
                                  &nbsp;
                                  <Slider
                                    range
                                    defaultValue={[
                                      prePregnancyWeigh + val?.weightGainMin,
                                      prePregnancyWeigh + val?.weightGainMax,
                                    ]}
                                    className="slider"
                                    disabled
                                  />
                                </div>
                                <br />
                              </div>
                            </>
                          );
                        })}
                      </> */}
                      {/* <div className="horizontal-container">
                        {numb.map((v: any, ind: number) => {
                          return (
                            <p key={ind} className="horizontal-value">
                              {v.num}
                            </p>
                          );
                        })}
                      </div> */}
                    </div>
                  </div>

                  <div className="weight-kg">Weight (kg)</div>

                  {/* <div className="frame-container">
                    <div className="rectangle-container">
                      <div className="frame-child1"></div>
                      <div className="healthy">Healthy</div>
                    </div>
                    <div className="rectangle-parent1">
                      <div className="frame-child2"></div>
                      <div className="healthy">Current weight</div>
                    </div>
                    <div className="rectangle-parent2">
                      <div className="frame-child3"></div>
                      <div className="healthy">Unhealthy</div>
                    </div>
                  </div> */}

                  <div className="biinfo-circle-fill-parent mt-10">
                    <img
                      className="biinfo-circle-fill-icon"
                      alt=""
                      src={Info}
                    />

                    <div className="this-graph-shows">
                      This graph shows how you're placed towards your total
                      pregnancy weight gain.
                    </div>
                  </div>

                  <div className="mt-3">
                    <Collapse
                      defaultActiveKey={["1"]}
                      ghost
                      expandIconPosition="right"
                    >
                      <Panel header="Recommended weight range" key="1">
                        <Table
                          columns={columns}
                          dataSource={dataSource}
                          pagination={false}
                          rowClassName={rowClassName}
                          className="recomnded-range-tabel"
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default PregnancyCalcResult;
