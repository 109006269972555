import { getAccessToken } from "actions/auth";
import { apiGet, apiPost, getDefaultAxiosConfig, apiDelete, apiPatch } from "../../apis/btpApi";


export type TGroupResponse = {
  status: boolean;
  message: string;
  data:{
    groups:[],
    pageInfo:{
        totalCount:number
    }
  }
};

export type TUJoinGroup={
  status:boolean;
  message:string;
  data:string
}

export type TUserPost={
 data:{
    posts:[
        {
            postTitle:string;
            postPrivacy:string;
            postMedia:[
                {
                    mediaType:"";
                    mediaKey:"";
                    postType:"";
                    totalLikes:"";
                    likedBy:[];
                    totalComments:number;
                    totalSavedPosts:number;
                    savedBy:[];
                    createdDate:"";
                    updatedDate:"";
                    importance:"";
                    fullName:"";
                    profilePic:"";
                    userStatus:number;
                    badge:""
                    likeStatus:number;
                    savedPostStatus:number
                }
            ]
        }
    ]
 }
}

export type TUGroupByID={
  status:boolean;
  message:string;
  data:{
    _id:string;
    altGroupId:number;
    groupName:string;
    groupImage:string;
    description:string;
    type:string;
    isSearchable:string;
    focus:string;
    groupStatus:string;
    invite:string;
    activityFeed:string;
    createdBy:number;
    isModerated:boolean;
    groupRule:string;
    membersCount:number;
    lastPostTime:string;
    createdAt:string;
    lastUpdated:string;
    groupMedia:{
      mediaType:string;
      mediaKey:string;
      mediaSize:string;
    }
    groupId:string;
    imageUrl:string
  }
}


export type TUserLike={
    status:boolean
}

export type TGroupLeave={
  status:boolean;
  message:string;
  data:{
    acknowledged:boolean;
    deletedCount:number
  }

}


export type TUGroupMemberList={
  status:boolean;
  message:string;
  data:{
    members:[
      {
        _id:string;
        isModerator:boolean;
        isAdmin:boolean;
        memberStatus:string
        groupId:string;
        userId:number;
        isMuted:boolean;
        id:boolean;
        lastUpdated:string;
        createdAt:string;
        full_name:string;
        profile_pic:string;
        followersCount:number;
        followingCount:number;
        connectionsCount:number;
        canSendConnectionRequest:{
          status:string;
          id:string
        },
        mutualConnection:string;
        canSeeConnections:string;
      }
    ],
    pageInfo:{
      totalCount:number
    }
  }
}

export type TUGroupPostByID={
  status:boolean;
  data:{
    posts:[
      {
        _id:string;
        userId:number;
        postTitle:string;
        postDescription:string;
        postPrivacy:string;
        postMedia:[
          {
            mediaType:string;
            mediaKey:string;
            mediaSize:number;
            createdDate:string;
            updatedDate:string;
            contentType:string;
            _id:String;
          }
        ],
        postType:string;
        groupId:string;
        totalLikes:number;
        likedBy:[];
        totalComments:number;
        totalSavedPosts:number;
        savedBy:[];
        createdDate:string;
        updatedDate:string;
        importance:number;
        __v:number;
        fullName:string;
        profilePic:string;
        userStatus:1;
        badge:string;
        likeStatus:number;
        savedPostStatus:number;
        comments:[]
      }
    ],
    pageInfo:{
      totalCount:number
    }
  }
}

export type TUConnect={
  userId:string
}

export type TUGroupPost={

  status:boolean;
  data:{
    comments:[]
    post:{
      comments:[]
    }
  }
}

export type TMomProfile={
  badge:string,
  c_code:string,
  connectionsCount:string,
  education:string,
  email:string,
  followersCount:number,
  followingCount:number,
  full_name:string,
  i_am:string,
  mobile:string,
  posts:[],
  profile_pic:string,
  role:string
}

export const createPostCore = async ( groupData: any) => {
    const url="/api/content/v1/post"
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken()};
    newconfig.headers = { ...head };
    return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
  };

  export const grpPostUpdate = async ( groupData: any,id:any) => {
    const url=`/api/content/v1/post/${id}`
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken()};
    newconfig.headers = { ...head };
    return apiPatch<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
  };

export const getUserPost = async () => {
    const url = "/api/content/v1/post";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiGet<TUserPost>(url, { ...newconfig, skipAuthRefresh: true, });
};

export const groupById = async (id: any) =>{
  let url=`/api/groups/v1/group?groupId=${id}`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TUGroupByID>(url,{...newconfig,skipAuthRefresh: true,});
};

export const groupPostByID = async (id: any,pageInfo:any) =>{
  let url=`/api/content/v1/group-posts/${id}?page=${pageInfo.current}&limit=${pageInfo.pageSize}`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TUGroupPostByID>(url,{...newconfig,skipAuthRefresh: true,});
};

export const joinGroupApi = async ( groupData: any) => {
  const url = "/api/groups/v1/join";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TUJoinGroup, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const groupMemberById = async (id: any,pageInfo:any) =>{
  let url=`/api/groups/v1/group-members?page=${pageInfo.current}&limit=${pageInfo.pageSize}&groupId=${id}&memberStatus=active`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TUGroupMemberList>(url,{...newconfig,skipAuthRefresh: true,});
};

export const leaveGroup = async (id: any) =>{
  let url=`/api/groups/v1/leave-group?groupId=${id}`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiDelete<TGroupLeave>(url,{...newconfig,skipAuthRefresh: true,});
};

export const deleteMyPost = async (id: any) => {
  const url = `/api/content/v1/post`;
  const newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  const requestBody = {
    postIds: [id],
  };
  return apiDelete<TGroupLeave>(url, {
    ...newconfig,
    skipAuthRefresh: true,
    data: requestBody,
  });
};

export const getMomProfile = async ( groupData: any) => {
  const url = "/api/users/v1/profile";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TMomProfile, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const postConnectApi = async ( groupData: any) => {
  const url = "/api/users/v1/moms/connect";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TUConnect, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const getGroupsCore = async (propsData: any, pageInfo: any) =>{
  let url=`/api/groups/v1/groups?page=${pageInfo.current}&limit=${pageInfo.pageSize}`
  if(propsData.recommended === true){
    url+= '&isRecommended=true';
  }
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const groupPostComment = async ( groupData: any) => {
  const url="/api/content/v1/post/comment"
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const groupPostCommentID = async (id: any) =>{
  let url=`/api/content/v1/post/${id}`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TUGroupPost>(url,{...newconfig,skipAuthRefresh: true,});
};

export const groupPostDeleteComment = async ( id: any) => {
  const url=`/api/content/v1/post/comment/${id}`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiDelete<TGroupLeave>(url,{...newconfig,skipAuthRefresh: true,});
};

export const savePostGroupApi = async (id: any) =>{
  const url=`/api/content/v1/posts/${id}/saveordelete`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TUserLike,{}>(url,{...newconfig,skipAuthRefresh: true,});
};

export const getSavePostItem = async () => {
  let url=`api/content/v1/saved-posts?page=1&limit=100`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TUserPost>(url, { ...newconfig, skipAuthRefresh: true, });
};


export const createGroup = async ( groupData: any) => {
  const url = "/api/groups/v1/group";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const updateGroup = async ( groupData: any) => {
  const url = "/api/groups/v1/group";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPatch<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const deleteGroup = async (id: any) =>{
  let url = '/api/groups/v1/group?groupId=' + id;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiDelete<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const grouplikeApi = async (id: any) =>{
  let url=`/api/content/v1/posts/${id}/likeandunlike`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TUserLike,{}>(url,{...newconfig,skipAuthRefresh: true,});
};
















// ++++++++++++++++++++++++++++++++++++++




export const getGroupDetails = async (params: any) =>{
  let url = '/groups/group/' + params.slug1;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const getGroupPosts = async (params: any, pageNumber: number) =>{
  let url = '/groups/group_posts/' + params.slug1 + '?limit=20&page=' + pageNumber;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};




export const createGroupMember = async ( groupData: any) => {
  const url = "/groups/create_group_member";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};


export const getPostByID = async (id: any) =>{
  let url = `api/content/v1/post/${id}`
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

// individual post

// api/content/v1/post/:postId





