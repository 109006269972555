import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, DatePicker, Button, Spin, Avatar, Input, Image } from "antd";
import { message, Upload } from "antd";
import { SendOutlined, MoreOutlined } from "@ant-design/icons";
import { fetchMomsMessage, chatRoomsInitiate } from "../../../apis/btpFallowUnfallow";
import { io, Socket } from "socket.io-client";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import { getAccessToken } from "actions/auth";
import { CloseOutlined } from "@ant-design/icons";

interface ModalProps {
  showMessage: any;
  setShowMessage: any;
  userID: any;
  fullName: any;
}

interface Message {
  text: string;
  senderId: number;
}

const MessageModal: React.FC<ModalProps> = ({ showMessage, setShowMessage, fullName, userID }) => {
  const [momUserId, setMomUserID] = useState<any>("");
  const [recivedMessage, setRecivedMessage] = useState<any>([]);
  const { isLoading, data } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));
  console.log("userID",userID);

  const chatRoomInitiate = async () => {
    let datß = JSON.stringify({
      participants: [userID],
      isGroup: false,
      title: null,
    });
    await chatRoomsInitiate(datß)
      .then(res => {
        setMomUserID(res.id);
      })
      .catch(err => {
        message.error(err);
      });
  };

  useEffect(() => {
    chatRoomInitiate();
  }, []);

  const getMessage = async () => {
    const messages = await fetchMomsMessage(momUserId);
    setRecivedMessage(messages);
  };

  useEffect(() => {
    if (momUserId) {
      getMessage();
    }
  }, [momUserId]);

  const [socket, setSocket] = useState<Socket | null>(null);
  console.log("momUserId",momUserId);

  useEffect(() => {
    if (momUserId) {
      const newSocket = io(process.env.REACT_APP_CHATROOMS + `/?room=${momUserId}`, {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: getAccessToken(),
            },
          },
        },
        // transports: ['websocket'],
        // transportOptions: {
        //   websocket: {
        //     extraHeaders: {
        //       Authorization: getAccessToken(),
        //     },
        //   },
        // },
        reconnection: false,
        reconnectionDelay: 30000,
        reconnectionAttempts: 3,
      });
      setSocket(newSocket);
      newSocket.on("message", newMessage => {
        setRecivedMessage((prevMessages: Message[]) => [...prevMessages, newMessage]);
      });
      newSocket.on("newMessage", () => {
        getMessage();
        scrollToBottom();
      });
      newSocket.emit("join", {
        chatroomId: momUserId,
      });
      return () => {
        newSocket.disconnect();
      };
    }
  }, [momUserId]);

  const [inputVal, setInputVal] = useState("");
  function generateRandomId() {
    const characters = "0123456789abcdef";
    let id = "";
    for (let i = 0; i < 24; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters[randomIndex];
    }
    return id;
  }

  const randomId = generateRandomId();
  const sendMessage = () => {
    if (socket) {
      const messageData = {
        chatroomId: momUserId,
        senderId: data?.sk_user_id,
        _id: randomId,
        type: "text",
        textContent: inputVal,
        mediaKey: null,
      };
      socket.emit("message", messageData);
      setInputVal("");
      getMessage();
    }
  };

  const handleCancel = () => {
    if (socket) {
      setShowMessage(false);
      socket.emit("leave", {
        chatroomId: momUserId,
      });
      socket.disconnect();
    }
  };

  // Ne line
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollableContainerRef.current;
      scrollableContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [recivedMessage, getMessage]);

  return (
    <Modal
      title={fullName}
      open={showMessage}
      footer={null}
      className="no-padding-modal"
      closable={true}
      closeIcon={<CloseOutlined style={{ fontSize: "24px", color: "#000" }} />}
      onCancel={handleCancel}
      style={{
        marginBottom: "-50px",
        top: "auto",
        bottom: 0,
        position: "fixed",
        float: "right",
        right: 0,
      }}>
      <div className="comment-scrollable" ref={scrollableContainerRef}>
        <>
          {recivedMessage?.map((val: any, index: number) => {
            const sentTime = new Date(val.sentOn); // Convert sentOn to a Date object
            const currentTime = new Date(); // Current time

            const timeDiffInSeconds = Math.floor((currentTime.getTime() - sentTime.getTime()) / 1000); // Calculate time difference in seconds

            let timeAgo = "";

            if (timeDiffInSeconds >= 0) {
              if (timeDiffInSeconds > 60) {
                const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                if (timeDiffInMinutes < 60) {
                  timeAgo = `${timeDiffInMinutes} minute${timeDiffInMinutes !== 1 ? "s" : ""} ago`;
                } else if (timeDiffInMinutes < 1440) {
                  const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
                  timeAgo = `${timeDiffInHours} hour${timeDiffInHours !== 1 ? "s" : ""} ago`;
                } else if (timeDiffInMinutes < 43200) {
                  const timeDiffInDays = Math.floor(timeDiffInMinutes / 1440);
                  timeAgo = `${timeDiffInDays} day${timeDiffInDays !== 1 ? "s" : ""} ago`;
                } else if (timeDiffInMinutes < 525600) {
                  const timeDiffInMonths = Math.floor(timeDiffInMinutes / 43200);
                  timeAgo = `${timeDiffInMonths} month${timeDiffInMonths !== 1 ? "s" : ""} ago`;
                } else {
                  const timeDiffInYears = Math.floor(timeDiffInMinutes / 525600);
                  timeAgo = `${timeDiffInYears} year${timeDiffInYears !== 1 ? "s" : ""} ago`;
                }
              } else if (timeDiffInSeconds > 0) {
                timeAgo = `${timeDiffInSeconds} second${timeDiffInSeconds !== 1 ? "s" : ""} ago`;
              }
            }

            return (
              <>
                {data?.sk_user_id == val.senderId && (
                  <div>
                    <div className="chat-message sender">
                      <div className="message-box">
                        {" "}
                        <p>{val?.textContent}</p>
                      </div>
                      <div className="message-time">{timeAgo}</div>
                    </div>
                  </div>
                )}
                {data?.sk_user_id != val.senderId && (
                  <div className="chat-message receiver">
                    <div className="message-box">
                      {" "}
                      <p>{val?.textContent}</p>
                    </div>
                    <div className="message-time">{timeAgo}</div>
                  </div>
                )}
              </>
            );
          })}
        </>
      </div>

      <div>
        <Form>
          <Form.Item name="textContent">
            <div className="comment-input">
              {data && data.profile_pic != null ? (
                <img src={data && data.profile_pic} alt="User's Profile Picture" width="40" height="40" className="comment-image" />
              ) : (
                <Avatar shape="circle" size={40} className="mr-5" />
              )}

              <Input
                placeholder="Your Comment"
                name="userComment"
                autoComplete="off"
                className="ml-2"
                value={inputVal}
                onChange={e => setInputVal(e.target.value)}
                addonAfter={
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "black",
                      height: "13px",
                    }}
                    type="primary"
                    htmlType="submit"
                    icon={
                      <SendOutlined
                        style={{
                          fontSize: "16px",
                          color: "gray",
                          border: "none",
                        }}
                        onClick={sendMessage}
                      />
                    }
                  />
                }
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default MessageModal;
