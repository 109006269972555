import { Col, Layout, Menu, Row, Card } from "antd";
import MomsImg from "../../images/moms-like-you.png";
import Profile_bg from "../../../images/profile_cover.png";
import Profile_img from "../../../images/profile_img.png";
import Edit_icon from "../../../images/edit_icon.png";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { Button, Popover } from "antd";
import Connect from "components/global/buttonsection/Connect";
import Followbutton from "components/global/buttonsection/Followbutton";
import { useParams } from 'react-router-dom';

function ProfileDetails(props: any) {
  const { profileData } = props;

  let userIdForConnect  = useParams();
  // console.log(userIdForConnect);
  
  // console.log(profileData);

  // const [modal2Visible, setModal2Visible] = useState(false);
  // const [visible, setVisible] = useState(false);
  const like_stylus = "py-1 w-20 text-sm";

  // const content = (
  //   <div>
  //     <div className="flex items-center pt-2.5 pb-2.5">
  //       <UserAddOutlined />
  //       <a className="ml-2.5">
  //         <Followbutton {...profileData} className={like_stylus} />
  //       </a>
  //     </div>
  //     <Link to={""}>
  //       <div className="flex items-center pt-2.5 pb-2.5">
  //         <MailOutlined /> <p className="ml-2.5">Send message</p>
  //       </div>
  //     </Link>
  //     <div className="flex items-center pt-2.5 pb-2.5 block_modal">
  //       <BiBlock />
  //       <a className="ml-2.5" onClick={() => setModal2Visible(true)}>
  //         <span onClick={() => setVisible(false)}>Block</span>
  //       </a>
  //       <Modal
  //         title="Block Member?"
  //         centered
  //         visible={modal2Visible}
  //         onOk={() => setModal2Visible(false)}
  //         onCancel={() => setModal2Visible(false)}
  //         className="mb-6 bg-slate-200"
  //         footer={
  //           <div>
  //             <button onClick={() => setModal2Visible(false)} className="mr-5">
  //               Cancel
  //             </button>
  //             <button
  //               onClick={() => setModal2Visible(false)}
  //               type="button"
  //               className="btn pink_outline_btns"
  //             >
  //               Confirm
  //             </button>
  //           </div>
  //         }
  //       >
  //         <p className="mb-3.5">
  //           Please confirm you want to block this member.
  //         </p>
  //         <p className="mb-3.5">You will no longer be able to:</p>
  //         <ul className="ml-3.5 list-disc">
  //           <li className="mb-1.5">See blocked member's posts</li>
  //           <li className="mb-1.5">Mention this member in posts</li>
  //           <li className="mb-1.5">Invite this member to groups</li>
  //           <li className="mb-1.5">Message this member</li>
  //           <li className="mb-1.5">Add this member as a connection</li>
  //         </ul>
  //         <p>
  //           <span className="font-black text-lg	">Please note:</span> This action
  //           will also remove this member from your connections and send a report
  //           to the site admin.Please allow a few minutes for this process to
  //           complete.{" "}
  //         </p>
  //       </Modal>
  //     </div>
  //   </div>
  // );

  return (
    <Layout>
      <div className="bg-white mt-20 container">
        <Content className="mt-10">
          <Card className="mt-10">
            <Row gutter={16}>
              <Col span={6}>
                <div
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "50%",
                    background: `url(${
                      profileData
                        ? profileData.profile_pic
                        : "your-default-image-url"
                    })`,
                    backgroundSize: "cover",
                  }}
                />
              </Col>
              <Col span={18} className="mt-10">
                <div>
                  <h2>
                    {profileData.full_name !== ""
                      ? profileData.full_name
                      : null}
                  </h2>
                  {profileData.i_am == "3" && <p>I'm Parent</p>}
                  {profileData.i_am == "1" && <p>I'm Planning</p>}
                  {profileData.i_am == "2" && <p>I'm Pregnant</p>}
                  {profileData.i_am == "4" && <p>Parent and Pregnant</p>}
                  {profileData.i_am == "5" && <p>Parent and Planning</p>}
                </div>
                <Row gutter={16} className="mt-3">
                  <Col span={3}>{profileData.connectionsCount}</Col>
                  <Col span={3}>{profileData.followersCount}</Col>
                  <Col span={3}>{profileData.followingCount}</Col>
                </Row>
                <Row gutter={16} style={{ marginTop: "10px" }}>
                  <Col span={3}>Connections</Col>
                  <Col span={3}>Followers</Col>
                  <Col span={3}>Following</Col>
                </Row>
                <div>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    <div>
                      <Connect
                        {...profileData}
                        getConnectionList={props.getConnectionList}
                        userIdForConnect={userIdForConnect}
                      />
                    </div>
                    <div style={{ borderRadius: "12px" }}>
                      <Button
                        className="mb-1"
                        style={{
                          backgroundColor: "#ed4d41",
                          padding: "2px",
                          borderRadius: "5px",
                        }}
                      >
                        <Followbutton
                          {...profileData}
                          getConnectionList={props.getConnectionList}
                          class={like_stylus}
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </Content>

        <Content className="table_content mt-5">
          <div className="hdng_sec">
            <h3>Profile of {profileData && profileData.full_name}</h3>
          </div>
        </Content>
        <Content className="table_content">
          <h4>Personal Details</h4>
          <div className="table_inner_cnt">
            <Row>
              <Col span={4}>
                <h5>Email</h5>
              </Col>
              <Col span={4}>
                <p>{profileData && profileData.email}</p>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <h5>Name</h5>
              </Col>
              <Col span={4}>
                <p>{profileData && profileData.full_name}</p>
              </Col>
            </Row>
          </div>
        </Content>
      </div>
    </Layout>
  );
}

export default ProfileDetails;
