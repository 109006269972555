import React from 'react'
import { Button, Checkbox, Form, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

function LoginInformation() {
   
    const onFinish = (values: any) => {
        console.log('Success:', values);
      };
    
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };

      const style = {color: "white",padding:"14px",backgroundColor: "#007cff",}

  return (
    <div className='p-4 lg:py-10 lg:px-20 bg-white login_section'>
        <h3 className='text-xl font-light mb-7'>Login Information</h3>
        <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            >
            <Form.Item
                label="Current Password (required to update email or change current password)"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
            <Input.Password className="h-14"/>
            </Form.Item>
            <div className='my-8'><a className='text-red-600 my-10'>Lost your password?</a></div> 
            <Form.Item
                label="Account Email"
                name="Account Email"
                rules={[{ required: true, message: 'Please Enter Email' }]}
                >
                <Input   className="h-14"/>
            </Form.Item>
            <div className='flex mb-5 login_section' style={{backgroundColor:'#fbfbfc'}}>
                <InfoCircleOutlined style={style} />
                <p className='m-0 py-4 px-5'>Leave password fields blank for no change</p>
            </div>
            <Form.Item
                label="Add Your New Password"
                name=""
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
            <Input.Password className="h-14 "/>
            </Form.Item>
            <Form.Item
                label="Repeat Your New Password"
                name=""
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
            <Input.Password   className="h-14"/>
            </Form.Item>
            <Button htmlType="submit" style={{backgroundColor:"#ff5a5f",borderRadius:"50px",color:"#fff"}}>
              Save Changes
            </Button>
        </Form>
    </div>
  )
}

export default LoginInformation