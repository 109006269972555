import { useEffect, useState, createElement } from 'react';
import { getGroupPosts } from "./GroupApi";
import { useParams } from "react-router-dom";
import { Layout } from 'antd';
import { List, Avatar, Skeleton, Tooltip, Divider} from 'antd';
import { LikeOutlined, DislikeFilled, DislikeOutlined, LikeFilled, MessageOutlined } from '@ant-design/icons';
import { likeandunlikepost, likedlist } from "apis/likeunlikepost";
import InfiniteScroll from 'react-infinite-scroll-component';

let groupPostCallCount = 0;
const GroupPostList = (props:any) => {
  const [groupPosts, setGroupPosts] = useState(
	[]
	);
	const [totalRows, setTotalRows] = useState(0);
	const [loading, setLoading] = useState(false);
	const [hasMoreItems, setHasMoreItems] = useState(true);
	const [likes, setLikes] = useState<any>([]);

  const params: any = useParams();

	useEffect(() => {
		getGroupPostList();
	}, [params]);


	const getGroupPostList = async () => {
		setLoading(true);
		let data: any = await getGroupPosts(params, groupPostCallCount);
		if(data.data.length === 0){
			setHasMoreItems(false);
		}
		setGroupPosts(groupPosts.concat(data.data));
		setTotalRows(data.total_rows);
		setLoading(false);
		groupPostCallCount = groupPostCallCount + 1;
	}

	

	const onClickLike = async (post_id: string) => {
    let index = likes.findIndex((x: any) => x === post_id);
    if (index >= 0){
			await likes.splice(index, 1);
			await likeandunlikepost(post_id, 'unlike').then((res) => {
			});
		}
    else {
			await likes.push(post_id);
			await likeandunlikepost(post_id, 'like').then((res) => {
			});
		}
    setLikes([...likes]);   
		await getGroupPostList();
    } 
	
	
	return(
		<div
      id="scrollableDiv"
      style={{
        height: 400,
        overflow: 'auto',
        padding: '0 16px',
        border: '1px solid rgba(140, 140, 140, 0.35)',
      }}
    >
			<InfiniteScroll
				dataLength={groupPosts.length}
				next={getGroupPostList}
				hasMore={hasMoreItems}
				loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
				// endMessage={<Divider plain></Divider>}
				scrollableTarget="scrollableDiv"
			
			>
				<List
					itemLayout="vertical"
					size="large"
					dataSource={groupPosts}
					renderItem={(item: any) => {
						return <List.Item
							key={item.sk_post_id}
							actions={[
								<Tooltip key="like" title="Like">
									<span className="comment-action" onClick={() => onClickLike(item.sk_post_id)}>
										{createElement(likes.findIndex((x: any) => x === item.sk_post_id) >= 0 || item.like_status === 1  ? LikeFilled : LikeOutlined)}
										<span className="comment-action">{item.like_count}</span>
									</span>
								</Tooltip>,
								<Tooltip key="comments" title="Comments">
									<span>
										{createElement(MessageOutlined)}
										<span>{item.comment_count}</span>
									</span>
								</Tooltip>,
							]}
						>
							<List.Item.Meta
								avatar={<Avatar src={item.profile_pic} />}
								title={item.full_name + " posted in " + props.groupData.group_name}
								description={item.post_description}
							/>
							{item.content}
						</List.Item>
					}}
				/>
	    </InfiniteScroll>
		</div>
	);
}

export default GroupPostList;