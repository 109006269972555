import React, { useEffect, useState } from "react";
import { Table, Button, Layout, Tabs, message } from "antd";
import { Link, useHistory, useParams } from "react-router-dom";
import Headersection from "views/MainHeader/Header";
const { Header, Content } = Layout;
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { viewProfileApi } from "../../../apis/btpViewProfile";
import { findAllName, addFavName, getFavName } from "../../../apis/babyName";
import { useQuery } from "react-query";
import NameDetails from "./NameDetails";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Footer from "views/MainFooter/Footer";

const alphabet = [
  {
    alphaval: "A",
  },
  {
    alphaval: "B",
  },
  {
    alphaval: "C",
  },
  {
    alphaval: "D",
  },
  {
    alphaval: "E",
  },
  {
    alphaval: "F",
  },
  {
    alphaval: "G",
  },
  {
    alphaval: "H",
  },
  {
    alphaval: "I",
  },
  {
    alphaval: "K",
  },
  {
    alphaval: "L",
  },
  {
    alphaval: "M",
  },
  {
    alphaval: "N",
  },
  {
    alphaval: "O",
  },
  {
    alphaval: "P",
  },
  {
    alphaval: "Q",
  },
  {
    alphaval: "R",
  },
  {
    alphaval: "S",
  },
  {
    alphaval: "T",
  },
  {
    alphaval: "U",
  },
  {
    alphaval: "V",
  },
  {
    alphaval: "W",
  },
  {
    alphaval: "X",
  },
  {
    alphaval: "Y",
  },
  {
    alphaval: "Z",
  },
];

const Nakshatra = (props: any) => {
  const history = useHistory();
  const pathname = history.location?.pathname || "";
  const formattedPathname = pathname.replace(
    /^\/(.)(.*)/,
    (_, firstChar, rest) => {
      return firstChar.toUpperCase() + rest;
    }
  );

  // console.log(formattedPathname);
  // console.log(history.location?.pathname);

  const [size, setSize] = useState<any>("large");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isLoading: loading,
    data: userData,
    refetch,
  } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );

  const [selectedTab, setSelectedTab] = useState<any>("1");
  const handleTabChange = (key: any) => {
    setSelectedTab(key);
  };
  const params: any = useParams();
  const [typeValue, setTypeValue] = useState<any>();
  const [alphaValue, setAlphaVlaue] = useState<any>();
  const [apiData, setapiData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [getFavNameData, setFavNameData] = useState<any>([]);
  // alphabet: alphaValue || "a",
  const fetchName = (alphaValue: any | undefined, page: number) => {
    if (!typeValue) {
      return;
    }
    let data = JSON.stringify({
      page: 1,
      pageLimit: pageLimit,
      alphabet: alphaValue,
      type: typeValue,
      nakshatra: params?.id,
    });
    setLoadingMore(true);
    findAllName(data)
      .then((res) => {
        setapiData(res.matchedNames);
        setLoadingMore(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    fetchName(alphaValue, currentPage);
  }, [typeValue, alphaValue, currentPage, pageLimit, params?.id]);

  useEffect(() => {
    if (selectedTab == 1) {
      setTypeValue("Boy");
    } else if (selectedTab == 2) {
      setTypeValue("Girl");
    } else {
      setTypeValue("Unisex");
    }
  }, [selectedTab]);

  const handleClick = (e: any, val: any) => {
    fetchName(val.alphaval, currentPage);
    setAlphaVlaue(val.alphaval);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    setPageLimit((prevPageLimit) => prevPageLimit + 10);
    fetchName(alphaValue, currentPage);
  };

  const handleNameClick = (record: any) => {
    if (record && record.name) {
      let data = JSON.stringify({
        userId: userData?.sk_user_id,
        name: record.name,
      });
      addFavName(data)
        .then((res) => {
          getFavNameList();
          Swal.fire({
            icon: "success",
            title: "Name added to your favourite list !",
            showConfirmButton: false,
            timer: 900,
          });
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const [propData, setPropData] = useState<any>({});
  const handleNClick = (record: any) => {
    if (record && record.name) {
      setIsModalOpen(true);
      setPropData(record);
    }
  };

  const getFavNameList = () => {
    if (userData && userData.sk_user_id) {
      getFavName(userData.sk_user_id)
        .then((res) => {
          setFavNameData(res?.matchedData);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
      message.error("error");
    }
  };

  useEffect(() => {
    getFavNameList();
  }, [userData && userData.sk_user_id]);

  const backButtonStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    cursor: "pointer",
    display: "flex", // Allows alignment of icon and text
    alignItems: "center", // Aligns icon and text vertically
  };

  const arrowIconStyle = {
    marginRight: "8px", // Adjust the space between icon and text
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <a>{text}</a>,
      onCell: (record: any) => ({
        onClick: () => {
          handleNClick(record);
        },
      }),
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
    {
      title: "Numerology",
      dataIndex: "numerology",
      key: "numerology",
    },
    {
      title: "Religion",
      dataIndex: "religion",
      key: "religion",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Fav",
      dataIndex: "fav",
      key: "fav",
      render: (fav: any, record: any) => {
        const isFav = getFavNameData?.some(
          (getFavNameData: any) => getFavNameData._id == record?._id
        );
        return isFav ? (
          <HeartFilled className="darkFav-icon" />
        ) : (
          <HeartOutlined className="outlineFav-icon" />
        );
      },
      onCell: (record: any) => ({
        onClick: () => {
          handleNameClick(record);
        },
      }),
    },
  ];

  return (
    <>
      {isModalOpen && (
        <NameDetails
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          propData={propData}
        />
      )}
      <Layout className="h-screen">
        <Header
          className=""
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Headersection />
        </Header>

        <div className=" bg-white mt-20">
          <Content
            className=" container "
            style={{
              margin: "24px auto",
            }}
          >
            <Link to="/babynames">
              <p>
                <ArrowLeftOutlined style={arrowIconStyle} />
                Baby Names
              </p>
            </Link>
            <div className="Artical_card3 relative mt-3">
              <p style={backButtonStyle}>{formattedPathname}</p>
              <div className="mt-5">
                <Tabs
                  type="card"
                  size={size}
                  defaultActiveKey="1"
                  onChange={handleTabChange}
                >
                  <Tabs.TabPane
                    tab={
                      <>
                        <h4>Boys</h4>
                      </>
                    }
                    key={"1"}
                  >
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {alphabet.map((val: any, index: number) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="alphabetButtonWrapper"
                              >
                                <div
                                  className="alphabetButton"
                                  onClick={(e) => handleClick(e, val)}
                                >
                                  <button>{val?.alphaval}</button>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <hr className="mt-5" />
                      <div className="mt-5">
                        <Table
                          columns={columns}
                          dataSource={apiData}
                          pagination={false}
                        />
                        {apiData?.length !== 0 && (
                          <Button
                            style={{ width: "100%" }}
                            onClick={handleLoadMore}
                            loading={loadingMore}
                          >
                            Load more
                          </Button>
                        )}
                      </div>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <>
                        <h4>Girls</h4>
                      </>
                    }
                    key={"2"}
                  >
                    <div>
                      <div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {alphabet.map((val: any, index: number) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className="alphabetButtonWrapper"
                                >
                                  <div
                                    className="alphabetButton"
                                    onClick={(e) => handleClick(e, val)}
                                  >
                                    <button>{val?.alphaval}</button>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                        <hr className="mt-5" />
                        <div className="mt-5">
                          <Table
                            columns={columns}
                            dataSource={apiData}
                            pagination={false}
                          />
                          {apiData?.length !== 0 && (
                            <Button
                              style={{ width: "100%" }}
                              onClick={handleLoadMore}
                              loading={loadingMore}
                            >
                              Load more
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={
                      <>
                        <h4>Unisex</h4>
                      </>
                    }
                    key={"3"}
                  >
                    <div>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {alphabet.map((val: any, index: number) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="alphabetButtonWrapper"
                              >
                                <div
                                  className="alphabetButton"
                                  onClick={(e) => handleClick(e, val)}
                                >
                                  <button>{val?.alphaval}</button>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <hr className="mt-5" />
                      <div className="mt-5">
                        <Table
                          columns={columns}
                          dataSource={apiData}
                          pagination={false}
                          className="cursor-pointer"
                        />
                        {apiData?.length !== 0 && (
                          <Button
                            style={{ width: "100%" }}
                            onClick={handleLoadMore}
                            loading={loadingMore}
                          >
                            Load more
                          </Button>
                        )}
                      </div>
                    </div>
                  </Tabs.TabPane>
                </Tabs>
              </div>
            </div>
          </Content>
        </div>
        <Footer/>
      </Layout>
    </>
  );
};

export default Nakshatra;
