export enum TFetchingStatus {
    Default = "Default",
    Loading = "Loading",
    Success = "Success",
    Failure = "Failure",
  }
export enum TRoutes {
  home = "/",
  login = "/login",
  dashboard = "/dashboard",
  forgotpass = "/forgot-password",
  editprofile = "/editprofile",
  momslikeyou = "/momslikeyou",
  momsnearyou = "/momsnearyou",
  accountsettings = "/accountsettings",
  timeline = "/timeline",
  fourms = "/fourms",
  internslogin="/internal-login"
}