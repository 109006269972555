import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Dropdown, Avatar } from "antd";
import { SendOutlined, MoreOutlined } from "@ant-design/icons";
import { groupPostComment, groupPostCommentID, groupPostDeleteComment } from "views/Groups/btpGroupApi";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import { articleCommentByID } from "apis/btpArticles";

interface ChildProps {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  setPostID: any;
  id: any;
  pageInfoGPost: any;
  groupPosts: any;
  groupData: string;
  postId:string
}

const GroupComments: React.FC<ChildProps> = ({
  isModalOpen,
  setIsModalOpen,
  postId,
  groupPosts,
  groupData,
  id,
  pageInfoGPost,
}) => {
  const { isLoading, data, refetch } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));
  const [postData, setPostData] = useState<any>([]);
  const [inputVal, setInputVal] = useState("");
  const [form] = Form.useForm();
  const handleCancel = () => {
    setIsModalOpen(false);
    setInputVal("");
    form.resetFields();
  };

  const getGroupComment = () => {
    articleCommentByID(postId)
      .then(res => {
        setPostData(res?.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGroupComment();
  }, [postId]);

  const onSubmit = async (values: any) => {
    try {
      let formData = JSON.stringify({
        userId: data && data.sk_user_id,
        postId: postId,
        userComment: inputVal,
      });
      const res = await groupPostComment(formData);
      if (res.status === true) {
        form.resetFields();
        setInputVal("");
        await groupPosts(id, pageInfoGPost);
        getGroupComment()
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handleDelete = (e: any, val: any) => {
    groupPostDeleteComment(val)
      .then(res => {
        groupPosts(id, pageInfoGPost);
        getGroupComment()
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    groupPosts(id, pageInfoGPost);
  }, [id, pageInfoGPost]);

  const [parentCommentID, setParentCommentID] = useState<any>("");
  const [mainCommentID, setMainCommentId] = useState("");
  const [replyShow, setReplyShow] = useState(false);
  const [replyUser, setReplyUser] = useState("");
  const [replyValue, setReplyValue] = useState("");

  const handleClickReply = (reply: any) => {
    setReplyShow(true);
    setReplyUser(`@${reply?.full_name}`);
    setParentCommentID(reply?._id);
    setMainCommentId(reply?.userId);
  };


  const onSubmit1 = async (values: any) => {
    let formData = JSON.stringify({
      userId: data && data.sk_user_id,
      postId: postId,
      userComment: replyValue,
      postType: "",
      parentCommentId: parentCommentID,
      mainCommentUserId: mainCommentID,
    });
    await groupPostComment(formData).then(res => {
      getGroupComment();
      if (res.status === true) {
        form.resetFields();
        refetch();
        setInputVal("");
        setReplyValue("");
      }
    });
  };


  const handleClickReplyOfReply = (val: any) => {
    setReplyShow(true);
    setReplyUser(`@${val?.full_name}`);
    setParentCommentID(val?.parentCommentId);
    setMainCommentId(val?.mainCommentUserId);
  };

  return (
    <Modal title={groupData} open={isModalOpen} closable={true} onCancel={handleCancel} footer={null} className="no-padding-modal">
      <div className="comment-scrollable">
        {postData &&
          postData?.post?.comments.map((list: any, ind: number) => {
            const sentTime = new Date(list.createdDate);
            const currentTime = new Date();
            const timeDiffInSeconds = Math.floor((currentTime.getTime() - sentTime.getTime()) / 1000);
            let timeAgo = "";
            if (timeDiffInSeconds >= 0) {
              if (timeDiffInSeconds < 5) {
                timeAgo = "few seconds ago";
              } else if (timeDiffInSeconds > 60) {
                const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                if (timeDiffInMinutes < 60) {
                  timeAgo = `${timeDiffInMinutes} minute${timeDiffInMinutes !== 1 ? "s" : ""} ago`;
                } else if (timeDiffInMinutes < 1440) {
                  const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
                  timeAgo = `${timeDiffInHours} hour${timeDiffInHours !== 1 ? "s" : ""} ago`;
                } else if (timeDiffInMinutes < 43200) {
                  const timeDiffInDays = Math.floor(timeDiffInMinutes / 1440);
                  timeAgo = `${timeDiffInDays} day${timeDiffInDays !== 1 ? "s" : ""} ago`;
                } else if (timeDiffInMinutes < 525600) {
                  const timeDiffInMonths = Math.floor(timeDiffInMinutes / 43200);
                  timeAgo = `${timeDiffInMonths} month${timeDiffInMonths !== 1 ? "s" : ""} ago`;
                } else {
                  const timeDiffInYears = Math.floor(timeDiffInMinutes / 525600);
                  timeAgo = `${timeDiffInYears} year${timeDiffInYears !== 1 ? "s" : ""} ago`;
                }
              } else if (timeDiffInSeconds > 0) {
                timeAgo = `${timeDiffInSeconds} second${timeDiffInSeconds !== 1 ? "s" : ""} ago`;
              }
            }
            return (
              <>
                <div className="comment-container">
                  <div className="comment-image">
                    <img src={list.profilePic} alt="User's Profile Picture" width="40" height="40" />
                  </div>
                  <div className="comment-text">
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "16px",
                      }}>
                      {list?.full_name} &nbsp;

                    </span>
                    <br />
                    <p style={{ textAlign: "justify" }}> {list?.userComment}</p>
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: "justify",
                      }}>
                      <div
                        style={{
                          color: "#999999",
                          cursor: "pointer",
                          float: "right",
                          textAlign: "justify",
                        }}>
                        <div
                          style={{
                            color: "#999999",
                            cursor: "pointer",
                            float: "right",
                            textAlign: "justify",
                          }}>
                          {timeAgo != "0 Hours Ago" && <p> {timeAgo !== null && <p>{timeAgo}</p>}</p>}
                        </div>
                      </div>
                      <div className="flex" style={{ float: "right" }}>
                        <p
                          style={{
                            color: "#999999",
                            cursor: "pointer",
                            float: "right",
                          }}
                          onClick={() => handleClickReply(list)}>
                          Reply
                        </p>
                        &nbsp; &nbsp;
                        {data && data.sk_user_id === list?.userId
 && (
                          <p
                            style={{
                              color: "#999999",
                              cursor: "pointer",
                              float: "right",
                            }}
                            onClick={e => handleDelete(e, list._id)}>
                            Delete{" "}
                          </p>
                        )}
                      </div>
                    </div>

                    {/* <p>Reply Comment</p> */}
                    {list?.replyComments?.map((val: any, ind: number) => {
                    const sentTimeReply = new Date(val.createdDate);
                    const currentTime = new Date();
                    const timeDiffInSeconds = Math.floor((currentTime.getTime() - sentTimeReply.getTime()) / 1000);
                    let timeAgoReply = "";
                    if (timeDiffInSeconds >= 0) {
                      if (timeDiffInSeconds < 5) {
                        timeAgoReply = "few seconds ago";
                      } else if (timeDiffInSeconds > 60) {
                        const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                        if (timeDiffInMinutes < 60) {
                          timeAgoReply = `${timeDiffInMinutes} minute${timeDiffInMinutes !== 1 ? "s" : ""} ago`;
                        } else if (timeDiffInMinutes < 1440) {
                          const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
                          timeAgoReply = `${timeDiffInHours} hour${timeDiffInHours !== 1 ? "s" : ""} ago`;
                        } else if (timeDiffInMinutes < 43200) {
                          const timeDiffInDays = Math.floor(timeDiffInMinutes / 1440);
                          timeAgoReply = `${timeDiffInDays} day${timeDiffInDays !== 1 ? "s" : ""} ago`;
                        } else if (timeDiffInMinutes < 525600) {
                          const timeDiffInMonths = Math.floor(timeDiffInMinutes / 43200);
                          timeAgoReply = `${timeDiffInMonths} month${timeDiffInMonths !== 1 ? "s" : ""} ago`;
                        } else {
                          const timeDiffInYears = Math.floor(timeDiffInMinutes / 525600);
                          timeAgoReply = `${timeDiffInYears} year${timeDiffInYears !== 1 ? "s" : ""} ago`;
                        }
                      } else if (timeDiffInSeconds > 0) {
                        timeAgoReply = `${timeDiffInSeconds} second${timeDiffInSeconds !== 1 ? "s" : ""} ago`;
                      }
                    }

                    return (
                      <>
                        <div className="comment-container ml-10">
                          <div className="comment-left">
                            <div className="comment-image-reply">
                              <img src={val?.profilePic} alt="User's Profile Picture" width="40" height="40" />
                            </div>
                          </div>
                          <div className="comment-right">
                            <div className="comment-text">
                              <span style={{ fontWeight: "bold", color: "black", fontSize: "16px" }}>
                                {val.full_name} &nbsp;
                                {data && data.sk_user_id === list.userId && (
                                  <span
                                    style={{
                                      color: "red",
                                      cursor: "pointer",
                                      float: "right",
                                      textAlign: "justify",
                                    }}></span>
                                )}
                              </span>
                              <br />
                              <p style={{ textAlign: "justify" }}> {val.userComment}</p>
                              <div
                                className="mt-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  textAlign: "justify",
                                }}>
                                <div
                                  style={{
                                    color: "#999999",
                                    cursor: "pointer",
                                    float: "right",
                                    textAlign: "justify",
                                  }}>
                                  {timeAgoReply != "0 Hours Ago" && <p> {timeAgoReply !== null && <p>{timeAgoReply}</p>}</p>}
                                </div>
                                &nbsp;&nbsp;
                                <div style={{ float: "right", display: "flex" }}>
                                  <p
                                    style={{
                                      color: "#999999",
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                    onClick={() => handleClickReplyOfReply(val)}>
                                    Reply
                                  </p>
                                  &nbsp; &nbsp;
                                  {data && data.sk_user_id === val.userId && (
                                    <p
                                      style={{
                                        color: "#999999",
                                        cursor: "pointer",
                                        float: "right",
                                      }}
                                      onClick={e => handleDelete(e, val._id)}>
                                      Delete{" "}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>

                            {/* Other content on the right side */}
                          </div>
                        </div>
                      </>
                    );
                  })}


                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* <div>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item name="comment">
            <div className="comment-input">
              <img src={data && data.profile_pic} alt="User's Profile Picture" width="40" height="40" className="comment-image" />
              <Input
                placeholder="Your Comment"
                name="userComment"
                value={inputVal}
                onChange={e => setInputVal(e.target.value)}
                addonAfter={
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      color: "black",
                      height: "13px",
                    }}
                    type="primary"
                    htmlType="submit"
                    icon={
                      <SendOutlined
                        style={{
                          fontSize: "16px",
                          color: "gray",
                          border: "none",
                        }}
                      />
                    }
                  />
                }
              />
            </div>
          </Form.Item>
        </Form>
      </div> */}


{replyShow == false && (
        <div>
          <Form form={form} onFinish={onSubmit}>
            <Form.Item name="comment">
              <div className="comment-input">
                {data && data.profile_pic != null ? (
                  <img src={data && data.profile_pic} alt="User's Profile Picture" width="40" height="40" className="comment-image" />
                ) : (
                  <Avatar shape="circle" size={40} className="mr-5" />
                )}

                <Input
                  placeholder="Your Comment"
                  name="userComment"
                  className="ml-2"
                  value={inputVal}
                  onChange={e => setInputVal(e.target.value)}
                  addonAfter={
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "black",
                        height: "13px",
                      }}
                      type="primary"
                      htmlType="submit"
                      icon={
                        <SendOutlined
                          style={{
                            fontSize: "16px",
                            color: "gray",
                            border: "none",
                          }}
                        />
                      }
                    />
                  }
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      )}

      {replyShow === true && (
        <div>
          <Form form={form} onFinish={onSubmit1}>
            <Form.Item name="comment">
              <div className="comment-input">
                {data && data.profile_pic != null ? (
                  <img src={data && data.profile_pic} alt="User's Profile Picture" width="40" height="40" className="comment-image" />
                ) : (
                  <Avatar shape="circle" size={40} className="mr-5" />
                )}

                <Input
                  addonBefore={replyUser}
                  placeholder={`Your reply`}
                  name="userComment"
                  className="ml-2"
                  value={replyValue}
                  onChange={e => setReplyValue(e.target.value.replace(/^\s/, ""))}
                  addonAfter={
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "black",
                        height: "13px",
                      }}
                      type="primary"
                      htmlType="submit"
                      icon={
                        <SendOutlined
                          style={{
                            fontSize: "16px",
                            color: "gray",
                            border: "none",
                          }}
                        />
                      }
                    />
                  }
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      )}



    </Modal>
  );
};

export default GroupComments;
