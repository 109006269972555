import { unfollowUser } from "apis/GetFeeds";
import { Unfollowingbutton } from "apis/likeunlikepost";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

export type TUnfollowUserRequest = { requestee_id: string };

export type TUnfollowuserResponse = {
  status: boolean;
  data: any;
  message: string;
};

export const unfollowUserInitAction = createAction("/users/UNFOLLOWUSER_INIT")();
export const unfollowUserSuccessAction = createAction("/users/UNFOLLOWUSER_SUCCESS")<TUnfollowuserResponse>();
export const unfollowUserFailureAction = createAction("/users/UNFOLLOWUSER_ERROR")<Error>();

export type TUnfollowUserInitAction = ActionType<typeof unfollowUserInitAction>
export type TUnfollowUserSuccessAction = ActionType<typeof unfollowUserSuccessAction>
export type TUnfollowUserFailureAction = ActionType<typeof unfollowUserFailureAction>

export type TUnfollowUserActions = | TUnfollowUserInitAction| TUnfollowUserSuccessAction | TUnfollowUserFailureAction

export type TUnfollowUserThunkActionType = ThunkAction<void, TRootState, null, TUnfollowUserActions>;

type TUnfollowUserAction = (payload : TUnfollowUserRequest) => TUnfollowUserThunkActionType;

export const unfollowUserdispatcher: TUnfollowUserAction = (payload : any) => (
    dispatch: (action: Action | Promise<Action> | ThunkAction<void, TRootState, null, TUnfollowUserActions>) => any
) => {
    dispatch(unfollowUserInitAction())
    return  Unfollowingbutton(payload)
    .then(async(res)=>{
        console.log("payload",await res)
        if(res.status){
            console.log(res,res.data[0])
            dispatch(unfollowUserSuccessAction(res.data));
          }else{
            dispatch(unfollowUserFailureAction({message:res.message} as Error))
          }
    })
}