import { TFetchingStatus } from "helper";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import { TUnfollowUserActions, unfollowUserFailureAction, unfollowUserInitAction, unfollowUserSuccessAction } from "actions/unfollow";


export type TUnfollowUser = {
    fetchingStatus: TFetchingStatus,
    error: Error | null
}

export const initialState: TUnfollowUser = {
    fetchingStatus: TFetchingStatus.Default,
    error: null
}

export const unfollowUserReducer = createReducer<TUnfollowUser, TUnfollowUserActions>(initialState)
    .handleAction(unfollowUserInitAction, (state) => 
    produce(state,(draftState) => {
        console.log("init", state, draftState);
        draftState.fetchingStatus=TFetchingStatus.Loading
        draftState.error= null
    }))
    .handleAction(unfollowUserSuccessAction, (state, {payload}) => produce(state,(draftState) => {
        console.log("success", state, payload)
        draftState.fetchingStatus=TFetchingStatus.Success
        draftState.error= null
    }))
    .handleAction(unfollowUserFailureAction, (state, {payload}) => produce(state, (draftState) => {
        console.log("failed", state, payload);
        draftState.fetchingStatus=TFetchingStatus.Failure
        draftState.error= payload
    }))

