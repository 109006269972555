import React, { useEffect, useState } from "react";
import {
  Layout,
  Tabs,
  Select,
  Card,
  Avatar,
  Button,
  List,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  FolderOutlined,
  MessageOutlined,
  UserOutlined,
  ExclamationOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  getConnectionReq,
  getviewprofileConnectionReq,
  parentConnectionDetail,
} from "apis/ViewProfile";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import { connectionAcceptApi, connectionRejectApi } from "apis/connect";
import { Link } from "react-router-dom";
import Messages from "views/messages/Messages";
import { useHistory } from "react-router";

const { Header, Content, Footer, Sider } = Layout;
const { TabPane } = Tabs;
const { Meta } = Card;

const { Option } = Select;

interface profile {
  setChat?: any;
}

const Connection = (props: any, { setChat }: profile) => {
  const authInfo = useSelector((state: TRootState) => state.auth);
  const [requestData, setRequestData] = useState([]);
  const [example, setExample] = useState();
  const [myconnections, setMyconnections] = useState<any>([]);
  const [status, setStatus] = useState<any>([]);
  const [show, setShow] = useState(true);
  const [list, setList] = useState(false);
  const history = useHistory();

  const handleChange = (value: { value: string; label: React.ReactNode }) => {

  };

  const handleChange1 = (value: { value: string; label: React.ReactNode }) => {
    if (value.value === "Fathers") {
      setShow(false);
    } else if (value.value === "Pregnants") {
      setShow(true);
    }
  };

  const handleList = () => {
    setList(false);
  };
  const handlegrid = () => {
    setList(true);
  };
  const onChange = (key: string) => {
    // console.log(key);
    props.setDefaultActiveSub(key);
    getConnectionReq(Accesstoken).then((res) => {
      setRequestData(res.data);
      // console.log("getConnectionReq", res.data);
    });
  };

  const { Accesstoken } = useSelector((state: TRootState) => state.auth);

  useEffect(() => {
    getviewprofileConnectionReq(Accesstoken).then((res) => {
      // console.log("getviewprofileConnectionReq", res.data.length);
      setMyconnections(res.data);
    });
  }, []);

  const handleClickLike = (item: any, index: any) => {
    connectionAcceptApi(
      item.sk_connection_id,
      item.connection,
      item.follow,
      item.block
    ).then((res) => {
      const updatedRequest: any = requestData.map((ele: any, idx) => {
        if (index === idx) {
          ele.isAccepted = true;
        }
        return ele;
      });
      setRequestData(updatedRequest);
      // setChat(res);
   
    });
  };
  const handleClickReject = (item: any, index: any) => {
    // console.log("item", item)
    connectionRejectApi(item.sk_connection_id).then((res) => {
      const updatedReques: any = requestData.map((ele: any, idx) => {
        if (index === idx) {
          ele.isReject = true;
        }
        return ele;
      });
      setRequestData(updatedReques);
    });
  };

  const handleMessageRedirect = (carddetails: any) => {
    // console.log("carddetails", carddetails);
    history.push("/messages", { carddetailsrecord: carddetails });
  };

  // console.log("cshbchsvchasv", myconnections);
  return (
    <div>
      <Content>
        <Tabs
          defaultActiveKey={props.defaultActive}
          activeKey={props.defaultActive}
          onChange={onChange}
        >
          <TabPane tab="My Connections" key="my_connections">
            <div className="flex flex-row flex-wrap gap-2 xs:justify-items-start justify-end mb-5">
              <Select
                labelInValue
                defaultValue={{ value: "All Types", label: "All Types" }}
                className="text-sm"
                onChange={handleChange1}
              >
                <Option value="All Types" className="text-sm">
                  All Types
                </Option>
                <Option value="Fathers" className="text-sm">
                  Fathers
                </Option>
                <Option value="Pregnants" className="text-sm">
                  Pregnants
                </Option>
              </Select>
              <Select
                labelInValue
                defaultValue={{
                  value: "Recently Active",
                  label: "Recently Active",
                }}
                onChange={handleChange}
              >
                <Option value="Recently Active">Recently Active</Option>
                <Option value="Newest Members">Newest Members</Option>
                <Option value="Alphabetical">Alphabetical</Option>
              </Select>
              <span className="flex flex-row mr-5  align-middle">
                <Tooltip title="Grid View">
                  <AppstoreOutlined
                    style={{
                      fontSize: "20px",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handleList}
                  />
                </Tooltip>
                <Tooltip title="List View">
                  <UnorderedListOutlined
                    style={{
                      fontSize: "20px",
                      color: "#a3a5a9",
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                    }}
                    className="border"
                    onClick={handlegrid}
                  />
                </Tooltip>
              </span>
            </div>
            {list ? (
              <div>
                {show ? (
                  <List className="">
                    {myconnections.length > 0 ? (
                      <div>
                        {myconnections.map((connection: any) => (
                          <List.Item
                            className="border ml-4 hover:shadow"
                            actions={[
                              <UserOutlined key="user" />,
                              <MessageOutlined key="message" />,
                            ]}
                          >
                            {/* <img
                             alt="example"
                             src={connection.profile_pic}
                             className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                           /> */}
                            {connection.profile_pic ? (
                              <img
                                alt="example"
                                src={connection.profile_pic}
                                //  className="image-style  object-center"
                                className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                              />
                            ) : (
                              <img
                                alt="example1"
                                src={connection.gender_pic}
                                //  className="image-style  object-center"
                                className="image-style object-center ml-5 w-12 h-12 mr-5 object-cover rounded-full"
                              />
                            )}
                            <List.Item.Meta
                              title={connection.full_name}
                              description="pregnant"
                            />
                            <div className="p-tag">
                              <p className="px-7">0 followers</p>
                              <a
                                className="px-8 text-blue-800 py-1 md:ml-20 border border-blue-800 rounded-2xl "
                                href="#"
                              >
                                Follow
                              </a>
                            </div>
                          </List.Item>
                        ))}
                      </div>
                    ) : (
                      <div className="icon">
                        <div className="icon2">
                          <ExclamationOutlined className="icon-sty" />
                        </div>
                        <div>
                          <p>Sorry, no members were found.</p>
                        </div>
                      </div>
                    )}
                  </List>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {show ? (
                  <div className="card-sty mr-2 mb-10">
                    {myconnections.map((cardconnection: any) => (
                      <Card
                        style={{ width: 200 }}
                        cover={
                          <>
                            {cardconnection.profile_pic ? (
                              <img
                                alt="example"
                                src={cardconnection.profile_pic}
                                className="image-style  object-center"
                                style={{
                                  borderRadius: "50%",
                                  maxWidth: "90px",
                                  margin: "0 auto",
                                  height: "90px",
                                }}
                              />
                            ) : (
                              <img
                                alt="example1"
                                src={cardconnection.gender_pic}
                                className="image-style  object-center"
                                style={{
                                  borderRadius: "50%",
                                  maxWidth: "90px",
                                  margin: "0 auto",
                                  height: "90px",
                                }}
                              />
                            )}
                          </>
                        }
                        actions={[
                          <UserOutlined key="user" />,
                          <MessageOutlined
                            key="message"
                            onClick={() =>
                              handleMessageRedirect(cardconnection)
                            }
                          />,
                        ]}
                      >
                        <Meta
                          title={cardconnection.full_name}
                          description="pregnant"
                        />
                        <div className="p-tag">
                          <p className="py-4">0 followers</p>
                          <a
                            className="p-4 text-black hover:text-red-400"
                            href="#"
                          >
                            Follow
                          </a>
                        </div>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <div className="icon">
                    <div className="icon2">
                      <ExclamationOutlined className="icon-sty" />
                    </div>
                    <div>
                      <p>Sorry, no members were found.</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </TabPane>
          <TabPane tab="Requests" key="pending_requests">
            {requestData.map((item: any, index: number) => {
              // console.log("gsxvg", item);
              return (
                <div>
                  <Card
                    title={
                      <>
                        {" "}
                        <div className="flex items-center">
                          {item.profile_pic ?
                          <Avatar shape="circle" src={item.profile_pic} />: <Avatar shape="circle" src={authInfo.parent_info?.gender_pic} />}
                          <div className="flex flex-col ml-2 text-center">
                            <a>{item.full_name}</a>
                          </div>
                        </div>{" "}
                      </>
                    }
                    extra={
                      <div className="flex item-center gap-5">
                        {item.isAccepted ? (
                          <Button
                            danger
                            className="button-resize"
                            onClick={() =>
                              handleMessageRedirect(item)
                            }
                          >
                            Message
                          </Button>
                        ) : (
                          <>
                            {item.isReject ? (
                              <h3>Request cancel</h3>
                            ) : (
                              <>
                                <Button
                                  danger
                                  className="button-resize"
                                  onClick={() => handleClickLike(item, index)}
                                >
                                  Accept
                                </Button>

                                <Button
                                  danger
                                  className="button-resize"
                                  onClick={() => handleClickReject(item, index)}
                                >
                                  Reject
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    }
                  ></Card>
                  {show ? null : <Messages />}
                </div>
              );
            })}
          </TabPane>
        </Tabs>
      </Content>
      {/* <div style={{ paddingTop: "20px" }}>
        <span>Viewing 1 - 10 of 10 members</span>
      </div> */}
    </div>
  );
};

export default Connection;
