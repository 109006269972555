import {
  logInFailureAction,
  logInInitAction,
  logInSuccessAction,
  TAuthActions,
  TParentInfo,
} from "actions/auth";
import { TFetchingStatus } from "helper";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import {
  getFeedFailureAction,
  getFeedFollowAction,
  getFeedInitAction,
  getFeedSuccessAction,
  TBanner,
  Tfeed,
  TGetFeedActions,
  TIconWeekwise,
  TKeyHighLight,
  TTodoList,
} from "actions/getfeed";


export type TFeeds = {
  banner_section: TBanner | null;
  icon_required: boolean;
  icon_weekwise: Array<TIconWeekwise> | [];
  todo_list_required: boolean;
  todo_list: Array<TTodoList> | [];
  key_highlight_required: boolean;
  key_highlights: Array<TKeyHighLight> | [];
  pregnancyTips_required: boolean;
  pregnancy_Tips: Array<TKeyHighLight> | [];
  feed: Array<Tfeed> | [];
  fetchingStatus: TFetchingStatus;
  error: Error | null;
};
export const initialState: TFeeds = {
  banner_section: null,
  icon_required: false,
  icon_weekwise: [],
  todo_list_required: false,
  todo_list: [],
  key_highlight_required: false,
  key_highlights: [],
  pregnancyTips_required: false,
  pregnancy_Tips: [],
  feed: [],
  fetchingStatus: TFetchingStatus.Default,
  error: null,
};
export const feedReducer = createReducer<TFeeds, TGetFeedActions>(initialState)
  .handleAction(getFeedInitAction, (state) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Loading;
      draftState.error = null;
    })
  )
  .handleAction(getFeedSuccessAction, (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.banner_section = payload.banner_section;
      draftState.icon_required = payload.icon_required;
      draftState.icon_weekwise = payload.icon_weekwise;
      draftState.todo_list_required = payload.todo_list_required;
      draftState.todo_list = payload.todo_list;
      draftState.key_highlight_required = payload.key_highlight_required;
      draftState.key_highlights = payload.key_highlights;
      draftState.pregnancyTips_required = payload.pregnancyTips_required;
      draftState.pregnancy_Tips = payload.pregnancy_Tips;
      draftState.feed = payload.feed;
      draftState.error = null;
      draftState.fetchingStatus = TFetchingStatus.Success;
    })
  )
  .handleAction(getFeedFailureAction, (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.error = payload;
    })
  )
  .handleAction(getFeedFollowAction, (state, { payload }) =>
    produce(state, (draftState) => {
      const objAIndex = state.feed
        .filter((vv) => vv.type == "momslikeyou" || vv.type == "momsnearyou")
        .map((vv) =>
          vv.details.map((details) => {
            if (details.sk_user_id === payload.sk_user_id){
              
            }
          }
        ));
      // console.log("objAIndex", objAIndex);
    })
  );
