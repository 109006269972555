import { authProfileUpdateAction, logInFailureAction, logInInitAction, logInSuccessAction, TAuthActions, TParentInfo } from "actions/auth";
import { TFetchingStatus } from "helper";
import {createReducer} from "typesafe-actions";
import produce from "immer";

export type TAuth = {
    Accesstoken: string,
    userId: string,
    fullname: string,
    wp_user_id: string,
    username_momnexus: string,
    stagingInfo: string,
    email_verification_status: string,
    mail: string |null,
    parent_info:TParentInfo |null
    fetchingStatus: TFetchingStatus,
    error: Error | null,
    profile_pic: string;
    gender_pic: string;
    
}       
export const initialState: TAuth = {
    Accesstoken: "",
    userId: "",
    fullname: "",
    gender_pic:"",
    wp_user_id: "",
    username_momnexus: "",
    stagingInfo: "",
    email_verification_status: "",
    mail: "",
    parent_info:null,
    profile_pic:"",
    fetchingStatus: TFetchingStatus.Default,
    error: null
};
export const authReducer = createReducer<TAuth, TAuthActions>(initialState)
.handleAction(logInInitAction, (state) =>
  produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Loading;
      draftState.error = null;
  }))
.handleAction(logInSuccessAction, (state, {payload}) =>
    produce(state, (draftState) => {
        console.log("payload1",payload)
        draftState.error=null;
        draftState.fetchingStatus = TFetchingStatus.Success;
        draftState.Accesstoken = payload.Accesstoken;
        draftState.userId=payload.userId
        draftState.email_verification_status=payload.email_verification_status
        draftState.fullname=payload.fullname
        draftState.mail=payload.mail
        draftState.parent_info=payload.parent_info
        draftState.wp_user_id=payload.wp_user_id
        draftState.stagingInfo=payload.stagingInfo
        draftState.username_momnexus=payload.username_momnexus
    })
  )
  .handleAction(logInFailureAction, (state, {payload}) =>
  produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.error = payload;
  })
)
  .handleAction(authProfileUpdateAction, (state, { payload }) => 
    produce(state, (draftState) => {
      Object.entries<any>(payload).map(([field,values])=>{
      draftState[field as keyof TAuth]=values as never
      })
    }
    ))