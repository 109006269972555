import React, { useState, useEffect, ChangeEvent } from "react";
import { Layout, Input, Form, message, Slider, Radio, Card, Image } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import Preg from "../icon/HCGCal.svg";
import { eye_colour,getToolsDetailsData } from "../../../../apis/btpTools";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Footer from "views/MainFooter/Footer";
import EyePic from "../icon/EyePic.jpeg";

const EyeParents = () => {
  const history = useHistory();
  const [form] = Form.useForm();

  const handleResult = () => {
    // const values = form.getFieldsValue();
    form
      .validateFields()
      .then(values => {
        const motherBloodType = values["mother-blood-type"];
        const fatherBloodType = values["father-blood-type"];
        console.log("Mother's eye type:", motherBloodType);
        console.log("Father's eye type:", fatherBloodType);

        let data = JSON.stringify({
            fatherEyeColor: selectedColorRight,
            motherEyeColor: selectedColorLeft,
        });
        eye_colour(data)
          .then(res => {
            console.log("res.message eye colour", res);
            history.push({
              pathname: "/baby-eye-color",
              state: {
                data: res,
              },
            });
          })
          .catch(err => {
            message.error(err);
          });
      })
      .catch(errorInfo => {
        console.log("Validation failed:", errorInfo);
      });
  };

  const hndleClickHome=()=>{
    history.push("/");
  }


  const[selectedColorEye,setSelectedColorEye]=useState({})
  const getEyeDetails=()=>{
    getToolsDetailsData("").then((res)=>{
      console.log("getToolsDetailsData",res);
      console.log("toolsdata", res.toolsdata[0]);
      setSelectedColorEye(res.toolsdata[0])
      setSelectedColorLeft(res.toolsdata[0]?.motherEyeColor)
      setSelectedColorRight(res.toolsdata[0]?.fatherEyeColor)

    }).catch((err)=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    getEyeDetails()
  },[])


  const [selectedColorLeft, setSelectedColorLeft] = useState<any>();
  const [selectedColorRight, setSelectedColorRight] = useState<any>();


  const handleLeftInputChange = (event:any) => {
    setSelectedColorLeft(event.target.value);
    console.log("event.target.value", event.target.value);

};

const handleRightInputChange = (event:any) => {
    setSelectedColorRight(event.target.value);
};


  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-15">
          <Content
            className="container blood-type-preg"
            >
            <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
              <Card style={{ border: "none" }}>
                <div className="preg-calc-card-res">
                  <div className="rectangle-parent-child">
                    <div className="frame-child-blood"></div>
                    <div className="line-icons-parent">
                      <ArrowLeftOutlined className="line-icons" />
                      <div className="pregnancy-weight-gain">
                        <button onClick={hndleClickHome}>Child Eye Colour</button>
                      </div>
                    </div>

                    <div className="bloodType-image">
                      <div className="result-parent-hcg">
                        <Image
                          alt=""
                          src={EyePic}
                          width={250}
                          height={250}
                          className="child-blood-img"
                          preview={false}
                        />
                      </div>
                    </div>
                  </div>


                  <Form
                    form={form}
                    onFinish={handleResult}
                    className="group-wrapper-faq-ques"
                    style={{ height: "auto", textAlign: "center" }}>
                    <div className="flex" style={{ width: "100%" }}>
                      <div className="group-wrapper-faq-ques" style={{ height: "auto", textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", color: "black", fontSize: "26px" }}>What is mother’s eye colour?</p>
                        <Form.Item name="mother-blood-type"

                        // rules={[{ required: true, message: "Please select mother's eye colour?" }]}

                        >
                          <div className="frame-parent mt-5">
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="blue1" name="mother-blood-type" value="blue"
                                   checked={selectedColorLeft === "blue"}
                                   onChange={handleLeftInputChange}


                                  />
                                  <label htmlFor="blue1">Blue</label>
                                </div>
                              </div>
                            </div>

                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="green1" name="mother-blood-type" value="green"
                                  checked={selectedColorLeft === "green"}
                                  onChange={handleLeftInputChange}

                                  />
                                  <label htmlFor="green1">Green</label>
                                </div>
                              </div>
                            </div>
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="brown1" name="mother-blood-type" value="brown"
                                  checked={selectedColorLeft === "brown"}
                                  onChange={handleLeftInputChange}

                                  />
                                  <label htmlFor="brown1">Brown</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form.Item>
                      </div>

                      <div className="group-wrapper-faq-ques" style={{ height: "auto", textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", color: "black", fontSize: "26px" }}>What is father’s eye colour?</p>

                        <Form.Item name="father-blood-type"

                        // rules={[{ required: true, message: "Please select father's eye colour?" }]}

                        >
                          <div className="frame-parent mt-5">
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="blue" name="father-blood-type" value="blue"
                                              checked={selectedColorRight === "blue"}
                                              onChange={handleRightInputChange}

                                  />
                                  <label htmlFor="blue">Blue</label>
                                </div>
                              </div>

                            </div>
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="green" name="father-blood-type" value="green"
                                              checked={selectedColorRight === "green"}
                                              onChange={handleRightInputChange}
                                  />
                                  <label htmlFor="green"> Green</label>
                                </div>
                              </div>
                            </div>
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="brown" name="father-blood-type" value="brown"
                                              checked={selectedColorRight === "brown"}
                                              onChange={handleRightInputChange}
                                  />
                                  <label htmlFor="brown">Brown</label>
                                </div>
                              </div>
                            </div>
                            <p>
                              <i
                                style={{ fontSize: "40px", cursor: "pointer" }}
                                className="fa-solid fa-arrow-right"
                                onClick={handleResult}></i>
                            </p>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>



                </div>
              </Card>

            </div>
          </Content>
        </div>
        <Footer/>
      </Layout>
    </>
  );
};

export default EyeParents;
