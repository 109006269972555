import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";
type TimelineResponse = {
  status: boolean;
  message: string;
  data: any;
};

export const recentTimeApi = async (token: any) => {
  const url = "/Connection/get_user_timeline";
  console.log("sending", process.env.REACT_APP_GLOBALTOKEN);
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TimelineResponse>(url, { ...newconfig, skipAuthRefresh: true });
};
