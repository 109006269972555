import { useState, useEffect } from "react";
import { Layout, Tabs } from "antd";
import Sidenav from "views/MainHeader/SideNav";
import Headersection from "views/MainHeader/Header";
import Momsnearlist from "views/MomsNearyou/MomsNearlList/Momnearlist";
import GroupsList from "./GroupsList";
import GroupFormTabs from "./GroupFormTabs";
import { useLocation } from "react-router-dom";
import Footer from "views/MainFooter/Footer";

const { Header, Content, Sider } = Layout;

interface LocationState {
  state: {
    _id: string;
    activityFeed: string;
    //  state:{
    //   _id:string,
    //   activityFeed:string
    //  }
  };
}

const Groups = (props: any) => {
  const location = useLocation<LocationState>();
  // console.log("location value", location);
  // console.log(location.state?.state?._id);
  const [activeTab, setActiveTab] = useState("1");
  const [groupId, setGroupId] = useState("");
  const [_id, setId] = useState("");
  // console.log(groupId);
  // console.log("activeTab",activeTab);
  useEffect(() => {
    if (location.state?.state?._id != "" && location.state?.state?._id != undefined) {
      setActiveTab("2");
    } else if (location?.state?.state?._id == "") {
      setActiveTab("1");
    } else if (location?.state?.state?._id == undefined) {
      setActiveTab("1");
    } else {
      setActiveTab("1");
    }
  }, [location]);

  function handleChange(tab: string) {
    setActiveTab(tab);
    setGroupId("");
    setId("");
  }

  function setTab(tab: string) {
    setActiveTab("1");
  }
  function changeTab(tab: string, groupId: string) {
    setActiveTab(tab);
    if (tab === "2") {
      setGroupId(groupId);
    } else {
      setGroupId("");
      setId(_id);
    }
  }
  return (
<div>
      {/* <Layout className="h-screen"> */}
        <Sider breakpoint="lg" collapsedWidth="0" onBreakpoint={broken => {}}>
          <div className="logo" />
          <Sidenav />
        </Sider>
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection {...props} />
        </Header>

        <Layout className="container">
          <div className="bg-white mt-16">
            <Content className="moms_like_content mt-16">
              <Tabs onChange={handleChange} activeKey={activeTab} defaultActiveKey={"1"}>
                <Tabs.TabPane
                  tab={
                    <>
                      <h4>My Groups</h4>
                    </>
                  }
                  key={"1"}>
                  <GroupsList activeTab={activeTab} changeTab={changeTab} groupId={groupId} _id={_id} />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <>
                      <h4>Create Group</h4>
                    </>
                  }
                  key={"2"}>
                  <GroupFormTabs activeTab={activeTab} setTab={setTab} groupId={groupId} changeTab={changeTab} />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab={
                    <>
                      <h4>Recommended Groups</h4>
                    </>
                  }
                  key={"3"}>
                  <GroupsList activeTab={activeTab} recommended={true} changeTab={changeTab} groupId={groupId} _id={_id} />
                </Tabs.TabPane>
              </Tabs>
            </Content>
          </div>
        </Layout>
		<br />
		<Footer/>
      {/* </Layout> */}
	  </div>
  );
};

export default Groups;
