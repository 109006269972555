import React, { useState, useEffect, Fragment } from "react";
import { Button, Progress } from "antd";
import { LeftOutlined, RightOutlined, CloseOutlined } from "@ant-design/icons";
import { customizestoryStoryById } from "apis/GetFeeds";

interface Props {
  setShowTips: React.Dispatch<React.SetStateAction<boolean>>;
  actionParamsId: any;
}

const Tips: React.FC<Props> = ({ setShowTips, actionParamsId }) => {
  const [slides, setSlides] = useState<any>([]);
  useEffect(() => {
    const getStoryById = async () => {
      try {
        const res = await customizestoryStoryById(actionParamsId);
        const fetchedSlides = res?.data?.slides || [];
        const dummyData = {
          title: fetchedSlides.length,
          description: "tips for this week",
        };
        setSlides([dummyData, ...fetchedSlides]);
      } catch (err) {
        console.log(err);
      }
    };
    getStoryById();
  }, [actionParamsId]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const onNextClick = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % slides.length);
  };

  const onPrevClick = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + slides.length) % slides.length);
  };

  const progressPercentage = Math.round(((currentSlide + 1) / slides.length) * 100);

  const currentData = slides[currentSlide];
  const handleCloseTips = () => {
    setShowTips(false);
  };

  return (
    <>
      {slides.length != 0 && (
        <div className="tips-of-week" >
          <div className="week-card-border-line">
            <div className="sympt-card-close" >
              <a>
                <CloseOutlined
                  className="cursor-pointer read close-outline-btn"
                  size={40}
                  onClick={handleCloseTips}
                />
              </a>
            </div>
            <br />
            <Progress percent={progressPercentage} status="active" showInfo={false} />
            <div className="progress-percentage" >{`${progressPercentage}%`}</div>
            <div className="left-right-tips-tbn">
              <LeftOutlined size={40} onClick={onPrevClick} />
              <RightOutlined size={40} onClick={onNextClick} />
            </div>
            <div
              style={{
                width: "80%",
                marginLeft: "10%",
              }}>
              <p className="tips-para"
                style={{
                  fontSize: currentSlide === 0 ? "100px" : "33px",
                  color: currentSlide === 0 ? "green" : "black",
                }}>
                {currentData?.title}
              </p>
              {currentSlide != 0 && (
                <img
                  src={currentData?.imageUrl}
                  className="tips-of-week-img"
                />
              )}
              <p
                className="tips-of-week-p"
                style={{
                  fontSize: currentSlide === 0 ? "100px" : "16px",
                  marginTop: currentSlide === 0 ? "0px" : "30px",
                  color: currentSlide === 0 ? "green" : "black",
                }}>
                {currentData?.description}
              </p>
              {currentData?.buttons?.map((btn: any, ind: number) => {
                return (
                  <Fragment key={ind}>
                    <a href={btn?.actionParams?.url} target="_blank">
                      <Button className="mt-3 mb-3">{btn?.buttonText}</Button>
                    </a>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tips;
