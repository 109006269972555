import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore, { history } from "store";
import { ConnectedRouter } from "connected-react-router";
import { persistStore } from 'redux-persist'


const store = configureStore();
persistStore(store)
ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode>
      <BrowserRouter>
      <App />
      </BrowserRouter>
  </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
