import { useEffect, useState } from "react";
import { Form, Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import {  Radio } from "antd";

const GroupControls = (propsData: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [moderation, setModeration] = useState<any>(false)
  const [invites, setInvites] = useState<any>('Everyone')
  const [activityFeed, setActivityFeed] = useState<any>('everyone');
  const onFinish = async (values: any) => {
    setIsLoading(true);
    let reqBody = (await form.validateFields()) as any;
    reqBody.isModerated=moderation;
    reqBody.invite=invites;
    reqBody.activityFeed=activityFeed
    let nextTab = "controls";
    propsData.getControlData(reqBody, nextTab);
    setIsLoading(false);
  };

  useEffect(() => {
    if(propsData.resetControlsFields === true){
      form.resetFields();
    }
  }, [propsData]);

  const onFinishFailed = (errorInfo: any) => {
    setIsLoading(false)
  };

  const onPrevious = () => {
    propsData.getControlData('', 'settings');
  }
  const antIcon = <LoadingOutlined style={{ fontSize: 18, color:"white"}}  spin />
  return (
    <div>
      <Form
        name="controls"
        form={form}
        layout="vertical"
        autoComplete="off"
        className="input-sty"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="isModerated"
          label="Group Post Moderation"
          valuePropName="checked"
        >
          <Radio.Group value={moderation} options={[{ label: 'All post to moderated before going live', value: true },{ label: 'All post to be live without moderation', value: false }]} onChange={(e)=>{setModeration(e.target.value)}}/>
        </Form.Item>
        <Form.Item
          name="invite"
          label="Which member of this group are allowed to invite others?"
        >
           <Radio.Group
           defaultValue={'Everyone'}
           options={[{ label: 'Everyone', value: 'Everyone' },{ label: 'Admin', value: 'Admin' }, { label: 'Admin and Moderator', value: 'Admin and Moderator' }]} onChange={(e)=>{setInvites(e.target.value)}}/>
        </Form.Item>
        <Form.Item
          name="activityFeed"
          label="Which member of this group are allowed to post into the activity feed?"
        >
          <Radio.Group
          defaultValue={'everyone'}
          options={[{ label: 'Everyone', value: 'everyone' },{ label: 'Admin', value: 'admin' }, { label: 'Admin and Moderator', value: 'adminAndModerator' }]} onChange={(e)=>{setActivityFeed(e.target.value)}}/>
        </Form.Item>
      </Form>
      <Button
        type="primary"
        htmlType="submit"
        className="button-sty float-left"
        danger
        onClick={onPrevious}
      >
        <span className="mr-2"> Back</span>
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        className="button-sty float-right"
        onClick={onFinish}
        danger
      >
      <span className="mr-2"> Save</span>
        {isLoading &&
            <Spin indicator={antIcon} size="small" />
        }
      </Button>
    </div>
  );
};

export default GroupControls;
