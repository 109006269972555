import { Collapse, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, DatePicker, Radio, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "reducers";
import moment from "moment";
import {
  TUpdateProfileRequest_parenting_duedate,
  updateprofile,
} from "actions/updateprofile";
import { viewProfileApi } from "apis/btpViewProfile";
import { updateParentingApi,updateProfileApi } from "apis/UpdateProfile";
import { useQuery } from "react-query";
const { Panel } = Collapse;
const { Option } = Select;
import Swal from "sweetalert2";

const ParentingInfo = () => {
  const { isLoading, data, refetch } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );
  const profileData = data;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [formList, setformList] = useState<
    | Array<{
        childname: string;
        datepicker: string;
        childnickname: string;
        gender: string;
      }>
    | []
  >([{ childname: "", childnickname: "", datepicker: "", gender: "" }]);

  useEffect(() => {}, [formList]);
  const { Accesstoken } = useSelector((state: TRootState) => state.auth);

  const [date, setDate] = useState<string>();
  const onSelectDates = (date: any, dateString: any) => {
    setDate(dateString);
  };
  const parentinginfo = useSelector(
    (state: TRootState) => state.userprofile.child_info
  );

  const onFinish = async (values: any) => {
    let reqBody = (await form.validateFields()) as any;
    reqBody.type = "parenting";
    (reqBody.sk_user_id = profileData?.sk_user_id),
      reqBody.children = reqBody.children.map((child: any) => ({
        ...child,
        stage_of_parenting: String(profileData?.i_am),
        stage_status:"2"
      }));
      Swal.fire({
        icon: "success",
        showConfirmButton: false,
        timer: 900,
      });
    dispatch(
      updateprofile(
        reqBody as TUpdateProfileRequest_parenting_duedate,
        Accesstoken,
        (res) => handleCallback(res)
      )
    );
  };

  const handleCallback = (res: any) => {
    // console.log("Received values of form: ", res);
  };
  const onChange = (key: string | string[]) => {
    // console.log(key);
  };
  const genExtra = (callback: (s: number) => void, name: number) => (
    <DeleteOutlined onClick={() => callback(name)} />
  );

  const onSelectDate = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const initialValues = {
    stage_status: "1", 
    stage_of_parenting:"2"
  };

  const renderDynamicForm = useCallback(
    (formList: any) => {
      let formList1:
        | Array<{
            sk_stage_id: string;
            child_name: string;
            dob: moment.Moment;
            nick_name: string;
            gender: string;
            stage_of_parenting: string;
            stage_status: string;
          }>
        | [] = [];
      const removeFromitem = (name: number) => {
        formList1.splice(name, 1);
      };
      if (parentinginfo && parentinginfo.length > 0) {
        // console.log("::::", formList1, parentinginfo);
        parentinginfo.forEach((ab, ind) => {
          if (ab !== null) {
            if (ind < formList1.length - 1) {
              formList1[ind]["sk_stage_id"] = ab.sk_stage_id;
              formList1[ind]["child_name"] = ab.child_name;
              formList1[ind]["dob"] = moment(ab.dob);
              formList1[ind]["nick_name"] = ab.nick_name;
              formList1[ind]["gender"] = ab.gender;
              formList1[ind]["stage_of_parenting"] = String(profileData?.i_am) 
              formList1[ind]["stage_status"] = "1";
            } else {
              let obj: any = {
                sk_stage_id: ab?.sk_stage_id,
                child_name: ab?.child_name,
                dob: moment(ab?.dob),
                nick_name: ab?.nick_name,
                gender: ab?.gender,
                stage_of_parenting: profileData?.i_am,
                stage_status: "1",
              };
              formList1.push(obj as never);
            }
          }
        });
      }

      return (
        <Form.List initialValue={formList1} name="children">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Collapse onChange={onChange}>
                  <Panel
                    header={formList1[name]?.child_name || "New Child"}
                    key="10"

                    //  extra={<DeleteOutlined
                    //     onClick={() => { removeFromitem(name); remove(name) }}
                    //   />}
                  >
                    <div>
                      <Form.Item
                        name={[name, "sk_stage_id"]}
                        rules={[
                          {
                            required: !!form.getFieldValue("sk_stage_id"),
                            message: "Missing stage id",
                          },
                        ]}
                        {...restField}
                        hidden
                      >
                        <Input placeholder="Child Name" />
                      </Form.Item>


                      <Form.Item
                        name={[name, "stage_of_parenting"]}
                        label="Stage of Parenting"
                        hidden
                        valuePropName={profileData?.i_am}
                      >
                        <Input type="" ></Input>
                      </Form.Item>

                      <Form.Item
                        label="Stage Status"
                        name={[name, "stage_status"]}
                        hidden
                        valuePropName={profileData?.i_am}
                      >
                        <Input type="" ></Input>
                      </Form.Item>


                      <Form.Item
                        name={[name, "child_name"]}
                        label="Child’s Name"
                        rules={[
                          { required: true, message: "Missing child name" },
                        ]}
                        {...restField}
                      >
                        <Input placeholder="Child Name" />
                      </Form.Item>
                      {/* <Public /> */}
                      <Form.Item
                        name={[name, "dob"]}
                        rules={[
                          {
                            required: true,
                            message: "Date of birth is missing",
                          },
                        ]}
                        label="Child’s Date of Birth"
                      >
                        <DatePicker
                          // onChange={onSelectDate}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                      {/* <Public /> */}
                      {/* <Form.Item
                        name={[name, "nick_name"]}
                        label="Child’s Nick Name(Required)"
                        rules={[
                          { required: true, message: "Child Nickname missing" },
                        ]}
                      >
                        <Input />
                      </Form.Item> */}
                      {/* <Public /> */}
                      <p>Child’s Gender</p>
                      <Form.Item name={[name, "gender"]}>
                        <Select
                          showSearch
                          placeholder="Choose"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            (option!.children as unknown as string).includes(
                              input
                            )
                          }
                          filterSort={(optionA, optionB) =>
                            (optionA!.children as unknown as string)
                              .toLowerCase()
                              .localeCompare(
                                (
                                  optionB!.children as unknown as string
                                ).toLowerCase()
                              )
                          }
                        >
                          <Option value="1">----</Option>
                          <Option value="2">Choose</Option>
                          <Option value="3">Male</Option>
                          <Option value="4">Female</Option>
                        </Select>
                      </Form.Item>
                      {/* <Public /> */}
                    </div>
                  </Panel>
                </Collapse>
              ))}

              <div className="lg:flex flex-row lg:justify-between block">
                <Button
                  danger
                  className="button-resize m-3"
                  onClick={() => add()}
                >
                  <PlusOutlined className="site-form-item-icon " />
                  Add Another
                </Button>
                <Button
                  type="primary"
                  className="button-sty m-3"
                  danger
                  onClick={onFinish}
                >
                  Save Changes
                </Button>
              </div>
            </>
          )}
        </Form.List>
      );
    },
    [parentinginfo]
  );

  return (
    <Form
      name="dynamic_form_nest_item"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={initialValues}
      layout="vertical"
      className="input-sty"
    >
      {renderDynamicForm(formList)}
    </Form>
  );
};

export default React.memo(ParentingInfo, areEquals);
function areEquals(prev: any, next: any) {
  return prev === next;
}

