import { getAccessToken } from "actions/auth";
import React from "react";

import { Redirect, Route } from "react-router-dom";


// @ts-ignore
export const PrivateRoute = ({ component: Component ,...rest }) => {
  const {role, ...routeProps} = rest;

  return (

    <Route
      {...routeProps}
      render={(props) =>
        getAccessToken() ? ( <Component {...props} /> 
        ) : (
          <Redirect push
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
)};
