import { apiGet, apiPost, getDefaultAxiosConfig } from "./btpApi";
import { getAccessToken } from "actions/auth";

export type Tnotify = {
  notifications: [
    {
      _id: string;
      messageType: string;
      status: string;
      readStatus: string;
      deliveryStatus: string;
      userId: number;
      title: string;
      body: string;
      icon: string;
      target: string;
      createdAt: string;
      updatedAt: string;
    }
  ];
  status: string;
};

export const getNotifications = async (requestData: any) => {
  const url = process.env.REACT_APP_Notifications + "/api/v1/fcm/notifications";
  let accesstoken = getAccessToken();
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiGet<Tnotify>(url, {
    ...newconfig,
    data: requestData,
    skipAuthRefresh: true,
  });
};


