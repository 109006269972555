import React, { Fragment, useEffect, useState } from "react";
import { Card, Carousel, Skeleton, Tabs, Input, Collapse, Row, Col, Avatar, Typography, Space, Button } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { EditOutlined, EllipsisOutlined, SettingOutlined, LikeTwoTone } from "@ant-design/icons";
import { GoComment } from "react-icons/go";
import { BsShare, BsBookmark } from "react-icons/bs";
import { BiLike } from "react-icons/bi";
const { Text } = Typography;
const { Meta } = Card;
import { useInfiniteQuery } from "react-query";
import { followUser, getFeedsApi, getNearapi, getLikesapi, unfollowUser } from "apis/GetFeeds";
import ScrollList from "../UsersList/ScrollList";
import HorizontalScroll from "./HorizontalScroll";

const MomsLikeMe = (props: any) => {
  let {
    data: feedData,
    isLoading: isFeedLoading,
    isError: isFeedError,
  } = useInfiniteQuery("getFeed", getFeedsApi, {
    getNextPageParam: (lastPage, pages) => {
      if (pages.length && lastPage.hasNext !== false) return pages.length;
      return undefined;
    },
  });

  const [Likedlist, setLikedlist] = useState<any>([]);
  // useEffect(() => {
  //     getLikesapi().then((res) => {
  //       const likelist = res.data;
  //       setLikedlist((likelist))

  //     });
  // }, []);

  // const [nearlist, setNearlist] = useState<any>([])
  useEffect(() => {
    getNearByMom();
  }, []);

  async function getNearByMom() {
    const nearList = await getLikesapi();
    setLikedlist(nearList.data);
  }
  function getConnectionList() {
    // console.log("test");
    getNearByMom();
  }

  return (
    <div className="line">
      {isFeedLoading ? (
        <Skeleton active />
      ) : (
        <div>
          <div>
            {feedData &&
              feedData.pages[0].data
                .filter((vv: any) => vv?.type === "momslikeme")
                .map((vvv: any, ind: number) => {
                  return (
                    <Fragment key={ind}>
                      <HorizontalScroll Likedlist={Likedlist} vvv={vvv} title="MOMS LIKE ME" getConnectionList={getConnectionList} />
                    </Fragment>
                  );
                })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MomsLikeMe;
