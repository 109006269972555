import { apiGet, getDefaultAxiosConfig } from "./btpApi";
export type TUserResponse = {
    status: boolean;
    message: string;
    token: string
  };
export const internalLogIn = async (exchangeToken: string) => {
  let newconfig = getDefaultAxiosConfig()
  let url=`api/internalUsers/v1/token?exchangeToken=` + exchangeToken
    const head = { ...newconfig.headers};
    newconfig.headers = { ...head };
    return apiGet<TUserResponse>(url, { ...newconfig, skipAuthRefresh: true, });
};
