// import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";
import { apiGet, apiPatch, apiPost, getDefaultAxiosConfig } from "./btpApi";
import { TUpdateProfileRequest_personal, TUpdateProfileRequest_contact, TUpdateProfileRequest_parenting_new, TUpdateProfileRequest_parenting_update, TUpdateProfileRequest_parenting_duedate, TUpdateProfileResponse ,FileUpload,TUpdateCoverPhotoAction} from 'actions/updateprofile';
import { getAccessToken } from "actions/auth";


export type TUpdateParents_Profile={
  sk_user_id:number,
  childName:string,
  sk_stage_id: string;
  child_name: string;
  dob: string;
  gender: string;
  nick_name: string;
}

export const updateProfileApi = async (req: TUpdateProfileRequest_personal | TUpdateCoverPhotoAction |TUpdateProfileRequest_contact | TUpdateProfileRequest_parenting_new | TUpdateProfileRequest_parenting_update | TUpdateProfileRequest_parenting_duedate, token: any) => {
  // const url = '/Btpmobileapi/updateuser-details'
  console.log(req);
  // console.log(req.type);
  let url:any
  // if ('type' in req) {
  //   console.log(req.type);
  //   if(req.type=="parenting"){
  //     url="/api/users/v1/children"
  //   }else{
  //     url = '/api/users/v1/'
  //   }

  // }
  let newconfig = getDefaultAxiosConfig()
  const head = { ...newconfig.headers, accesstoken: getAccessToken() }
  newconfig.headers = { ...head }

  
  if ('type' in req) {
    if(req.type === "parenting"){
      console.log('req', req);
      const body:any = req;
      url = "/api/users/v1/children";
      
      return apiPatch<any, any>(url, body.children, { ...newconfig, skipAuthRefresh: true });

    }else{
      url = '/api/users/v1/'
    }

  }

  return apiPatch<TUpdateProfileResponse, TUpdateProfileRequest_personal | TUpdateCoverPhotoAction|TUpdateProfileRequest_contact | TUpdateProfileRequest_parenting_new | TUpdateProfileRequest_parenting_update | TUpdateProfileRequest_parenting_duedate>(url, req, { ...newconfig, skipAuthRefresh: true });
};

// export const updateParentingApi = async (req:TUpdateParents_Profile,  token: any) => {
//   // const url = '/Btpmobileapi/updateuser-details'
//   const url = '/api/users/v1/children'
//   let newconfig = getDefaultAxiosConfig()
//   const head = { ...newconfig.headers, accesstoken: getAccessToken() }
//   newconfig.headers = { ...head }
//   return apiPatch<TUpdateParents_Profile>(url, req, { ...newconfig, skipAuthRefresh: true });
// };

export const updateParentingApi = async (requestee_id: string) => {
  const url = '/api/users/v1/children'
  // const url = '/api/content/v1/following'
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TUpdateParents_Profile, { requestee_id: string }>(url, { requestee_id: requestee_id }, { ...newconfig, skipAuthRefresh: true, });
};



export const mediaUpload = async (req: any) => {
  const url=process.env.REACT_APP_MEDIA_URL+"/api/media/v1/upload?folder=profile_pics"
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  const formData = new FormData();
  formData.append("file", req);
  return apiPost(url, formData, { ...newconfig, skipAuthRefresh: true });
};


export const coverPhotoAPi = async (file_image: string) => {
  const url = "/Account_settings/insert_cover_photo";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TUpdateProfileResponse, { file_image: string }>(url, { file_image: file_image }, { ...newconfig, skipAuthRefresh: true, });
};






 