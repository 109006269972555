import React, { useEffect, useState } from "react";
import { Table, Layout ,message} from "antd";
import { godName,addFavName,getFavName} from "../../../apis/babyName";
import { useParams, Link, useHistory } from "react-router-dom";
import Headersection from "views/MainHeader/Header";
const { Header, Content } = Layout;
import NameDetails from "./NameDetails";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { useQuery } from "react-query";
import { viewProfileApi } from "../../../apis/btpViewProfile";
import Footer from "views/MainFooter/Footer";

const GodsName = () => {

  const params: any = useParams();
  const [apiData, setapiData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const[getFavNameData,setFavNameData]=useState<any>([])
  // console.log(getFavNameData);

  const history = useHistory();
  const pathname = history.location?.pathname || "";
  const formattedPathname = pathname.replace(
    /^\/(.)(.*)/,
    (_, firstChar, rest) => {
      return firstChar.toUpperCase() + rest;
    }
  );
  const {
    isLoading: loading,
    data: userData,
    refetch,
  } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );
  const fetchName = () => {
    let data = JSON.stringify({
      godName: params?.id,
    });
    godName(data)
      .then((res) => {
        setapiData(res.matchedNames);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchName();
  }, []);

  const [propData, setPropData] = useState<any>({});
  const handleNClick = (record: any) => {
    if (record && record.name) {
      setIsModalOpen(true);
      setPropData(record);
    }
  };

  const getFavNameList = () => {
    if (userData && userData.sk_user_id) {
      getFavName(userData.sk_user_id)
        .then((res) => {
          console.log("res?.matchedData",res?.matchedData);
          setFavNameData(res?.matchedData);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
     message.error("error")
    }
  };

  useEffect(() => {
    getFavNameList();
  }, [userData && userData.sk_user_id]);

  const backButtonStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    cursor: "pointer",
    display: "flex", // Allows alignment of icon and text
    alignItems: "center", // Aligns icon and text vertically
  };

  const arrowIconStyle = {
    marginRight: "8px", // Adjust the space between icon and text
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <a>{text}</a>,
      onCell: (record: any) => ({
        onClick: () => {
          handleNClick(record);
        },
      }),
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
    // {
    //   title: "Numerology",
    //   dataIndex: "numerology",
    //   key: "numerology",
    // },
    // {
    //   title: "Religion",
    //   dataIndex: "religion",
    //   key: "religion",
    // },
    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    // },
    {
      title: "Fav",
      dataIndex: "fav",
      key: "fav",
      render: (fav: any,record: any) => {
        const isFav = getFavNameData?.some((getFavNameData: any) => getFavNameData.name == record?.name);

        console.log("getFavNameData.name",getFavNameData?.name);
        console.log("record?.name",record?.name);


        return isFav ? <HeartFilled className="darkFav-icon" /> : <HeartOutlined className="outlineFav-icon" />;
      },
      onCell: (record: any) => ({
        onClick: () => {
          handleNameClick(record);
        },
      }),
    },
  ];

  const handleNameClick = (record: any) => {
    if (record && record.name) {
      let data = JSON.stringify({
        userId: userData?.sk_user_id,
        name: record.name,
      });
      addFavName(data)
        .then((res) => {
          getFavNameList()
          Swal.fire({
            icon: "success",
            title: "Name added to your favourite list !",
            showConfirmButton: false,
            timer: 900,
          });
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  return (
    <>
      {isModalOpen && (
        <NameDetails
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          propData={propData}
        />
      )}
      <Layout className="h-screen">
        <Header
          className=""
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Headersection />
        </Header>
        <div className=" bg-white mt-20">
          <Content
            className=" container "
            style={{
              margin: "24px auto",
            }}
          >
            <Link to="/babynames">
              <p>
                <ArrowLeftOutlined style={arrowIconStyle} />
                Baby Names
              </p>
            </Link>
            <div className="Artical_card3 relative mt-3">
              <p style={backButtonStyle}>{formattedPathname}</p>
              <div className="mt-5">
                <div>
                  <div className="mt-5">
                    <Table
                      columns={columns}
                      dataSource={apiData}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
        <Footer/>
      </Layout>
    </>
  );
};

export default GodsName;
