import React, { useState } from "react";
import { Layout, Tabs, Card, Badge, Skeleton, Input, Button } from "antd";
import Followbutton from "components/global/buttonsection/Followbutton";
import { useQuery } from "react-query";
import { getMomApi } from "../../../apis/GetFeeds";
import { Link } from "react-router-dom";
import Connect from "components/global/buttonsection/Connect";
const { Content } = Layout;
const { Meta } = Card;
const tabs = [
  {
    title: "Moms like me",
    value: "like",
  },
  {
    title: "Connections",
    value: "connections",
  },
  {
    title: "Following",
    value: "following",
  },
  {
    title: "Followers",
    value: "followers",
  },
  {
    title: "Pending Request",
    value: "pending",
  },
];
const { Search } = Input;
const Momnearlist = (props: any) => {
  const [activeKey, setActiveKey] = React.useState("like");
  const [searchValue, setSearchValue] = useState<any>("");
  const { filter } = props;
  const {
    data: momData,
    isLoading,
    refetch,
  } = useQuery(["getLikesapi", filter, activeKey, searchValue], () => getMomApi({ type: filter, filter_type: activeKey }, searchValue));

  const like_stylus = "border-like_stylus0 text-red-600 hover:text-red-300";
  const onKeyChange = (key: any) => {
    setActiveKey(key);
  };

  const onSearch = (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {};

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    if (value.trim() === "") {
      refetch();
      setSearchValue("");
      setActiveKey("like");
    } else {
      setSearchValue(value);
    }
  };

  return (
    <div>
      <Content style={{ padding: "50px", backgroundColor: "#fafbfd" }}>
        <Tabs defaultActiveKey="all" className="Moms_Following_list" onChange={onKeyChange} activeKey={activeKey}>
          {tabs.map(tab => (
            <Tabs.TabPane
              tab={
                <span className="badge">
                  {tab.title}
                  <Badge className="badge-style" />
                </span>
              }
              key={tab.value}>
              {isLoading ? (
                <Skeleton />
              ) : (
                <>
                  <Search
                    placeholder="Search"
                    onSearch={onSearch}
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      padding: "9px",
                    }}
                    onChange={handleInputChange}
                  />
                  <br />
                  <div className="card-sty mr-2">
                    {momData?.data.map((item: any) => {
                      console.log("item", item);

                      return (
                        <>
                          <Card
                            className="Card-moms"
                            style={{ width: 250 }}
                            cover={
                              <img
                                alt={item.fullName}
                                src={item.profilePic}
                                className="image_logo  object-center"
                                style={{
                                  borderRadius: "50%",
                                }}
                              />
                            }>
                            <Link to={"/momslikelistprofile/" + item.userId}>
                              <Meta title={item.fullName} description={item.stageName} />
                            </Link>
                            <div className="text-center pt-2">
                              <p>Mutual {item.mutualConnections}</p>
                            </div>

                            <div className="p-tag flex items-center">
                              <p className="">
                                {item.follow}
                                <span className="follow_section">
                                  <Connect {...item} class={like_stylus} getConnectionList={refetch} />
                                </span>
                              </p>
                              <div>
                                <Button
                                  className="follow_section"
                                  style={{
                                    backgroundColor: "#ED4D41",
                                    border: "2px",
                                    borderRadius: "0.375rem",
                                    width: "6rem",
                                    borderWidth: "2px",
                                    paddingTop: "0.25rem",
                                    paddingBottom: "0.25rem",
                                  }}>
                                  <Followbutton {...item} class={like_stylus} getConnectionList={refetch} />
                                </Button>
                              </div>
                            </div>
                          </Card>{" "}
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Content>
    </div>
  );
};

export default Momnearlist;
