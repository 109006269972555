import React, { useState, useEffect, useRef } from "react";
import { Layout, message, Form, Button, Input, Avatar, Popover, Modal } from "antd";
import Headersection from "views/MainHeader/Header";
import { getChatRoom } from "../../apis/btpFallowUnfallow";
import { useQuery } from "react-query";
const { Content } = Layout;
const { Header } = Layout;
import Male from "../../images/btp_male.png";
import Female from "../../images/btp_female.png";
import { viewProfileApi } from "apis/ViewProfile";
import { chatRoomsInitiate, fetchMomsMessage, deleteIndividualMessage, clearChatIndividual } from "apis/btpFallowUnfallow";
import { SendOutlined, MoreOutlined, DownOutlined, DeleteOutlined } from "@ant-design/icons";
import { io, Socket } from "socket.io-client";
import { getAccessToken } from "actions/auth";
import Swal from "sweetalert2";
import Footer from "views/MainFooter/Footer";
interface Message {
  text: string;
  senderId: number;
}

const Messages = () => {
  const { isLoading, data } = useQuery("viewProfile", () => viewProfileApi({ user_id: "" }), {
    enabled: true,
  });
  const profileData = data && data;
  // const { isLoading, data } = useQuery("getChatRoom", () => getChatRoom());
  // console.log("data", data && data)
  const [momUserId, setMomUserID] = useState<any>("");
  const [chatRooms, setChatRooms] = useState<any>({});
  const [recivedMessage, setRecivedMessage] = useState<any>([]);
  const [firstDefault, setFirstDefault] = useState<any>({});
  const [userID, setuserID] = useState<any>(0);

  const getChat = () => {
    getChatRoom()
      .then(res => {
        setChatRooms(res);
        const fetchedUserID = res?.usersData[0]?.sk_user_id;
        setuserID(fetchedUserID);
        setFirstDefault(res?.usersData[0]);
      })
      .catch(err => {
        message.error(err);
      });
  };

  useEffect(() => {
    getChat();
  }, []);

  const [userName, setUserName] = useState<any>({});
  useEffect(() => {
    if (Object.keys(userName).length === 0) {
      let datß = JSON.stringify({
        participants: [userID],
        isGroup: false,
        title: null,
      });
      chatRoomsInitiate(datß)
        .then(res => {
          setMomUserID(res.id);
        })
        .catch(err => {
          message.error(err);
        });
    }
  }, [userID]);

  const handleUserID = async (value: any) => {
    let datß = JSON.stringify({
      participants: [value?.sk_user_id],
      isGroup: false,
      title: null,
    });
    await chatRoomsInitiate(datß)
      .then(res => {
        setMomUserID(res.id);
      })
      .catch(err => {
        message.error(err);
      });
    setUserName(value);
    getChat();
  };

  const getMessage = async () => {
    const messages = await fetchMomsMessage(momUserId);
    setRecivedMessage(messages);
  };

  useEffect(() => {
    if (momUserId) {
      getMessage();
      getChat();
    }
  }, [momUserId]);

  const [socket, setSocket] = useState<Socket | null>(null);
  const hide = (val: any) => {
    Swal.fire({
      title: "Delete this message ?",
      text: "Are you sure you want to delete this message ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        deleteIndividualMessage(val)
        .then(res => {
          if (socket) {
            socket.emit("delete", val);
            getMessage()
            getChat()
          }
          Swal.fire({
            icon: "success",
            timer: 900,
            showConfirmButton: false,
          });
        })
        .catch(errr => {
          console.log(errr);
        });

      }
      if (result.isConfirmed) {
        if (socket) {
          socket.emit("delete", val);
        }
      }
    });
  };


  useEffect(() => {
    if (momUserId) {
      const newSocket = io(process.env.REACT_APP_CHATROOMS + `/?room=${momUserId}`, {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: getAccessToken(),
            },
          },
        },
        reconnection: false,
        reconnectionDelay: 30000,
        reconnectionAttempts: 3,
      });
      setSocket(newSocket);
      newSocket.on("message", newMessage => {
        setRecivedMessage((prevMessages: Message[]) => [...prevMessages, newMessage]);
        getChat();
        getMessage();
        scrollToBottom();
      });
      newSocket.on("newMessage", () => {
        getChat();
        getMessage();
        scrollToBottom();
      });
      newSocket.emit("join", {
        chatroomId: momUserId,
      });
      return () => {
        newSocket.disconnect();
      };
    }
  }, [momUserId]);
  useEffect(() => {
    if (socket) {
      socket.on('delete', deletedMessageId => {
        // console.log('Received delete event for message:', deletedMessageId);
        setRecivedMessage((prevMessages:any) =>
          prevMessages.filter((msg: any) => msg._id !== deletedMessageId.message_id)
          );
      });

    }
  }, [socket]);

  const [inputVal, setInputVal] = useState("");
  function generateRandomId() {
    const characters = "0123456789abcdef";
    let id = "";
    for (let i = 0; i < 24; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      id += characters[randomIndex];
    }
    return id;
  }

  const randomId = generateRandomId();
  const sendMessage = () => {
    if (socket) {
      const messageData = {
        chatroomId: momUserId,
        senderId: data?.sk_user_id,
        _id: randomId,
        type: "text",
        textContent: inputVal,
        mediaKey: null,
      };
      socket.emit("message", messageData);
      setInputVal("");
      getMessage();
      getChat();
    }
  };

  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (scrollableContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollableContainerRef.current;
      scrollableContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [recivedMessage, getMessage]);


  const handleClearChat = () => {
    console.log("momUserId", momUserId);
    Swal.fire({
      title: "Are you sure ?",
      text: "Clearing chat will be permanently deleted ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        if (socket) {
          socket.emit("delete");
        clearChatIndividual(momUserId)
          .then(res => {
            getMessage();
            getChat();
            Swal.fire({
              icon: "success",
              timer: 900,
              showConfirmButton: false,
            });
          })
          .catch(errr => {
            console.log(errr);
          });
        }
      }
    });
  };

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-20">
          <Content
            className=" container "
            style={{
              margin: "24px auto",
            }}>
            <div className="message-chat-body">
              <div className="message-chat-ui">
                <div className="message-chat-main-container">
                  <div className="message-chat-left-container">
                    <div className="message-chat-header">
                      <div className="message-chat-user-img">
                        {profileData?.profile_pic ? (
                          <img src={profileData?.profile_pic} alt="" className="dp" />
                        ) : (
                          <Avatar shape="circle" size={50} />
                        )}
                      </div>
                    </div>
                    <div className="chat-list">
                      {chatRooms.chatrooms?.map((chatValue: any, ind: number) => {
                        // console.log("chatValue",chatValue);

                        return chatRooms.participants?.map((partValue: any, inde: number) => {
                          if (chatValue?._id === partValue?.chatroomId && partValue?.userId !== profileData?.sk_user_id) {
                            return (
                              <>
                                {chatRooms?.usersData?.map((userV: any, index: number) => {
                                  return (
                                    <>
                                      {partValue.userId == userV.sk_user_id && (
                                        <>
                                          <div className="chat-box" onClick={() => handleUserID(userV)}>
                                            <div className="img-box">
                                              {userV?.gender == "Male" ? (
                                                <img className="img-cover" src={Male} alt="" />
                                              ) : (
                                                <img className="img-cover" src={Female} alt="" />
                                              )}
                                            </div>

                                            <div className="chat-details">
                                              <div className="text-head">
                                                <h4>{userV.full_name}</h4>
                                                <p>
                                                  {chatRooms.latestMessages.filter((mess: any) => chatValue._id === mess._id).slice(-1)[0]
                                                    ?.message?.sentOn &&
                                                    new Date(
                                                      chatRooms.latestMessages
                                                        .filter((mess: any) => chatValue._id === mess._id)
                                                        .slice(-1)[0]?.message?.sentOn
                                                    ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                                                </p>
                                              </div>

                                              {chatRooms?.latestMessages && (
                                                <>
                                                  {chatRooms.latestMessages.filter((mess: any) => chatValue._id === mess._id).length >
                                                    0 && (
                                                    <div className="text-message">
                                                      {chatRooms?.unreadMessages?.map((un: any, inl: number) => {
                                                        const isMatch = chatValue?._id === un?._id;
                                                        const latestMessage = chatRooms.latestMessages
                                                          .filter((mess: any) => chatValue._id === mess._id)
                                                          .slice(-1)[0]?.message?.textContent;

                                                        return (
                                                          <span key={inl}>
                                                            <p
                                                              style={{
                                                                fontWeight: isMatch ? "bold" : "normal",
                                                                color: isMatch ? "black" : "gray",
                                                              }}>
                                                              {isMatch
                                                                ? `${latestMessage}`
                                                                : chatRooms.latestMessages
                                                                    .filter((mess: any) => chatValue._id === mess._id)
                                                                    .slice(-1)[0]?.message?.textContent}
                                                            </p>
                                                            <p style={{ color: "black", fontWeight: "bold" }}></p>
                                                          </span>
                                                        );
                                                      })}

                                                      {chatRooms?.unreadMessages.length == 0 && (
                                                        <p>
                                                          {" "}
                                                          {
                                                            chatRooms.latestMessages
                                                              .filter((mess: any) => chatValue._id === mess._id)
                                                              .slice(-1)[0]?.message?.textContent
                                                          }
                                                        </p>
                                                      )}

                                                      {chatRooms?.unreadMessages?.map((un: any, inl: number) => {
                                                        return <>{chatValue?._id == un?._id && <b>{un?.count}</b>}</>;
                                                      })}
                                                    </div>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                              </>
                            );
                          }
                        });
                      })}
                    </div>
                  </div>
                  <div className="message-chat-right-container">
                    <div className="message-chat-header">
                      <div className="img-text">
                        <div className="message-chat-user-img">
                          {userName?.gender == "Female" ? (
                            <img className="dp" src={Female} alt="" />
                          ) : (
                            <img className="dp" src={Male} alt="" />
                          )}
                        </div>
                        {Object.keys(userName).length === 0 ? (
                          <h4>{firstDefault?.full_name}</h4>
                        ) : (
                          <h4>
                            {userName?.full_name}
                            <br />
                          </h4>
                        )}
                      </div>
                      <div className="message-chat-nav-icons">
                        <Popover
                          trigger="click"
                          placement="bottom"
                          content={
                            <a className="mess_delete_chat" onClick={handleClearChat}>
                              <DeleteOutlined /> Clear Chat
                            </a>
                          }>
                          <li>
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </li>
                        </Popover>
                      </div>
                    </div>
                    <div className="message-chat-container" ref={scrollableContainerRef}>
                      {recivedMessage?.map((val: any, index: number) => {
                        const sentTime = new Date(val.sentOn);
                        const currentTime = new Date();
                        const timeDiffInSeconds = Math.floor((currentTime.getTime() - sentTime.getTime()) / 1000);
                        let timeAgo = "";
                        if (timeDiffInSeconds >= 0) {
                          if (timeDiffInSeconds > 60) {
                            const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                            if (timeDiffInMinutes < 60) {
                              timeAgo = `${timeDiffInMinutes} minute${timeDiffInMinutes !== 1 ? "s" : ""} ago`;
                            } else if (timeDiffInMinutes < 1440) {
                              const timeDiffInHours = Math.floor(timeDiffInMinutes / 60);
                              timeAgo = `${timeDiffInHours} hour${timeDiffInHours !== 1 ? "s" : ""} ago`;
                            } else if (timeDiffInMinutes < 43200) {
                              const timeDiffInDays = Math.floor(timeDiffInMinutes / 1440);
                              timeAgo = `${timeDiffInDays} day${timeDiffInDays !== 1 ? "s" : ""} ago`;
                            } else if (timeDiffInMinutes < 525600) {
                              const timeDiffInMonths = Math.floor(timeDiffInMinutes / 43200);
                              timeAgo = `${timeDiffInMonths} month${timeDiffInMonths !== 1 ? "s" : ""} ago`;
                            } else {
                              const timeDiffInYears = Math.floor(timeDiffInMinutes / 525600);
                              timeAgo = `${timeDiffInYears} year${timeDiffInYears !== 1 ? "s" : ""} ago`;
                            }
                          } else if (timeDiffInSeconds > 0) {
                            timeAgo = `${timeDiffInSeconds} second${timeDiffInSeconds !== 1 ? "s" : ""} ago`;
                          }
                        }
                        return (
                          <>
                            {profileData?.sk_user_id === val?.senderId && (
                              <div className="chat-ui-message-box my-message">
                                <p>
                                  <div className="flex">
                                    {val?.textContent}
                                    <Popover
                                      trigger="click"
                                      content={
                                        <a className="mess_delete_chat" onClick={() => hide(val._id)}>
                                          Delete
                                        </a>
                                      }>
                                      <Button
                                        style={{ background: "none", width: "auto", height: "auto", marginLeft: "3px", marginTop: "-4px" }}
                                        icon={<DownOutlined />}
                                        type="text"
                                      />
                                    </Popover>
                                  </div>
                                  <span>{timeAgo}</span>
                                </p>
                              </div>
                            )}
                            {profileData?.sk_user_id !== val?.senderId && (
                              <div className="chat-ui-message-box friend-message">
                                <p>
                                  <div className="flex">
                                    {val?.textContent}
                                    {profileData?.sk_user_id === val?.senderId && (
                                      <Popover
                                        trigger="click"
                                        content={
                                          <a className="mess_delete_chat" onClick={() => hide(val._id)}>
                                            Delete
                                          </a>
                                        }>
                                        <Button
                                          style={{
                                            background: "none",
                                            width: "auto",
                                            height: "auto",
                                            marginLeft: "3px",
                                            marginTop: "-4px",
                                          }}
                                          icon={<DownOutlined />}
                                          type="text"
                                        />
                                      </Popover>
                                    )}
                                  </div>
                                  <span>{timeAgo}</span>
                                </p>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                    <div className="message-chatbox-input">
                      <div style={{ display: "flex", alignItems: "center", width: "95%" }}>
                        <input
                          type="text"
                          placeholder="Your Comment"
                          className="message-chat-input"
                          value={inputVal}
                          onChange={e => setInputVal(e.target.value)}
                        />
                        <button type="submit" className="message-chat-input-btn" onClick={sendMessage}>
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </div>
        <Footer/>
      </Layout>
    </>
  );
};

export default Messages;
