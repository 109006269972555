import {
  Skeleton,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import ScrollList from "./UsersList/ScrollList"
import { getLikesapi, getFeedsApi, getNearapi, } from "apis/GetFeeds";
import { useInfiniteQuery } from "react-query";
import RecomndedGroupList from "./UsersList/RecomndedGroupList";

const Articalsection2 = () => {
  const [Likedlist, setLikedlist] = useState<any>([])
  let { data: feedData, isLoading: isFeedLoading, isError: isFeedError} = useInfiniteQuery('getFeed', getFeedsApi, {
    getNextPageParam: (lastPage, pages) => {
      if (pages.length && lastPage.hasNext !== false) return pages.length;
      return undefined;
    }}
  );
  const [nearlist, setNearlist] = useState<any>([])
  async function getNearByMom(){
      const nearList = await getNearapi();
      setNearlist((nearList.data));
      const nearListLike = await getLikesapi();
      setLikedlist((nearListLike.data));
  }
  useEffect(() => {
    getNearByMom();
}, []);

  function getConnectionList(){
      getNearByMom();
      getNearByMom();
  }

  return (
    <div className="Artical_card2">
      {isFeedLoading ? <Skeleton active /> :
      <div className="w-full mb-7 border p-3 rounded-md ">
          <div>
            {feedData &&
              feedData.pages[0].data
                .filter((vv:any) => vv?.type === "momslikeme")
                .map((vvv:any,inde:number) => {
                  return (
                    <Fragment key={inde}>
                    <ScrollList Likedlist={Likedlist} vvv={vvv} title="MOMS LIKE ME"
                    getConnectionList={getConnectionList}
                    />
                    </Fragment>
                  )
                })}
          </div>
      </div>}

      {isFeedLoading ? <Skeleton active /> :
      <div className="w-full mb-7 border p-3 rounded-md">
          <div>
            {feedData &&
              feedData.pages[0].data
                .filter((vv:any) => vv?.type === "momsnearme")
                .map((vvv:any,ind:number) => {
                  return (
                    <Fragment key={ind}>
                    <ScrollList Likedlist={nearlist} vvv={vvv} title="MOMS NEAR ME"
                    getConnectionList={getConnectionList}
                    />
                    </Fragment>
                  )
                })}
          </div>
      </div>}

      <div className="w-full mb-7 border p-3 rounded-md">
          <div>
            <RecomndedGroupList/>
          </div>
      </div>
    </div>
  );
};

export default Articalsection2;