import React from 'react';
// import 'antd/dist/antd.css';
// import './index.css';
import { Layout, Menu, Tabs } from 'antd';
import Sidenav from 'views/MainHeader/SideNav';
import Headersection from 'views/MainHeader/Header';

import Momsnearlist from 'views/MomsNearyou/MomsNearlList/Momnearlist';
const { Header, Content, Footer, Sider } = Layout;






const MomsLikeYou = (props:any) => (
  
    
    <Layout className='h-screen'>
        <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
            // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
        }}
        >
        <div className="logo" />
        <Sidenav />
        </Sider>
        <Header className='' style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <Headersection {...props} />
        </Header>
        <Layout className='container'>
            <div className='bg-white mt-16'>
                <Content className='moms_like_content mt-16'>
                    <Tabs defaultActiveKey="1">
                        

                        <Tabs.TabPane tab={<h4>Moms</h4>} key="1">
                            <Momsnearlist filter="momslikeyou" />
                        </Tabs.TabPane>

                        

                        {/* <Tabs.TabPane tab={<h4>Moms Near Me</h4>} key="2">
                            <Momsnearlist filter="momsnearyou" />
                        </Tabs.TabPane> */}


                    </Tabs>
                </Content>
            </div>
        </Layout>
    </Layout>
);

export default MomsLikeYou;