import React, { useState } from "react";
import { Layout, Card } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Chart from "react-apexcharts";

const BloodTypeResult = () => {
  const history = useHistory();
  const location = useLocation();
  const res = (location.state as any)?.data;

  const customStyles = buildStyles({
    pathColor: "#ff0000",
    trailColor: "#eee",
    textColor: "#000",
  });

  const handleBack = () => {
    history.push("/child-blood-type");
  };

  const [chartData, setChartData] = useState<any>({
    series: [res?.possibleBloodType?.bloodtype1percent, res?.possibleBloodType?.bloodtype2percent, res?.possibleBloodType?.bloodtype3percent, res?.possibleBloodType?.bloodtype4percent],
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        `${res?.possibleBloodType?.bloodtype1percent}% ${res?.possibleBloodType?.bloodtype1}`,
        `${res?.possibleBloodType?.bloodtype2percent}% ${res?.possibleBloodType?.bloodtype2}`,

        `${res?.possibleBloodType?.bloodtype3percent}% ${res?.possibleBloodType?.bloodtype3}`,

        `${res?.possibleBloodType?.bloodtype4percent}% ${res?.possibleBloodType?.bloodtype4}`,
      ],
      colors: ['#FFB6C1', '#FF0000', '#8A2BE2', '#FF6347'],
      plotOptions: {
        pie: {
          customScale: 0.8,
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
            enabled: true,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <Layout className="h-screen">
      <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Headersection />
      </Header>
      <div className=" bg-white mt-15">
        <Content
          className="container blood-type-preg"
          style={{
            margin: "24px auto",
            width: "70%",
          }}>
          <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
            <Card style={{ border: "none" }}>
              <div className="child-blood-type-res">
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                  <div className="cursor-pointer">
                    <i className="fa fa-arrow-left" aria-hidden="true" onClick={handleBack}>
                      &nbsp;
                      <span>Child Blood Type</span>
                    </i>
                  </div>
                  <div>{/* <i className="fa fa-arrow-left" aria-hidden="true"><span>Home</span></i> */}</div>
                </div>
                <p>Child's Blood Type</p>

                <div className="ellipse-parent-blood-type mt-5">
                  {/* <div className="group-child-blood-type"></div>
                  <div className="group-item-blood-type"></div>
                  <div className="group-inner-blood-type"></div>
                  <div className="ab-wrapper">
                    <div className="ab">
                      <p className="p">20%</p>
                      <p className="p">AB</p>
                    </div>
                  </div>
                  <div className="a-wrapper">
                    <div className="ab">
                      <p className="p">30%</p>
                      <p className="p">A</p>
                    </div>
                  </div>
                  <div className="b-wrapper">
                    <div className="ab">
                      <p className="p">50%</p>
                      <p className="p">B</p>
                    </div>
                  </div> */}
                  <Chart options={chartData.options} series={chartData.series} type="donut" />

                </div>

                <hr className="mt-10" />
                <div className="possible-blood-rh-type-parent">
                  <div className="possible-blood-rh-type">Possible Blood RH-Type</div>
                  <div className="frame-child-bllod-res">
                    <CircularProgressbar value={66} text={``} styles={customStyles} strokeWidth={12} />
                  </div>

                  <div className="ellipse-parent">
                    <div className="frame-item-bllod-res"></div>
                    <div className="negative">25% Negative</div>
                  </div>
                  <div className="ellipse-group">
                    <div className="frame-inner"></div>
                    <div className="negative">75% Positive</div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </Content>
      </div>
    </Layout>
  );
};

export default BloodTypeResult;
