import { TFetchingStatus } from "helper";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import {
  individualChatHistoryFailureAction,
  individualChatHistoryInitAction,
  individualChatHistorySuccessAction,
  individualChatHistoryUpdateAction,
  TIndividualChatHistoryActions,
  TMessageHistory,
  TMessageMedia,
} from "actions/message";

export type TIndividualChatHistory = {
  message_history: Array<TMessageHistory> | [];
  fetchingStatus: TFetchingStatus;
  error: Error | null;
};

export const initialState: TIndividualChatHistory = {
  message_history: [],
  fetchingStatus: TFetchingStatus.Default,
  error: null,
};

export const individualChatHistoryReducer = createReducer<
  TIndividualChatHistory,
  TIndividualChatHistoryActions
>(initialState)
  .handleAction(individualChatHistoryInitAction, (state) =>
    produce(state, (draftState) => {
      console.log("init", state, draftState);
      draftState.fetchingStatus = TFetchingStatus.Loading;
      draftState.error = null;
    })
  )
  .handleAction(individualChatHistorySuccessAction, (state, { payload }) =>
    produce(state, (draftState) => {
      console.log("success", state, payload);
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.error = null;
      draftState.message_history = payload as any;
    })
  )
  .handleAction(individualChatHistoryFailureAction, (state, { payload }) =>
    produce(state, (draftState) => {
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.error = payload;
    })
  )
  .handleAction(individualChatHistoryUpdateAction , (state, { payload })  =>
  produce(state, (draftState) => {  
      draftState.fetchingStatus = TFetchingStatus.Success;
      draftState.error = null;
      
  }));
