import React, { lazy, Suspense } from "react";
// import 'antd/dist/antd.css';
// import './index.css';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Sidenav from "views/MainHeader/SideNav";
import Headersection from "views/MainHeader/Header";
import Bannersection from "components/Content";

const { Header, Content, Footer, Sider } = Layout;

const Dashboard = (props: any) => {
    const getCurrentYear = () => {
        return new Date().getFullYear();
      };
  return (
    <>
      <Layout className="h-screen" style={{ height: "95vh" }}>
        <Layout>
          <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
            <Headersection />
          </Header>
          <div className=" bg-white mt-20">
            <Content
              className=" container "
              style={{
                margin: "24px auto",
              }}>
              <div>
                <Bannersection />
              </div>
            </Content>
          </div>
        </Layout>

      </Layout>
      <footer
          style={{
            textAlign: "center",
            color: "#454444",
            fontSize: "16px",
            fontWeight: "bold",
            height: "5vh",
            width: "100%",
            backgroundColor: "#e3e3e3",
            position: "fixed",
            marginBottom: "0vh",
            padding: "10px",
          }}>
          <p>&copy; 2014-{getCurrentYear()} Mykids Ventures Private Limited.</p>
        </footer>
    </>
  );
};

export default Dashboard;
