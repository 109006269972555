import React, { useState } from "react";
import { Avatar, Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Select } from "antd";
import { AiOutlineCamera, AiOutlineVideoCamera } from "react-icons/ai";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { TRootState } from "reducers";
import { useSelector } from "react-redux";
import { mediaUpload } from "apis/UpdateProfile";
import { createPostCore } from "views/Groups/btpGroupApi";
import { viewProfileApi } from "apis/btpViewProfile";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import ImgCrop from "antd-img-crop";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import "antd/es/modal/style";
import "antd/es/slider/style";
import { Modal } from "antd";

const { Option } = Select;
interface Props {
  setpost?: any;
  groupId?: any;
}

const Post: React.FC<Props> = ({ groupId }) => {
  const authInfo = useSelector((state: TRootState) => state.auth);
  const [showResults, setShowResults] = React.useState(false);
  const [inputVal, setInputVal] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const onClick = () => setShowResults(true);
  const { isLoading, data } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));
  const profileData = data;
  const [privacyValue, setPrivacyValue] = useState("public");
  const handleChange1 = (value: string) => {
    setPrivacyValue(value);
  };

  if (isLoading) {
    return <div />;
  }
  const [postedFiles, setPostedFiles] = useState<any>([]);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [errText, setErrText] = useState("");
  const onSubmit = async () => {
    if (!inputVal && uploadedFiles.length == 0) {
      setErrText("Please add some content or media to post");
      return;
    }
    let data = JSON.stringify({
      postDescription: inputVal,
      title: inputVal,
      postPrivacy: privacyValue,
      postType: "userPost",
      postMedia: uploadedFiles,
    });
    setErrText("");
    await createPostCore(data).then(res => {
      if (res.status === true) {
        form.resetFields();
        setInputVal("");
        setUploadedFiles([]);
        setPostedFiles([]);
        setShowResults(false);
        queryClient.invalidateQueries("getUserPost");
        Swal.fire({
          icon: "success",
          title: "Posted successfully",
          showConfirmButton: false,
          timer: 900,
        });
      }
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputVal(e.target.value);
    setErrText("");
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    Modal.info({
      title: "Image Preview",
      content: <img src={src} style={{ maxWidth: "100%" }} />,
    });
  };

  const onVideoPreview = async (file: UploadFile<any>) => {
    Modal.info({
      title: file.name,
      content: (
        <video controls style={{ maxWidth: "100%" }}>
          <source src={file.url || URL.createObjectURL(file.originFileObj as Blob)} type={file.type} />
          Your browser does not support the video tag.
        </video>
      ),
      onOk() {},
    });
  };

  const props: UploadProps = {
    name: "file",
    accept: "image/*,video/*",
    multiple: true,
    onRemove: async file => {
      const updatedUploadedFiles = uploadedFiles.filter((item: any) => item.uid !== file.uid);
      setUploadedFiles(updatedUploadedFiles);
      const updatedPostedFiles = postedFiles.filter((item: any) => item.uid !== file.uid);
      setPostedFiles(updatedPostedFiles);
      if (file.response && file.response.imageUrl) {
        try {
          console.log("File removed from API successfully.");
        } catch (error) {
          console.error("Error while removing file from API:", error);
        }
      } else {
        console.error("File response or imageUrl is undefined.");
      }
    },
    beforeUpload: async (file: any) => {
      mediaUpload(file)
        .then((res: any) => {
          const mediaObject = {
            mediaType: "image",
            mediaKey: res.imageUrl,
            uid: file.uid,
          };
          setErrText("");
          setUploadedFiles([...uploadedFiles, mediaObject]);
        })
        .catch(error => {
          console.log(error);
        });

      return false;
    },
    onChange(info: any) {
      if (info.file.status === "done") {
        form.setFieldsValue({ image: info?.file?.originFileObj });
      }
    },
  };

  const videoprops: UploadProps = {
    name: "file",
    accept: "image/*,video/*",
    multiple: true,
    onRemove: async file => {
      const updatedUploadedFiles = uploadedFiles.filter((item: any) => item.uid !== file.uid);
      setUploadedFiles(updatedUploadedFiles);
      const updatedPostedFiles = postedFiles.filter((item: any) => item.uid !== file.uid);
      setPostedFiles(updatedPostedFiles);
      if (file.response && file.response.imageUrl) {
        try {
          console.log("File removed from API successfully.");
        } catch (error) {
          console.error("Error while removing file from API:", error);
        }
      } else {
        console.error("File response or imageUrl is undefined.");
      }
    },
    beforeUpload: async (file: any) => {
      mediaUpload(file)
        .then((res: any) => {
          const mediaObject = {
            mediaType: "mp4",
            mediaKey: res.imageUrl,
            uid: file.uid,
          };
          setErrText("");
          setUploadedFiles([...uploadedFiles, mediaObject]);
        })
        .catch(error => {
          console.log(error);
        });

      return false;
    },
    onChange(info: any) {
      if (info.file.status === "done") {
        form.setFieldsValue({ image: info?.file?.originFileObj });
      }
    },
  };

  return (
    <div>
      <div>
        <div className="text-area-input flex items-center">
          <div className="p-5">
            {profileData?.profile_pic ? (
              <img src={profileData?.profile_pic} className="prof-pic-home" />
            ) : (
              <Avatar src={authInfo.parent_info?.gender_pic} />
            )}
          </div>
          <div>
            <p className="mb-2">{profileData?.full_name}</p>
          </div>
        </div>
        <div className="">
          <Form layout="vertical" form={form} onFinish={onSubmit}>
            <Form.Item name="postTitle">
              <TextArea
                placeholder="What is on your mind ?"
                rows={4}
                onClick={onClick}
                className="text-area-placeholder"
                value={inputVal}
                onChange={handleChange}
              />
            </Form.Item>
            {showResults ? (
              <>
                <div className="flex post-control-form" style={{ width: "100%" }}>
                  {!groupId ? (
                    <>
                      <div >
                        <ImgCrop>
                          <Upload {...props} onPreview={onPreview}
                           listType="picture"
                           className="upload-list-inline user-post" accept="image/*,">
                            <div>
                              <AiOutlineCamera className="post-icon mr-3 " size={30} />
                            </div>
                          </Upload>
                        </ImgCrop>
                      </div>
                      <div >
                        <Upload
                          {...videoprops}
                          onPreview={onVideoPreview}
                          listType="picture"
                          className="upload-list-inline user-post"
                          accept="video/*">
                          <div>
                            <AiOutlineVideoCamera className="post-icon " size={30} />
                          </div>
                        </Upload>
                      </div>
                    </>
                  ) : null}
                  <div style={{ display: "flex", width: "100%" }}>
                    {!groupId ? (
                      <Form.Item className="postPrivacy" name="postPrivacy">
                        <Select defaultValue="public" onChange={handleChange1}>
                          <Option value="public" className="mt-0">
                            Public
                          </Option>
                          <Option value="followers">Followers</Option>
                          <Option value="connections">My Connections</Option>
                          <Option value="private">Only Me</Option>
                        </Select>
                      </Form.Item>
                    ) : null}
                    <Button htmlType="submit" className="home-post-btn">
                      Post
                    </Button>
                  </div>
                </div>
                <div className="text-center">
                  <p className="post-form-text-danger mr-3">{errText}</p>
                </div>
              </>
            ) : null}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Post;
