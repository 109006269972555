import Logo from "../../../images/logo.svg";
import React,{useEffect} from "react";
const MobileHeader = () => {
  const handleOpenButtonClick = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/iphone|ipad|ipod/.test(userAgent)) {
      window.open("https://app.btp.mom/momnexus");
    } else if (/android/.test(userAgent)) {
      window.open("https://app.btp.mom/momnexus");
    } else {
      console.log("Unknown mobile device");
    }
  };

  useEffect(() => {
    // Check if the browser is Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      const logoElement = document.getElementById("logoImageH");
      if (logoElement) {
        logoElement.classList.add("safari-logo");
      }
    }
  });

  return (
    <div className="btn-new-header" onClick={handleOpenButtonClick}>
      <div>
        {/* <img src={Logo} alt="Logo" className="mobile-img-heder-logo" id="logoImageH" /> */}
        {/* Hi */}
        <h3 className="btn-new-header-p " style={{fontWeight:"bold",fontSize:"15px"}}>Open in the beingtheparent app</h3>
      </div>
      <div>
        <button className="open-btn-app" style={{fontWeight:"bold"}}>Open</button>
      </div>
    </div>
  );
};

export default MobileHeader;
