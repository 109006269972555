import Stepone from 'components/Loginforms/index'
import React from 'react'

const Createuser = () => {
  return (
    <div>
        <Stepone />
    </div>
  )
}

export default Createuser