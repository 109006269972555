
import {
    Card, Carousel, Skeleton, Tabs, Input, Collapse, Button, Modal, Badge,
    DatePicker, Space,
    Form
} from "antd";
import React, { useState, useEffect } from 'react';
import Calendar, { TileDisabledFunc } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getBannerApi, getLikesapi, getListsApi, getFeedsApi,postCalendar,TCalendarData } from "apis/GetFeeds"

import {
    BellOutlined,
    DownOutlined,
    FileOutlined,
    LogoutOutlined,
    SearchOutlined,
    UserOutlined,
    RightOutlined
} from "@ant-design/icons";


interface ModalProps {
    isOpenTracker: boolean;
    setIsOpenTracker: React.Dispatch<React.SetStateAction<boolean>>;
}

const SetUpTracker = ({ isOpenTracker, setIsOpenTracker }: ModalProps) => {
    const showModal = () => {
        setIsOpenTracker(true);
    };
    const [dateState, setDateState] = useState(new Date());
    const [showSecondData, setShowSecondData] = useState(false);
    const [selectedDay, setSelectedDay] = useState(28);
    // console.log(selectedDay);
    
    const dispatch = useDispatch();
    const handleOk = () => {
        setIsOpenTracker(false);
    };
    const handleCancel = () => {
        setIsOpenTracker(false);
    };


    const changeDate = (e: any) => {
        setDateState(e);
    };

    const tileDisabled: TileDisabledFunc = ({ date }) => {
        return date > new Date();
    };

    const handleShowSecondData = () => {
        setShowSecondData(true);
    };
    
    const renderSliderNumbers = () => {
        const numbers = [];
        for (let i = 20; i <= 40; i++) {
            const isSelected = i === selectedDay;
            numbers.push(
                <div
                    key={i}
                    className={`slider-number ${isSelected ? 'selected' : ''}`}
                    onClick={() => handleSliderNumberClick(i)}
                >
                    {i} days
                </div>
            );
        }
        return numbers;
    };

    const handleSliderNumberClick = (number: number) => {
        setSelectedDay(number);
    };

    const calendarData: TCalendarData = {
        periodDate: moment(dateState).format('YYYY-MM-DD'),
        cycleDays: selectedDay, 
      };


    const handleSubmit = () => {
        postCalendar(calendarData)
        .then((response) => {
          handleCancel();
        })
        .catch((error) => {
          console.error("Post failed:", error);
        });
    }


    return (
        <>
            <Button type="primary" onClick={showModal}>
                Open Modal
            </Button>
            <Modal title="Set Up Tracker" open={isOpenTracker} onOk={handleOk} onCancel={
                handleCancel}
                footer={null}
            >

                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        showSecondData ? (
                            <div className="react_calendar_card">
                                <p>How long does your cycle usually last ?</p>
                                <div className="react_calendar_card_in">
                                    <div className="slider-container">
                                        <div className="slider-scrollable">{renderSliderNumbers()}</div>
                                        <Button className='claendar_btn' onClick={handleSubmit}>
                                            <RightOutlined />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="react_calendar_card">
                                <p>When was you first day of your last period</p>
                                <Form>
                                    <div className="react_calendar_card_in">
                                        <Calendar
                                            value={dateState}
                                            onChange={changeDate}
                                            tileDisabled={tileDisabled}
                                        />
                                        <Button className='claendar_btn' onClick={handleShowSecondData}>
                                            <RightOutlined />
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        )
                    }
                </div>
            </Modal>
        </>
    )
}

export default SetUpTracker