import React,{useState, useEffect} from 'react'
import { Card, Carousel, Skeleton, Tabs, Input, Collapse, Row, Col, Avatar, Typography, Space, Button } from "antd";
import { MoreOutlined } from '@ant-design/icons'
import { EditOutlined, EllipsisOutlined, SettingOutlined, LikeTwoTone } from '@ant-design/icons';
import { GoComment } from "react-icons/go";
import { BsShare, BsBookmark } from "react-icons/bs";
import { BiLike } from "react-icons/bi";
const { Text } = Typography;
const { Meta } = Card;
import { useInfiniteQuery } from "react-query";
import { followUser, getFeedsApi, getNearapi, unfollowUser } from "apis/GetFeeds";
import ScrollList from '../UsersList/ScrollList';
import HorizontalScroll from './HorizontalScroll';
import { useQuery } from "react-query";


const MomsNearMe = (props:any) => {
    let { data: feedData, isLoading: isFeedLoading, isError: isFeedError,refetch} = useInfiniteQuery('getFeed', getFeedsApi, {
        enabled: false,
        getNextPageParam: (lastPage, pages) => {
            if (pages.length && lastPage.hasNext !== false) return pages.length;
            return undefined;
        }   
    }
    );
    const [nearlist, setNearlist] = useState<any>([])
    useEffect(() => {
        getNearByMom();
    }, []);
    
    async function getNearByMom(){
        const nearList = await getNearapi();    
        setNearlist((nearList.data));
    }
    function getConnectionList(){
        getNearByMom();
    }




    


    return (
        <div className='line'>
            {isFeedLoading ? <Skeleton active /> :
                <div >
                    <div>
                        {feedData &&
                            feedData.pages[0].data
                                .filter((vv: any) => vv?.type === "momsnearme")
                                .map((vvv: any) => {
                                    return (
                                        <HorizontalScroll Likedlist={nearlist} vvv={vvv} title="MOMS NEAR ME"  key={vvv.type} getConnectionList={getConnectionList}/>
                                    )
                                })}
                    </div>

                </div>}
        </div>
    )
}

export default MomsNearMe