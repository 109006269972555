import React, { useEffect, useState } from "react";
import { Table, Layout, message } from "antd";
import Headersection from "views/MainHeader/Header";
import { viewProfileApi } from "../../../apis/btpViewProfile";
import { getFavName ,removeFavName} from "../../../apis/babyName";
import { useQuery } from "react-query";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
const { Header, Content } = Layout;
import Swal from "sweetalert2";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Footer from "views/MainFooter/Footer";



const FavName = () => {
  const arrowIconStyle = {
    marginRight: "8px",
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
    {
      title: "Numerology",
      dataIndex: "numerology",
      key: "numerology",
    },
    {
      title: "Religion",
      dataIndex: "religion",
      key: "religion",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Fav",
      dataIndex: "fav",
      key: "fav",
      render: (fav: any) =>
        fav ?  <HeartFilled className="darkFav-icon" /> : <HeartOutlined className="outlineFav-icon" />,
      onCell: (record: any) => ({
        onClick: () => {
          handleNameClick(record);
        },
      }),
    },
  ];

  const {
    isLoading: loading,
    data: userData,
    refetch,
  } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );

  const [favNameData, setFavNameData] = useState<any>([]);
  const getFavNameList = () => {
    if (userData && userData.sk_user_id) {
      getFavName(userData.sk_user_id)
        .then((res) => {
          setFavNameData(res?.matchedData);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
     message.error("error")
    }
  };

  useEffect(() => {
    getFavNameList();
  }, [userData && userData.sk_user_id]);

  const handleNameClick = (record: any) => {
    let data = JSON.stringify({
      userId: userData?.sk_user_id,
      name: record.name,
    });
    removeFavName(data).then((res)=>{
        if(res.message=="Name removed from fav list"){
            getFavNameList();
            Swal.fire({
                icon: "success",
                title: "Name removed from your favorite list!",
                showConfirmButton: false,
                timer: 900,
              });
        }
    }).catch((err)=>{
       message.error(err)
    })
  };
  return (
    <>
      <Layout className="h-screen">
        <Header
          className=""
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Headersection />
        </Header>
        <div className=" bg-white mt-20">
          <Content
            className=" container "
            style={{
              margin: "24px auto",
            }}
          >
             <Link to="/babynames">
            <p>
              <ArrowLeftOutlined style={arrowIconStyle} />
              Baby Names
            </p>
            </Link>
            <div className="Artical_card3 relative mt-10">
              <Table
                columns={columns}
                dataSource={favNameData}
                pagination={false}
                className="cursor-pointer"
              />
            </div>
          </Content>
        </div>
        <Footer/>
      </Layout>
    </>
  );
};

export default FavName;
