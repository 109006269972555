import { Layout, Card, Collapse } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";
const { Panel } = Collapse;

const HCGCalcResult = () => {
  const location = useLocation();
  const res = (location.state as any)?.data?.hcgOutput;
  const history = useHistory();
  const panels = [
    {
      key: 1,
      header: "What is hCG?",
      content: (
        <p>
          Human chorionic gonadotropin, or hCG , is the hormone your body produces when you are pregnant. hCG showing up in your blood or
          urine is one of the earliest signs of pregnancy, which is why lots of people are eager to know about normal hCG levels.
        </p>
      ),
    },
    {
      key: 2,
      header: "What are hCG levels?",
      content: (
        <p>
          Extremely high levels of hCG can indicate a multiple pregnancy (for example, twins). On the other hand, low hCG levels may
          indicate an ectopic pregnancy.
        </p>
      ),
    },
    {
      key: 3,
      header: "What are normal Doubling times?",
      content: (
        <>
          {" "}
          <p>
            You can expect the following doubling times. Please note it is only an indication and can vary from one pregnancy to an another.
          </p>
          <ul className="hcg-circle-list">
            <li>hCG under 1,200 mlU/ml:between 30 and 72 hours.</li>
            <li>hCG under 1,200-6000 mlU/ml:between 72 and 96 hours.</li>
            <li>hCG under 6,000 mlU/ml:between over 96 hours.</li>
          </ul>
        </>
      ),
    },
  ];
  const handleBack = () => {
    history.push("/hcg-calculator");
  };
  return (
    <Layout className="h-screen">
      <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Headersection />
      </Header>
      <Content className="container mt-10">
        <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
          <Card style={{ border: "none" }}>
            <div className="preg-calc-card-res">
              <div className="rectangle-parent">
                <div className="frame-child"></div>
                <div className="line-icons-parent">
                  <ArrowLeftOutlined className="line-icons" onClick={handleBack}/>
                  <div className="pregnancy-weight-gain">
                    <button onClick={handleBack}>HCG Calculator</button>
                  </div>
                </div>
                <div className="group-wrapper">
                  <div className="result-parent-hcg">
                    <div className="result">📉 Result</div>
                    <div className="your-pregnancy-weight-gain-is-parent">
                      <div className="pregnancy-weight-gain">
                        Difference <i className="fa fa-info-circle"></i>
                      </div>
                      <div className="not-healthy">
                        <p>
                          <span style={{ color: "#038D63", fontSize: "30px", fontWeight: "bold" }}>
                            {res?.differencePercent !== undefined ? res?.differencePercent.toFixed(2) : ""} %
                          </span>
                          <span style={{ color: "#666666" }}>
                            {res?.differenceHCG !== undefined ? res?.differenceHCG.toFixed(2) : ""}
                            mlU/mL
                          </span>
                        </p>
                      </div>
                    </div>
                    <br />

                    <div className="your-bmi-body-mass-index-parent">
                      <div className="pregnancy-weight-gain">
                        Doubling time <i className="fa fa-info-circle"></i>
                      </div>
                      <div className="kgm">
                        <p style={{ color: "#666666" }}>
                          {res?.dubblingTime !== undefined ? res.dubblingTime.toFixed(2) : ""}
                          hours | {res?.timeDay !== undefined ? res?.timeDay.toFixed(2) : ""}
                          days
                        </p>
                      </div>
                    </div>
                    <div className="your-target-weight-gain-parent">
                      <div className="pregnancy-weight-gain">
                        One day increase <i className="fa fa-info-circle"></i>
                      </div>
                      <div className="kgm">
                        <p style={{ color: "#666666" }}>{res?.oneDayIncrease !== undefined ? res?.oneDayIncrease.toFixed(2) : ""} %</p>
                      </div>
                    </div>

                    <div style={{ marginTop: "200px" }}>
                      <div className="pregnancy-weight-gain">
                        Two day increase <i className="fa fa-info-circle"></i>
                      </div>
                      <div className="kgm">
                        <p style={{ color: "#666666" }}>{res?.twoDayIncrease !== undefined ? res?.twoDayIncrease.toFixed(2) : ""} %</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              <div className="group-wrapper-faq-ques">
                <div className="group-wrapper-faq">
                  <div className="result-parent-faq">
                    <div className="result">FAQs</div>
                    <div key={1}>
                      <Collapse
                        style={{
                          backgroundColor: "#F0F0F0",
                        }}
                        expandIconPosition="end">
                        {panels?.map((vv: any, index: number) => {
                          return (
                            <>
                              <Panel
                                key={index + 1}
                                header={`${index + 1}.` + vv?.header}
                                style={{
                                  marginBottom: "10px",
                                  border: "1px solid #ccc",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  backgroundColor: "#F0F0F0",
                                }}>
                                <div>
                                  <p
                                    style={{
                                      backgroundColor: "#F0F0F0",
                                      padding: "16px",
                                    }}>
                                    <span>{vv?.content}</span>
                                  </p>
                                </div>
                              </Panel>
                            </>
                          );
                        })}
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};

export default HCGCalcResult;
