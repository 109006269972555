
import { Layout,Tabs } from 'antd';
import Sidenav from 'views/MainHeader/SideNav';
import Headersection from 'views/MainHeader/Header';
import Momsnearlist from './MomsNearlList/Momnearlist';
import Momslikelist from 'views/MomsLikeYou/MomsLikeList/Momslikelist';


const { Header, Content,  Sider } = Layout;


const MomsNearYou = (props:any) => (

    <Layout className='h-screen'>
        <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
            console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
        }}
        >
        <div className="logo" />
        <Sidenav />
        </Sider>
        <Header className='' style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Headersection {...props} />
        </Header>
        <Layout className='container'>
            <div className='bg-white mt-16'>
                <Content className='moms_like_content mt-16'>
                    <Tabs defaultActiveKey="2">
                        <Tabs.TabPane tab={<><h4>Moms Like Me</h4></>} key="1">
                            <Momslikelist className="" />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<><h4>Moms Near Me</h4></>} key="2">
                         <Momsnearlist className="" />
                        </Tabs.TabPane>
                    </Tabs>
                    <Momsnearlist />
                </Content>
            </div>
        </Layout>
    </Layout>
);

export default MomsNearYou;