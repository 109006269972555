import { getAccessToken } from "actions/auth";
import React from "react";
import { apiGet, apiPost, getDefaultAxiosConfig, apiDelete } from "../../apis/apiCall";



export type TGroupResponse = {
  status: boolean;
  message: string;
  data: [];
  type:string;
  content_id:string;
  reason:string
};


// New Api Added

export const postReportGroup = async ( groupData: any) => {
  const url = "/groups/post_report";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};


// End


export const createGroup = async ( groupData: any) => {
  const url = "/groups/create";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};



export const getGroups = async (propsData: any, pageInfo: any) =>{
  let url = '/groups/groups?page='  + pageInfo.current + '&limit='  + pageInfo.pageSize;
  if(propsData.recommended === true){
    url+= '&is_recommended=true';
  }
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const getGroupDetails = async (params: any) =>{
  let url = '/groups/group/' + params.slug1;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const getGroupPosts = async (params: any, pageNumber: number) =>{
  let url = '/groups/group_posts/' + params.slug1 + '?limit=20&page=' + pageNumber;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const createGroupMember = async ( groupData: any) => {
  const url = "/groups/create_group_member";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};

export const createPost = async ( groupData: any) => {
  const url = "/groups/create_post";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TGroupResponse, {}>(url, groupData, {...newconfig,skipAuthRefresh: true,});
};



export const deleteGroup = async (id: any) =>{
  let url = 'groups/delete/' + id;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  console.log('data', head)
  newconfig.headers = { ...head };
  return apiDelete<TGroupResponse>(url, {...newconfig,skipAuthRefresh: true,});
};



