import {
    Button, Modal,
    Form
} from "antd";
import Calendar from 'react-calendar';
import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';

interface CustomTileClassNames {
    [date: string]: string;
}
interface ModalProps {
    data:{
        forecast:{
            data:{
                fertile:{
                    start:string,
                    end:string,
                    color:string,
                    title:string
                },
                implantation:{
                    date:string,
                    title:string,
                    color:string
                },
                ovulation:{
                    date:string,
                    color:string,
                    title:string
                },
                period:{
                    color:string,
                    end:string,
                    start:string,
                    title:string
                }
            }
        }

    }
    openNextPeriodModal: boolean;
    setOpenNextPeriodModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const NextPeriod = ({ openNextPeriodModal, setOpenNextPeriodModal, data }: ModalProps) => {    
    const showModal = () => {
        setOpenNextPeriodModal(true);
    };

    const handleOk = () => {
        setOpenNextPeriodModal(false);
    };

    const handleCancel = () => {
        setOpenNextPeriodModal(false);
    };


    const [renderedDates, setRenderedDates] = useState<Set<string>>(new Set());
    const customTileClassNames: CustomTileClassNames = {
        ...(data && data.forecast.data.period.start && { [data.forecast.data.period.start]: 'period' }),
        ...(data && data.forecast.data.period.end && { [data.forecast.data.period.end]: 'period' }),
        ...(data && data.forecast.data.ovulation.date && { [data.forecast.data.ovulation.date]: 'ovulation' }),

        ...(data && data.forecast.data.fertile.start && { [data.forecast.data.fertile.start]: 'fertileWindow' }),
        ...(data && data.forecast.data.fertile.end && { [data.forecast.data.fertile.end]: 'fertileWindow' }),

        ...(data && data.forecast.data.implantation.date && { [data.forecast.data.implantation.date]: 'implantation' }),
    };

    const tileContent = ({ date }: { date: Date }) => {
        const formattedDate = date.toISOString().slice(0, 10);
        const className = customTileClassNames[formattedDate];
        if (renderedDates.has(formattedDate)) {
            return null;
        }
        setRenderedDates(new Set(renderedDates).add(formattedDate));
        return (
            <div className={`custom-tile ${className ? className : ''}`}>
                {className && date.getDate()}
            </div>
        );
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                Open Modal
            </Button>
            <Modal title="Set Up Tracker" open={openNextPeriodModal} onOk={handleOk} onCancel={
                handleCancel}
                footer={null}
            >
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                    <div className="react_calendar_card" style={{
                        backgroundColor: "#FAD3D0"
                    }}>
                        <div style={{
                            padding: '20px',
                        }}>
                            <h6>Next Period</h6>
                        </div>

                        <Form>
                            <div className="react_calendar_card_in">
                                <Calendar
                                    tileContent={tileContent}
                                    tileClassName={({ date }) => {
                                        const formattedDate = date.toISOString().slice(0, 10);
                                        const className = customTileClassNames[formattedDate];
                                        return className ? `custom-tile ${className}` : '';
                                    }}
                                />
                                <Button className='claendar_btn' >
                                </Button>
                                <div style={{
                                    display: 'flex',
                                    padding: '20px',
                                    textAlign: "justify",
                                }}>
                                    <div className="left_warning" style={{ marginRight: 'auto' }}>
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <div style={{
                                                width: "21px",
                                                height: "21px",
                                                borderRadius: '50%',
                                                backgroundColor: data && data.forecast.data.ovulation.color
                                            }}>
                                            </div>
                                            <div className="ml-2"><span> {data && data.forecast.data.ovulation.title}</span></div>
                                        </div>

                                        <br />
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <div style={{
                                                width: "21px",
                                                height: "21px",
                                                borderRadius: '50%',
                                                backgroundColor: data && data.forecast.data.fertile.color
                                            }}></div>
                                            <div className="ml-2"><span> {data && data.forecast.data.fertile.title}</span></div>
                                        </div>
                                    </div>

                                    <div className="right_warning" style={{ marginLeft: 'auto' }}>
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <div style={{
                                                width: "21px",
                                                height: "21px",
                                                borderRadius: '50%',
                                                backgroundColor: data && data.forecast.data.period.color
                                            }}></div>
                                            <div className="ml-2"><span> {data && data.forecast.data.period.title}</span></div>
                                        </div>


                                        <br />
                                        <div style={{
                                            display: 'flex'
                                        }}>
                                            <div style={{
                                                width: "21px",
                                                height: "21px",
                                                borderRadius: '50%',
                                                backgroundColor: data && data.forecast.data.implantation
                                                    .color
                                            }}></div>
                                            <div className="ml-2"><span> {data && data.forecast.data.implantation
                                                .title}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NextPeriod