import React from 'react';
import { Card } from "antd";
import { Link } from "react-router-dom"
import Connect from "../buttonsection/Connect";
import Followbutton from "../buttonsection/Followbutton";

const like_stylus='border-2 rounded-md py-1 w-20 text-sm';

const  ScrollList = (props:any) => {
  const {vvv, title} = props;

  return (
    <div key={vvv.type}>
      <div className="flex items-center justify-between">
        <div>
          <h2 className="pink_text text-sm font-medium text-left">
            {title}
          </h2>
        </div>
        <div>
            <div className="text-right pb-3 py-2 underline text-red-300">
              <Link
                to="/momslikeyou"
                className="text-red-800 !hover:text-red-300"
              >
                View all
              </Link>
            </div>
        </div>
      </div>
      <div
        className="overflow-y-scroll h-full momes_border"
        style={{ maxHeight: "300px" }}
      >

        {
          props.Likedlist?.map((vv:any, index:number)=>{
            // console.log("vvv",vv);

            return(
              <div className="flex  mb-3 border_align" key={index}>
              <div className="mr-3">
                <img src={vv.profilePic} alt=""  className="h-12 w-12 object-cover rounded-full" style={{borderRadius:"50%"}}/>
              </div>
              <div>
                <Link to={"/momslikelistprofile/" + vv.userId}>
                  <h3>{vv.fullName}</h3>
                </Link>
                <p>{vv.stageName}</p>
                <span style={{fontSize:"12px"}}>{vv.mutualConnections} connections</span>
                <div className="flex my-2">
                  <div className="mr-3">
                    <Followbutton {...vv} class={like_stylus}   getConnectionList={props.getConnectionList}/>
                  </div>
                  <div>
                    <Connect {...vv} getConnectionList={props.getConnectionList}/>
                  </div>
                </div>
              </div>
            </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default ScrollList;