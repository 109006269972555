import React, { useEffect } from "react";
import { Layout, Row, Col, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "reducers";
import { viewprofile, TUserDetailRequest } from "actions/viewprofile";
import { Link } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import moment from "moment";

const { Header, Content, Footer, Sider } = Layout;
const DetailsPersonal = () => {

  const { isLoading, data,refetch } = useQuery('viewProfile', () => 
  viewProfileApi({ user_id: "" ,enabled: false},
  ))
  const profileData = data;

  const { Accesstoken, userId } = useSelector(
    (state: TRootState) => state.auth
  );

  // const profileData = useSelector((state: TRootState) => state.userprofile);
  const userdata = useSelector((state: TRootState) => state.userprofile);
  // console.log("DID", userdata);
  const dispatch = useDispatch();
  let reqBody = { user_id: userId };
  const childrenList = userdata.child_info.map((item, index) => {
    return (
      <Row>
        <Col span={4}>
          <h5>Child Name</h5>
        </Col>
        <Col span={4}>
          <p>{item.child_name}</p>
        </Col>
        <Col span={4}>
          <h5>Child gender</h5>
        </Col>
        <Col span={4}>
          <p>{item.gender}</p>
        </Col>
        <Col span={4}>
          <h5>Child dob</h5>
        </Col>
        <Col span={4}>
        
          <p>{moment(item.dob).format('DD-MM-YYYY')}</p>
        </Col>
        <Col span={4}>
          <h5>Child sk_stage_id</h5>
        </Col>
        <Col span={4}>
          <p>{item.sk_stage_id}</p>
        </Col>
        <Col span={4}>
          <h5>Child nick_name</h5>
        </Col>
        <Col span={4}>
          <p>{item.nick_name}</p>
        </Col>
        <Col span={4}>
          <h5>Child child_profile_pic</h5>
        </Col>
        <Col span={4}>
          <p>{item.child_profile_pic}</p>
        </Col>
      </Row>
    );
  });

  useEffect(() => {
    if (Accesstoken !== "" && Accesstoken !== null)
      dispatch(viewprofile(reqBody as TUserDetailRequest, Accesstoken));
  }, [Accesstoken]);




  return (
    <div>
      <Content className="table_content">
        <div className="hdng_sec">
          <h2 className="text-lg font-medium">View Profile</h2>
          <Button
            className="button-sty rounded-2xl"
            style={{ color: "red", backgroundColor: "white" }}
          >
            <Link className=" flex" to={{ pathname: "/editprofile/edit" }}>
              <EditOutlined className="site-form-item-icon mt-1 mr-2" />
              <p className="!text-sm">Edit Profile</p>
            </Link>
          </Button>
        </div>

        <h4 className="text-lg font-normal">Parenting Information</h4>
        {profileData?.childDetails.map((item, index) => (
          <div className="table_inner_cnt">
            <Row>
              <Col xl={6} xs={8}>
                <h5>Child Name</h5>
              </Col>
              <Col xl={4}>
                <p>{item.child_name}</p>
              </Col>
            </Row>
            <Row>
              <Col xl={6} xs={8}>
                <h5>DOB</h5>
              </Col>
              <Col xl={4}>
               <p> {moment(item.dob).format('DD-MM-YYYY')}</p>
              </Col>
            </Row>
            <Row>
              <Col xl={6} xs={8}>
                <h5>Nickname</h5>
              </Col>
              <Col xl={4}>
                <p>{item.nick_name}</p>
              </Col>
            </Row>
            <Row>
              <Col xl={6} xs={8}>
                <h5>Gender</h5>
              </Col>
              <Col xl={4}>
                <p>{item.gender}</p>
              </Col>
            </Row>
          </div>
        ))}
      </Content>

      <Content className="table_content">
        <h4>Personal Information</h4>
        <div className="table_inner_cnt">
          <Row>
            <Col xl={6} xs={8}>
              <h5>Full Name</h5>
            </Col>
            <Col xl={4}>
              <p>{profileData?.full_name} {profileData?.alies_name}</p>
            </Col>
          </Row>
          {/* <Row>
            <Col xl={6} xs={8}>
              <h5>Last Name</h5>
            </Col>
            <Col xl={4}>
              <p>{profileData.alies_name}</p>
            </Col>
          </Row> */}
          <Row>
            <Col xl={6} xs={8}>
              <h5>Username</h5>
            </Col>
            <Col xl={4}>
              <p>{profileData?.username_momnexus}</p>
            </Col>
          </Row>
        </div>
      </Content>
      <Content className="table_content">
        <h4>Contact Information</h4>
        <div className="table_inner_cnt">
          <Row>
            <Col xl={6} xs={8}>
              <h5>Country</h5>
            </Col>
            <Col xl={4}>
              <p>{profileData?.c_code}</p>
            </Col>
          </Row>
        </div>
      </Content>
    </div>
  );
};

export default DetailsPersonal;
