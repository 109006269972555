import { Col, Layout, Menu, Row } from "antd";
import Sidenav from "views/MainHeader/SideNav";
import Headersection from "views/MainHeader/Header";

import Sider from "antd/lib/layout/Sider";
import { Header } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { apiPost } from "apis/apiCall";
import { profileDetailsApi } from "apis/ViewProfile";
import { TUserDetails } from "actions/viewprofile";
import ProfileDetails from "./ProfileDetails";
import {getMomProfile,TMomProfile} from "../../Groups/btpGroupApi"
import { followUser,getLikesapi, getFeedsApi, getNearapi, unfollowUser } from "apis/GetFeeds";



function MomsLikeListProfile(props:any) {
  // console.log(props);
  const [profileData, setProfileData] = useState<TMomProfile|null>(null);
  const getProfile = async () => {
    let profileId = parseInt(props.match.params.id)
    let result =await getMomProfile({id: profileId})
    // console.log(result);
    setProfileData(result)
  }
  useEffect(() => {
    getProfile();
  }, []);



function getConnectionList(){
  getProfile()
}

  return (
    <Layout className="h-screen">
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <div className="logo" />
        <Sidenav />
      </Sider>
      <Header
        className=""
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <Headersection {...props}  />
      </Header>
      {profileData ? <ProfileDetails profileData={profileData} getConnectionList={getConnectionList}/> : null}
    </Layout>
  );
}

export default MomsLikeListProfile;
