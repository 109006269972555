interface EnvironmentConfig {
    API_URL: string;
    BLOG_URL: string;
    CORE_URL:string;

}

let API_URL = '';
let BLOG_URL = '';
let CORE_URL='';
// console.log(process.env.REACT_APP_ENVIRONMENT);

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    API_URL = 'https://auth.dev.beingtheparent.com/';
    BLOG_URL = "https://dev.beingtheparent.com";
    CORE_URL =  "https://core.dev.beingtheparent.com"
}
else if(process.env.REACT_APP_ENVIRONMENT === 'test') {
    API_URL = 'https://auth.dev.beingtheparent.com/';
    BLOG_URL = "https://dev.beingtheparent.com";
    CORE_URL =  "https://core.dev.beingtheparent.com"

    // API_URL = 'https://auth.beingtheparent.com';
    // BLOG_URL = "https://www.beingtheparent.com";
    // CORE_URL =  "https://core.beingtheparent.com"
}
else {
    API_URL = 'https://auth.beingtheparent.com';
    BLOG_URL = "https://www.beingtheparent.com";
    CORE_URL =  "https://core.beingtheparent.com"
}

export const environmentConfig: EnvironmentConfig = {
    API_URL, BLOG_URL, CORE_URL
}

