import React, { useState, useEffect, ChangeEvent } from "react";
import { Layout, Input, Form, message, Slider, Radio, Card, Image } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import Preg from "../icon/HCGCal.svg";
import { hcg_calculator, blood_type } from "../../../../apis/btpTools";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const MotherBlood = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  // const handleResult = () => {
  //   history.push("/blood-types");
  // };

  const [form] = Form.useForm();

  const handleResult = () => {
    // Get the form values when navigating to the result page
    const values = form.getFieldsValue();
    console.log("values", values);

    form
      .validateFields()
      .then(values => {
        const motherBloodType = values["mother-blood-type"];
        const fatherBloodType = values["father-blood-type"];
        console.log("Mother's blood type:", motherBloodType);
        console.log("Father's blood type:", fatherBloodType);

        let data = JSON.stringify({
          fatherBlood: fatherBloodType,
          motherBlood: motherBloodType,
        });
        blood_type(data)
          .then(res => {
            console.log("res.message", res);
            // console.log("possibleBloodType",res?.possibleBloodType)
            history.push({
              pathname: "/blood-types",
              state: {
                data: res,
              },
            });

            // console.log("res",res?.status);

            // if (res && res.message === "true") {
            //   history.push({
            //     pathname: "/blood-types",
            //     state: {
            //       data: res,
            //     },
            //   });
            // }
          })
          .catch(err => {
            message.error(err);
          });
      })
      .catch(errorInfo => {
        console.log("Validation failed:", errorInfo);
      });

    // history.push("/blood-types");
  };

  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-15">
          <Content
            className="container blood-type-preg"
            style={{
              margin: "24px auto",
              width: "70%",
            }}>
            <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
              <Card style={{ border: "none" }}>
                <div className="preg-calc-card-res">
                  <div className="rectangle-parent-child">
                    <div className="frame-child-blood"></div>
                    <div className="line-icons-parent">
                      <ArrowLeftOutlined className="line-icons" />
                      <div className="pregnancy-weight-gain">
                        <button>Child Blood Type</button>
                      </div>
                    </div>

                    <div className="bloodType-image">
                      <div className="result-parent-hcg">
                        <Image
                          alt=""
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/2560px-Image_created_with_a_mobile_phone.png"
                          width={250}
                          height={250}
                          className="child-blood-img"
                          preview={false}
                        />
                      </div>
                    </div>
                  </div>
                  <Form
                    form={form}
                    onFinish={handleResult}
                    className="group-wrapper-faq-ques"
                    style={{ height: "auto", textAlign: "center" }}>
                    <div className="flex" style={{ width: "100%" }}>
                      <div className="group-wrapper-faq-ques" style={{ height: "auto", textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", color: "black", fontSize: "26px" }}>What is mother's blood type?</p>

                        {/* <Radio.Group> */}
                        <Form.Item name="mother-blood-type" rules={[{ required: true, message: "Please select mother's blood type!" }]}>
                          <div className="frame-parent mt-5">
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="O+" name="mother-blood-type" value="O+" />
                                  <label htmlFor="O+">O+</label>
                                </div>
                              </div>

                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="O-" name="mother-blood-type" value="O-" />
                                  <label htmlFor="O-"> O-</label>
                                </div>
                              </div>
                            </div>

                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id=" A+" name="mother-blood-type" value="A+" />
                                  <label htmlFor=" A+"> A+</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="A-" name="mother-blood-type" value="A-" />
                                  <label htmlFor="A-"> A-</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="B+" name="mother-blood-type" value="B+" />
                                  <label htmlFor="B+"> B+</label>
                                </div>
                              </div>
                            </div>
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="B-" name="mother-blood-type" value="B-" />
                                  <label htmlFor="B-"> B-</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="AB+" name="mother-blood-type" value="AB+" />
                                  <label htmlFor="AB+">AB+</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="AB-" name="mother-blood-type" value="AB-" />
                                  <label htmlFor="AB-">AB-</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form.Item>
                      </div>

                      <div className="group-wrapper-faq-ques" style={{ height: "auto", textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", color: "black", fontSize: "26px" }}>What is father's blood type?</p>

                        <Form.Item name="father-blood-type" rules={[{ required: true, message: "Please select father's blood type!" }]}>
                          <div className="frame-parent mt-5">
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FO+" name="father-blood-type" value="O+" />
                                  <label htmlFor="FO+">O+</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FO-" name="father-blood-type" value="O-" />
                                  <label htmlFor="FO-"> O-</label>
                                </div>
                              </div>
                            </div>
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FA+" name="father-blood-type" value="A+" />
                                  <label htmlFor="FA+"> A+</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FA-" name="father-blood-type" value="A-" />
                                  <label htmlFor="FA-"> A-</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FB+" name="father-blood-type" value="B+" />
                                  <label htmlFor="FB+"> B+</label>
                                </div>
                              </div>
                            </div>
                            <div className="frame-group">
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FB-" name="father-blood-type" value="B-" />
                                  <label htmlFor="FB-"> B-</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FAB+" name="father-blood-type" value="AB+" />
                                  <label htmlFor="FAB+">AB+</label>
                                </div>
                              </div>
                              <div className="o-wrapper">
                                <div className="o">
                                  <input type="radio" id="FAB-" name="father-blood-type" value="AB-" />
                                  <label htmlFor="FAB-">AB-</label>
                                </div>
                              </div>
                            </div>
                            <p>
                              <i
                                style={{ fontSize: "40px", cursor: "pointer" }}
                                className="fa-solid fa-arrow-right"
                                onClick={handleResult}></i>
                            </p>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                </div>
              </Card>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default MotherBlood;
