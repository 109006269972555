import React, { useState, useEffect } from "react";
import { Layout, Input, Form, Select, message } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import Preg from "../icon/Preg.svg";
import { useHistory } from "react-router-dom";
import Week from "../PregnancyCalculator/PregnancyWeek.json";
import { pregnancy_calculator } from "../../../../apis/btpTools";
const { Option } = Select;
const PregnancyCalc = () => {
  const [form] = Form.useForm();
  const [jsonData, setJsonData] = useState<any[]>([]);
  useEffect(() => {
    setJsonData(Week);
  }, []);
  const history = useHistory();
  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("formData") || "{}");
    if (Object.keys(savedFormData).length > 0) {
      form.setFieldsValue(savedFormData);
    }
  }, [form]);

  const handleFisnish = (values: any) => {
    let data = JSON.stringify({
      prePregnancyWeight: parseInt(values?.prePregnancyWeight),
      presentPregnancyWeight: parseInt(values?.presentPregnancyWeight),
      heightFt: parseInt(values?.heightFt),
      heightInch: parseInt(values?.heightInch),
      week: values?.week,
    });
    pregnancy_calculator(data)
      .then(res => {
        console.log(res.message);
        if (res && res.message === "true") {
          history.push({
            pathname: "/pregnancy-calculator-weight-result",
            state: {
              data: res,
              prePregnancyWeight: values?.prePregnancyWeight,
              presentPregnancyWeight: values?.presentPregnancyWeight,
            },
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
    localStorage.setItem("formData", JSON.stringify(values));
  };

  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-20">
          <Content
            className="container "
            style={{
              margin: "24px auto",
            }}>
            <div>
              <div className="preg-calc-card">
                <img alt="example" src={Preg} className="mb-10 preg-calc-img" />
                <Form layout="vertical" autoComplete="off" className="input-sty mt-10" onFinish={handleFisnish} form={form}>
                  <Form.Item
                    label="Week of Pregnancy"
                    name="week"
                    rules={[
                      {
                        required: true,
                        message: "Please select the week of pregnancy",
                      },
                    ]}>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      allowClear>
                      {jsonData.map((item: any) => (
                        <Option key={item.week} value={item.week}>
                          {item.week}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Your Pre Pregnancy Weight"
                    name="prePregnancyWeight"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the pre pregnancy weight",
                      },
                    ]}>
                    <Input placeholder="0 Kgs" />
                  </Form.Item>
                  <Form.Item
                    label="Your Weight Right Now"
                    name="presentPregnancyWeight"
                    rules={[
                      {
                        required: true,
                        message: "Please enter weight right now",
                      },
                    ]}>
                    <Input placeholder="0 Kgs" />
                  </Form.Item>

                  <Form.Item
                    label="Your Height"
                    style={{
                      marginBottom: 0,
                    }}>
                    <Form.Item
                      name="heightFt"
                      rules={[
                        {
                          required: true,
                          message: "Please enter height in Ft",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                      }}>
                      <Input placeholder="0 Feet" />
                    </Form.Item>
                    <Form.Item
                      name="heightInch"
                      rules={[
                        {
                          required: true,
                          message: "Please enter height in Inch",
                        },
                      ]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px",
                      }}>
                      <Input placeholder="0 Inch" />
                    </Form.Item>
                  </Form.Item>
                  <button type="submit">Calculate</button>
                </Form>
              </div>
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default PregnancyCalc;
