import React from "react";
import { Card, Form, Layout, Input, Button } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { changePassword } from "../../apis/btpViewProfile";
import Swal from "sweetalert2";

const ChangePassword = () => {
  const [form] = Form.useForm();
  // const passwordStrengthPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const passwordStrengthPattern = /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;



  const onFinish = (values: any) => {
    const data = {
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    changePassword(data)
      .then((res) => {
        console.log(res.status);
        console.log("res",res);
        
        Swal.fire({
          icon: "success",
          title: "Password changed successfully.",
          showConfirmButton: false,
          timer: 900,
        });
      })

      .catch((err) => {
        if (err.response) {
          console.log("Status:", err.response.data.status);
          console.log("Message:", err.response.data.message);
          Swal.fire({
            icon: "error",
            title:err.response.data.message=="Invalid password"?"Wrong current password":"",
            showConfirmButton: false,
            timer: 1000,
          });
          console.log("Raw Error:", err.response.data.rawError);
        } else {
          console.log("Error:", err.message);
        }
      });
      
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };
  return (
    <Layout>
      <Header
        className=""
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <Headersection />
      </Header>
      <Layout className="Content-body container mt-20" style={{ width: "50%" }}>
        <div className="bg-white mt-16">
          <p>Change Password</p>
          <br />
          <Card className="mt-10">
            <Form
              name="personal"
              form={form}
              layout="vertical"
              autoComplete="off"
              className="input-sty"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Current Password"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    pattern: passwordStrengthPattern,
                    message: 'Password must be of atleast length 8 and contain alpha-numeric characters',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                  {
                    pattern: passwordStrengthPattern,
                    message: 'Password must be of atleast length 8 and contain alpha-numeric characters',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Password do not match")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>

              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-sty float-right"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </Layout>
    </Layout>
  );
};

export default ChangePassword;
