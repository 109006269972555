import { TFetchingStatus } from "helper";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import {
  followUserInitAction,
  followUserSuccessAction,
  followUserErrorAction,
  TFollowUserActions,
} from "actions/follow";

export type TFollowUser = {
  fetchingStatus: TFetchingStatus;
  error: Error | null;
};

export const initialState: TFollowUser = {
  fetchingStatus: TFetchingStatus.Default,
  error: null,
};

export const followUserReducer = createReducer<TFollowUser, TFollowUserActions>(
  initialState
)
  .handleAction(followUserInitAction, (state) =>
    produce(state, (draftState) => {
      console.log("init", state, draftState);
      draftState.fetchingStatus = TFetchingStatus.Loading;
      draftState.error = null;
    })
  )
  .handleAction(followUserSuccessAction, (state, {payload}) =>
    produce(state, (draftState) => {
        console.log("success", state, payload);
        draftState.fetchingStatus = TFetchingStatus.Success
        draftState.error = null;
    })
  )
  .handleAction(followUserErrorAction, (state, {payload}) =>
  produce(state, (draftState) => {
    console.log("error", state, payload);
    draftState.fetchingStatus = TFetchingStatus.Failure;
    draftState.error = payload;
  })
  )
