import React, { Fragment, useState } from "react";
import { Modal, Avatar, Image } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { postMessageSend } from "apis/GetFeeds";
import { getMomApi } from "apis/GetFeeds";

interface ChildProps {
  isSendShow: boolean;
  setIsSendShow: (isOpen: boolean) => void;
  artcileData: any;
  type: string;
  setArticleData: any;
}

const SendModal: React.FC<ChildProps> = ({ setIsSendShow, isSendShow, artcileData, type, setArticleData }) => {

  const [activeKey, setActiveKey] = React.useState("followers");
  const [filter, setFilter] = useState("followers");
  const [searchValue, setSearchValue] = useState<any>("");
  const {
    data: momData,
    isLoading: loader,
    refetch,
  } = useQuery(["getLikesapi", filter, activeKey, searchValue], () => getMomApi({ type: filter, filter_type: activeKey }, searchValue));

  const handleCancel = () => {
    setIsSendShow(false);
    setArticleData({});
    setSentStatus([])
  };

  const [sentStatus, setSentStatus] = useState<boolean[]>([]);
  const handleClickSend = (index: any, item: any) => {
    setSentStatus(prevSentStatus => {
      const newSentStatus = [...prevSentStatus];
      newSentStatus[index] = true;
      return newSentStatus;
    });

    if (type == "blog") {
      let data = JSON.stringify({
        userId: item?.userId,
        messageText: artcileData?.title,
        mediaKey: artcileData?.thumbnailImage,
        type: "blog",
        url: artcileData?.permalink,
      });
      postMessageSend(data)
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    } else if (type == "userpost") {
      let data = JSON.stringify({
        userId: item?.userId,
        messageText: artcileData?.postDescription,
        mediaKey: artcileData?.userDetails?.profilePic,
        type: "user_post",
        url:
          artcileData?.userDetails?.profilePic +
          `?postCreatorUserId=` +
          artcileData?.userId +
          `&name=` +
          artcileData?.userDetails?.name +
          `&createdAt=` +
          artcileData?.createdDate +
          `&postId=` +
          artcileData?.id,
      });
      postMessageSend(data)
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    } else if (type == "group_post") {
      let data = JSON.stringify({
        userId: item?.userId,
        messageText: artcileData?.postDescription,
        mediaKey: artcileData?.userDetails?.profilePic,
        type: "user_post",
        url:
          artcileData?.profilePic +
          `?postCreatorUserId=` +
          artcileData?.userId +
          `&name=` +
          artcileData?.fullName +
          `&createdAt=` +
          artcileData?.createdDate +
          `&postId=` +
          artcileData?._id,
      });
      postMessageSend(data)
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    } else if (type == "save_blog") {
      let data = JSON.stringify({
        userId: item?.userId,
        messageText: artcileData?.title,
        mediaKey: artcileData?.thumbnailImage,
        type: "blog",
        url: artcileData?.permalink,
      });
      postMessageSend(data)
        .then(res => {})
        .catch(err => {
          console.log(err);
        });
    } else {
      let data = JSON.stringify({
        userId: item?.userId,
        messageText: artcileData?.postDescription,
        mediaKey: artcileData?.userDetails?.profilePic,
        type: "my_post",
        url:
          artcileData?.profilePic +
          `?postCreatorUserId=` +
          artcileData?.userId +
          `&name=` +
          artcileData?.fullName +
          `&createdAt=` +
          artcileData?.createdDate +
          `&postId=` +
          artcileData?._id,
      });
      postMessageSend(data)
        .then(res => {
          // console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
  return (
    <Modal title="Send this post to" open={isSendShow} maskClosable={false} closable={true} onCancel={handleCancel} footer={null}>
      {momData?.data.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            <div className="flex mt-2 mb-2" style={{ justifyContent: "space-between" }}>
              <div className="flex ">
                {item.profilePic !== "" ? (
                  <Image src={item.profilePic} preview={false} width={40} height={40} style={{ borderRadius: "50%" }} />
                ) : (
                  <Avatar
                    style={{
                      backgroundColor: "#87d068",
                    }}
                    icon={<UserOutlined />}
                  />
                )}
                <p className="mt-1 ml-2">{item.fullName}</p>
              </div>
              <div>
                {/* <button className="text-red-800" onClick={(e) => handleClickSend(e,  item)}>
                Send
              </button> */}

                <button
                    className={`text-${sentStatus[index] ? 'green' : 'red'}-800`}
                  onClick={() => handleClickSend(index, item)}
                  disabled={sentStatus[index]}>
                  {sentStatus[index] ? <span className="green-sent-btn">Sent</span> : "Send"}
                </button>
              </div>
            </div>
            <div className="line"></div>
          </Fragment>
        );
      })}
    </Modal>
  );
};

export default SendModal;
