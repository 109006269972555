import React, { useEffect, useState } from "react";
import { connectionRequestApi, connectionAcceptApi } from "apis/btpFallowUnfallow";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import MessageModal from "./MessageModal";
const Connect = (propsFollowsection: any) => {
  // console.log("propsFollowsection", propsFollowsection);
  // console.log("propsFollowsection?.fullName",propsFollowsection?.fullName);

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<any>(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleClickLike = async () => {
    setIsLoading(true);
    let userIdToSend = propsFollowsection?.userId || parseInt(propsFollowsection.userIdForConnect?.id);
    if (
      propsFollowsection.isConnection === "connectionAllowed" ||
      propsFollowsection?.canSendConnectionRequest?.status === "connectionAllowed"
    ) {
      let data = JSON.stringify({
        channel: "momnexus",
        connectedTo: userIdToSend,
        page: "feed",
        withdraw: false,
      });
      connectionRequestApi(data).then(res => {
        res.status && setStatus("request sent");
        propsFollowsection.getConnectionList();
        setIsLoading(false);
      });
    } else if (propsFollowsection.isConnection === "sent" || propsFollowsection?.canSendConnectionRequest?.status === "sent") {
      let data = JSON.stringify({
        channel: "momnexus",
        connectedTo: userIdToSend,
        page: "feed",
        withdraw: true,
      });
      connectionRequestApi(data).then(res => {
        propsFollowsection.getConnectionList();
        res.status && setStatus("connect");
        setIsLoading(false);
      });
    } else if (propsFollowsection.isConnection === "received" || propsFollowsection?.canSendConnectionRequest?.status === "received") {
      let data = JSON.stringify({
        channel: "momnexus",
        connectedTo: userIdToSend,
        page: "feed",
        withdraw: true,
      });
      connectionAcceptApi(data).then(res => {
        propsFollowsection.getConnectionList();
        res.status && setStatus("connect");
        setIsLoading(false);
      });
    } else {
      propsFollowsection.getConnectionList();
    }
  };



  useEffect(() => {
    if (
      propsFollowsection.isConnection === "connectionAllowed" ||
      propsFollowsection?.canSendConnectionRequest?.status === "connectionAllowed"
    ) {
      setStatus("connect");
    } else if (
      propsFollowsection.isConnection === "Request Sent" ||
      propsFollowsection?.canSendConnectionRequest?.status === "Request Sent"
    ) {
      setStatus("request sent");
    }
  }, [propsFollowsection]);

  const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />;

  const handleMessage = (e: any, value: any) => {
    // console.log(value);
    setShowMessage(true);
  };
  return (
    <>
      {showMessage && (
        <MessageModal
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          userID={propsFollowsection?.userId || parseInt(propsFollowsection.userIdForConnect?.id)}
          fullName={propsFollowsection?.fullName}
        />
      )}

      <div>
        <div className="mr-1 cursor-pointer" onClick={handleClickLike}>
          {(propsFollowsection.isConnection === "connectionAllowed" || propsFollowsection?.canSendConnectionRequest?.status === "connectionAllowed") && (
            <button className="border-2 rounded-md py-1 w-24" style={{ fontSize: "13px" }}>
              <span className="mr-2"> Connect</span>
              {isLoading && <Spin indicator={antIcon} size="small" />}
            </button>
          )}
          {(propsFollowsection.isConnection === "sent" || propsFollowsection?.canSendConnectionRequest?.status === "sent") && (
            <button className="border-2 rounded-md py-1 w-24" style={{ fontSize: "13px" }}>
              <span className="mr-2"> Request Sent</span>
            </button>
          )}
          {(propsFollowsection.isConnection === "received" || propsFollowsection?.canSendConnectionRequest?.status === "received") && (
            <button className="border-2 rounded-md py-1 w-24" style={{ fontSize: "13px" }}>
              <span className="mr-2" style={{ width: "100%", display: "flex" }}>
                Accept Request
              </span>
            </button>
          )}
          {(propsFollowsection.isConnection === "connected" ||
          propsFollowsection?.canSendConnectionRequest?.status === "connected"
          ) && (
            <button
              className="border-2 rounded-md py-1 w-24"
              style={{ fontSize: "13px" }}

              // onClick={e => {
              //   handleMessage(e, propsFollowsection?.userId || parseInt(propsFollowsection.userIdForConnect?.id));
              // }}
            >
              <span className="mr-2">Message</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Connect;
