import React, { useState } from "react";
import { Layout, Tabs, Tag, Space, Table, Radio } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import NameFilter from "./NameFilter";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams, Link } from "react-router-dom";
import Footer from "views/MainFooter/Footer";

const Name = () => {
  const [size, setSize] = useState<any>("large");
  const onChange = (e: any) => {
    setSize(e.target.value);
  };

  const [selectedTab, setSelectedTab] = useState<any>("1");
  const handleTabChange = (key: any) => {
    setSelectedTab(key);
  };

  const backButtonStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    cursor: "pointer",
    display: "flex", // Allows alignment of icon and text
    alignItems: "center", // Aligns icon and text vertically
  };

  const arrowIconStyle = {
    marginRight: "8px", // Adjust the space between icon and text
  };

  return (
    <Layout className="h-screen">
      <Header
        className=""
        style={{ position: "fixed", zIndex: 1, width: "100%" }}
      >
        <Headersection />
      </Header>
      <div className=" bg-white mt-20">
        <Content
          className=" container "
          style={{
            margin: "24px auto",
          }}
        >
          <Link to="/babynames">
            <p>
              <ArrowLeftOutlined style={arrowIconStyle} />
              Baby Names
            </p>
          </Link>
          <div className="Artical_card3 relative mt-3">
            <div>
              <Tabs
                type="card"
                size={size}
                defaultActiveKey="1"
                onChange={handleTabChange}
              >
                <Tabs.TabPane
                  tab={
                    <>
                      <h4>Boys</h4>
                    </>
                  }
                  key={"1"}
                >
                  <NameFilter type={selectedTab} />
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <>
                      <h4>Girls</h4>
                    </>
                  }
                  key={"2"}
                >
                  <div>
                    <NameFilter type={selectedTab} />
                  </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={
                    <>
                      <h4>Unisex</h4>
                    </>
                  }
                  key={"3"}
                >
                  <NameFilter type={selectedTab} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </div>
        </Content>
      </div>
      <Footer/>
    </Layout>
  );
};

export default Name;
