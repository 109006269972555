import { updateProfileApi,} from "apis/UpdateProfile";
import moment from "moment";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { authProfileUpdateAction, logInSuccessAction } from "./auth";
import { updateProfileUpdateAction } from "./viewprofile";


export type TUpdateProfileRequest_personal = {
  type: string;
  full_name:string,
  country: string;
  pincode: string;
  i_am: string;
  // file_image: string;
  profile_pic:string;
  user_bio:string;
  gender:string;
};


export type TUpdateCoverPhotoAction = {
  type: string;
  cover_photo:string;
}


export type FileUpload = {
  type: string;
  file: string,
  name: string,
  size: string,
  key: string,
};

export type TUpdateProfileRequest_contact = {
  type: string;
  contact_no: string;
  c_code: string;
};
export type TUpdateProfileRequest_parenting_new = {
  type: string;
  childdata: Array<TChild_Data_new>;
};

export type TUpdateProfileRequest_parenting_update = {
  type: string;
  children: Array<TChild_Data_update>;
};

export type TChild_Data_new = {
  child_name: string;
  dob: string;
  gender: string;
  nick_name: string;
};
export type TChild_Data_update = {
  sk_stage_id: string;
  child_name: string;
  dob: string;
  gender: string;
  nick_name: string;
  stage_of_parenting:string;
  stage_status:string
};

export type TUpdateProfileRequest_parenting_duedate = {
  // sk_stage_id: string;
  // due_date: string;
  // i_am_1: string;
  sk_user_id:number,
  due_date_parent:string
  stage_of_parenting:string
};




export type TUpdateProfileResponse = {
  status: boolean;
  message: string;
  data: any;
};

export const updateProfileInitAction = createAction(
  "users/UPDATEPROFILE_INIT"
)();
export const updateProfileSuccessAction = createAction(
  "users/UPDATEPROFILE_SUCCESS"
)<TUpdateProfileResponse>();
export const updateProfileFailureAction = createAction(
  "users/UPDATEPROFILE_FAILURE"
)<Error>();

export type TUpdateProfileInitAction = ActionType<
  typeof updateProfileInitAction
>;
export type TUpdateProfileSuccessAction = ActionType<
  typeof updateProfileSuccessAction
>;
export type TUpdateProfileFailureAction = ActionType<
  typeof updateProfileFailureAction
>;

export type TUpdateProfileActions =
  | TUpdateCoverPhotoAction
  | TUpdateProfileInitAction
  | TUpdateProfileSuccessAction
  | TUpdateProfileFailureAction;
export type TUpdateProfileThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TUpdateProfileActions
>;

type TUpdateProfileAction = (
  payload:
    | TUpdateProfileRequest_personal
    | TUpdateCoverPhotoAction
    | TUpdateProfileRequest_contact
    | TUpdateProfileRequest_parenting_new
    | TUpdateProfileRequest_parenting_update
    | TUpdateProfileRequest_parenting_duedate,
    
  token: any,
  callback: (res: string) => void
) => TUpdateProfileThunkActionType;

export const updateprofile: TUpdateProfileAction =
  (payload: any, token: any, callback: (res: any) => void) =>
    (
      dispatch: (
        action:
          | Action
          | Promise<Action>
          | ThunkAction<void, TRootState, null, TUpdateProfileActions>
      ) => any
    ) => {
      dispatch(updateProfileInitAction());
      if (payload) {
        if (payload.type == "personal") {
          let data: any = {
            full_name: payload?.full_name,
            country_id: payload?.country,
            pincode: payload?.pincode,
            i_am: payload?.i_am,
            user_bio:payload?.user_bio,
            mobile:payload?.mobile,
            gender:payload?.gender,
            
          }
          if (payload.file_image) {
            data.profile_pic = payload.file_image;
          }
          dispatch(
            updateProfileUpdateAction(data)
          );
          dispatch(
            authProfileUpdateAction({
              full_name: payload?.full_name,
              country_id: payload?.country,
              pincode: payload?.pincode,
              i_am: payload?.i_am,
            })
          );
        } else if (payload.type == "parenting") {
          console.log('payload', payload);
          
          dispatch(
            updateProfileUpdateAction({
              child_info: payload?.children,
            })
          );
        } else if (payload.type == "parenting_due_date") {
          dispatch(
            updateProfileUpdateAction({
              due_date_parent: moment(payload.due_date).format('DD-MM-YYYY'),
              i_am: payload?.i_am,
            })
          );
        } else if (payload.type == "contact") {
          // console.log(payload, "<======");
          dispatch(
            updateProfileUpdateAction({
              mobile: payload?.c_code,
            })
          );
        }
      }
      // if(payload){
      //   console.log(payload,"<contact>======")
      //   if(payload.type=="contact"){
      //     dispatch(updateProfileUpdateAction({
      //       email:payload?.email,
      //       mobile:payload?.mobile,
      //     }))
      //     dispatch(authProfileUpdateAction({
      //       email:payload?.email,
      //     }))
      //   }
      // }

      return updateProfileApi(payload, token)
        .then(async (res) => {
          if (res.status) {
            // console.log("updateprofileResp", res, res.data);
            const resp = await res
            dispatch(updateProfileSuccessAction(res.data));
            callback({ resp, ...payload });
          } else {
            dispatch(
              updateProfileFailureAction({ message: res.message } as Error)
            );
          }
        })
        .catch(async (err) => { });
    };
