import partner_image from "../../images/login_partner.webp";
import { useEffect } from "react";
import { environmentConfig } from "../../config";
import { useHistory } from "react-router-dom";
import { ACCESS_TOKEN_LS_KEY } from "libs/Constants";
import { internalLogIn } from '../../apis/internalUser';
const InternalLogin = () => {
  const history = useHistory();
  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    const exchangeToken = searchParams.get('exchangeToken');
    if(exchangeToken){
      (async () => {
        let data: any = await internalLogIn(exchangeToken);
        localStorage.setItem(ACCESS_TOKEN_LS_KEY, JSON.stringify({Accesstoken: data.token}));
        history.push("/");
      })();
    }
    else {
      window.location.replace(environmentConfig.BLOG_URL + '/signin');
    }
  });

  return (
    <div style={{ backgroundColor: "rgba(241, 241, 241, 1)" }}>
      <div className="my-2.5">
        <img className="w-32 m-auto" src={partner_image} />
      </div>
  </div>
  );
};

export default InternalLogin;
