import { Button } from "antd";
import React, { useState,  useEffect} from "react";
import { UserOutlined, EditOutlined} from "@ant-design/icons";
import { Layout, notification, Upload } from "antd";
import { Tabs } from "antd";
import PersonalDetails from "./PersonalDetails";
import PregnancyInfo from "./PregnancyInfo";
import { Link, useRouteMatch } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import Headersection from "views/MainHeader/Header";
import ParentingInfo from "./ParentingInfo";
import ImgCrop from "antd-img-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import { mediaUpload } from "apis/UpdateProfile";
import { TRootState } from "reducers";
import { TUpdateProfileRequest_personal, updateprofile} from "actions/updateprofile";
import { viewProfileApi } from "../../apis/btpViewProfile";
import { useQuery } from "react-query";
import "cropperjs/dist/cropper.css";
import type { RcFile, UploadFile,} from "antd/es/upload/interface";
const { TabPane } = Tabs;
type TabPosition = "left" | "right" | "top" | "bottom";
const { Header, Content } = Layout;
import Swal from "sweetalert2";

const EditProfile = (props: any) => {
  const { isLoading, data, refetch } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));
  const profileData = data;
  const dispatch = useDispatch();
  const [tabPosition, setTabPosition] = useState<TabPosition>("left");
  const [tabkeyval, setTabkeyval] = useState("edit");
  const [subtabkeyval, setSubtabkeyval] = useState("");
  const router: any = useRouteMatch();
  const [isLoadin, setIsLoading] = useState(false);

  const onChange = (key: string) => {
    setSubtabkeyval(key);
    console.log("key", key);
  };

  useEffect(() => {
    if (Object.keys(router.params).length > 0) {
      if (typeof router.params["slug1"] !== "undefined") {
        setTabkeyval(router.params["slug1"]);
      }
      if (router.params["slug1"] !== "undefined" && router.params["slug2"] !== "undefined") {
        setSubtabkeyval(router.params["slug2"]);
      }
    }
  }, [router]);

  useEffect(() => {
    if (profileData?.i_am == "3") {
      setSubtabkeyval("parentInformation");
    } else if (profileData?.i_am == "2") {
      setSubtabkeyval("pregrancyInformation");
    } else if (profileData?.i_am == "5" || profileData?.i_am == "4" ) {
      setSubtabkeyval("parentInformation");
    } else {
      setSubtabkeyval("personalInformation");
    }
  }, [profileData]);

  const handleTabClick = (key: any) => {
    setTabkeyval(key);
  };
  const authinfo = useSelector((state: TRootState) => state.auth);
  const [selectedFileName, setSelectedFileName] = useState<any>("");
  const handleCallback = (res: any) => {
    console.log("Received values of form: ", res);
  };

  const handleChangeImageGroup = (e: any) => {
    setSelectedFileName(e.file.originFileObj);
  };


  const handleUpload = () => {
    mediaUpload(selectedFileName)
      .then((res: any) => {
        dispatch(
          updateprofile(
            {
              type: "personal",
              sk_user_id: profileData?.sk_user_id,
              full_name: profileData?.full_name,
              country: profileData?.c_code,
              pincode: profileData?.pincode,
              i_am: profileData?.i_am,
              profile_pic: res.imageUrl,
              user_bio: profileData?.user_bio,
              gender: profileData?.gender,
            } as TUpdateProfileRequest_personal,
            authinfo.Accesstoken,
            updateProfileRes => {
              handleCallback(updateProfileRes);
              refetch();
              notification.success({
                message: "uploaded",
                description: "Your new profile photo was uploaded successfully.",
              });
            }
          )
        );
        refetch();
        Swal.fire({
          icon: "success",
          title: "Profile Image Updated Successfully",
          showConfirmButton: false,
          timer: 900,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className="Container">
      <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Headersection />
      </Header>
      <div className="container">
        <div className="edit-head">
          <p>Edit Profile</p>
          <div>
            <div className="view_myprofile">
              <Button danger className="button-resize">
                <Link className=" flex" to={{ pathname: "/viewprofile" }}>
                  <UserOutlined className="site-form-item-icon mt-1" />
                  <p className="!text-sm text-red-500">View My Profile</p>
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <div>
          <div className="Content-body">
            <Content className="menu-bar">
              <Tabs tabPosition={tabPosition} activeKey={tabkeyval} onChange={handleTabClick} className="main-tab">
                <TabPane
                  tab={
                    <>
                      <div className="flex items-center">
                        <EditOutlined />
                        <div>Edit</div>
                      </div>
                    </>
                  }
                  key="edit">
                  <div className="tab-sty">
                    <Tabs activeKey={subtabkeyval} onChange={onChange}>
                      <TabPane tab="Personal Details" key="personalInformation">
                        <PersonalDetails />
                      </TabPane>

                      <TabPane tab="Parenting Information" key="parentInformation">
                        <ParentingInfo />
                      </TabPane>
                      <TabPane tab="Pregnancy Information" key="pregrancyInformation">
                        <PregnancyInfo />
                      </TabPane>
                    </Tabs>
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <>
                      <div className="flex items-center">
                        <FaRegUserCircle className="mr-3" />
                        <div>Profile Photo</div>
                      </div>
                    </>
                  }
                  key="profilePhoto">
                  <div className="tab-sty2">
                    <h6>Change Profile Photo</h6>
                    <Tabs type="card">
                      <TabPane tab="Upload" key="8">
                        <>
                          <div>
                            <ImgCrop cropShape="rect" aspect={1 / 1}>
                              <Upload
                                className="hide-delete-button"
                                listType="picture-card"
                                onChange={handleChangeImageGroup}
                                onPreview={onPreview}
                                multiple={false}>
                                {selectedFileName.length === 0 && "+ Upload"}
                              </Upload>
                            </ImgCrop>
                            {selectedFileName.length !== 0 && (
                              <Button onClick={handleUpload} disabled={isLoadin}>
                                {isLoadin ? "Uploading..." : "Upload"}
                              </Button>
                            )}
                          </div>
                        </>
                      </TabPane>
                    </Tabs>
                  </div>
                </TabPane>
              </Tabs>
            </Content>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProfile;
