import { motion } from "framer-motion";
import { ReactNode } from "react";

type AuthLayoutType = {
  children: ReactNode;
};

const AuthLayout = ({ children }: AuthLayoutType) => {
  return (
    <section className="min-h-screen flex">
      <motion.div
        initial={{ opacity: 0, scale: 0.85, x: -10 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{
          default: { duration: 0.3 },
        }}
        className="m-auto px-11 py-14 bg-white rounded-lg w-96 max-w-sm"
      >
        <div className="mt-3">{children}</div>
      </motion.div>
    </section>
  );
};

export default AuthLayout;
