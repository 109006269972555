import React, { useState, useEffect } from "react";
import { recentTimeApi } from "apis/Timeline";
import {
  Avatar,
  Image,
  Comment,
  Tooltip,
  Space,
  Card,
  Select,
  Button,
  Col,
  Row,
  Empty,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import {
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
  SmileOutlined,
  CloseCircleOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { createElement } from "react";
import Post from "components/modal/Post";
import {
  AiOutlineCamera,
  AiOutlineGif,
  AiOutlineVideoCamera,
} from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import Picker from "emoji-picker-react";
import TimelinePost from "./TimelinePost";
import Chatbox from "components/global/Chatbox";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import Likedsection from "components/global/postlist/Likedsection";
import Savedsection from "components/global/postlist/Savedsection";
import { TPostDetails } from "actions/getfeed";

const { Dragger } = Upload;
const { Option } = Select;
const handleChange = (value: string) => {
  // console.log(`selected ${value}`);
};
type Props = {
  isActive: boolean;
  action: () => void;
  data: any;
  setpost?:any;
};
const props: UploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      // console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    // console.log("Dropped files", e.dataTransfer.files);
  },
};

const Timeline = () => {
  const [tymLIne, setTymLIne] = useState<any>([]);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [action, setAction] = useState<string | null>(null);
  const [show, setShow] = useState(false);
  const [showResults, setShowResults] = React.useState(false);
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showPicker, setShowPicker] = useState(false);
  const { Accesstoken } = useSelector((state: TRootState) => state.auth);
  const [post, setPost]=useState<any>([]);
  

  useEffect(() => {
    recentTimeApi(Accesstoken).then((res) => setTymLIne(res.data));
  }, []);
  // console.log("timelineData", tymLIne);

  const onEmojiClick = (event: any, emojiObject: any) => {
    setChosenEmoji(emojiObject);
  };
  const onClick = () => setShowResults(true);

  const onClick1 = () => setShowResults(false);

  const like = () => {
    setLikes(1);
    setDislikes(0);
    setAction("liked");
  };

  const dislike = () => {
    setLikes(0);
    setDislikes(1);
    setAction("disliked");
  };

  const actions = [
    <Tooltip key="comment-basic-like" title="Like">
      <span onClick={like}>
        {createElement(action === "liked" ? LikeFilled : LikeOutlined)}
        <span className="comment-action">{likes}</span>
      </span>
    </Tooltip>,
    <Tooltip key="comment-basic-dislike" title="Dislike">
      <span onClick={dislike}>
        {React.createElement(
          action === "disliked" ? DislikeFilled : DislikeOutlined
        )}
        <span className="comment-action">{dislikes}</span>
      </span>
    </Tooltip>,
    <span key="comment-basic-reply-to">Comment</span>,
  ];

  return (
    <>
      <Content className="table_content2 border rounded">
        <Post setpost={setPost}/>
      </Content>
      
      {tymLIne?.posts?.map((item: any, index: number) => (
        <div className="mb-5">
          <Card  title={
            <>
              <div className="flex">
                <div className="mr-3">
                  <img src={tymLIne.profile_pic} alt="" style={{width:"40px",height:"40px",objectFit:"cover",borderRadius:"50%"}} />
                </div>
                <div>
                  <h2 className="mb-0"> {tymLIne.full_name}</h2>
                  <span className="text-xs">{item.created_date_time_post}</span>
                </div>
              </div>
            </>
          } extra={<a href="#">More</a>} >
            <div>
              {item.media.length > 0 ? (
                item.media?.map((el: any) => (
                  <div>
                    {item.post_description !== "undefined" ? (
                      <p className="pb-5">{item?.post_description}</p>
                    ) : (
                      ""
                    )}
                    <Image src={el.attchment_name} alt=""  style={{width:"100%",height:"450px",objectFit:"cover"}}/>
                  </div>
                ))
              ) : (
                <Empty />
              )}
            </div>
            <div className="Card_footer p-4">
              <div className="flex justify-between items-end">
                <div className="flex items-end">
                  <Likedsection />
                  {/* <div className="mr-5">
                    <img src={Send} alt="" />
                  </div>
                  <div className="mr-5">
                    <img src={Share} alt="" />
                  </div> */}
                </div>
                <div>
                  <Savedsection  />
                </div>
              </div>
            </div>
          </Card>
          {/* <Content className="table_content">
            <Comment
              // actions={actions}
              author={
                <a>
                  
                </a>
              }
              content={
                <div>
                  {item.media.length > 0 ? (
                    item.media?.map((el: any) => (
                      <div>
                        {item.post_description !== "undefined" ? (
                          <p>{item?.post_description}</p>
                        ) : (
                          ""
                        )}
                        <img src={el.attchment_name} alt="" />
                      </div>
                    ))
                  ) : (
                    <Empty />
                  )}
                </div>
              }
            />
          </Content> */}
        </div>
      ))}
    </>
  );
};

export default Timeline;
