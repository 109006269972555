import { apiGet, apiPost,apiDelete, getDefaultAxiosConfig } from "./btpApi";
import { getAccessToken } from "actions/auth";
export type TLikeUnlikeResponse = {
  status: boolean;
  message: string;
  data: any;
};

export type TConnectResponse = {
  status: boolean;
  message: string;
  data: any;
};

export type TMomMessage = {
  createdAt: string;
  id: string;
  isGroup: false;
  updatedAt: string;
};

export type TFetchMomMessage = {
  chatroomId: string;
  isRead: boolean;
  mediaKey: string;
  senderId: number;
  sentOn: string;
  textContent: string;
  type: string;
  _id: string;
};

export type TConnectAccept = {
  status: boolean;
  message: string;
  data: any;
};

export type TConnectReject = {
  status: boolean;
  message: string;
  data: any;
};

export type TFetchChatRoom = {
  chatrooms: [
    {
      _id: string;
      isGroup: boolean;
      createdAt: string;
      updatedAt: string;
    }
  ];
  latestMessages: [
    {
      _id: string;
      message: {
        _id: string;
        type: string;
        textContent: string;
        mediaKey: string;
        chatroomId: string;
        senderId: number;
        sentOn: string;
        isRead: boolean;
      };
    }
  ];
  participants: [
    {
      addedAt: string;
      chatroomId: string;
      userId: string;
      _id: string;
    }
  ];
  usersData: [
    {
      email: string;
      full_name: string;
      gender: string;
      mobile: string;
      profile_pic: string;
      profile_pic_key: string;
      sk_user_id: number;
      username_momnexus: string;
    }
  ];
  unreadMessages: [
    {
      _id: string;
      count: number;
    }
  ];
};

export const followingbutton = async (requestee_id: any) => {
  const url = "/api/followers/v1/follow";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {}>(url, requestee_id, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const Unfollowingbutton = async (requestee_id: any) => {
  const url = "/api/followers/v1/unfollow";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {}>(url, requestee_id, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const connectionRequestApi = async (requestee_id: any) => {
  const url = "/api/connections/v1/add-connection";
  let newconfig = getDefaultAxiosConfig();
  const head = {
    ...newconfig.headers,
    accesstoken: getAccessToken(),
    "Content-Type": "application/json",
  };
  newconfig.headers = { ...head };
  return apiPost<TConnectResponse, {}>(url, requestee_id, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const connectionAcceptApi = async (connection_id: any) => {
  const url = "/api/connections/v1/accept-connection";
  let newconfig = getDefaultAxiosConfig();
  const head = {
    ...newconfig.headers,
    accesstoken: getAccessToken(),
    "Content-Type": "application/json",
  };
  newconfig.headers = { ...head };
  return apiPost<TConnectAccept, {}>(url, connection_id, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const fetchMomsMessage = async (userid: any) => {
  const url = process.env.REACT_APP_CHATROOMS + `/api/messages/v2/?id=${userid}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TFetchMomMessage>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const chatRoomsInitiate = async (requestee_id: any) => {
  const url = process.env.REACT_APP_CHATROOMS + "/api/chatroom/v1/initiate";
  let newconfig = getDefaultAxiosConfig();
  const head = {
    ...newconfig.headers,
    accesstoken: getAccessToken(),
    "Content-Type": "application/json",
  };
  newconfig.headers = { ...head };
  return apiPost<TMomMessage, {}>(url, requestee_id, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getIndividualChatMessage = async (userid: any) => {
  const url = process.env.REACT_APP_CHATROOMS + `/api/messages/v2/?id=${userid}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TFetchMomMessage>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getChatRoom = async () => {
  const url = process.env.REACT_APP_CHATROOMS + `/api/chatroom/v2/`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TFetchChatRoom>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};


export const deleteIndividualMessage = async (id: any) => {
  const url = process.env.REACT_APP_CHATROOMS + `/api/messages/v1/?id=${id}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiDelete<TFetchChatRoom>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};



export const clearChatIndividual = async (id: any) => {
  const url = process.env.REACT_APP_CHATROOMS + `/api/messages/v1/clear-messages?chatroomId=${id}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiDelete<TFetchChatRoom>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};