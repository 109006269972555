import { Button, RadioChangeEvent } from 'antd';
import { Input, Radio, Space } from 'antd';
import React, { useState } from 'react';

const options = [
    {
      label: "",
      value: "Apple"
    },
    {
      label: "",
      value: "Pear"
    }
  ];
  

function EmailPreferences() {

  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    // console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const [value1, setValue1] = useState("Apple");
  const [value2, setValue2] = useState("Apple");
  const [value3, setValue3] = useState("Apple");
  const [value4, setValue4] = useState("Apple");
  const [value5, setValue5] = useState("Apple");
  const [value6, setValue6] = useState("Apple");
  const [value7, setValue7] = useState("Apple");
  const [value8, setValue8] = useState("Apple");
  const [value9, setValue9] = useState("Apple");
  const [value10, setValue10] = useState("Apple");
  const [value11, setValue11] = useState("Apple");
  const [value12, setValue12] = useState("Apple");
  const [value13, setValue13] = useState("Apple");

  const onChange1 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue1(e.target.value);
  };

  const onChange2 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue2(e.target.value);
  };

  const onChange3 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue3(e.target.value);
  };

  const onChange4 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue4(e.target.value);
  };

  const onChange5 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue5(e.target.value);
  };

  const onChange6 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue6(e.target.value);
  };

  const onChange7 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue7(e.target.value);
  };

  const onChange8 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue8(e.target.value);
  };

  const onChange9 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue9(e.target.value);
  };

  const onChange10 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue10(e.target.value);
  };

  const onChange11 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue11(e.target.value);
  };

  const onChange12 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue12(e.target.value);
  };

  const onChange13 = (e: any) => {
    // console.log('radio checked', e.target.value);
    setValue13(e.target.value);
  };


  return (
    <div className='p-4 lg:py-10 lg:px-20 bg-white email_radio'>
        <h3 className='text-xl font-light mb-7'>Email Preferences</h3>
        <p className='border-b-2 pb-5'>Choose your email notification preferences.</p>
        <div className='flex py-7 justify-between'>
            <p className=''>Activity Feed</p>
            <div className='flex mr-7'>
                <p className='mr-4'>Yes</p>
                <p>NO</p>
            </div>
        </div>
        <div className='flex justify-between'>
            <p className='email_paragraph'>A member mentions you in an update using "@anuja"</p>
            <div className='  lg:pl-7'>
                <Radio.Group options={options} id="red" onChange={onChange1} value={value1} />
            </div>
        </div>
        <div className='flex justify-between py-7 border-b-2 pb-5'>
            <p className='email_paragraph'>A member replies to an update or comment you've posted</p>
            <div>
                <Radio.Group options={options} onChange={onChange2} value={value2} />
            </div>
        </div>
        <div className='flex py-4 justify-between'>
            <p className=''>MESSAGES</p>
            <div className='flex mr-7'>
                <p className='mr-4'>Yes</p>
                <p>NO</p>
            </div>
        </div>
        <div className='flex justify-between  border-b-2 pb-5'>
            <p className='email_paragraph'>A member replies to an update or comment you've posted</p>
            <div>
                <Radio.Group options={options} onChange={onChange3} value={value3} />
            </div>
        </div>
        <div className='flex py-4 justify-between'>
            <p className=''>Social Groups</p>
            <div className='flex mr-7'>
                <p className='mr-4'>Yes</p>
                <p>NO</p>
            </div>
        </div>
        <div className='flex justify-between py-5'>
            <p className='email_paragraph'>  A member invites you to join a group</p>
            <div>
                <Radio.Group options={options} onChange={onChange4} value={value4} />
            </div>
        </div>
        <div className='flex justify-between py-5'>
            <p>Group information is updated</p>
            <div>
                <Radio.Group options={options} onChange={onChange5} value={value5} />
            </div>
        </div>
        <div className='flex justify-between py-5'>
            <p className='email_paragraph'>You are promoted to a group organizer or moderator</p>
            <div>
                <Radio.Group options={options} onChange={onChange6} value={value6} />
            </div>
        </div>
        <div className='flex justify-between py-5'>
            <p className='email_paragraph'>A member requests to join a private group you organize</p>
            <div>
                <Radio.Group options={options} onChange={onChange7} value={value7} />
            </div>
        </div>
        <div className='flex justify-between py-5'>
            <p className='email_paragraph'>Your request to join a group has been approved or denied</p>
            <div>
                <Radio.Group options={options} onChange={onChange8} value={value8} />
            </div>
        </div>
        <div className='flex justify-between  border-b-2 py-5'>
            <p>A group sends you a new message</p>
            <div>
                <Radio.Group options={options} onChange={onChange9} value={value9} />
            </div>
        </div>
        <div className='flex py-7 justify-between'>
            <p className=''>Forums</p>
            <div className='flex mr-7'>
                <p className='mr-4'>Yes</p>
                <p>NO</p>
            </div>
        </div>
        <div className='flex justify-between'>
            <p className='email_paragraph'>A member replies to a discussion you are subscribed</p>
            <div>
                <Radio.Group options={options} onChange={onChange10} value={value10} />
            </div>
        </div>
        <div className='flex justify-between py-7 border-b-2 pb-5'>
            <p className='email_paragraph'>A member creates discussion in a forum you are subscribed</p>
            <div>
                <Radio.Group options={options} onChange={onChange11} value={value11} />
            </div>
        </div>
        <div className='flex py-7 justify-between'>
            <p className=''>Connections</p>
            <div className='flex mr-7'>
                <p className='mr-4'>Yes</p>
                <p>NO</p>
            </div>
        </div>
        <div className='flex justify-between'>
            <p>A member invites you to connect</p>
            <div>
                <Radio.Group options={options} onChange={onChange12} value={value12} />
            </div>
        </div>
        <div className='flex justify-between py-7 border-b-2 pb-5'>
            <p className='email_paragraph'>A member accepts your connection request</p>
            <div>
            <div>
                <Radio.Group options={options} onChange={onChange13} value={value13} />
            </div>
            </div>
        </div>
        <Button htmlType="submit" className='mt-10' style={{backgroundColor:"#ff5a5f",borderRadius:"50px",color:"#fff"}}>
              Save Changes
        </Button>
    </div>
  )
}

export default EmailPreferences