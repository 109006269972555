import { apiGet, apiPost, getDefaultAxiosConfig } from "./btpApi";
import {
  TUserDetailRequest,
  TUserDetailResponse,
  TGetConnectionRes,
  TUserDetailConnection,
  TCoonectionReq,
  TProfileDetailRequest,
  TUserProfile,
} from "actions/viewprofile";
import { getAccessToken } from "actions/auth";
// import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";

export type TGlobalSearch = {
  posts: [
    {
      _id: string;
      userId: string;
      postTitle: string;
      postDescription: string;
      postPrivacy: string;
      postMedia: [
        {
          mediaType:string,
          mediaKey: string,
          mediaSize: string,
          contentType:string,
          _id:string,
          createdDate:string,
          updatedDate:string
      }
      ];
      postType: string;
      totalLikes: number;
      likedBy: [];
      totalComments: number;
      totalSavedPosts: number;
      savedBy: [];
      createdDate: string;
      updatedDate: string;
      importance: number;
      postPriority: number;
      __v: number;
      fullName: number;
      profilePic: number;
      userStatus: number;
      badge: string;
      education: string;
      likeStatus: number;
      savedPostStatus: number;
      comments: [
        {
          _id: string;
          userId: string;
          postId: string;
          userComment: string;
          updatedDate: string;
          importance: number;
          createdDate: string;
          __v: number;
          full_name: string;
          profile_pic: string;
          userStatus: number;
          badge: string;
          education: string;
        }
      ];
    }
  ],
  articles:[
    {
      status: boolean,
      message:string,
      data: [
        {
            page_no: number,
            thumbnail_image:string,
            Title:string,
            permalink:string,
            content:string,
            search_type:string
        },
      ]
    }
  ],
  groups: [
    {
        _id:string,
        numberOfPosts:number,
        groupName:string,
        groupImage:string,
        imageUrl:string,
        description: string,
        type:string,
        isSearchable:boolean,
        focus:string,
        groupStatus:string,
        invite:string,
        activityFeed:string,
        isModerated:boolean,
        groupRule:string,
        membersCount: number,
        createdBy: number,
        lastUpdated:string,
        createdAt:string
    }
  ],
  users: [
    {
        weekdiff: number,
        userId:number,
        fullName:string,
        profilePic: string,
        gender: string,
        stageName:string,
        connectionId: string,
        blocked: boolean,
        canSendConnectionRequest: boolean,
        mutualConnections: number,
        following: boolean,
        isConnection: string,
        canSeeConnections: boolean
    }]
};

// Check
export const viewProfileApi = async (token: any) => {
  let accesstoken = getAccessToken();
  const url = "/api/users/v1/";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiGet<TUserProfile>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

// Chnage password Api
export const changePassword = async (req: any) => {
  const url = "/api/users/v1/change-password";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TUserDetailConnection, TCoonectionReq>(url, req, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const profileDetailsApi = async (req: TProfileDetailRequest) => {
  const url = "/Connection/user_profile_details";
  let accesstoken = getAccessToken();
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiPost<TUserDetailResponse, TProfileDetailRequest>(url, req, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const parentConnectionDetail = async (req: TCoonectionReq) => {
  const url = "/Momnexus/get_connections";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TUserDetailConnection, TCoonectionReq>(url, req, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getConnectionReq = async (token: any) => {
  const url = "/Connection/get_pending_requests";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getviewprofileConnectionReq = async (token: any) => {
  const url = "Connection/getMyConnections";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getviewprofileFollowingReq = async (token: any) => {
  const url = "Account_settings/following_list";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};
export const getviewprofileFollowersReq = async (token: any) => {
  const url = "Account_settings/my_followers";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<TGetConnectionRes>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const getcountrylistReq = async (
  sk_country_id: string,
  country_status: string
) => {
  const url = "Bptglobalgeo/global-settings-geo-country-view";
  let accessToken = getAccessToken();
  console.log("bwbkjwkguwkjdwkjd" + accessToken);

  let newconfig = getDefaultAxiosConfig();
  const head = {
    ...newconfig.headers,
    accesstoken: accessToken,
  };
  newconfig.headers = { ...head };
  return apiPost<
    TGetConnectionRes,
    { sk_country_id: string; country_status: string }
  >(
    url,
    { sk_country_id: sk_country_id, country_status: country_status },
    {
      ...newconfig,
      skipAuthRefresh: true,
    }
  );
};

export const globalSearch = async (search: any) => {
  console.log("wwwrwrr", search);
  const url =`/api/search/v1/global?q=${search}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TGlobalSearch>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};
