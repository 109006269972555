import React, { useState } from "react";
import { Layout, Card, Image } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "react-circular-progressbar/dist/styles.css";
import Chart from "react-apexcharts";
import EyePic from "../icon/EyePic.jpeg";
import Footer from "views/MainFooter/Footer";

const EyeResult = () => {
  const location = useLocation();
  const res = (location.state as any)?.data;
  const history = useHistory();

  const hndleClickHome = () => {
    history.push("/baby-eye");
  };
  const chancesValues = res && res?.possibleEyeColor?.map((item: any) => parseFloat(item?.chances));
  const chancesAndColor =
    res &&
    res?.possibleEyeColor?.map((item: any) => ({
      color: item?.color,
      chances: parseFloat(item?.chances),
    }));
  const labels = chancesAndColor?.map((item: any) => `${item?.color} ${item?.chances}%`);
  const chancesColor = res?.possibleEyeColor?.map((item: any) => item?.color);
  const getColorCode = (colorName: any) => {
    const lowercaseColor = colorName?.toLowerCase();
    switch (lowercaseColor) {
      case "brown":
        return "#603101";
      case "green":
        return "#337a2c";
      case "blue":
        return "#A1CAF1";
      default:
        return "#000000";
    }
  };

  const colorCodes = chancesColor?.map((colorName: any) => getColorCode(colorName)) || [];
  const [chartData, setChartData] = useState<any>({
    series: chancesValues,
    options: {
      chart: {
        type: "donut",
      },
      labels: labels,
      colors: colorCodes,
      plotOptions: {
        pie: {
          customScale: 0.8,
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
            enabled: true,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  });

  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-15">
          <Content className="container blood-type-preg">
            <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
              <Card style={{ border: "none" }}>
                <div className="preg-calc-card-res">
                  <div className="rectangle-parent-child">
                    <div className="frame-child-blood"></div>
                    <div className="line-icons-parent">
                      <ArrowLeftOutlined className="line-icons" />
                      <div className="pregnancy-weight-gain">
                        <button onClick={hndleClickHome}>Child Eye Colour</button>
                      </div>
                    </div>

                    <div className="bloodType-image">
                      <div className="result-parent-hcg">
                        <Image alt="" src={EyePic} width={250} height={250} className="child-blood-img" preview={false} />
                      </div>
                    </div>
                  </div>

                  <div className="flex" style={{ width: "100%" }}>
                    <div className="group-wrapper-faq-ques" style={{ height: "auto", textAlign: "center" }}>
                      <div>
                        <div style={{ marginLeft: "10%" }}>
                          <Card className="predicted-child-eye-card">
                            <h1>Predicted Child Eye Colour</h1>
                            <Chart options={chartData.options} series={chartData.series} type="donut" />
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </Content>
        </div>
        <Footer />
      </Layout>
    </>
  );
};

export default EyeResult;
