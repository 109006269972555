import { apiGet, apiPost, getDefaultAxiosConfig } from "./btpApi";
import { getAccessToken } from "actions/auth";
export type TDataGod = [
  {
    name: string;
    imageUrl: string;
  }
];

export type TFindAll = {
  matchedNames: [
    {
      _id: string;
      name: string;
      meaning: string;
      type: string;
      numerology: number;
      religion: string;
      rashi: string;
      nakshatra: string;
      fav: boolean;
    }
  ];
  length: number;
};

export type TGetFav = {
  message: string;
  status: number;
  matchedData: [
    {
      _id: string;
      name: string;
      meaning: string;
      type: string;
      numerology: number;
      religion: string;
      rashi: string;
      nakshatra: string;
      fav: boolean;
    }
  ];
};



export type TToolsDetailsData = {
  status:true;
  message:string;
  data:{
    tools:[
      {
        _id:string;
        name:string;
        link:string;
        slug:string;
        icon:string
      }
    ]
  }
}

export const godList = async (name: any) => {
  // console.log("name", name);
  if (name == "gods") {
    let accesstoken = getAccessToken();
    const url = process.env.REACT_APP_BABYNAME + `find-list/${name}`;
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: accesstoken };
    newconfig.headers = { ...head };
    return apiGet<TDataGod>(url, {
      ...newconfig,
      skipAuthRefresh: true,
    });
  } else if (name == "rashi") {
    let accesstoken = getAccessToken();
    const url = process.env.REACT_APP_BABYNAME + `find-list/${name}`;
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: accesstoken };
    newconfig.headers = { ...head };
    return apiGet<TDataGod>(url, {
      ...newconfig,
      skipAuthRefresh: true,
    });
  } else if (name == "nakshatra") {
    let accesstoken = getAccessToken();
    const url = process.env.REACT_APP_BABYNAME + `find-list/${name}`;
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: accesstoken };
    newconfig.headers = { ...head };
    return apiGet<TDataGod>(url, {
      ...newconfig,
      skipAuthRefresh: true,
    });
  } else if (name == "numerology") {
    let accesstoken = getAccessToken();
    const url = process.env.REACT_APP_BABYNAME + `find-list/${name}`;
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: accesstoken };
    newconfig.headers = { ...head };
    return apiGet<TDataGod>(url, {
      ...newconfig,
      skipAuthRefresh: true,
    });
  }else if(name=="religion"){
    let accesstoken = getAccessToken();
    const url = process.env.REACT_APP_BABYNAME + `find-list/${name}`;
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: accesstoken };
    newconfig.headers = { ...head };
    return apiGet<TDataGod>(url, {
      ...newconfig,
      skipAuthRefresh: true,
    });
  }
};

export const findAllName = async (data: string) => {
  const url = process.env.REACT_APP_BABYNAME + "find-all-name";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TFindAll, {}>(url, data, { ...newconfig, skipAuthRefresh: true });
};

export const godName = async (data: string) => {
  const url = process.env.REACT_APP_BABYNAME + "find-god";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TFindAll, {}>(url, data, { ...newconfig, skipAuthRefresh: true });
};

export const serachNameMeaning = async (data: string) => {
  let accesstoken = getAccessToken();
  const url = process.env.REACT_APP_BABYNAME + `search?name=${data}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiGet<TFindAll>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const serachExactNameMeaning = async (data: string) => {
  let accesstoken = getAccessToken();
  const url = process.env.REACT_APP_BABYNAME + `search-name?name=${data}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiGet<TFindAll>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};



export const toolsListsData = async () => {
  const url = `/api/tools/v2/tools-details`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiGet<TToolsDetailsData>(url, { ...newconfig, skipAuthRefresh: true });
};



export const generateNames = async (data: string) => {
  const url = process.env.REACT_APP_BABYNAME + "generate-name";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TFindAll, {}>(url, data, { ...newconfig, skipAuthRefresh: true });
};

export const addFavName = async (data: string) => {
  const url = process.env.REACT_APP_BABYNAME + "fav-name";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TFindAll, {}>(url, data, { ...newconfig, skipAuthRefresh: true });
};

export const getFavName = async (data: string) => {
  let accesstoken = getAccessToken();
  const url = process.env.REACT_APP_BABYNAME + `fav-name/?userId=${data}`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: accesstoken };
  newconfig.headers = { ...head };
  return apiGet<TGetFav>(url, {
    ...newconfig,
    skipAuthRefresh: true,
  });
};

export const removeFavName = async (data: string) => {
  const url = process.env.REACT_APP_BABYNAME + "remove-fav-name";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TGetFav, {}>(url, data, { ...newconfig, skipAuthRefresh: true });
};
