import React, { useEffect, useState } from "react";
import { Modal} from "antd";


const NameDetails = (prop: any) => {
  const [propData, setPropData] = useState<any>();
  useEffect(() => {
    setPropData(prop.propData);
  }, [prop]);

  const handleCancel = () => {
    prop.setIsModalOpen(false);
  };



  return (
    <Modal
      title={propData?.name}
      open={prop.isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <div>
        <div className="name-table">
          {propData?.meaning && (
            <div className="name-table-row">
              <div className="name-table-heading">Meaning</div>
              <div className="name-table-data">{propData?.meaning}</div>
            </div>
          )}

          {propData?.nakshatra && (
            <div className="name-table-row">
              <div className="name-table-heading">Nakshatra</div>
              <div className="name-table-data">{propData?.nakshatra}</div>
            </div>
          )}

          {propData?.numerology && (
            <div className="name-table-row">
              <div className="name-table-heading">Numerology</div>
              <div className="name-table-data">{propData?.numerology}</div>
            </div>
          )}

          {propData?.rashi && (
            <div className="name-table-row">
              <div className="name-table-heading">Rashi</div>
              <div className="name-table-data">{propData?.rashi}</div>
            </div>
          )}

          {propData?.religion && (
            <div className="name-table-row">
              <div className="name-table-heading">Religion</div>
              <div className="name-table-data">{propData?.religion}</div>
            </div>
          )}
          {propData?.type && (
            <div className="name-table-row">
              <div className="name-table-heading">Type</div>
              <div className="name-table-data">{propData?.type}</div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NameDetails;
