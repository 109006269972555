import { useState } from "react";
import { Layout } from "antd";
import { Tabs, Avatar } from "antd";
import { createGroup } from "./btpGroupApi";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import GeneralInformation from "./GeneralInformation";
import GroupSettings from "./GroupSettings";
import GroupControls from "./GroupControls";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { mediaUpload } from "apis/UpdateProfile";

const { TabPane } = Tabs;
const { Content } = Layout;
var groupInfo: any;
var infoData = {};
let controlData = {};
let settingsData = {};
let resetGeneralIfoFields = false;
let resetSettingsFields = false;
let resetControlsFields = false;

interface LocationState {
  state: {
    _id: string;
    activityFeed: string;
  };
}

const GroupFormTabs = (props: any) => {
  const location = useLocation<LocationState>();
  const [subtabkeyval, setSubtabkeyval] = useState("general");
  const onChange = (key: string) => {
    setSubtabkeyval(key);
  };
  const history = useHistory();
  const authinfo = useSelector((state: TRootState) => state.auth);

  async function saveGroupData() {
    groupInfo = { ...infoData, ...controlData, ...settingsData, user_id: authinfo.userId };
    if (location.state?.state?._id != "" && location.state?.state?._id != undefined) {
      try {
        let thumbnai: any;
        let groupIma: any;
        if (groupInfo.thumbnail instanceof File) {
          try {
            const thumbnailRes: any = await mediaUpload(groupInfo.groupImage);
            thumbnai = thumbnailRes.imageUrl;
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
          }
        } else {
          thumbnai = groupInfo.groupImage;
        }
        if (groupInfo.groupImage instanceof File) {
          try {
            const groupImageRes: any = await mediaUpload(groupInfo.groupImage);
            groupIma = groupImageRes.imageUrl;
          } catch (error) {
            console.error("Error uploading thumbnail:", error);
          }
        } else {
          groupIma = groupInfo.thumbnail;
        }
        let data = JSON.stringify({
          id: location?.state?.state?._id,
          groupName: groupInfo.groupName,
          groupImage: groupIma,
          thumbnail: thumbnai,
          description: groupInfo.description,
          shortDescription: groupInfo.shortDescription,
          type: groupInfo.groupType,
          isSearchable: groupInfo.groupSearch,
          focus: groupInfo.groupFocus,
          groupStatus: "activated",
          invite: groupInfo.invite,
          activityFeed: groupInfo.activityFeed,
          isModerated: groupInfo.isModerated,
          groupRule: groupInfo.rule,
        });
        await createGroup(data);
        props.setTab("1");
        history.push(`/group-profile/${location?.state?.state?._id}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      const groupImageRes: any = await mediaUpload(groupInfo.groupImage);
      groupInfo.groupImage = groupImageRes?.imageUrl;
      const thumbnailRes: any = await mediaUpload(groupInfo.thumbnail);
      groupInfo.thumbnail = thumbnailRes.imageUrl;
      let data = JSON.stringify({
        groupName: groupInfo.groupName,
        groupImage: groupInfo.groupImage,
        thumbnail: groupInfo.thumbnail,
        description: groupInfo.description,
        type: groupInfo.groupType,
        isSearchable: groupInfo.groupSearch,
        focus: groupInfo.groupFocus,
        groupStatus: "activated",
        invite: groupInfo.invite,
        activityFeed: groupInfo.activityFeed,
        isModerated: groupInfo.isModerated,
        groupRule: groupInfo.rule,
        shortDescription: groupInfo.shortDescription,
        groupMedia: [
          {
            mediaType: groupImageRes.imageUrl.split(".").pop()?.split(/\#|\?/)[0],
            mediaKey: groupImageRes.key,
            mediaSize: groupImageRes?.imageUrl?.size,
            mediaUrl: groupImageRes.imageUrl,
          },
          {
            mediaType: thumbnailRes.imageUrl.split(".").pop()?.split(/\#|\?/)[0],
            mediaKey: thumbnailRes?.key,
            mediaSize: thumbnailRes?.imageurl?.size,
            mediaUrl: thumbnailRes.imageUrl,
          },
        ],
      });
      await createGroup(data);
      props.setTab("1");
    }
  }

  async function getInfoData(formData: object, nextTab: any) {
    await setSubtabkeyval(nextTab);
    infoData = { ...formData };
  }
  const getSettingsData = async (formData: object, nextTab: any) => {
    settingsData = { ...formData };
    setSubtabkeyval(nextTab);
  };
  async function getControlData(formData: object, nextTab: any) {
    setSubtabkeyval(nextTab);
    controlData = { ...formData };
    await saveGroupData();
  }
  return (
    <div className="container">
      <Content className="menu-bar">
        <div className="tab-sty">
          <Tabs defaultActiveKey={subtabkeyval} activeKey={subtabkeyval} onChange={onChange}>
            <TabPane tab="General" key="general">
              <GeneralInformation getInfoData={getInfoData} resetGeneralIfoFields={resetGeneralIfoFields} />
            </TabPane>
            <TabPane tab="Settings" key="settings">
              <GroupSettings getSettingsData={getSettingsData} resetSettingsFields={resetSettingsFields} />
            </TabPane>
            <TabPane tab="Controls" key="controls">
              <GroupControls getControlData={getControlData} resetControlsFields={resetControlsFields} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  );
};

export default GroupFormTabs;
