import React, { useState, useEffect } from "react";
import { getSavePostItem } from "views/Groups/btpGroupApi";
import { Card, Layout, Image,Pagination } from "antd";
import Headersection from "views/MainHeader/Header";
import PostComment from "components/modal/PostComment";
import { PaginationProps } from "antd";
import moment from "moment";
import { grouplikeApi, savePostGroupApi } from "views/Groups/btpGroupApi";
import Swal from "sweetalert2";
const { Header, Content } = Layout;
import { LikeOutlined, MessageOutlined, TabletOutlined, LikeFilled, MobileFilled } from "@ant-design/icons";
import SaveImg from "../../images/SavedIcon.svg";
import Save from "../../images/save.svg";
import CommentImg from "../../images/comment.svg";
import Send from "../../images/send.svg";
import ShareIcon from "../../images/share_icon.svg";
import Share from "views/ShareModal/Share";
import SendModal from "views/Posts/SendModal";
import UserComment from "views/Posts/UserComment";
import { articleSave } from "apis/btpArticles";

// articleSave

// import SaveImgDark from

const SavedItems = () => {
  const [postData, setPostData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(1);
  const getGroupComment = () => {
    getSavePostItem()
      .then(res => {
        // console.log("res.data.posts", res.data.posts);
        setPostData(res.data.posts);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGroupComment();
  }, []);

  const hanldeSavePost = async (e: any, val: any) => {
    // console.log("save remove val",val);

    await savePostGroupApi(val._id)
      .then(res => {
        getGroupComment();
        if (val.savedPostStatus == 0) {
          Swal.fire({
            icon: "success",
            title: "Post saved",
            showConfirmButton: false,
            timer: 900,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickLike = async (value: any) => {
    await grouplikeApi(value)
      .then(res => {
        getGroupComment();
      })
      .catch(err => {
        console.log(err);
      });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postId, setPostID] = useState<any>("");


  const handleLike = (like: any) => {};

  const handleSaveBtn = async (e:any,like: any) => {
    // console.log("like id", like);


    // console.log("postId", postId);
    // setSavePost(!savePost);
    articleSave(like?.articleId)
      .then(res => {
        getGroupComment();
        // props.refresh();
      })
      .catch(err => {
        console.log(err);
      });

    // await savePostGroupApi(like._id)
    // .then(res => {
    //   getGroupComment();
    //   if (like.savedPostStatus == 0) {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Post saved",
    //       showConfirmButton: false,
    //       timer: 900,
    //     });
    //   }
    // })
    // .catch(err => {
    //   console.log(err);
    // });

  };

  const [isSendShow, setIsSendShow] = useState(false);
  const [artcileData, setArticleData] = useState<any>({});
  const [type, setType] = useState<any>("blog");
  const [shareOpenModal, setShareOpenModal] = useState(false);
  const [isModalArticleOpen, setISModalArticleOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const handleShareBtn = (permalink: any) => {
    setShareOpenModal(true);
    setShareLink(permalink);
  };

  const handleSendBtn = (permalink: any) => {
    setIsSendShow(true);
    setArticleData(permalink);
    setType("save_blog");
  };

  const showModalComment = (e: any, values: any) => {
    // console.log("values", values);
    setPostID(values);
    setIsModalOpen(true);
  };

  const handleCommentArticle = (like: any) => {
    setISModalArticleOpen(true)
    // console.log("like", like);
    setPostID(like);
  };

  // const onShowSizeChange=(e:any)=>{
  //   console.log("e",e);
  // }

  return (
    <>
      {isSendShow && (
        <SendModal
          setIsSendShow={setIsSendShow}
          isSendShow={isSendShow}
          artcileData={artcileData}
          type={type}
          setArticleData={setArticleData}
        />
      )}

      {setISModalArticleOpen && <UserComment setISModalArticleOpen={setISModalArticleOpen} isModalArticleOpen={isModalArticleOpen} postId={postId} />}


      {shareOpenModal && <Share setShareOpenModal={setShareOpenModal} shareOpenModal={shareOpenModal} shareLink={shareLink} />}
      {
        isModalOpen &&
      <PostComment setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} postId={postId} setPostID={setPostID}/> }
      <Layout>
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <Layout className="Content-body container mt-20">
          <div className="bg-white mt-16">
            <Content className="profike_imgs">
              {postData?.map((list: any) => {
                // console.log("list", list);
                return (
                  <>
                    {list?.articleId ? (
                      <Card className="Liked_post_section" style={{ marginBottom: "45px" }}>
                        <div key="">
                          <div className="mb-3 mt-3">
                            <a href={list.permalink}>
                              <img className="object-cover w-full" src={list.thumbnailImage} alt="" loading="lazy" />
                              <p></p>
                              <p style={{ color: "#666666", fontSize: "bold", marginTop: "2px" }}>
                                {list?.views} views &nbsp; &#x2022; {list?.readTime} min read
                              </p>
                              <h2 className="my-3">{list?.title}</h2>
                            </a>
                            <p>{list?.content}</p>
                          </div>

                          <div className="flex" style={{ justifyContent: "space-between" }}>
                            <div>
                              {list?.totalLikes !== 0 && (
                                <span
                                  className="mt-1 cursor-pointer"
                                  style={{
                                    fontWeight: "600px",
                                    fontSize: ".9375rem",
                                  }}>
                                  {list.totalLikes} Like
                                </span>
                              )}
                            </div>
                            <div>
                              {list?.totalComments !== 0 && (
                                <span
                                  className="mt-1 cursor-pointer"
                                  style={{
                                    fontWeight: "600px",
                                    fontSize: ".9375rem",
                                  }}>
                                  {list?.totalComments}&nbsp; Comment
                                </span>
                              )}
                            </div>
                          </div>

                          <hr className="mt-2" />
                          <div className="flex text-center mt-3">
                            <div className="flex text-center pointer">
                              <span className="mt-1 mr-8">
                                {list.likeStatus == 0 ? (
                                  <LikeFilled style={{ fontSize: "20px" }} className="ml-1" onClick={() => handleLike(list?._id)} />
                                ) : (
                                  <LikeOutlined style={{ fontSize: "20px" }} className="ml-1" onClick={() => handleLike(list?._id)} />
                                )}
                              </span>

                              <span className="mt-1"></span>
                            </div>
                            <div className="flex text-center">
                              <span className="mt-1 mr-8">
                                <Image
                                  src={CommentImg}
                                  preview={false}
                                  className="cursor-pointer"
                                  onClick={() => handleCommentArticle(list?.articleId)}
                                />
                              </span>
                              <span className="mt-1 cursor-pointer mr-8">
                                <Image src={ShareIcon} preview={false} onClick={() => handleShareBtn(list?.permalink)} />
                              </span>
                              <span className="mt-1 cursor-pointer mr-8">
                                <Image src={Send} preview={false} onClick={() => handleSendBtn(list)} />
                              </span>
                            </div>

                            <div
                              className="flex text-center"
                              style={{
                                marginLeft: "auto",
                              }}>
                              <span className="mr-1">
                                <Image
                                  src={SaveImg}
                                  preview={false}
                                  className="cursor-pointer mt-1"
                                  onClick={(e) => handleSaveBtn(e,list)}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    ) : (
                      <Card
                        className="Liked_post_section"
                        style={{ marginBottom: "45px" }}
                        title={
                          <>
                            <div className="flex mb-5">
                              <div className="mr-3">
                                <img src={list.profilePic} alt="" style={{ width: "40px", height: "40px" }} />
                              </div>

                              <div>
                                <h2 style={{ marginBottom: "0px" }}>{list.fullName}</h2>
                                <span>{moment(list.createdDate).format("DD-MM-YYYY")}</span>
                              </div>
                            </div>
                          </>
                        }>
                        <p className="mb-2">{list.postDescription !== "undefined" ? <p>{list?.postDescription}</p> : ""}</p>

                        {list.postMedia.map((media_itam: any) => {
                          // console.log("media_itam",media_itam);
                          return (
                            <>
                              <div
                                style={{
                                  width: "auto",
                                  height: "450px",
                                }}>
                                <img
                                  src={media_itam.mediaKey}
                                  alt=""
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "100%",
                                    width: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            </>
                          );
                        })}
                        <hr className="mt-3" />
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}>
                            <span
                              className="mt-1"
                              style={{
                                fontWeight: "600px",
                                fontSize: ".9375rem",
                              }}>
                              {list.totalLikes != 0 && list.totalLikes} Like
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "16px",
                            }}>
                            <span
                              className="mt-1 cursor-pointer"
                              style={{
                                fontWeight: "600px",
                                fontSize: ".9375rem",
                              }}>
                              {list.totalComments != 0 && list.totalComments} Comment
                            </span>
                          </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div style={{ display: "flex", alignItems: "center" }} className="mt-3">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={() => handleClickLike(list._id)}>
                              {list.likeStatus == 1 ? (
                                <span style={{ marginRight: "8px" }} className="mt-1">
                                  <LikeFilled style={{ fontSize: "18px" }} className="ml-1" />
                                </span>
                              ) : (
                                <span style={{ marginRight: "8px" }} className="mt-1">
                                  <LikeOutlined style={{ fontSize: "18px" }} className="ml-1" />
                                </span>
                              )}
                              <span
                                className="mt-1"
                                style={{
                                  fontWeight: "600px",
                                  fontSize: ".9375rem",
                                }}></span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "16px",
                              }}>
                              <span style={{ marginRight: "8px" }} className="mt-1">
                                <Image
                                  src={CommentImg}
                                  preview={false}
                                  onClick={e => showModalComment(e, list._id)}
                                  className="cursor-pointer"
                                />
                              </span>
                              <span
                                className="mt-1 cursor-pointer"
                                style={{
                                  fontWeight: "600px",
                                  fontSize: ".9375rem",
                                }}
                                onClick={e => showModalComment(e, list._id)}></span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <span style={{ marginRight: "8px" }}>
                                <Image src={SaveImg} preview={false} className="cursor-pointer" onClick={e => hanldeSavePost(e, list)} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </Card>
                    )}
                  </>
                );
              })}

{/* <Pagination defaultCurrent={1} total={totalPages} onChange={onShowSizeChange} /> */}

              {postData?.length == 0 && <p style={{ textAlign: "center" }}>There are no saved items</p>}
            </Content>
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default SavedItems;
