import { TFetchingStatus } from "helper";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import {
    connectReqInitAction,
    connectUserSuccessAction,
  connectUserErrorAction,
  TConnectUserActions,
} from "actions/connect";

export type TConnectUser = {
  fetchingStatus: TFetchingStatus;
  error: Error | null;
};

export const initialState: TConnectUser = {
  fetchingStatus: TFetchingStatus.Default,
  error: null,
};

export const connectUserReducer = createReducer<TConnectUser, TConnectUserActions>(
  initialState
)
  .handleAction(connectReqInitAction, (state) =>
    produce(state, (draftState) => {
      console.log("init", state, draftState);
      draftState.fetchingStatus = TFetchingStatus.Loading;
      draftState.error = null;
    })
  )
  .handleAction(connectUserSuccessAction, (state, {payload}) =>
    produce(state, (draftState) => {
        console.log("success", state, payload);
        draftState.fetchingStatus = TFetchingStatus.Success
        draftState.error = null;
    })
  )
  .handleAction(connectUserErrorAction, (state, {payload}) =>
  produce(state, (draftState) => {
    console.log("error", state, payload);
    draftState.fetchingStatus = TFetchingStatus.Failure;
    draftState.error = payload;
  })
  )
