import { Card, Row, Col, Space, Button } from 'antd';
// import { MoreOutlined } from '@ant-design/icons'

const Service = (props: any) => {
    let deatil = props.data;
    const truncateDescription = (description: any, wordLimit: any) => {
        const words = description.split(' ');
        const truncatedWords = words.slice(0, wordLimit);
        if (words.length > wordLimit) {
            return truncatedWords.join(' ') + ' ...';
        }
        return truncatedWords.join(' ');
    };
    return (
        <div className='line' >
            <div>
                <Row align='middle' justify='space-between' className='mt-3'>
                    <Col span={20}>
                        <Space align='center'>
                            <div>
                                <h3 style={{
                                    color: 'color',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    lineHeight: '22px'
                                }}>{deatil.title}</h3>
                            </div>
                        </Space>
                    </Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        {/* <MoreOutlined /> */}
                    </Col>
                </Row>
                <p>{deatil.content}</p>
            </div>
            <div className='mt-2 mb-5' style={{
                backgroundColor: "#F0F0F0",
                padding: '20px',
                width: '100%'

            }}>
                <h3 >{deatil.productCategory}</h3>
                <div className='s-card-scroll' style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                    {deatil.recomondedServices.map((value: any) => (
                        <div key={value.id} className='s-card-container' style={{ border: 'none', display: 'inline-block'}}>
                            <div className='sds-card border-0 card'>
                                <div className='s-card-img border-0'   style={{
                    width: '100%', 
                    height: '500px', 
                    overflow: 'hidden', 
                  }}>
                                <a href={value.productLink.url} target='_blank'>
                                    <img src={value.productImage} alt=''   style={{
                      width: '100%',
                      height: '100%',
                      // objectFit: 'contain', 
                    }}/>
                                    </a>
                                </div>
                                <div className="mt-3" style={{
                                    display: 'flex', justifyContent: 'space-between', padding: '10px',
                                    width: '100%',
                                    height: "50%"
                                }}>
                                    <div className='mb-3'  style={{
                                        textAlign:"justify"
                                    }}>
                                        <h3 className='ellipsis-title'>{value.productTitle}</h3>
                                        <p >{truncateDescription(value.productDescription, 8)}</p>
                                    </div>
                                    <div className='mb-4' style={{
                                        width: '20%',
                                    }}>
                                        <a href={value.productLink.url} target='_blank'>
                                            <button>Explore</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Service