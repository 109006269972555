import { viewProfileApi } from "apis/ViewProfile";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { logInSuccessAction, saveAccessToken, TLogData } from "./auth";
import { TUpdateProfileResponse } from "./updateprofile";

export type TUserDetailRequest = {
  user_id: string;
};

export type TProfileDetailRequest = {
  profile_id: string;
};

export type TCoonectionReq = {
  type:string;
  filter_type:string;
};


export type TGetConnectionRes={
  status: boolean,
  message: string
  data: any
}


export type TUserDetailResponse = {
  status: boolean;
  message: string;
  profile_pic:string;
  full_name:string
  data: Array<TUserDetails>;
  username_momnexus:string;
  sk_user_id:number
};

export type TUserDetailConnection = {
  status: boolean;
  message: string;
  data: any;
  momsdata: Array<any>;
};

export type TUserChild = {
  child_name: string;
  dob: string;
  gender: string;
  sk_stage_id: string;
  nick_name: string;
  child_profile_pic: string;
  stage_of_parenting:string;
};

export type TUserProfile={
  c_code:string;
  chat_status:string;
  childDetails:Array<any>;
  created_date_time:string;
  current_user_session:string;
  due_date_parent:string;
  email:string;
  emil_verification_status:number;
  full_name: string;
  gender:string;
  gst_no:string;
  i_am:string;
  isSocialLogin:boolean;
  sk_user_id: string;
  mobile: string;
  profile_pic:string;
  stage_name:string;
  total_percentage:string;
  alies_name: string;
  gender_pic: string;
  dob: string;
  username_momnexus:string;
  message:string
  status:boolean;
  pincode:string;
  country_name:string;
  stage:string;
  user_bio:string;
  week:string

}

export type TUserDetails = {
  personal_details_progression: number;
  personal_details_updated: number;
  personal_details_maxfields: number;
  contact_information_progression: number;
  contact_information_updated: number;
  contact_information_maxfields: number;
  pregnancy_information_progression: number;
  pregnancy_information_updated: number;
  pregnancy_information_maxdields: number;
  parenting_information_progression: number;
  parenting_information_updated: number;
  parenting_information_maxfields: number;
  total_percentage: number;
  parenting_information_progression_isValid: string;
  sk_user_id: string;
  full_name: string;
  alies_name: string;
  gender: string;
  gender_pic: string;
  c_code: string;
  email: string;
  mobile: string;
  dob: string;
  i_am: string;
  username_momnexus: string;
  profile_pic: string;
  cover_photo: string;
  pincode: string;
  stage_name: string;
  country_name: string;
  country_id: string;
  personal: string;
  mobiledata: string;
  parentingdata: string;
  due_date_parent: string;
  due_date_status: string;
  user_bio: string;
  registered_date: string;
  child_info: Array<TUserChild>;
};

export const viewProfileInitAction = createAction("users/VIEWPROFILE_INIT")();

export const viewProfileSuccessAction = createAction(
  "users/VIEWPROFILE_SUCCESS"
)<TUserDetails>();
export const viewProfileFailureAction = createAction(
  "users/VIEWPROFILE_FAILURE"
)<Error>();

export const logOutAction = createAction("users/LOGOUT")();

export type TViewProfileInitAction = ActionType<typeof viewProfileInitAction>;
export type TViewProfileSuccessAction = ActionType<
  typeof viewProfileSuccessAction
>;
export type TViewProfileFailureAction = ActionType<
  typeof viewProfileFailureAction
>;

export const updateProfileUpdateAction = createAction(
  "users/UPDATEPROFILE_UPDATE"
)<Partial<TUserDetails>>();
export type TUpdateProfileUpdate = ActionType<typeof updateProfileUpdateAction>;

export type TViewProfileActions =
  | TViewProfileInitAction
  | TViewProfileSuccessAction
  | TViewProfileFailureAction
  | TUpdateProfileUpdate;
export type TViewProfileThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TViewProfileActions
>;

type TViewProfileAction = (
  payload: TUserDetailRequest,
  token: any
) => TViewProfileThunkActionType;


export const viewprofile: TViewProfileAction =
  (payload: TUserDetailRequest, token: any) =>
  (
    dispatch: (
      action:
        | Action
        | Promise<Action>
        | ThunkAction<void, TRootState, null, TViewProfileActions>
    ) => any
  ) => {
    console.log('viewProfileInitAction', payload, token);

    dispatch(viewProfileInitAction());
    return viewProfileApi(payload).then(async (res) => {
      console.log('payload 11', res);

      //console.log("payload", await res);
      // if (res.status) {

      //   let userData:TLogData = {
      //     ...res.data[0],
      //     Accesstoken: token,
      //     userId: res.data[0].sk_user_id,
      //     fullname: res.data[0].full_name,
      //     mail: res.data[0].email,
      //     wp_user_id: '',
      //     stagingInfo: '',
      //     email_verification_status: '',
      //     parent_info: null
      //   };
      //  saveAccessToken(userData);
      //  dispatch(logInSuccessAction(userData));
      //  dispatch(viewProfileSuccessAction(res.data[0]));
      // } else {
      //   dispatch(viewProfileFailureAction({ message: res.message } as Error));
      // }
    });
  };
