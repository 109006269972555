import React, { useState } from 'react'
import Saved from "../../../images/saved.svg"
import Save from "../../../images/save.svg"
import { savedpostlikeandunlike, unsavedpostlikeandunlike } from 'apis/likeunlikepost'


const Savedsection = (propssavesection:any) => {
const [status, setStatus] = useState<any>(propssavesection.postIsSaved==1 ? true : false)
const handleClickLike = () => {
    if (status) {
      unsavedpostlikeandunlike(propssavesection.postid).then((res)=>{console.log("first",res)})
      setStatus(false)
      console.log("button3",status,propssavesection.postIsSaved)
    } else {
        setStatus(true)
        console.log("button4",status,propssavesection.postIsSaved)
        savedpostlikeandunlike(propssavesection.postid).then((res)=>{console.log("first",res)})
    }
  }
  return (
    <div>
        <div className="mr-5 cursor-pointer" onClick={handleClickLike}>
            {status
            ? <img src={Saved} alt=""/>

            :  <img src={Save} alt="" />

            }

        </div>
    </div>
  )
}

export default Savedsection