import { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import type { RadioChangeEvent } from "antd";
import { Radio } from "antd";
import { useLocation } from "react-router-dom";

interface LocationState {
  state: {
    _id: string;
    activityFeed: string;
    groupName: string;
    description: string;
    groupRule: string;
    groupImage: string;
    thumbnail: string;
    shortDescription: string;
    type: string;
    focus: string;
    isSearchable: string;
  };
}

const GroupSettings = (propsData: any) => {
  const location = useLocation<LocationState>();
  console.log(location?.state?.state);


  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [groupType, setGroupType] = useState<any>("");
  const [groupSearch, setGroupSearch] = useState<any>();
  const [groupFocus, setGroupFocus] = useState<any>();

  // console.log("defaultValue={groupType}", groupType);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    let reqBody = { groupSearch: groupSearch, groupFocus: groupFocus, groupType: groupType };
    let nextTab = "controls";
    propsData.getSettingsData(reqBody, nextTab);
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsLoading(false);
  };

  const onPrevious = () => {
    propsData.getSettingsData("", "general");
  };

  console.log(location?.state?.state?.type);

  useEffect(() => {
    if (propsData.resetSettingsFields === true) {
      form.resetFields();
    } else if (location?.state?.state) {
      setGroupType(location?.state?.state?.type);
      setGroupSearch(location?.state?.state?.isSearchable);
      setGroupFocus(location?.state?.state?.focus);
    }
  }, [propsData]);

  const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />;
  return (
    <div>
      <Form
        name="settings"
        form={form}
        layout="vertical"
        autoComplete="off"
        className="input-sty"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item name="type" label="Select the Group Type" valuePropName="checked">
          <Radio.Group
            value={groupType}
            options={[
              { label: "Public", value: "Public" },
              { label: "Private", value: "Private" },
            ]}
            onChange={e => {
              setGroupType(e.target.value);
            }}
          />
        </Form.Item>
        {groupType === "Private" ? (
          <Form.Item name="groupSearch" label="Ability to Search Groups" valuePropName="checked">
            <Radio.Group
              value={groupSearch}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={e => {
                setGroupSearch(e.target.value);
              }}
            />
          </Form.Item>
        ) : null}
        <Form.Item name="focus" label="Select the Group Focus" valuePropName="checked">
          <Radio.Group
            value={groupFocus}
            options={[
              { label: "Pregnancy", value: "Pregnancy" },
              { label: "Parenting", value: "Parenting" },
              { label: "Conceiving", value: "Conceiving" },
            ]}
            onChange={e => {
              setGroupFocus(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
      <Button type="primary" htmlType="submit" className="button-sty float-left" danger onClick={onPrevious}>
        <span className="mr-2"> Back</span>
      </Button>
      <Button type="primary" htmlType="submit" className="button-sty float-right" onClick={onFinish} danger>
        <span className="mr-2"> Next</span>
        {isLoading && <Spin indicator={antIcon} size="small" />}
      </Button>
    </div>
  );
};

export default GroupSettings;
