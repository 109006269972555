import { useEffect, useState } from 'react';
import { getGroupDetails, } from "./GroupApi";
import { useParams } from "react-router-dom";
import { Layout } from 'antd';
import Sidenav from 'views/MainHeader/SideNav';
import Headersection from 'views/MainHeader/Header';
import { Avatar, Badge, Col, Modal, Row, Tooltip, Upload, Button, Tabs, Image } from "antd";
import GroupPostList from './GroupPostList';
import Post from 'components/modal/Post';
const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;




const GroupDetails = (props:any) => {
	console.log("groupdeatil", props);
	
  const [groupData, setGroupData] = useState({
		group_image: '',
		group_name: '',
		members_count: '',
		type: '',
		focus: '',
		group_id: ''
	});
 
  const params: any = useParams();

	useEffect(() => {
		getGroupDetail();
	}, [params]);

	const getGroupDetail = async () => {
		let data: any = await getGroupDetails(params);
		setGroupData(data.data);
	}

	const getGroupPosts = () => {
		console.log('test')
	}
	return(<Layout className='h-screen'>
		<Sider
			breakpoint="lg"
			collapsedWidth="0"
			onBreakpoint={(broken) => {
			}}
			>
			<div className="logo" />
			<Sidenav />
		</Sider>
		<Header className='' style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
			<Headersection {...props} />
		</Header>
		<Row className="group-content">
      <Col span={6}>
			</Col>
			<Col span={12}>
        <Content className="group_image">
					<Image
						width={500}
						src={groupData.group_image}
					/>
					<div >
						<div>
							<h2 className="profile-head-name">{groupData.group_name}</h2>
						</div>
						<div className="flex mx-3">
						{groupData.focus.charAt(0).toUpperCase() + groupData.focus.slice(1)}<span className="px-2">•</span>{groupData.type.charAt(0).toUpperCase() + groupData.type.slice(1)} 
						</div>
						<div className="flex mx-3">
							{groupData.members_count} {"Members"}
						</div>
						<hr></hr>
						<div>
							<Post groupId={groupData.group_id} />
						</div>
						<div>
							<GroupPostList groupData={groupData} getGroupPosts={getGroupPosts}  />
						</div>
        	</div>
        </Content>
			</Col>
		</Row>		
    </Layout>
	);
}

export default GroupDetails;