import { Drawer, Input, Tooltip } from "antd";
import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useHistory } from "react-router";

const Searchsection = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const onSearch = (value: string) => {
    history.push("/searchvalue", {state:value});
    setOpen(false)
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { Search } = Input;
  return (
    <div>
      <div>
        <Tooltip placement="bottom" title="Search" className="cursor-pointer">
          <FiSearch style={{ fontSize: "21px" }} onClick={showDrawer} />
        </Tooltip>
      </div>
      <Drawer
        title={
          <>
            <div
              className="search_feild_section"
              style={{ width: "100%", maxWidth: "900px", margin: "0 auto" }}
            >
              <Search
                placeholder="Start typing to search..."
                onSearch={onSearch}
                size="large"
                style={{ border: "0px" }}
              />
            </div>
          </>
        }
        placement="top"
        onClose={onClose}
        visible={open}
        height={80}
      ></Drawer>
    </div>
  );
};

export default Searchsection;
