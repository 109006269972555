import { getAccessToken } from "actions/auth";
import React from "react";
import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";
// import { apiGet, apiPost, getDefaultAxiosConfig } from "./btpApi";


export type TConnectResponse = {
    status: boolean;
    message: string;
    data: any;
  };

  export type TConnectAccept = {
    status: boolean;
    message: string;
    data: any;
  };

  
  export type TConnectReject = {
    status: boolean;
    message: string;
    data: any;
  };
  
  


  export const connectionRequestApi = async (requestee_id : string ) => {
    const url = "/Connection/connection";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken(), 'Content-Type' : 'text/plain'};
  newconfig.headers = { ...head };
  return apiPost<TConnectResponse, {requestee_id : string}>(url, {requestee_id : requestee_id}, {...newconfig,skipAuthRefresh: true,});
  };

  export const connectionAcceptApi = async (connection_id : string, connection:number, follow:number, block:number ) => {
    const url = "/Connection/accept_connection";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken(), 'Content-Type' : 'text/plain', connection:connection, follow:follow, block:block};
  newconfig.headers = { ...head };
  return apiPost<TConnectAccept, {connection_id : string}>(url, {connection_id : connection_id}, {...newconfig,skipAuthRefresh: true,});
  };

  export const connectionRejectApi = async (connection_id : string ) => {
    const url = "/Connection/reject_connection";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken(), 'Content-Type' : 'text/plain'};
  newconfig.headers = { ...head };
  return apiPost<TConnectReject, {connection_id : string}>(url, {connection_id : connection_id}, {...newconfig,skipAuthRefresh: true,});
  };
