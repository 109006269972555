import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Card, message, Carousel,} from "antd";
const { Header, Content, Sider } = Layout;
import Sidenav from "views/MainHeader/SideNav";
import Headersection from "views/MainHeader/Header";
import { useLocation } from "react-router-dom";
import Connect from "./buttonsection/Connect";
import Followbutton from "./buttonsection/Followbutton";
import { Link } from "react-router-dom";
const like_stylus = "border-2 rounded-md py-1 w-20 text-sm";
import { globalSearch } from "../../apis/btpViewProfile";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import { viewProfileApi } from "apis/btpViewProfile";
import { deleteMyPost } from "views/Groups/btpGroupApi";
import { grouplikeApi, savePostGroupApi } from "views/Groups/btpGroupApi";
import Swal from "sweetalert2";
import PostComment from "components/modal/PostComment";

import {
  LikeOutlined,
  MessageOutlined,
  TabletOutlined,
  LikeFilled,
  MobileFilled,
  MoreOutlined,
} from "@ant-design/icons";

interface LocationState {
  state: {
    posts: any[];
    articles: any;
    groups: any[];
    users: any[];
  };
}

const SearchValue = (props: any) => {
  const location = useLocation<LocationState>();

  const { isLoading: loading, data: userData, refetch: refres } = useQuery("viewProfile", () => viewProfileApi({ user_id: "" }));

  const [data, setData] = useState<any>([]);
  const [isMoreVisible, setIsMoreVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postId, setPostID] = useState<any>("");
  const toggleDisplay = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  const getConnectionList = () => {
    globalSearch(location?.state?.state)
      .then(res => {
        console.log("res", res);
        setData(res);
      })
      .catch(err => {
        message.error(err);
      });
  };

  useEffect(() => {
    getConnectionList();
  }, [location?.state?.state]);

  const queryClient = useQueryClient();
  const handleClickLike = async (value: any) => {
    await grouplikeApi(value)
      .then(res => {})
      .catch(err => {
        console.log(err);
      });
    await queryClient.invalidateQueries("getUserPost");
    getConnectionList();
  };

  const hanldeSavePost = async (e: any, val: any) => {
    await savePostGroupApi(val._id)
      .then(res => {
        getConnectionList();
        if (val.savedPostStatus == 0) {
          Swal.fire({
            icon: "success",
            title: "Post saved",
            showConfirmButton: false,
            timer: 900,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
    await queryClient.invalidateQueries("getUserPost");
    getConnectionList();
  };

  const handleDeleteClick = (e: any, list: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Post.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteMyPost(list)
          .then(res => {
            getConnectionList();
          })
          .catch(err => {
            console.log(err);
          });
        toggleDisplay();
        Swal.fire("Deleted!", "Your post has been deleted.", "success");
      }
    });
  };

  const showModalComment = (e: any, values: any) => {
    setPostID(values);
    setIsModalOpen(true);
  };

  const onChange = (currentSlide: any) => {
    // console.log(currentSlide);
  };

  return (
    <>
      <PostComment setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} postId={postId} setPostID={setPostID} />
      <Layout className="h-screen">
        <Layout>
          <Sider breakpoint="lg" collapsedWidth="0" onBreakpoint={broken => {}}>
            <div className="logo" />
            <Sidenav />
          </Sider>
          <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
            <Headersection {...props} />
          </Header>
          <div className=" bg-white mt-20">
            <Content
              className=" container "
              style={{
                margin: "24px auto",
              }}>
              <div>
                <div className="lg:px-5">
                  <Row>
                    <Col xs={24} xl={6} md={7} className="lg:pr-5 lg:pl-7  md:pr-5">
                      <div className="Artical_card1">
                        <Row className="">
                          <Col xs={12} sm={12} className="text-left"></Col>
                          <Col xs={12} sm={12} className=""></Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={24} xl={14} md={12} className="lg:pr-5 md:pr-5">
                      <div className="Artical_card3 relative">
                        <div>
                          {data?.users?.length !== 0 && <h1>Moms Profile</h1>}
                          {data?.users?.map((mom: any, ind: number) => {
                            return (
                              <>
                                <div className="flex   border_align line" key={ind}>
                                  <div className="mr-3 mt-5">
                                    <img
                                      src={mom?.profilePic}
                                      alt=""
                                      className="h-20 w-20 object-cover rounded-full"
                                      style={{ borderRadius: "100%" }}
                                    />
                                  </div>
                                  <div className="mt-3 mb-3">
                                    <Link to={"/momslikelistprofile/" + mom?.userId}>
                                      <h3>{mom?.fullName}</h3>
                                    </Link>
                                    <p>{mom?.stageName}</p>
                                    <span style={{ fontSize: "12px" }}>{mom?.mutualConnections} mutual connections</span>
                                    <div className="flex my-2">
                                      <div className="mr-3">
                                        <Followbutton class={like_stylus} {...mom} getConnectionList={getConnectionList} />
                                      </div>

                                      <div>
                                        <Connect {...mom} class={like_stylus} getConnectionList={getConnectionList} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        <div>
                          {data?.articles?.data?.length !== 0 && <h1>Blogs</h1>}
                          {data?.articles?.data?.map((val: any, ind: number) => {
                            return (
                              <>
                                <div className="line" key={ind}>
                                  <div className="mb-3 mt-3">
                                    <a href={val?.permalink}>
                                      <img className="object-cover w-full" src={val?.thumbnail_image} alt="" loading="lazy" />
                                      <h2 className="my-3">{val.Title}</h2>
                                    </a>
                                    <p>{val.content}</p>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        <div>
                          {data?.groups?.length !== 0 && <h1>Groups</h1>}
                          {data?.groups?.map((group: any, index: number) => {
                            let srcUrl = group.groupImage;
                            if (!srcUrl.startsWith("http://") && !srcUrl.startsWith("https://")) {
                              srcUrl = "https://media.dev.beingtheparent.com/api/media/v3/no-auth?key=" + srcUrl;
                            }
                            return (
                              <>
                                <div className="line mt-3" key={index}>
                                  <Link to={`/group-profile/${group?._id}`}>
                                    <Card
                                      hoverable
                                      style={{ width: "auto", marginTop: "10px" }}
                                      cover={<img alt={srcUrl} src={srcUrl} className="object-cover w-full" loading="lazy" />}>
                                      <div style={{ justifyContent: "center" }}>
                                        <h1>{group?.groupName}</h1>
                                        <p>{group?.groupStatus === "activated" && "Activate"}</p>
                                        <p className="global_date">{moment(group?.lastUpdated).format("DD-MM-YYYY")}</p>
                                      </div>
                                      <div style={{ display: "flex" }}>
                                        <div>
                                          <p className="global_date">{group?.type}</p>
                                        </div>

                                        <div>
                                          <p className="global_date">&#x2022; {group?.focus}</p>
                                        </div>

                                        <div>
                                          <p className="global_date">&#x2022; {group?.membersCount} members</p>
                                        </div>
                                      </div>
                                    </Card>
                                  </Link>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        <div>
                          {data?.posts?.length !== 0 && <h1>Posts</h1>}
                          {data?.posts?.map((post: any, ind: number) => {
                            return (
                              <>
                                <div className="line mt-3" key={ind}>
                                  <Card
                                    className="Liked_post_section"
                                    style={{ marginBottom: "45px" }}
                                    title={
                                      <>
                                        <div className="flex mb-5">
                                          <div className="mr-3">
                                            <img src={post?.profilePic} alt="" style={{ width: "40px", height: "40px" }} />
                                          </div>

                                          <div style={{ flex: 1 }}>
                                            <h2 style={{ marginBottom: "0px" }}>{post?.fullName}</h2>
                                            <span>{moment(post?.createdDate).format("DD-MM-YYYY")}</span>
                                          </div>

                                          <div>
                                            {post?.userId === userData?.sk_user_id && (
                                              <p>
                                                {isMoreVisible ? (
                                                  <MoreOutlined className={`more-link cursor-pointer`} onClick={toggleDisplay} />
                                                ) : (
                                                  <div className="dropdown-menu">
                                                    <a onClick={e => handleDeleteClick(e, post._id)} className="delete-link">
                                                      Delete
                                                    </a>
                                                  </div>
                                                )}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    }>
                                    <p className="mb-2">{post?.postDescription !== "undefined" ? <p>{post?.postDescription}</p> : ""}</p>
                                    <Carousel autoplay afterChange={onChange}>
                                      {post?.postMedia.map((media_item: any, index: number) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: "inline-block",
                                            marginRight: 20,
                                          }}>
                                          {media_item.mediaType === "image" && (
                                            <img
                                              src={media_item.mediaKey}
                                              alt=""
                                              style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                              }}
                                            />
                                          )}
                                          {media_item.mediaType === "video" && (
                                            <video src={media_item.mediaKey} controls autoPlay style={{ width: "100%", height: "100%" }} />
                                          )}
                                        </div>
                                      ))}
                                    </Carousel>

                                    <hr className="mt-3" />

                                    <div style={{ display: "flex" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}>
                                        <span
                                          className="mt-1"
                                          style={{
                                            fontWeight: "600px",
                                            fontSize: ".9375rem",
                                          }}>
                                          {post?.totalLikes != 0 && post?.totalLikes} Like
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginLeft: "16px",
                                        }}>
                                        <span
                                          className="mt-1 cursor-pointer"
                                          style={{
                                            fontWeight: "600px",
                                            fontSize: ".9375rem",
                                          }}>
                                          {post?.totalComments != 0 && post?.totalComments} Comment
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                      <div style={{ display: "flex", alignItems: "center" }} className="mt-3">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleClickLike(post._id)}>
                                          {post?.likeStatus == 1 ? (
                                            <span style={{ marginRight: "8px" }} className="mt-1">
                                              <LikeFilled style={{ fontSize: "18px" }} className="ml-1" />
                                            </span>
                                          ) : (
                                            <span style={{ marginRight: "8px" }} className="mt-1">
                                              <LikeOutlined style={{ fontSize: "18px" }} className="ml-1" />
                                            </span>
                                          )}
                                          <span
                                            className="mt-1"
                                            style={{
                                              fontWeight: "600px",
                                              fontSize: ".9375rem",
                                            }}></span>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "16px",
                                          }}>
                                          <span style={{ marginRight: "8px" }} className="mt-1">
                                            <MessageOutlined
                                              style={{ fontSize: "18px" }}
                                              className="ml-2"
                                              onClick={e => showModalComment(e, post._id)}
                                            />
                                          </span>
                                          <span
                                            className="mt-1 cursor-pointer"
                                            style={{
                                              fontWeight: "600px",
                                              fontSize: ".9375rem",
                                            }}
                                            onClick={e => showModalComment(e, post._id)}></span>
                                        </div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginLeft: "auto",
                                        }}>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                          <span style={{ marginRight: "8px" }}>
                                            {post?.savedPostStatus != 1 ? (
                                              <TabletOutlined
                                                style={{ fontSize: "18px" }}
                                                className="mt-1"
                                                onClick={e => hanldeSavePost(e, post)}
                                              />
                                            ) : (
                                              <MobileFilled
                                                onClick={e => hanldeSavePost(e, post)}
                                                style={{ fontSize: "18px" }}
                                                className="mt-1"
                                              />
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Content>
          </div>
        </Layout>
      </Layout>
    </>
  );
};

export default SearchValue;
