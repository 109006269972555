import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { customizestoryStoryById } from "apis/GetFeeds";
interface Props {
  setShowSymptoms: React.Dispatch<React.SetStateAction<boolean>>;
  actionParamsId: any;
}

const Symptoms: React.FC<Props> = ({ setShowSymptoms, actionParamsId }) => {
  const handleCloseTips = () => {
    setShowSymptoms(false);
  };

  const [symptomsData, setSymptomsData] = useState<any>([]);
  const getStoryById = () => {
    customizestoryStoryById(actionParamsId)
      .then(res => {
        console.log(res?.data?.slides);
        setSymptomsData(res?.data?.slides);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStoryById();
  }, [actionParamsId]);

  return (
    <div
      className="mt-3 week-card-border-line">
      <div className="sympt-card-close">
        <a>
          <CloseOutlined
            className="cursor-pointer read close-outline-btn"
            size={40}
            onClick={handleCloseTips}
          />
        </a>
      </div>
      <br />

      <div className="weekcard-scroll" >
        <div className="flex horizontal-scroll-container-minicard">
          {symptomsData?.map((cardVal: any, ind: number) => {
            return (
              <div
                key={ind}
                className="mr-3 sympt-card mb-4"
                style={{
                  backgroundColor: `${cardVal?.style?.bgColor}`,
                }}>
                <p className="sympt-p-1">{cardVal?.title}</p>
                <img src={cardVal?.imageUrl} alt={cardVal?.imageUrl} loading="lazy" />
                <p className="sympt-p-2">{cardVal?.description}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Symptoms;
