import React, { useState, useEffect } from "react";
import { Layout, Input, Form, message, Slider, Radio } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import Preg from "../icon/HCGCal.svg";
import { hcg_calculator } from "../../../../apis/btpTools";
import { useHistory } from "react-router-dom";
import Footer from "views/MainFooter/Footer";

const HCGCalc = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [radioValue, setRadioValue] = useState("Day");
  const handleChange = (e: any) => {
    setRadioValue(e.target.value);
  };

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("formData") || "{}");
    if (Object.keys(savedFormData).length > 0) {
      form.setFieldsValue(savedFormData);
    }
  }, [form]);

  const handleFisnish = (values: any) => {
    let data: any = {
      firstHCGLevel: parseInt(values?.firstHCGLevel),
      lastHCGLevel: parseInt(values?.lastHCGLevel),
    };
    if (values?.timeTestDay && !values?.timeTestHour) {
      data = {
        ...data,
        timeTestDay: values?.timeTestDay,
        dayOrHour: "day",
      };
    } else if (!values?.timeTestDay && values?.timeTestHour) {
      data = {
        ...data,
        timeTestHour: values?.timeTestHour,
      };
    }
    hcg_calculator(data)
      .then(res => {
        console.log(res.message);
        if (res && res.message === "true") {
          history.push({
            pathname: "/hcg-calculator-result",
            state: {
              data: res,
            },
          });
        }
      })
      .catch(err => {
        message.error(err);
      });
    localStorage.setItem("formData", JSON.stringify(values));
  };
  return (
    <Layout className="h-screen">
      <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
        <Headersection />
      </Header>
      <div className=" bg-white mt-20">
        <Content
          className="container "
          style={{
            margin: "24px auto",
          }}>
          <div>
            <div className="preg-calc-card">
              <img alt="example" src={Preg} className="mb-10 preg-calc-img" />

              <Form layout="vertical" autoComplete="off" className="input-sty mt-10" onFinish={handleFisnish} form={form}>
                <Form.Item
                  label="1st HCG level"
                  name="firstHCGLevel"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the pre pregnancy weight",
                    },
                  ]}>
                  <Input placeholder="0 mlU/mL" />
                </Form.Item>
                <Form.Item
                  label="2nd HCG level"
                  name="lastHCGLevel"
                  rules={[
                    {
                      required: true,
                      message: "Please enter weight right now",
                    },
                  ]}>
                  <Input placeholder="0 mlU/mL" />
                </Form.Item>
                <Form.Item>
                  <div className="flex" style={{ justifyContent: "space-between" }}>
                    <div>
                      <label htmlFor="">Time between tests</label>
                    </div>
                    <div>
                      <Radio.Group onChange={handleChange} value={radioValue}>
                        <Radio value="Day">Day </Radio>
                        <Radio value="Hour"> Hour </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </Form.Item>
                {radioValue == "Day" && (
                  <Form.Item
                    label="Day"
                    name="timeTestDay"
                    style={{
                      marginBottom: 0,
                    }}>
                    <Slider min={0} max={27} />
                  </Form.Item>
                )}
                {radioValue == "Hour" && (
                  <Form.Item
                    label="Hour"
                    name="timeTestHour"
                    style={{
                      marginBottom: 0,
                    }}>
                    <Slider min={0} max={72} />
                  </Form.Item>
                )}
                <br />
                <button type="submit">Calculate</button>
              </Form>
            </div>
          </div>
        </Content>
      </div>
      <Footer/>
    </Layout>
  );
};

export default HCGCalc;
