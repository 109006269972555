import React, { useState, useEffect, ChangeEvent } from "react";
import { Layout,  message, Slider, Card, Image, Collapse, Col } from "antd";
const { Header, Content } = Layout;
import Headersection from "views/MainHeader/Header";
import { kickCounter } from "../../../../apis/btpTools";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Play from "../icon/Play.svg";
import Contraction from "../icon/contraction.svg";
import Pause from "../icon/Pause.svg";
import Reset from "../icon/Reset.svg";
import Start from "../icon/Start.svg";
import Flag from "../icon/Flag.svg";
import Kick from "../icon/Kick.svg"
import Legs from "../icon/Legs.svg"

const { Panel } = Collapse;

const KickCounter = () => {
  const history = useHistory();

  const hndleClickHome = () => {
    history.push("/");
  };



  const [showContraction, setShowContraction] = useState(false);
  const [showStart, setShowStart] = useState(false);

  const handleClickPlay = () => {
    setShowContraction(true);
    handleStart()
  };

  const [elapsedTime, setElapsedTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [sessionData, setSessionData] = useState<any>([]);
  const [startTime, setStartTime] = useState<any>("");
  const handleStart = () => {
    if (!timer) {
      const interval = setInterval(() => {
        setElapsedTime(prevElapsedTime => prevElapsedTime + 1);
      }, 1000);
      setTimer(interval);
      setShowStart(true);
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = elapsedTime % 60;
      const timerStart = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const m = String(currentDate.getMinutes()).padStart(2, '0');
    const s = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${m}:${s}.${milliseconds}`;


      setStartTime(formattedDate);
      setSessionData((prevData: any) => [...prevData, { timeStart: timerStart, Timerpaused: "" , kick: 0}]);
    }
  };

  const handlePause = () => {
    if (timer) {
      clearInterval(timer);
      setTimer(null);
      setShowStart(false);
      const minutes = Math.floor(elapsedTime / 60);
      const seconds = elapsedTime % 60;
      const timerPaused = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;



    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const m = String(currentDate.getMinutes()).padStart(2, '0');
    const s = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${m}:${s}.${milliseconds}`;


      let data = JSON.stringify({
        startTime: startTime,
        EndTime: formattedDate,
        totalKicks: count,
      });
      kickCounter(data)
        .then(res => {
          console.log("res.message eye colour", res);
        })
        .catch(err => {
          message.error(err);
        });

      setSessionData((prevData: any) => {
        const updatedData = [...prevData];
        if (updatedData.length > 0) {
          updatedData[updatedData.length - 1].Timerpaused = timerPaused;
        }
        return updatedData;
      });

    setSessionData((prevData: any) => {
        const updatedData = [...prevData];
        if (updatedData.length > 0) {
          const lastItem = updatedData[updatedData.length - 1];
          lastItem.Timerpaused = timerPaused;
          lastItem.kick = count
        }
        return updatedData;
      });

    }
  };

  console.log("session", sessionData);


  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  const minutes = Math.floor(elapsedTime / 60);
  const seconds = elapsedTime % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  const [sliderValue, setSliderValue] = useState(1);

  useEffect(() => {
    setSliderValue(seconds);

  }, [seconds]);
  useEffect(()=>{
    if(showStart==false){
        setCount(0)
    }
  })



  const handleChange = (e: any) => {
    setSliderValue(seconds);
  };

  const handleReset = () => {
    setElapsedTime(0);
    setSliderValue(1);
    setSessionData([]);
    setCount(0)
  };

  const [count, setCount] = useState(0);

  const increaseNumber = () => {
    setCount(count + 1);
  };
  return (
    <>
      <Layout className="h-screen">
        <Header className="" style={{ position: "fixed", zIndex: 1, width: "100%" }}>
          <Headersection />
        </Header>
        <div className=" bg-white mt-15">
          <Content className="container blood-type-preg">
            <div className=" bg-white mt-20" style={{ borderRadius: "15px" }}>
              {showContraction == false && (
                <Card style={{ border: "none" }}>
                  <div className="preg-calc-card-res">
                    <div className="rectangle-parent-contraction">
                      <div className="frame-child-blood-contraction">
                        <div className="line-icons-parent">
                          <ArrowLeftOutlined className="line-icons" />
                          <div className="pregnancy-weight-gain">
                            <button onClick={hndleClickHome}>Baby Kick Counter</button>
                          </div>
                        </div>

                        <div
                          style={{
                            marginLeft: "30%",
                            marginRight: "30%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                          }}>
                          <div style={{ marginBottom: "20px", marginTop: "90px" }}>
                            <Image alt="" src={Contraction} width={200} height={200} preview={false} style={{ borderRadius: "100%" }} />
                          </div>
                          <p
                            style={{
                              fontSize: "48px",
                              fontWeight: "400",
                              lineHeight: "130%",
                              color: "#fff",
                            }}>
                            00:00
                          </p>
                          <div style={{ marginTop: "20px" }}>
                            <Image
                              alt=""
                              src={Play}
                              width={160}
                              height={160}
                              preview={false}
                              style={{ borderRadius: "100%", cursor: "pointer" }}
                              onClick={handleClickPlay}
                            />
                          </div>
                          <p style={{fontSize:"17px",fontWeight:"bold",textAlign:"center"}}>Press play button to start</p>
                        </div>
                      </div>
                    </div>

                    <div className="group-wrapper-faq-ques">


                    </div>

                  </div>
                </Card>
              )}

              {showContraction == true && (
                <Card style={{ border: "none" }}>
                  <div className="preg-calc-card-res">
                    <div className="rectangle-parent">
                      <div className="frame-child-blood"></div>
                      <div className="line-icons-parent">
                        <ArrowLeftOutlined className="line-icons" />
                        <div className="pregnancy-weight-gain">
                          <button onClick={hndleClickHome}>Baby Kick Counter</button>
                        </div>
                      </div>

                      <div
                        style={{
                          marginLeft: "30%",
                          marginRight: "30%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}>
                        <div style={{ marginBottom: "20px", marginTop: "90px" }}>
                          <Image alt="" src={Contraction} width={200} height={200} preview={false} style={{ borderRadius: "100%" }} />
                        </div>
                      </div>
                    </div>

                    {showStart == false && (
                      <div className="group-wrapper-faq-ques" style={{ height: "auto" }}>
                        <div>
                          <div>
                            <p>{formattedTime}</p>
                          </div>
                          <Col span={24}>
                            <Slider
                              min={1}
                              max={60}
                              value={sliderValue}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                              }}
                              trackStyle={{
                                backgroundColor: "#ED4D41",
                                height: "6px",
                              }}
                              handleStyle={{
                                borderColor: "#ED4D41",
                                height: "18px",
                                width: "18px",
                                marginTop: "-6px",
                                backgroundColor: "#ED4D41",
                              }}
                            />
                          </Col>
                          <div style={{ padding: "5%", textAlign: "center" }}>
                            {sessionData?.length !== 0 && (
                              <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                <div style={{ flex: 1 }}>
                                  <p>
                                    <span>&nbsp;</span>
                                  </p>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <p>Duration</p>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <p>Kick</p>
                                </div>
                              </div>
                            )}

                            {sessionData
                              .slice()
                              .reverse()
                              .map((val: any, index: number, arr: any[]) => {
                                const timeStringToDate = (time: string): Date => {
                                  const [hours, minutes] = time.split(":").map(Number);
                                  const date = new Date();
                                  date.setHours(hours, minutes, 0, 0);
                                  return date;
                                };

                                const timeStart = val?.timeStart;
                                const timerPaused = val?.Timerpaused;
                                const startDate = timeStringToDate(timeStart);
                                const pausedDate = timeStringToDate(timerPaused);
                                const difference = pausedDate.getTime() - startDate.getTime();
                                const durationHours = Math.floor(difference / (1000 * 60 * 60));
                                const durationMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                                const durationString = `${durationHours.toString().padStart(2, "0")}:${durationMinutes
                                  .toString()
                                  .padStart(2, "0")}`;

                                const adjustedIndex = sessionData.length - index - 0;
                                return (
                                  <>
                                    <div style={{ display: "flex", justifyContent: "space-between", textAlign: "center" }}>
                                      <div className="flex" style={{ flex: 1 }}>
                                        <img src={Flag} style={{ width: "14px", height: "14px" }} className="mt-1" />
                                        &nbsp;
                                        <p>{adjustedIndex}</p>
                                      </div>
                                      <div style={{ flex: 1 }}>
                                        <p key={adjustedIndex}>{val?.Timerpaused}</p>
                                      </div>
                                      <div style={{ flex: 1 }}>{val?.kick}</div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </div>

                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <div className="text-center">
                            <Image src={Start} width={140} height={140} preview={false} className="cursor-pointer" onClick={handleStart} />
                            <p className="cursor-pointer" onClick={handleStart}>
                                {sessionData.length==0 ? <span>Start</span>:<span>Start again</span>}

                            </p>
                          </div>

                          <div className="text-center">
                            <Image src={Reset} width={140} height={140} preview={false} className="cursor-pointer" onClick={handleReset} />
                            <p className="cursor-pointer" onClick={handleReset}>
                              Reset
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {showStart == true && (
                      <div className="group-wrapper-faq-ques" style={{ height: "auto" }}>
                        <div>
                          <div className="frame-parent mt-5">
                          {/* className="frame-group" */}
                            <div   style={{display:"flex",justifyContent:"space-between",
                        width:"100%"
                        }}>

                              <div className="o" style={{ textAlign: "center" }}>
                                <p style={{ fontSize: "48px", color: "#333333" }}>{formattedTime}</p>
                              </div>

                              <div style={{display:"flex", textAlign:"center"}}>

                              <div className="o" style={{ textAlign: "center" }}>
                                {/* <p style={{ fontSize: "48px", color: "#333333" }}>{formattedTime}</p> */}
                                <Image
                                src={Legs}
                                width={40}
                                height={50}
                                preview={false}
                                className="cursor-pointer"
                                onClick={handlePause}
                                style={{marginTop:"10px"}}
                              />
                              </div>

                              <div className="o" style={{ textAlign: "center" }}>
                                <p style={{ fontSize: "48px", color: "#333333" }}>{count}</p>
                              </div>

                              </div>


                            </div>
                          </div>

                          <Col span={24}>
                            <Slider
                              min={1}
                              max={60}
                              value={sliderValue}
                              onChange={handleChange}
                              style={{
                                width: "100%",
                              }}
                              trackStyle={{
                                backgroundColor: "#ED4D41",
                                height: "6px",
                              }}
                              handleStyle={{
                                borderColor: "#ED4D41",
                                height: "18px",
                                width: "18px",
                                marginTop: "-6px",
                                backgroundColor: "#ED4D41",
                              }}
                            />
                          </Col>
                        </div>

                        <div className="frame-parent mt-5">
                          <div className="frame-group">


                          <div className="o" style={{ textAlign: "center" }}>
                              <Image
                                src={Kick}
                                width={130}
                                height={130}
                                preview={false}
                                className="cursor-pointer"
                                onClick={increaseNumber}
                              />
                              <p className="cursor-pointer" onClick={increaseNumber}>
                                Kick
                              </p>
                            </div>

                            <div className="o" style={{ textAlign: "center" }}>
                              <Image
                                src={Pause}
                                width={130}
                                height={130}
                                preview={false}
                                className="cursor-pointer"
                                onClick={handlePause}
                              />
                              <p className="cursor-pointer" onClick={handlePause}>
                                Resume
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              )}
            </div>
          </Content>
        </div>
      </Layout>
    </>
  );
};

export default KickCounter;
