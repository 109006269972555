import { apiGet, apiPost, getDefaultAxiosConfig } from "./btpApi";
import { getAccessToken } from "actions/auth";


export type TPregTools={
    message:string;
    week:string;
    userId:number;
    pregnancyInfo:{
        pregnancyWeightGain:number;
        pregnancyStatus:string;
        bodymassindex:number;
        targetWeight:string
    }
}

export type TEyeColor={
    possibleEyeColor:[
        {
            color: string,
            chances: string
        },
    ],
    motherEyeColor:string,
    fatherEyeColor:string
}


export type TEyeColorDetails={
    toolsdata:[
        {
            fatherEyeColor:string,
            motherEyeColor:string,
            createdDate:string,
            updatedDate:string
        }
    ]
}

export const pregnancy_calculator = async (data: string) => {
    const url = "api/tools/v1/pregnancy-calculator";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TPregTools, {}>(url,data, { ...newconfig, skipAuthRefresh: true, });
};

export const hcg_calculator = async (data: string) => {
    const url = "api/tools/v1/hcg-calculator";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TPregTools, {}>(url,data, { ...newconfig, skipAuthRefresh: true, });
};

export const blood_type = async (data: string) => {
    const url = "api/tools/v1/blood-type";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TPregTools, {}>(url,data, { ...newconfig, skipAuthRefresh: true, });
};

export const eye_colour = async (data: string) => {
    const url = "api/tools/v1/eye-color";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TEyeColor, {}>(url,data, { ...newconfig, skipAuthRefresh: true, });
};

export const contraction_tool = async (data: string) => {
    const url = "api/tools/v1/contraction";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TEyeColor, {}>(url,data, { ...newconfig, skipAuthRefresh: true, });
};


export const kickCounter = async (data: string) => {
    const url = "api/tools/v1/kick-counter";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TEyeColor, {}>(url,data, { ...newconfig, skipAuthRefresh: true, });
};


export const getToolsDetailsData = async (data: string) => {
    const url = "api/tools/v1/tools-data?tool=eyeColor";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiGet<TEyeColorDetails>(url,);
};



