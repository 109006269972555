import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getGroupsCore } from "../../../views/Groups/btpGroupApi";
import { Button } from "antd";

const RecomndedGroupList = () => {
  const [pageInfo, setPageInfo] = useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [groupsData, setGroupsData] = useState([]);
  const getGroupsData = async () => {
    let data1 = await getGroupsCore({ recommended: true }, pageInfo);
    setTotalPages(data1?.data?.pageInfo?.totalCount || 0);
    setGroupsData(data1.data.groups);
  };

  useEffect(() => {
    getGroupsData();
  }, [pageInfo]);

  useEffect(() => {
    getGroupsData();
  }, [pageInfo]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <h2 className="pink_text text-sm font-medium text-left">Recommended Groups</h2>
        </div>
        <div>
          <div className="text-right pb-3 py-2 underline text-red-300">
            <Link to="/groups" className="text-red-800 !hover:text-red-300">
              View all
            </Link>
          </div>
        </div>
      </div>
      <div className="overflow-y-scroll h-full momes_border" style={{ maxHeight: "300px" }}>
        {groupsData?.map((val: any, index: number) => {
          return (
            <div className="flex  mb-3 border_align" key={index}>
              <div className="mr-3">
                <img src={val?.imageUrl} alt="" className="h-12 w-12 object-cover rounded-full" style={{ borderRadius: "50%" }} />
              </div>
              <div>
                <h3>{val?.groupName}</h3>
                <p>{val?.focus}</p>
                <div className="flex my-2">
                  <div className="mr-3">
                    <Link to={`/group-profile/${val._id}`}>
                      <Button>View</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecomndedGroupList;
