import { Card, Empty } from "antd";

import { getFeedsApi } from "apis/GetFeeds";
import { useInfiniteQuery } from "react-query";


const MustRead = () => {

  let { data: feedData, isLoading: isFeedLoading, isError: isFeedError } = useInfiniteQuery('getFeed', getFeedsApi, {});
  // console.log(feedData && feedData);



  return (
    <div
      //  ref={stickyRef}
      className="sticky"
      style={{ top: "100px" }}
    >
      <div className="px-3 border   rounded-tr-md rounded-tl-md   rounded-br-none  rounded-bl-none" >
        <h2 className="my-3 pink_text text-sm font-medium text-left ">MUST READ</h2>
        {/* {icon_weekwise} */}
      </div>
      <Card className="w-full h-60 overflow-y-scroll rounded-tr-md rounded-tl-md   rounded-br-none  rounded-bl-none ">
        {
          feedData &&
          feedData?.pages?.map((page: any, i: number) => {
            return page.data?.map((data: any) => {
              return (<>
                {
                  (data?.type === 'article') &&
                  <div key={i + 1} className="flex mb-3 border_align">
                    <div className="mr-3">
                      <a href={data.article.permalink
                      }>
                        <img
                          src={data.article
                            .thumbnailImage}
                          alt=""
                          className="mark_image"
                        />
                      </a>
                    </div>
                    <div>
                      <a href={data.article.permalink
                      } style={{ color: 'black' }}>
                        <p className="text-left">{data.article.title}</p></a>
                    </div>
                  </div>}</>
              )
            });
          })
        }
      </Card>
    </div>

  )
}

export default MustRead;
