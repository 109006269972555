import { logIn } from "apis/Login";
import { ACCESS_TOKEN_LS_KEY } from "libs/Constants";
import { useHistory } from "react-router-dom";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { push } from "react-router-redux";
import { TUserDetailRequest, TUserDetails, viewprofile } from "./viewprofile";
import { TUpdateProfileResponse } from "./updateprofile";

export const getAccessToken=()=>{
    try {
        const data = window.localStorage.getItem(ACCESS_TOKEN_LS_KEY);
        const {Accesstoken} = data && JSON.parse(data);
        return Accesstoken;
      } catch (e) {
        return null;
      }
}

export type TLogInRequest = {
    Email: string;
    username: string;
    GID: string;
    Name: string;
    password: string;
  };
export type TLogInResponse = {
    status: boolean,
    message: string,
    data: TLogData
  };
  export type TParentInfo={
    parent_stage:string
    due_date:string
    dob:string
    logged_in_status:string
    avatar_name:string
    profile_pic:string
    gender:string
    country:string
    pincode:string
    gender_pic:string
    profile_pic_url:string
  }
export type TLogData = {
    Accesstoken: string,
    userId: string,
    fullname: string,
    wp_user_id: string,
    username_momnexus: string,
    stagingInfo: string,
    email_verification_status: string,
    mail: string,
    parent_info:TParentInfo | null
  };
  
export const logInInitAction = createAction("users/LOGIN_INIT")();
export const logInSuccessAction = createAction("users/LOGIN_SUCCESS")<TLogData>();
export const logInFailureAction = createAction("users/LOGIN_FAILURE")<Error>();


export const logOutAction = createAction("users/LOGOUT")();

export type TLogInInitAction = ActionType<typeof logInInitAction>;
export type TLogInSuccessAction = ActionType<typeof logInSuccessAction>;
export type TLogInFailureAction = ActionType<typeof logInFailureAction>;
export type TLogOutAction = ActionType<typeof logOutAction>;

export const authProfileUpdateAction = createAction("users/AUTHPROFILE_UPDATE")<Partial<TUserDetails>>();
export type TAuthProfileUpdate = ActionType<typeof authProfileUpdateAction>;

export type TLogInActions =
  | TLogInInitAction
  | TLogInSuccessAction
  | TLogInFailureAction
  | TAuthProfileUpdate;
export type TLoginThunkActionType = ThunkAction<void, TRootState, null, TLogInActions>;

type TLoginAction = (payload: TLogInRequest) => TLoginThunkActionType;

export const signIn: TLoginAction = (payload: TLogInRequest) => (
    dispatch: (action: Action | Promise<Action> | ThunkAction<void, TRootState, null, TLogInActions>) => any
  ) => {
  dispatch(logInInitAction());
  return logIn(payload)
  .then(async(res) => {
      if(res.status){
        saveAccessToken(res.data)
        dispatch(logInSuccessAction(res.data));
      let reqBody = { user_id: res.data.userId };
      dispatch(viewprofile(reqBody as TUserDetailRequest, res.data.Accesstoken));
        dispatch(push('/'))
      }else{
        dispatch(logInFailureAction({message:res.message} as Error))
      }
  })
}

export type TAuthActions =
| TLogInActions

// save access token
export function saveAccessToken(accessToken: TLogData) {
  const auth = JSON.stringify({...accessToken});
  window.localStorage.setItem(ACCESS_TOKEN_LS_KEY, auth);
}

const clearstorage = () => {
  window.localStorage.removeItem(ACCESS_TOKEN_LS_KEY);
}