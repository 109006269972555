import { followUser } from "apis/GetFeeds";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { getFeedFollowAction } from "./getfeed";

export type TConnectRequest = {
  requestee_id: string;
};

export type TConnectResponse = {
  status: boolean;
  message: string;
  data: any;
};

export const connectReqInitAction = createAction("users/CONNECTUSER_INIT")();
export const connectUserSuccessAction = createAction(
  "users/CONNECTUSER_SUCCESS"
)<TConnectResponse>();
export const connectUserErrorAction = createAction(
  "users/CONNECTUSER_ERROR"
)<Error>();

export type TConnectUserInitAction = ActionType<typeof connectReqInitAction>;
export type TConnectUserSuccessAction = ActionType<
  typeof connectUserSuccessAction
>;
export type TConnectUserErrorAction = ActionType<typeof connectUserErrorAction>;

export type TConnectUserActions =
  | TConnectUserInitAction
  | TConnectUserSuccessAction
  | TConnectUserErrorAction;

export type TConnectUserThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TConnectUserActions
>;

type TConnectUserAction = (
  payload: TConnectRequest
) => TConnectUserThunkActionType;

export const followUserdispatcher: TConnectUserAction =
  (payload: any) =>
  (
    dispatch: (
      action:
        | Action
        | Promise<Action>
        | ThunkAction<void, TRootState, null, TConnectUserActions>
    ) => any
  ) => {
    dispatch(connectReqInitAction());
    return followUser(payload).then(async (res) => {
      if (res.status) {
        // console.log("followUser",res.status, res.data);
        dispatch(connectUserSuccessAction(res.data));
        if(res.status === true){
          dispatch(getFeedFollowAction(payload))
        }
      } else {
        dispatch(connectUserErrorAction({ message: res.message } as Error));
      }
    });
  };
