import React, { useEffect, useState } from "react";
import { Table, Button, message } from "antd";
import { findAllName, addFavName,getFavName } from "../../../apis/babyName";
import { useParams } from "react-router-dom";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import Swal from "sweetalert2";
import { viewProfileApi } from "../../../apis/btpViewProfile";
import { useQuery } from "react-query";
import NameDetails from "./NameDetails";

const alphabet = [
  {
    alphaval: "A",
  },
  {
    alphaval: "B",
  },
  {
    alphaval: "C",
  },
  {
    alphaval: "D",
  },
  {
    alphaval: "E",
  },
  {
    alphaval: "F",
  },
  {
    alphaval: "G",
  },
  {
    alphaval: "H",
  },
  {
    alphaval: "I",
  },
  {
    alphaval: "K",
  },
  {
    alphaval: "L",
  },
  {
    alphaval: "M",
  },
  {
    alphaval: "N",
  },
  {
    alphaval: "O",
  },
  {
    alphaval: "P",
  },
  {
    alphaval: "Q",
  },
  {
    alphaval: "R",
  },
  {
    alphaval: "S",
  },
  {
    alphaval: "T",
  },
  {
    alphaval: "U",
  },
  {
    alphaval: "V",
  },
  {
    alphaval: "W",
  },
  {
    alphaval: "X",
  },
  {
    alphaval: "Y",
  },
  {
    alphaval: "Z",
  },
];

const NameFilter = (props: any) => {
  const { type } = props;
  const params: any = useParams();
  const [typeValue, setTypeValue] = useState<any>();
  const [alphaValue, setAlphaVlaue] = useState<any>();
  const [apiData, setapiData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(20);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const[getFavNameData,setFavNameData]=useState<any>([])
  const {
    isLoading: loading,
    data: userData,
    refetch,
  } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );
  // alphabet: alphaValue || "a",
  const fetchName = (alphaValue: any | undefined, page: number) => {
    if (!typeValue) {
      return;
    }
    let data = JSON.stringify({
      page: 1,
      pageLimit: pageLimit,
      alphabet: alphaValue,
      type: typeValue,
      religion: params?.id,
    });
    setLoadingMore(true);
    findAllName(data)
      .then((res) => {
        setapiData(res.matchedNames);
        setLoadingMore(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    fetchName(alphaValue, currentPage);
  }, [typeValue, alphaValue, currentPage, pageLimit,params?.id]);

  useEffect(() => {
    if (type == 1) {
      setTypeValue("Boy");
    } else if (type == 2) {
      setTypeValue("Girl");
    } else {
      setTypeValue("Unisex");
    }
  }, [props]);

  const handleClick = (e: any, val: any) => {
    fetchName(val.alphaval, currentPage);
    setAlphaVlaue(val.alphaval);
    setCurrentPage(1);
  };

  const handleLoadMore = () => {
    setPageLimit((prevPageLimit) => prevPageLimit + 10);
    fetchName(alphaValue, currentPage);
  };



  const handleNameClick = (record: any) => {
    if (record && record.name) {
      let data = JSON.stringify({
        userId: userData?.sk_user_id,
        name: record.name,
      });
      addFavName(data)
        .then((res) => {
          getFavNameList()
          Swal.fire({
            icon: "success",
            title: "Name added to your favourite list !",
            showConfirmButton: false,
            timer: 900,
          });
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const [propData, setPropData] = useState<any>({});
  const handleNClick = (record: any) => {
    if (record && record.name) {
      setIsModalOpen(true);
      setPropData(record);
    }
  };

  const getFavNameList = () => {
    if (userData && userData.sk_user_id) {
      getFavName(userData.sk_user_id)
        .then((res) => {
          setFavNameData(res?.matchedData);
        })
        .catch((err) => {
          message.error(err);
        });
    } else {
     message.error("error")
    }
  };

  useEffect(() => {
    getFavNameList();
  }, [userData && userData.sk_user_id]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any) => <a>{text}</a>,
      onCell: (record: any) => ({
        onClick: () => {
          handleNClick(record);
        },
      }),
    },
    {
      title: "Meaning",
      dataIndex: "meaning",
      key: "meaning",
    },
    {
      title: "Numerology",
      dataIndex: "numerology",
      key: "numerology",
    },
    {
      title: "Religion",
      dataIndex: "religion",
      key: "religion",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Fav",
      dataIndex: "fav",
      key: "fav",
      render: (fav: any,record: any) => {
        const isFav = getFavNameData?.some((getFavNameData: any) => getFavNameData._id == record?._id);
        return isFav ?  <HeartFilled className="darkFav-icon" /> : <HeartOutlined className="outlineFav-icon" />;
      },
      onCell: (record: any) => ({
        onClick: () => {
          handleNameClick(record);
        },
      }),
    },
  ];

  return (
    <>
      {isModalOpen && (
        <NameDetails
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          propData={propData}
        />
      )}

      <div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {alphabet.map((val: any, index: number) => {
            return (
              <>
                <div key={index} className="alphabetButtonWrapper">
                  <div
                    className="alphabetButton"
                    onClick={(e) => handleClick(e, val)}
                  >
                    <button>{val?.alphaval}</button>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <hr className="mt-5" />
        <div className="mt-5">
          <Table
            columns={columns}
            dataSource={apiData}
            pagination={false}
            className="cursor-pointer"
          />
          {
            apiData?.length!==0 &&
          <Button
            style={{ width: "100%" }}
            onClick={handleLoadMore}
            loading={loadingMore}
          >
            Load more
          </Button> }
        </div>
      </div>
    </>
  );
};

export default NameFilter;
