import React, { Fragment } from "react";
import { Card, Row, Col, Space } from "antd";

const Tools = (props: any) => {
  let deatil = props.data;

  return (
    <div className="line">
      <Row align="middle" justify="space-between" className="mt-3">
        <Col span={9}>
          <Space align="center">
            <div>
              <h3>Tools for you</h3>
            </div>
          </Space>
        </Col>
        {/* <Col span={15} style={{ textAlign: 'right', color: '#ED4D41' }}>
                    View All
                </Col> */}
      </Row>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <div className="flex items-center overflow-x-auto mt-3 horizontal-scroll-container">
          {deatil?.map((value: any, i: number) => {
            return (
              <Fragment key={i}>
                {(value.name == "Baby Name Generator" ||
                  value.name == "Pregnancy Weight Calculator" ||
                  value.name == "HCG Calculator" ||
                  value.name === "Baby Eye Colour Calculator" ||
                  value.name === "Contraction Calculator" ||
                  value.name === "Baby Kick Counter") && (
                  <div>
                    <div
                      style={{
                        width: "240px",
                        margin: "0 8px",
                        height: "300px",
                        textAlign: "left",
                        objectFit: "cover",
                      }}>
                      <Card
                        style={{
                          borderRadius: "8px",
                          backgroundColor: "#FFF",
                          objectFit: "cover",
                          padding: "10px",
                        }}>
                        {value.name == "Baby Name Generator" && (
                          <a href="/babynames" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )}
                        {value.name == "Pregnancy Weight Calculator" && (
                          <a href="/pregnancy-calculator" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )}

                        {value.name == "HCG Calculator" && (
                          <a href="/hcg-calculator" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )}

                        {value.name == "Baby Eye Colour Calculator" && (
                          <a href="/baby-eye" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )}

                        {/* {value.name == "Child Blood Type Calculator" && (
                          <a href="/blood-type" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )} */}

                        {/* Contraction Calculator */}
                        {value.name == "Contraction Calculator" && (
                          <a href="/contraction" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )}

                        {value.name == "Baby Kick Counter" && (
                          <a href="/kick-counts" target="_blank">
                            <img
                              src={value.icon}
                              alt={value.icon}
                              style={{
                                height: "230px",
                                objectFit: "cover",
                              }}
                            />
                          </a>
                        )}
                      </Card>
                      <h3 className="mt-3 ml-1">{value.name}</h3>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Tools;
