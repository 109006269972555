import React, { useEffect, useState } from "react";
import {
  Radio,
  Space,
  Button,
  Form,
  DatePicker,
  Input,
  Modal,
  Select,
} from "antd";
import type { RadioChangeEvent } from "antd";
import {
  updateprofile,
  TUpdateProfileRequest_parenting_duedate,
} from "actions/updateprofile";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "reducers";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import type { RangePickerProps } from "antd/es/date-picker";
import { Spin } from "antd";
const { Option } = Select;
import { viewProfileApi } from "apis/btpViewProfile";
import { useQuery } from "react-query";
import Swal from "sweetalert2";

const PregnancyInfo = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState<any>();
  const [selectedVal, setSelectedVal] = useState<number>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoadingf, setIsLoading] = useState(false);
  const [duedateval, setDueDateValue] = useState<any>();
  const [calduedateVal, setCalDueDateValue] = useState<any>();

  const { isLoading, data, refetch } = useQuery("viewProfile", () =>
    viewProfileApi({ user_id: "", enabled: false })
  );

  const profileData = data;
  const pregnacyInfo = useSelector((state: TRootState) => state.userprofile);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const calculateDate = async () => {
    let reqModalBody = (await formModal.validateFields()) as any;
    var dontknowdate = moment(date, "DD-MM-YYYY");
    const new_date_day = moment(dontknowdate).add(selectedVal, "days");
    const newduedatemonth = moment(new_date_day).add(9, "months");
    const finalduedate = newduedatemonth;
    setIsModalVisible(false);
    form.setFieldsValue({
      due_date: moment(finalduedate, "DD-MM-YYYY"),
    });
  };

  const { Accesstoken, userId } = useSelector(
    (state: TRootState) => state.auth
  );
  const [form] = Form.useForm();
  const [formModal] = Form.useForm();

  function onChange(date: any, dateString: any, abc: any) {
    const value = moment(dateString);
    setDueDateValue(dateString);
  }

  const onFinish = async (values: any) => {
    setIsLoading(true);
    let reqBody = (await form.validateFields()) as any;
    delete reqBody.datepicker;
    reqBody.type = "parenting_due_date";
    reqBody.sk_user_id = profileData?.sk_user_id;
    // reqBody.i_am_1 = JSON.parse(pregnacyInfo.i_am);
    reqBody.due_date_parent = moment(form.getFieldValue("due_date")).format(
      "YYYY-MM-DD"
    );
    Swal.fire({
      icon: "success",
      showConfirmButton: false,
      timer: 900,
    });
    await dispatch(
      updateprofile(
        reqBody as TUpdateProfileRequest_parenting_duedate,
        Accesstoken,
        (res) => handleCallback(res)
      )
    );
    setIsLoading(false);
  };

  const handleCallback = (res: any) => {
    // console.log("Received values of form: ", res);
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log("Failed:", errorInfo);
  };

  const handleChange = (value: any) => {
    setSelectedVal(value.value);
  };
  
  const config = {
    rules: [
      {
        type: "object" as const,
        required: true,
        message: "Please select Date",
      },
    ],
  };

  const cycle_length = {
    rules: [
      {
        type: "object" as const,
        required: true,
        message: "Please select Cycle Length",
      },
    ],
  };


  // const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  //   const dueDateParent = profileData?.due_date_parent;
  //   if (!dueDateParent) {
  //     return current && current < moment().endOf("day");
  //   }
  //   if (current.isSame(dueDateParent, "day")) {
  //     return true;
  //   }
  //   if (current.isBefore(dueDateParent, "day")) {
  //     return true;
  //   }
  //   const nineMonthsAfterDueDate = moment(dueDateParent).add(9, "months");
  //   return (
  //     current &&
  //     (current < moment().endOf("day") || current > nineMonthsAfterDueDate)
  //   );
  // };


  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (!current) {
      return false; 
    }
    const currentDate = moment();
    console.log("currentDate",currentDate);
    
    const nineMonthsAfterCurrentDate = currentDate.clone().add(9, "months");
    console.log("nineMonthsAfterCurrentDate",nineMonthsAfterCurrentDate);
    
    return current.isBefore(currentDate, "day") || current.isAfter(nineMonthsAfterCurrentDate, "day");
  };


 
  
  

  

  const onSelectDate = (date: any, dateString: any) => {
    setDate(dateString);
  };


  useEffect(() => {
    const { due_date_parent, i_am } = pregnacyInfo;
    if (i_am !== "" && i_am !== null && i_am) {
      form.setFieldsValue({
        i_am: i_am,
      });
    }
    if (due_date_parent !== "" && due_date_parent !== null && due_date_parent) { 
      form.setFieldsValue({
        due_date: moment(due_date_parent, "DD-MM-YYYY"),
      });
    }else if(profileData?.due_date_parent=="0000-00-00" ||profileData?.due_date_parent== "Invalid date"){
      form.setFieldsValue({
        due_date: null,
      });
    } else if(profileData?.due_date_parent==null || profileData?.due_date_parent==undefined){
      form.setFieldsValue({
        due_date: null,
      });
    }
    else {
      form.setFieldsValue({
        due_date: moment(profileData?.due_date_parent, moment.ISO_8601),
      });
      
    }
    console.log(profileData?.due_date_parent);
  }, [pregnacyInfo, form]);


  // console.log(profileData?.due_date_parent=="0000-00-00");
  
  



  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 18, color: "white" }}
      className="text-center"
      spin
    />
  );
  return (
    <div className="final-body">
      <div>
        <Form
          name="parenting_due_date"
          form={form}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          autoComplete="off"
          className="input-sty"
        >
          <Form.Item name={"i_am"} hidden>
            <input type="text" />
          </Form.Item>
          <Form.Item name="due_date" label={"Due Date"}>
            <DatePicker
              format={"DD-MM-YYYY"}
              disabledDate={disabledDate}
              onChange={(date, dateString) => onChange(date, dateString, 1)}
            />
          </Form.Item>
          {/* <a className="underline" onClick={showModal}>
            I don't know
          </a> */}
        </Form>

        <Modal
          title={"Due Date"}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="modal-sty"
        >
          <Form
            name="parenting_due_date"
            form={formModal}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
            className="input-sty"
          >
            <Form.Item
              name="datepicker"
              label="First Day Of Your Period"
              {...config}
            >
              <DatePicker
                onChange={onSelectDate}
                format="DD-MM-YYYY"
                disabledDate={(current) => {
                  return moment().add(0, "days") <= current;
                }}
              />
            </Form.Item>

            <Form.Item
              name="cycle_length"
              label="Cycle length"
              {...cycle_length}
            >
              <Select
                labelInValue
                style={{ width: "100%" }}
                onChange={handleChange}
                placeholder="Select cycle length"
              >
                <Option value="0">21 days</Option>
                <Option value="1">22 days</Option>
                <Option value="2">23 days</Option>
                <Option value="3">24 days</Option>
                <Option value="4">25 days</Option>
                <Option value="5">26 days</Option>
                <Option value="6">27 days</Option>
                <Option value="7">28 days</Option>
                <Option value="8">29 days</Option>
                <Option value="9">30 days</Option>
                <Option value="10">31 days</Option>
                <Option value="11">32 days</Option>
                <Option value="12">33 days</Option>
                <Option value="13">34 days</Option>
                <Option value="14">35 days</Option>
                <Option value="15">36 days</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="button-sty float-right mt-5"
                danger
                onClick={calculateDate}
              >
                Done
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Button
          type="primary"
          className="button-sty mt-5 float-right"
          danger
          onClick={onFinish}
        >
          <span className="mr-2"> Save Changes</span>
          {isLoading && <Spin indicator={antIcon} />}
        </Button>
      </div>
    </div>
  );
};

export default PregnancyInfo;
