import {
    Card, Carousel, Skeleton, Tabs, Input, Collapse, Button, Modal, Badge,
    DatePicker, Space,
    Form
} from "antd";
import React, { useState, useEffect } from 'react';
// import Calendar,{ CalendarTileProperties } from 'react-calendar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getBannerApi, getLikesapi, getListsApi, getFeedsApi, postCalendar, TCalendarData } from "apis/GetFeeds"
import {
    BellOutlined,
    DownOutlined,
    FileOutlined,
    LogoutOutlined,
    SearchOutlined,
    UserOutlined,
    RightOutlined
} from "@ant-design/icons";

interface CustomTileClassNames {
    [date: string]: string;
}


interface ModalProps {
    openOverview: boolean;
    setOpenoverview: React.Dispatch<React.SetStateAction<boolean>>;
    data: {
        overview: {
            title: string,
            subTitle: string,
            fertileWindow: {
                start: string,
                end: string,
                color: string,
                title: string,
                date: string
            },
            ovulation: {
                start: string,
                end: string,
                color: string,
                title: string,
                date: string
            },
            period: {
                start: string,
                end: string,
                color: string,
                title: string,
                date: string
            },
            implantation: {
                start: string,
                end: string,
                color: string,
                title: string,
                date: string,
            }
        }
        subtitle: string,
        color: string
    }
}

const OverView = ({ openOverview, setOpenoverview, data }: ModalProps) => {
    const showModal = () => {
        setOpenoverview(true);
    };
    const handleOk = () => {
        setOpenoverview(false);
    };
    const handleCancel = () => {
        setOpenoverview(false);
    };

    // console.log(data && data);
    console.log(data && data.overview.fertileWindow.start);

    const [renderedDates, setRenderedDates] = useState<Set<string>>(new Set());
    const customTileClassNames: CustomTileClassNames = {
        ...(data && data.overview.period.date && { [data.overview.period.date]: 'period' }),
        ...(data && data.overview.ovulation.date && { [data.overview.ovulation.date]: 'ovulation' }),
        ...(data && data.overview.fertileWindow.start && { [data.overview.fertileWindow.start]: 'fertileWindow' }),
        ...(data && data.overview.fertileWindow.end && { [data.overview.fertileWindow.end]: 'fertileWindow' }),
        ...(data && data.overview.implantation.date && { [data.overview.implantation.date]: 'implantation' }),
    };

    const tileContent = ({ date }: { date: Date }) => {
        const formattedDate = date.toISOString().slice(0, 10);
        const className = customTileClassNames[formattedDate];
        if (renderedDates.has(formattedDate)) {
            return null;
        }
        setRenderedDates(new Set(renderedDates).add(formattedDate));
        return (
            <div className={`custom-tile ${className ? className : ''}`}>
                {className && date.getDate()}
            </div>
        );
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Open Modal
            </Button>
            <Modal title="Set Up Tracker" open={openOverview} onOk={handleOk} onCancel={
                handleCancel}
                footer={null}
            >
                <div className="react_calendar_card" style={{
                    backgroundColor: '#F17A71',

                }}>
                    <div style={{
                        padding: '10px',
                    }}>
                        <h6>{data && data.overview.title}</h6>
                        <h6>{data && data.overview.subTitle}</h6>
                    </div>
                    <Form>
                        <div className="react_calendar_card_in">
                            <Calendar
                                tileContent={tileContent}
                                tileClassName={({ date }) => {
                                    const formattedDate = date.toISOString().slice(0, 10);
                                    const className = customTileClassNames[formattedDate];
                                    return className ? `custom-tile ${className}` : '';
                                }}
                            />
                            <Button className='claendar_btn' >
                            </Button>
                            <div style={{
                                display: 'flex',
                                padding: '20px',
                                textAlign: "justify",
                            }}>
                                <div className="left_warning" style={{ marginRight: 'auto' }}>
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            width: "21px",
                                            height: "21px",
                                            borderRadius: '50%',
                                            backgroundColor: data && data.overview.ovulation.color
                                        }}>
                                        </div>
                                        <div className="ml-2"><span> {data && data.overview.ovulation.title}</span></div>
                                    </div>

                                    <br />
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            width: "21px",
                                            height: "21px",
                                            borderRadius: '50%',
                                            backgroundColor: data && data.overview.fertileWindow.color
                                        }}></div>
                                        <div className="ml-2"><span> {data && data.overview.fertileWindow.title}</span></div>
                                    </div>
                                </div>

                                <div className="right_warning" style={{ marginLeft: 'auto' }}>
                                    <div style={{
                                        display: 'flex'
                                    }}>

                                        <div style={{
                                            width: "21px",
                                            height: "21px",
                                            borderRadius: '50%',
                                            backgroundColor: data && data.overview.period.color
                                        }}></div>
                                        <div className="ml-2"><span> {data && data.overview.period.title}</span></div>
                                    </div>


                                    <br />
                                    <div style={{
                                        display: 'flex'
                                    }}>
                                        <div style={{
                                            width: "21px",
                                            height: "21px",
                                            borderRadius: '50%',
                                            backgroundColor: data && data.overview.implantation
                                                .color
                                        }}></div>
                                        <div className="ml-2"><span> {data && data.overview.implantation
                                            .title}</span></div>
                                    </div>
                                </div>
                            </div>



                        </div>

                    </Form>


                    <div>

                    </div>
                </div>

                <div>

                </div>


            </Modal>
        </>
    )
}

export default OverView