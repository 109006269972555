import { followUser } from "apis/GetFeeds";
import { followingbutton } from "apis/likeunlikepost";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";
import { getFeedFollowAction } from "./getfeed";

export type TFollowUserRequest = {
  requestee_id: string;
};

export type TFollowUserResponse = {
  status: boolean;
  message: string;
  data: any;
};

export const followUserInitAction = createAction("users/FOLLOWUSER_INIT")();
export const followUserSuccessAction = createAction(
  "users/FOLLOWUSER_SUCCESS"
)<TFollowUserResponse>();
export const followUserErrorAction = createAction(
  "users/FOLLOWUSER_ERROR"
)<Error>();

export type TFollowUserInitAction = ActionType<typeof followUserInitAction>;
export type TFollowUserSuccessAction = ActionType<
  typeof followUserSuccessAction
>;
export type TFollowUserErrorAction = ActionType<typeof followUserErrorAction>;

export type TFollowUserActions =
  | TFollowUserInitAction
  | TFollowUserSuccessAction
  | TFollowUserErrorAction;

export type TFollowUserThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TFollowUserActions
>;

type TFollowUserAction = (
  payload: TFollowUserRequest
) => TFollowUserThunkActionType;

export const followUserdispatcher: TFollowUserAction =
  (payload: any) =>
  (
    dispatch: (
      action:
        | Action
        | Promise<Action>
        | ThunkAction<void, TRootState, null, TFollowUserActions>
    ) => any
  ) => {
    dispatch(followUserInitAction());
    return followingbutton(payload).then(async (res) => {
      console.log("payload", await res);
      if (res.status) {
        console.log("followUser",res.status, res.data);
        dispatch(followUserSuccessAction(res.data));
        if(res.status === true){
          dispatch(getFeedFollowAction(payload))
        }
      } else {
        dispatch(followUserErrorAction({ message: res.message } as Error));
      }
    });
  };
