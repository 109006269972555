import { individualChatHistoryApi } from "apis/message";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";


export type TSendMessageResponse = {
  status: boolean;
  message: string;
  data: any;
}
export type TPostResponse={
  status: boolean;
  message: string;
  data: any;
}

export type TDeleteChatHistoryResponse = {
  status: boolean;
  message: string;
  data: any;
}

export type TDeleteChatHistoryRequest = {
  receiver_id: string;
  message_id: string
}


export type TIndividualChatHistoryReq = {
  requestee_id: string;
};

export type TIndividualChatHistoryResponse = {
  status: boolean;
  message: string;
  data: any;
}

export type TRecentChatResponse = {
  status: boolean;
  message: string;
  data: any;
};

export type TMessageMedia = {
  sk_message_media_id: string;
  message_id: string;
  attachment_type: string;
  attachment_name: string;
  created_date_time: string;
  updated_date_time: string;
};

export type TMessageHistory = {
  sk_message_id: string;
  sender_id: string;
  receiver_id: string;
  message_description: string;
  message_visible_to: string;
  message_sent_date_time: string;
  message_delete_date_time: string;
  message_read_date_time: string;
  message_read_yes_or_no: string;
  message_media: Array<TMessageMedia>;
}

export type TChatHistory = {
  message_history: Array<TMessageHistory>;
};

export const individualChatHistoryInitAction = createAction(
  "users/INDIVIDUALCHATHISTORY_INIT"
)();
export const individualChatHistorySuccessAction = createAction(
  "users/INDIVIDUALCHATHISTORY_SUCCESS"
)<TChatHistory>();
export const individualChatHistoryFailureAction = createAction(
  "users/INDIVIDUALCHATHISTORY_FAILURE"
)<Error>();

export const individualChatHistoryUpdateAction = createAction(
  "users/INDIVIDUALCHATHISTORY_UPDATE"
)<TChatHistory>();


export type TIndividualChatHistoryInitAction = ActionType<
  typeof individualChatHistoryInitAction
>;
export type TIndividualChatHistorySuccessAction = ActionType<
  typeof individualChatHistorySuccessAction
>;
export type TIndividualChatHistoryFailureAction = ActionType<
  typeof individualChatHistoryFailureAction
>;

export type TIndividualChatHistoryUpdateAction = ActionType<
  typeof individualChatHistoryUpdateAction
>;



export type TIndividualChatHistoryActions =
  | TIndividualChatHistoryInitAction
  | TIndividualChatHistorySuccessAction
  | TIndividualChatHistoryFailureAction
  | TIndividualChatHistoryUpdateAction;

export type TIndividualChatHistoryThunkActionType = ThunkAction<
  void,
  TRootState,
  null,
  TIndividualChatHistoryActions
>;

type TIndividualChatHistoryAction = (
  payload: TIndividualChatHistoryReq
) => TIndividualChatHistoryThunkActionType;

export const individualChatHistorydispatcher: TIndividualChatHistoryAction =
  (payload: any) =>
  (
    dispatch: (
      action:
        | Action
        | Promise<Action>
        | ThunkAction<void, TRootState, null, TIndividualChatHistoryActions>
    ) => any
  ) => {
    dispatch(individualChatHistoryInitAction());
    return individualChatHistoryApi(payload).then(async (res) => {
      console.log("payload", await res);
      if (res.status) {
        console.log(res, res.data[0]);
        dispatch(individualChatHistorySuccessAction(res.data));
        if(res.status === true) {

        }
      } else {
        dispatch(
          individualChatHistoryFailureAction({ message: res.message } as Error)
        );
      }
    });
  };
