import { Link } from "react-router-dom";
import Connect from "../buttonsection/Connect";
import Followbutton from "../buttonsection/Followbutton";
const like_stylus = "border-2 rounded-md py-1 w-20 text-sm";

const HorizontalScroll = (props: any) => {
  const { vvv, title } = props;
  return (
    <div key={vvv?.type} className="like mt-3">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="pink_text text-sm font-medium text-left">{title}</h2>
        </div>
        <div>
          <div className="text-right pb-3 py-2 text-red-300">
            <Link to="/momslikeyou" className="text-red-800 !hover:text-red-300 link-no-underline">
              View all
            </Link>
          </div>
        </div>
      </div>

      <div className="horizontal-scroll-container">
        <div className="border-0" style={{ padding: "0px" }}>
          <div className="flex mb-3">
            {props.Likedlist?.map((vv: any, ind: number) => {
              return (
                <div key={ind} className="mb-3 border_align mr-3 card">
                  <div
                    style={{
                      width: "50%",
                      height: "50%",
                      textAlign: "justify",
                    }}>
                    <img
                      src={vv.profilePic}
                      alt=""
                      className="object-cover"
                      style={{
                        borderRadius: "50%",
                        width: "98px",
                        height: "98px",
                      }}
                    />
                  </div>
                  <div>
                    <Link to={"/momslikelistprofile/" + vv.userId}>
                      <h3>{vv.fullName}</h3>
                    </Link>
                    <p>{vv.stageName}</p>
                    <span style={{ fontSize: "12px" }}>{vv.mutualConnections} connections</span>
                    <div className="flex my-2">
                      <div className="mr-3">
                        <Followbutton {...vv} class={like_stylus} getConnectionList={props.getConnectionList} />
                      </div>
                      <div>
                        <Connect {...vv} getConnectionList={props.getConnectionList} />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalScroll;
