import { getAccessToken } from "actions/auth";
import { apiGet, apiPost, apiPut, getDefaultAxiosConfig } from "./btpApi";

export type TGetFeedRes = {
  status: boolean;
  message: string;
  hasNext: boolean;
  data: any;
  page: number;
};

export type TUArticleComment={
  status:boolean,
  data:{
    comments:[
      badge:string,
      createdDate:string,
      education:string,
      full_name:string,
      importance:number,
      postId:string,
      profilePic:string,
      updatedDate:string,
      userComment:string,
      userId:number,
      __v:number,
      _id:string,
      replyComments:[
        {
          badge:string,
          createdDate:string,
          education:string,
          full_name:string,
          importance:number,
          postId:string,
          profilePic:string,
          updatedDate:string,
          userComment:string,
          userId:number,
          __v:number,
          _id:string,
          mainCommentUserName:string,
          mainCommentUserId:number,
          parentCommentId:number,
        }
      ]
    ]
  }
}

export const articleLike = async (requestee_id: string) => {
  const url = `api/content/v1/posts/${requestee_id}}/article/like`;
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken() };
  newconfig.headers = { ...head };
  return apiPost<TGetFeedRes, { requestee_id: string }>(url, { requestee_id: requestee_id }, { ...newconfig, skipAuthRefresh: true });
};

export const articleSave = async (requestee_id: string) => {
    const url = `api/content/v1/posts/${requestee_id}}/article/save`;
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken() };
    newconfig.headers = { ...head };
    return apiPost<TGetFeedRes, { requestee_id: string }>(url, { requestee_id: requestee_id }, { ...newconfig, skipAuthRefresh: true });
  };

  export const articleCommentByID = async (id: any) =>{
    let url=`/api/content/v1/post/${id}`
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken()};
    newconfig.headers = { ...head };
    return apiGet<TUArticleComment>(url,{...newconfig,skipAuthRefresh: true,});
  };
