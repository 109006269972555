import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FileOutlined,
  InfoCircleOutlined,
  LockOutlined,
  MailOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import LoginInformation from "./LoginInformation";
import EmailPreferences from "./EmailPreferences";
import Privacy from "./Privacy";
import { Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Layout } from "antd";
import "antd/dist/antd.css";
import Headersection from "views/MainHeader/Header/Headersection";
import ExportData from "./ExportData";
import DeleteAccount from "./DeleteAccount";
import {useLocation } from "react-router";
import { Link } from "react-router-dom";


type TabPosition = "left" | "right" | "top" | "bottom";

function AccountSettings() {
  const { TabPane } = Tabs;
  // const history = useHistory()

  const [mode] = useState<TabPosition>("left");

  const onChanges = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  // const onChangess = (e: CheckboxChangeEvent) => {
  //   console.log(`checked = ${e.target.checked}`);
  // };

  // const style = { color: "red", marginbottom: "20px" };
  const stylesss = {
    color: "white",
    padding: "12px",
    backgroundColor: "#007cff",
  };
  // const styles = {
  //   color: "white",
  //   padding: "18px",
  //   backgroundColor: "#007cff",
  // };
  // const styless = {
  //   color: "white",
  //   padding: "10px",
  //   backgroundColor: "#007cff",
  // };
  const { Header } = Layout;

  const [tabkeyval, setTabkeyval] = useState('');
  const router = useLocation();
  useEffect(() => {
    let hash=router.hash;
    let activeKey;
    if (hash === "#LoginInformation") {
      activeKey = "LoginInformation";
      setTabkeyval(activeKey);
    } else if (hash === "#EmailPreferences") {
      activeKey = "EmailPreferences";
      setTabkeyval(activeKey);
    } else if (hash === "#Privacy") {
      activeKey = "Privacy";
      setTabkeyval(activeKey);
    } else if (hash === "#ExportData") {
      activeKey = "ExportData";
      setTabkeyval(activeKey);
    } else if (hash === "#DeleteAccount"){
      activeKey = "DeleteAccount";
      setTabkeyval(activeKey);
    }
  }, [router]);

  // console.log(tabkeyval)

  const handleTabClick =(key : any) => {
    setTabkeyval(key);
  }

  return (
    <>
      <Layout className="layout" style={{ background: "#fafbfd" }}>
        <Header
          className=""
          style={{ position: "fixed", zIndex: 1, width: "100%" }}
        >
          <Headersection />
        </Header>
        <div className="p-3 lg:ml-14 lg:mr-14 lg:mt-12 account_tabs">
          <div className="edit-head1">
            <h3 className="md:text-4xl sm:text-2xl">
              Account Settings
            </h3>
            <div>
            <div className="view_myprofile">
              <Button danger className="button-resize">
                <Link className=" flex" to={{ pathname: "/viewprofile"}}>
                  <UserOutlined className="site-form-item-icon mt-1" />
                  <p className="!text-sm">View My Profile</p>
                </Link>
              </Button>
            </div>
          </div>
          </div>
          <div className="privacy_section">
            <Tabs
              tabPosition={mode}
              onChange={handleTabClick}
              activeKey={tabkeyval}

            >
              <TabPane
                tab={
                  <span>
                    <SettingOutlined />
                    {"Login Information"}
                  </span>
                }
                key="LoginInformation"
              >
                <LoginInformation />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <MailOutlined />
                    {" Email Preferences"}
                  </span>
                }
                key="EmailPreferences"
              >
                <EmailPreferences />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <LockOutlined />
                    {"Privacy"}
                  </span>
                }
                key="Privacy"
              >
                <Privacy />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <FileOutlined />
                    {"Blocked Members"}
                  </span>
                }
                key="4"
              ></TabPane>
              <TabPane
                tab={
                  <span>
                    <UsergroupAddOutlined />
                    {"Group Invites"}
                  </span>
                }
                key="5"
              >
                <div className="p-4 lg:py-10 lg:px-20 bg-white">
                  <h3 className="text-xl font-light mb-7">Group Invites</h3>
                  <div className="flex login_section mb-4">
                    <InfoCircleOutlined style={stylesss} />
                    <p
                      className="py-2.5 px-1.5 m-0"
                      style={{
                        backgroundColor: "rgb(247 249 252",
                        color: "#4D5C6D",
                        border: "1px solid rgb(188, 201, 215)",
                      }}
                    >
                      Currently every member of the community can invite you to
                      join their groups. Optionally, you may restrict group
                      invites to your connections only.
                    </p>
                  </div>
                  <Checkbox onChange={onChanges}></Checkbox>
                  <p className="my-3">
                    Restrict Group invites to members who are connected.
                  </p>
                  <Button
                    htmlType="submit"
                    className="mt-4"
                    style={{
                      backgroundColor: "#ff5a5f",
                      borderRadius: "50px",
                      color: "#fff",
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <CloudDownloadOutlined />
                    {" Export Data "}
                  </span>
                }
                key="ExportData"
              >
                <ExportData />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <DeleteOutlined />
                    {"Delete Account"}
                  </span>
                }
                key="DeleteAccount"
              >
                <DeleteAccount />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AccountSettings;
