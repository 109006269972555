import { useEffect, useState, useRef } from "react";
import { Form, Input, Button} from "antd";
import { LoadingOutlined} from "@ant-design/icons";
import { Spin } from "antd";
import ImgCrop, { ImgCropProps } from "antd-img-crop";
import { Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useLocation } from "react-router-dom";

interface LocationState {
  state: {
    _id: string;
    activityFeed: string;
    groupName: string;
    description: string;
    groupRule: string;
    groupImage: string;
    thumbnail: string;
    shortDescription: string;
  };
}
const { TextArea } = Input;
const GeneralInformation = (propsData: any) => {
  const location = useLocation<LocationState>();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [groupImage, setGroupImgae] = useState<any>("");
  const [groupError, setGroupError] = useState<any>(false);
  const [thumbnail, setThumbnail] = useState<any>("");
  const [thumNailShow, setShowThumbnailShow] = useState<any>(true);
  const [groupImageShow, setShowGroupImageShow] = useState<any>(true);
  const handleChangeImageGroup = (e: any) => {
    setGroupImgae(e.file.originFileObj);
    setShowGroupImageShow(false);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  useEffect(() => {
    if (propsData.resetGeneralIfoFields === true) {
      form.resetFields();
    }
  }, [propsData]);

  const onFinish = async (values: any) => {
    if (location.state?.state?._id != "" && location.state?.state?._id != undefined) {
      setIsLoading(true);
      const reqBody = await form.validateFields();
      reqBody.groupImage = groupImage || location?.state?.state?.groupImage;
      reqBody.thumbnail = thumbnail || location?.state?.state?.thumbnail;
      const nextTab = "settings";
      await propsData.getInfoData(reqBody, nextTab);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      try {
        const reqBody = await form.validateFields();
        if (groupImage && reqBody.groupName && reqBody.description && reqBody.rule) {
          reqBody.groupImage = groupImage;
          reqBody.thumbnail = thumbnail;
          const nextTab = "settings";
          await propsData.getInfoData(reqBody, nextTab);
        } else {
          console.log("Please fill out all required fields");
        }
      } catch (errorInfo) {
        console.log("Validation failed:", errorInfo);
        setGroupError(true);
      }
      setIsLoading(false);
    }
  };

  let groupData = {
    groupName: "",
    description: "",
    rule: "",
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsLoading(false);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />;
  useEffect(() => {
    if (propsData.resetGeneralInfoFields === true) {
      form.resetFields();
    } else if (location?.state?.state) {
      form.setFieldsValue({
        groupName: location?.state?.state?.groupName || "",
        description: location?.state?.state?.description || "",
        rule: location?.state?.state?.groupRule || "",
        shortDescription: location?.state?.state?.shortDescription || "",
        groupImage: location.state.state.groupImage || "",
        thumbnail: location.state.state.thumbnail || "",
      });
    }
  }, [location]);

  useEffect(() => {
    if ((location?.state?.state?.groupImage == "")||location?.state?.state?.groupImage == undefined ) {
      setShowGroupImageShow(false);
    }
    if ((location?.state?.state?.thumbnail == "")||
     (location?.state?.state?.thumbnail == undefined)) {
      setShowThumbnailShow(false);
    }
  });
  const imgCropRef = useRef(null);
  const getSrcFromFile = (file: any) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onPreviewThumbnail = async (file: any) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);
    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setThumbnail(newFileList);
    setShowThumbnailShow(false);
  };
  return (
    <div>
      <Form
        name="general"
        form={form}
        initialValues={groupData}
        layout="vertical"
        autoComplete="off"
        className="input-sty"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item
          name="thumbnail"
          label="Group Logo"
          rules={[
            {
              message: "Please select group image!",
            },
          ]}>
          <ImgCrop cropShape="rect" aspect={1 / 1} ref={imgCropRef}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader custom-upload"
              onPreview={onPreviewThumbnail}
              onChange={handleChange}>
              {thumbnail.length < 1 && "+ Upload"}
            </Upload>
          </ImgCrop>
          {thumNailShow == true && <img src={location?.state?.state?.thumbnail} className="loc-img" />}
        </Form.Item>
        <Form.Item
          name="groupImage"
          label="Group Image"
          rules={[
            {
              message: "Please select group image!",
            },
          ]}>
          <ImgCrop cropShape="rect" aspect={1 / 1}>
            <Upload
              name="groupImage"
              className="custom-upload"
              listType="picture-card"
              onChange={e => handleChangeImageGroup(e)}
              onPreview={onPreview}>
              {groupImage.length === 0 && "+ Upload"}
            </Upload>
          </ImgCrop>
          {groupImageShow === true && <img src={location?.state?.state?.groupImage} className="loc-img" />}
          {groupError && <p style={{ color: "red" }}>Please Upload Group Image</p>}
        </Form.Item>
        <Form.Item name="groupName" label="Group Name" rules={[{ required: true, message: "Please enter Group Name!" }]}>
          <Input id="groupName" placeholder="Add group name" />
        </Form.Item>
        <Form.Item
          name="shortDescription"
          label="Short Description"
          rules={[
            {
              required: true,
              message: "Please enter short  description!",
            },
          ]}>
          <Input id="description" placeholder="A brief description of the group" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Long Description"
          rules={[
            {
              required: true,
              message: "Please enter group description!",
            },
          ]}>
          <TextArea
            placeholder="Add group description here"
            autoSize={{
              minRows: 3,
              maxRows: 5,
            }}
          />
        </Form.Item>
        <Form.Item
          name="rule"
          label="Rule"
          rules={[
            {
              required: true,
              message: "Please enter group rules!",
            },
          ]}>
          <Input id="rule" />
        </Form.Item>
      </Form>
      <Button type="primary" htmlType="submit" className="button-sty float-right" onClick={onFinish} danger>
        <span className="mr-2"> Next</span>
        {isLoading && <Spin indicator={antIcon} size="small" />}
      </Button>
    </div>
  );
};

export default GeneralInformation;
