import "antd/dist/antd.less";
import "../src/styles/main.less";
import "../src/styles/globle.less";
import "../src/index.css";
import "tailwindcss/tailwind.css";
import Dashboard from "views/Dashboardsection/Dashboard";
import Login from "views/Login";
import InternalLogin from "./views/InternalLogin";
import { Route, Redirect, RouteProps, Switch, BrowserRouter } from "react-router-dom";
import ForgotPassword from "views/ForgotPassword";
import EditProfile from "views/EditProfile/EditProfile";
import PersonalDetails from "views/EditProfile/PersonalDetails";
import ParentingInfo from "views/EditProfile/ParentingInfo";
import ContactInfo from "views/EditProfile/ContactInfo";
import PregnancyInfo from "views/EditProfile/PregnancyInfo";
import MomsLikeYou from "views/MomsLikeYou/MomsLikeYou";
import MomsNearYou from "views/MomsNearyou/MomsNearYou";
import Notification from "views/Notification";
import BabyNames from "components/global/babyname/BabyNames";
import MomsLikeListProfile from "views/MomsLikeYou/MomsLikeListProfile/MomsLikeListProfile";
import SendMessage from "views/MomsLikeYou/MomsLikeListProfile/SendMessage";
import { PrivateRoute } from "PrivateRoute";
import ViewProfile from "views/ViewProfile/ViewProfile";
import { TRoutes } from "helper";
import Savedpost from "views/SavedPost/Savedpost";
import { QueryClient, QueryClientProvider } from "react-query";
import AccountSettings from "views/AccountSettings/AccountSettings";
import Timeline from "views/ViewProfile/Timeline";
import Forums from "views/ViewProfile/Forums";
import Messages from "views/messages/Messages";
import Createuser from "views/CreateUser";
import Groups from "views/Groups/Groups";
import GroupDetails from "views/Groups/GroupDetails";
import GroupProfile from "views/Groups/GroupProfile";
import SavedItems from "views/SavedPost/SavedItems";
import ChangePassword from "views/ViewProfile/ChangePassword";
import SearchValue from "components/global/SearchValue";
import Name from "components/global/babyname/Name";
import Rashi from "components/global/babyname/Rashi";
import Nakshatra from "components/global/babyname/Nakshatra";
import Numerology from "components/global/babyname/Numerology";
import GodsName from "components/global/babyname/GodsName";
import GenerateName from "components/global/babyname/GenerateName";
import FavName from "components/global/babyname/FavName";
import PregnancyCalc from "components/global/tools/PregnancyCalculator/PregnancyCalc";
import PregnancyCalcResult from "components/global/tools/PregnancyCalculator/PregnancyCalcResult";
import MobileHeader from "views/MainHeader/Header/MobileHeader";
import React from "react";
import HCGCalc from "components/global/tools/HCGCalculator/HCGCalc";
import HCGCalcResult from "components/global/tools/HCGCalculator/HCGCalcResult";
import MotherBlood from "components/global/tools/ChildBloodType/MotherBlood";
import BloodTypeResult from "components/global/tools/ChildBloodType/BloodTypeResult";
import EyeParents from "components/global/tools/EyeColour/EyeParents";
import EyeResult from "components/global/tools/EyeColour/EyeResult";
import ContractionCalculator from "components/global/tools/Contraction/ContractionCalculator";
import KickCounter from "components/global/tools/KickCounter/KickCounter";

const queryClient = new QueryClient();
function App(props: RouteProps) {
  const [isMobileView, setIsMobileView] = React.useState<boolean>(false);

  React.useEffect(() => {
    const checkIsMobile = () => {
      const screenWidth = window.innerWidth;
      setIsMobileView(screenWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
      {isMobileView && <MobileHeader  />}
        <Switch>
          <Route exact path={TRoutes.login} component={Login} />
          <Route exact path={TRoutes.internslogin} component={InternalLogin} />
          <Route exact path={TRoutes.forgotpass} component={ForgotPassword} />
          <Route exact path={`${TRoutes.accountsettings}`} component={AccountSettings} />
          <PrivateRoute path={`${TRoutes.editprofile}/:slug1?/:slug2?`} component={EditProfile} />
          <PrivateRoute path={TRoutes.timeline} component={Timeline} />
          <PrivateRoute path={TRoutes.fourms} component={Forums} />
          <PrivateRoute exact path="/editprofile/personal" component={PersonalDetails} />
          <PrivateRoute exact path="/editprofile/parent" component={ParentingInfo} />
          <PrivateRoute exact path="/editprofile/contact" component={ContactInfo} />
          <PrivateRoute exact path="/babynames" component={BabyNames} />
          <PrivateRoute exact path="/group-profile/:id" component={GroupProfile} />
          <PrivateRoute exact path="/babynames/:id" component={Name} />
          <PrivateRoute exact path="/rashi/:id" component={Rashi} />
          <PrivateRoute exact path="/nakshatra/:id" component={Nakshatra} />
          <PrivateRoute exact path="/numerology/:id" component={Numerology} />
          <PrivateRoute exact path="/god/:id" component={GodsName} />
          <PrivateRoute exact path="/generate_names" component={GenerateName} />
          <PrivateRoute exact path="/fav_names" component={FavName} />
          <PrivateRoute exact path="/pregnancy-calculator" component={PregnancyCalc} />
          <PrivateRoute exact path="/pregnancy-calculator-weight-result" component={PregnancyCalcResult} />
          <PrivateRoute exact path="/hcg-calculator" component={HCGCalc} />
          <PrivateRoute exact path="/hcg-calculator-result" component={HCGCalcResult} />
          <PrivateRoute exact path="/child-blood-type" component={MotherBlood} />
          <PrivateRoute exact path="/blood-types" component={BloodTypeResult} />
          <PrivateRoute exact path="/baby-eye" component={EyeParents} />
          <PrivateRoute exact path="/baby-eye-color" component={EyeResult} />
          <PrivateRoute exact path="/contraction"  component={ContractionCalculator}/>
          <PrivateRoute exact path="/kick-counts"  component={KickCounter}/>




          <PrivateRoute exact path="/save-items/" component={SavedItems} />
          <PrivateRoute exact path="/change-password" component={ChangePassword} />
          <PrivateRoute exact path="editprofile/pregnancy" component={PregnancyInfo} />
          <PrivateRoute exact path="/searchvalue" component={SearchValue} />
          <PrivateRoute exact path="/momslikeyou" component={MomsLikeYou} />
          <PrivateRoute exact path="/momsnearyou" component={MomsNearYou} />
          <PrivateRoute exact path="/momslikelistprofile/:id?" component={MomsLikeListProfile} />
          <PrivateRoute exact path="/sendmessage" component={SendMessage} />
          <Route exact path="/viewprofile/:slug1?/:slug2?" component={ViewProfile} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/notification" component={Notification} />
          <PrivateRoute exact path="/savedpost" component={Savedpost} />
          <PrivateRoute exact path="/messages" component={Messages} />
          <PrivateRoute exact path="/ForgotPassword" component={ForgotPassword} />
          <PrivateRoute exact path="/Createuser" component={Createuser} />
          <PrivateRoute exact path="/groups" component={Groups} />
          <PrivateRoute exact path="/groups/:slug1?" component={GroupDetails} />
          <Redirect from="*" to={TRoutes.login} />
          <Redirect from="Article" to={""} />
        </Switch>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
export default App;
