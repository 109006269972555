import { getFeedsApi } from "apis/GetFeeds";
import { getBannerApi } from "apis/GetFeeds";
import { TRootState } from "reducers";
import { ThunkAction } from "redux-thunk";
import { Action, ActionType, createAction } from "typesafe-actions";

export type TBanner={
    banner_required:boolean;
    banner_image:string;
    alt_image:string;
}
export type TIconWeekwise={
    icon_image:string;
    text_area:string;
    post_link:{
        title:string;
        url:string;
        target:string;
    }
    week_status:string;

}
export type TTodoList={
    listIcon:string;
    listColor:string;
    title:string;
    listTitle:string;
    items:Array<any>
}
export type TKeyHighLight={
    icon_image:string;
    title:string;
    description:string;
}
export type TPostDetails={
    user_name:string
    profile_pic:string;
    sk_post_id:string
    user_id:string
    likes:string
    dislikes:string
    is_liked:string
    is_saved:string
    post_description:string
    post_visibility:string
    created_date_time:string
    updated_date_time:string
    post_media:Array<any>
    gender_pic: string
    time:string
    sk_user_id:string
}
export type TArticleDetails={
    post_id:string
    thumbnail_image:string;
    Title:string
    slug:string
    content:string
    permalink:string
    categoryName:string
    categorySlug:string
    week_category_name:string
    week_category_slug  :string
    special_category_name:string
    special_category_slug:string
    child_safety_category_name:string
    child_safety_category_slug:string
    life_skills_category_name:string
    life_skills_category_slug:string
    post_tag_name:string
    post_tag_slug:string
    sk_user_id:string
}
export type TMoms={
    user_stage:string;
    user_stage_name:string;
    sk_user_id:string;
    full_name:string;
    profile_pic:string;
    connection:string;
    follow:string;
    week:string;
}
export type Tfeed={
    type:string;
    details:Array<TPostDetails | TArticleDetails |TMoms>
}
export type TGETFEEDResponse={
    banner_section:TBanner;
    icon_required:boolean;
    icon_weekwise:Array<TIconWeekwise>;
    todo_list_required:boolean;
    todo_list:Array<TTodoList>;
    key_highlight_required:boolean;
    key_highlights:Array<TKeyHighLight>;
    pregnancyTips_required:boolean;
    pregnancy_Tips:Array<TKeyHighLight>;
    feed:Array<Tfeed>

}

export const getFeedInitAction = createAction("feed/GETFEED_INIT")();
export const getFeedSuccessAction = createAction("feed/GETFEED_SUCCESS")<TGETFEEDResponse>();
export const getFeedFailureAction = createAction("feed/GETFEEED_FAILURE")<Error>();

export const getFeedFollowAction = createAction("feed/GETFEED_FOLLOWUPDATE")<TMoms>();
export type TGetFeedFollowAction = ActionType<typeof getFeedFollowAction>;

export const logOutAction = createAction("users/LOGOUT")();

export type TGetFeedInitAction = ActionType<typeof getFeedInitAction>;
export type TGetFeedSuccessAction = ActionType<typeof getFeedSuccessAction>;
export type TGetFeedFailureAction = ActionType<typeof getFeedFailureAction>;

export type TGetFeedActions =
  | TGetFeedInitAction
  | TGetFeedSuccessAction
  | TGetFeedFailureAction
  |TGetFeedFollowAction;
export type TGETFEEDThunkActionType = ThunkAction<void, TRootState, null, TGetFeedActions>;

type TGetFeedAction = (payload:string) => TGETFEEDThunkActionType;

export const getFeed: TGetFeedAction = (payload:string) => (
    dispatch: (action: Action | Promise<Action> | ThunkAction<void, TRootState, null, TGetFeedActions>) => any
  ) => {
  dispatch(getFeedInitAction());
  return getBannerApi(payload)
  .then(async(res) => {
      if(res.status){
        dispatch(getFeedSuccessAction(res.data));
      }else{
        dispatch(getFeedFailureAction({message:res.message} as Error))
      }
  })
}