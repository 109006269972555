import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";
type Tnotify = {
  status: boolean;
  message: string;
  data: any;
};

export const notifysection = async (token: any) => {
  const url = "/Connection/notification_timeline";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, useraccesstoken: token };
  newconfig.headers = { ...head };
  return apiGet<Tnotify>(url, { ...newconfig, skipAuthRefresh: true });
};
