import { LikeFilled, LikeOutlined, MoreOutlined } from "@ant-design/icons";
import { Card, Carousel, Image } from "antd";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { grouplikeApi, savePostGroupApi } from "views/Groups/btpGroupApi";
import PostComment from "components/modal/PostComment";
import Swal from "sweetalert2";
const { Meta } = Card;
import { deleteMyPost } from "views/Groups/btpGroupApi";
import { viewProfileApi } from "apis/btpViewProfile";
import SaveImg from "../../images/SavedIcon.svg";
import Save from "../../images/save.svg";
import CommentImg from "../../images/comment.svg";
import Send from "../../images/send.svg";
import SendModal from "views/Posts/SendModal";
import Slider from "react-slick";

let settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: false,
  arrows: false
};

const Newpost = (props: any) => {
  let data = props.data;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postId, setPostID] = useState<any>("");
  const [isMoreVisible, setIsMoreVisible] = useState(true);
  const toggleDisplay = () => {
    setIsMoreVisible(!isMoreVisible);
  };

  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(data?.totalLikes || 0);
  const [savePost, setSavePost] = useState(false);

  useEffect(() => {
    if (data?.isLiked == true) {
      setLiked(true);
    } else {
      setLiked(false);
    }
    if (data?.isSaved === true) {
      setSavePost(true);
    } else {
      setSavePost(false);
    }
  }, [data]);

  const handleClickLike = async (value: any) => {
    setLiked(prevLiked => !prevLiked);
    setLikeCount((prevCount: any) => {
      const increment = liked ? -1 : 1;
      return prevCount + increment;
    });

    await grouplikeApi(value)
      .then(res => {
      })
      .catch(err => {
        console.log(err);
      });
  };

  const {
    isLoading: loading,
    data: userData,
    refetch: refres,
  } = useQuery("viewProfile", () => viewProfileApi({ user_id: "", enabled: false }));

  const showModalComment = (e: any, values: any) => {
    setPostID(values);
    setIsModalOpen(true);
    props.refresh();
  };

  const hanldeSavePost = async (e: any, val: any, saveval: any) => {
    if (saveval === "notsaved") {
      Swal.fire({
        icon: "success",
        title: "Post saved",
        showConfirmButton: false,
        timer: 900,
      });
    }
    setSavePost(!savePost);
    await savePostGroupApi(val.id)
      .then(res => {
        if (val.isSaved === true) {
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleDeleteClick = (e: any, list: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Post.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        deleteMyPost(list)
          .then(res => {
            props.refresh();
          })
          .catch(err => {
            console.log(err);
          });
        toggleDisplay();
        Swal.fire("Deleted!", "Your post has been deleted.", "success");
      }
    });
  };


  useEffect(() => {
    props.refresh();
  }, [isModalOpen]);
  const [isSendShow, setIsSendShow] = useState(false);
  const [artcileData, setArticleData] = useState<any>({});
  const [type, setType] = useState<any>("");

  const handleSendBtn = (permalink: any) => {
    setIsSendShow(true);
    setArticleData(permalink);
    setType("userpost");
  };

  return (
    <>
      {artcileData && (
        <SendModal
          setIsSendShow={setIsSendShow}
          isSendShow={isSendShow}
          artcileData={artcileData}
          type={type}
          setArticleData={setArticleData}
        />
      )}
      {isModalOpen && <PostComment setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} postId={postId} setPostID={setPostID} />}
      <div className="line mt-3">
        <>
          <Card
            className="Liked_post_section"
            style={{ marginBottom: "10px" }}
            title={
              <>
                <div className="flex mb-3">
                  <div className="mr-3">
                    <img src={data?.userDetails.profilePic} alt="" style={{ width: "40px", height: "40px" }} />
                  </div>
                  <div style={{ flex: 1 }}>
                    <h2 style={{ marginBottom: "0px" }}>{data?.userDetails.name}</h2>
                    <span>{moment(data?.createdDateTime).format("DD-MM-YYYY")}</span>
                  </div>

                  <div>
                    {data?.userId == userData?.sk_user_id && (
                      <p>
                        {isMoreVisible ? (
                          <MoreOutlined className={`more-link cursor-pointer`} onClick={toggleDisplay} />
                        ) : (
                          <div className="dropdown-menu">
                            <a onClick={e => handleDeleteClick(e, data?.id)} className="delete-link">
                              Delete
                            </a>
                          </div>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </>
            }>
            <p className="mb-2">{data?.postDescription !== "undefined" ? <p className="white-space-preline">{data?.postDescription}</p> : ""}</p>
            <Slider {...settings}>
              {data?.postMedia.map((media_item: any, index: number) => (
                <div key={index}
                className="grp-post-img"
                >
                  {media_item.attachmentType === "image" || media_item.attachmentType === "png" || media_item.attachmentType === "jpg" ? (
                    <img
                      src={media_item.attchmentName}
                      alt=""
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  ) : (media_item.attachmentType === "video" || media_item.attachmentType === "mp4") && (
                    <video src={media_item.attchmentName} controls autoPlay style={{ width: "100%", height: "100%" }} />
                  )}
                </div>

              ))}
            </Slider>
            <hr className="mt-3" />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="flex text-center cursor-pointer">
                {data?.likes != 0 && (
                  <span
                    className="mt-1"
                    style={{
                      fontWeight: "600px",
                      fontSize: ".9375rem",
                    }}
                    onClick={() => handleClickLike(data.id)}>
                    {likeCount} Like
                  </span>
                )}
              </div>
              <div className="flex text-center ml-6">
                {data?.totalComments != 0 && (
                  <span
                    className="mt-1 cursor-pointer"
                    style={{
                      fontWeight: "600px",
                      fontSize: ".9375rem",
                    }}
                    onClick={e => showModalComment(e, data.id)}>
                    {data?.totalComments != 0 && data?.totalComments} Comment
                  </span>
                )}
              </div>
            </div>

            <div className="flex text-center cursor-pointer">
              <div className="mt-3 flex text-center">
                <div className="flex text-center cursor-pointer" onClick={() => handleClickLike(data.id)}>
                  {liked ? (
                    <span className="mt-1 mr-8">
                      <LikeFilled style={{ fontSize: "20px" }} className="ml-1" />
                    </span>
                  ) : (
                    <span className="mt-1 mr-8">
                      <LikeOutlined style={{ fontSize: "20px" }} className="ml-1" />
                    </span>
                  )}
                </div>
                <div className="flex text-center">
                  <span className="mt-1 cursor-pointer mr-8">
                    <Image src={CommentImg} preview={false} onClick={e => showModalComment(e, data.id)} />
                  </span>

                  <span className="mt-1 cursor-pointer mr-8">
                    <Image src={Send} preview={false} onClick={() => handleSendBtn(data)} />
                  </span>
                </div>
              </div>

              <div
                className="flex text-center mt-3"
                style={{
                  marginLeft: "auto",
                }}>
                <span style={{ marginRight: "8px" }} className="mt-1">
                  {savePost ? (
                    <Image src={SaveImg} preview={false} onClick={e => hanldeSavePost(e, data, 'saved')} className="cursor-pointer" />
                  ) : (
                    <Image src={Save} preview={false} onClick={e => hanldeSavePost(e, data, 'notsaved')} className="cursor-pointer" />
                  )}
                </span>
              </div>
            </div>
          </Card>
        </>
      </div>
    </>
  );
};

export default Newpost;
