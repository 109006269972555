import { followUserdispatcher } from "actions/follow";
import { TPostDetails } from "actions/getfeed";
import { unfollowUserdispatcher } from "actions/unfollow";
import { Avatar, Col, Modal, Row } from "antd";
// import { followingbutton,Unfollowingbutton } from "apis/likeunlikepost";
// import { followingbutton,Unfollowingbutton } from "apis/btpLikeUnlikepost";


import {followingbutton,Unfollowingbutton} from "apis/btpFallowUnfallow"

import React, { useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { TRootState } from "reducers";
import { action } from "typesafe-actions";
import Like from "../../../images/like.svg";
import Liked from "../../../images/liked.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const Followbutton = (propsFollowsection:any) => { 
  // console.log("propsFollowsection",propsFollowsection.userId);
  // console.log(propsFollowsection.skUserId);
  
  
  const [isLoading, setIsLoading] = useState(false);
  const [likedcount, setLikedCount] = useState<[key: number]>();
  const { feed } = useSelector((state: TRootState) => state.feeds);

  const appReducer = (state: any, action: any) => {
    switch (action.type) {
      case "HANDLE_LIKE":
        return {
          ...state,
          likes: state.likes + action.payload,
        };
      case "HANDLE_DISLIKE":
        return {
          ...state,
          dislikes: state.dislikes + action.payload,
        };
      default:
        return state;
    }
  };

  const initialState = {
    likes: propsFollowsection.follow,
    dislikes: propsFollowsection.follow,
  };
  const [state, dispatch] = useReducer(appReducer, initialState);
  const { likes, dislikes } = state;
  const [status, setStatus] = useState<any>(null);
  const [apistatus, setApiStatus] = useState("like");
  // console.log(status);
  

  const handleClickLike = () => {
    setIsLoading(true)
    if (status === "follow") {
      const data={
          "followeeId": propsFollowsection.userId,
          "channel": "momnexus",
          "status": "follow",
          "page": "feed"
      }
      followingbutton(data).then((res) => {
        res.status && setStatus("unfollow");
        setIsLoading(false)
      });
      propsFollowsection.getConnectionList();
    } else {
      setApiStatus("unlike");
      const data={
        "followeeId": propsFollowsection.userId,
        "channel": "momnexus",
        "page": "feed"
    }
      Unfollowingbutton(data).then((res) => {
        propsFollowsection.getConnectionList();
        res.status && setStatus("follow");
        setIsLoading(false)
      });
    }
    propsFollowsection.getConnectionList();
  };
  
  useEffect(() => {
    if(propsFollowsection.following===true){
      setStatus("following");
    }else if(propsFollowsection.following===false){
      setStatus("follow");
    }
  }, [propsFollowsection])



  const antIcon = (
    <LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />
  );


  return (
    <div>
      <div className="mr-1 cursor-pointer" onClick={handleClickLike}>
        {status === "follow" ? (
        <button className={propsFollowsection.class} style={{
          backgroundColor:"#ED4D41",
          color:"white",
        }}> <span className="" style={{padding:"3px"}}> Follow</span>
        {isLoading && <Spin indicator={antIcon} />}</button>
        ) : (
        <button className={propsFollowsection.class}  style={{
          backgroundColor:"#ED4D41",
          color:"white",
        }}><span  style={{padding:"3px"}}
        > Following</span>
        {/* {isLoading && <Spin indicator={antIcon} />} */}
        
        </button>
        )}
      </div>
    </div>
  );
};

export default Followbutton;
