import { InfoCircleOutlined } from '@ant-design/icons'
import { Button, Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react'



const onChangess = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const styless = {color: "white",padding:"10px",backgroundColor: "#007cff",}



function DeleteAccount() {
  return (
    <div className='p-4 lg:py-10 lg:px-20 bg-white'>
    <h3 className='text-xl font-light mb-7'>Delete Account</h3>
    <div className='flex login_section mb-4'>
      <InfoCircleOutlined style={styless} />
      <p className='py-2.5 px-1.5 m-0' style={{ backgroundColor: "rgb(247 249 252", color: "#4D5C6D", border: "1px solid rgb(188, 201, 215)" }}>Deleting your account will delete all of the content you have created. It will be completely irrecoverable.</p>
    </div>
    <Checkbox onChange={onChangess}></Checkbox>
    <p className='my-3'>I understand the consequences.</p>
    <Button htmlType="submit" className='mt-4' style={{ backgroundColor: "#ff5a5f", borderRadius: "50px", color: "#fff" }}>
        Delete Account
    </Button>
</div>
  )
}

export default DeleteAccount