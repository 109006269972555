import { getAccessToken } from "actions/auth";
import React from "react";
import { apiGet, apiPost, getDefaultAxiosConfig } from "./apiCall";

export type TLikeUnlikeResponse = {
  status: boolean;
  message: string;
  data: any;
};

export const likeandunlikepost = async ( post_id:string, type: string) => {
  const url = "/Connection/like_and_unlike_post";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {post_id:string, type: string}>(url, {post_id: post_id, type: type}, {...newconfig,skipAuthRefresh: true,});
};

export const likedlist = async ( post_id:string) => {
  const url = "/Connection/get_like_list";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {post_id:string}>(url, {post_id: post_id}, {...newconfig,skipAuthRefresh: true,});
};

export const followingbutton = async ( requestee_id:string) => {
  const url = "/Connection/following";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {requestee_id:string}>(url, {requestee_id:requestee_id}, {...newconfig,skipAuthRefresh: true,});
};
export const Unfollowingbutton = async ( requestee_id:string) => {
  const url = "/Connection/unfollow";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {requestee_id:string}>(url, {requestee_id:requestee_id}, {...newconfig,skipAuthRefresh: true,});
};


export const savedpostlikeandunlike = async ( post_id:string) => {
    const url = "Connection/save_post";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken()};
    newconfig.headers = { ...head };
    return apiPost<TLikeUnlikeResponse, {post_id:string}>(url, {post_id: post_id}, {...newconfig,skipAuthRefresh: true,});
};

export const unsavedpostlikeandunlike = async ( post_id:string) => {
    const url = "Connection/unsave_post";
    let newconfig = getDefaultAxiosConfig();
    const head = { ...newconfig.headers, accesstoken: getAccessToken()};
    newconfig.headers = { ...head };
    return apiPost<TLikeUnlikeResponse, {post_id:string}>(url, {post_id: post_id}, {...newconfig,skipAuthRefresh: true,});
};

export const getsavedposts = async () =>{
  const url = "Connection/get_saved_posts";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiGet<TLikeUnlikeResponse>(url, {...newconfig,skipAuthRefresh: true,});
};

export const notificationreadAPI = async (receiver_id:string) => {
  const url = "Connection/mark_as_read";
  let newconfig = getDefaultAxiosConfig();
  const head = { ...newconfig.headers, accesstoken: getAccessToken()};
  newconfig.headers = { ...head };
  return apiPost<TLikeUnlikeResponse, {receiver_id:string}>(url, {receiver_id: receiver_id}, {...newconfig,skipAuthRefresh: true,});
};