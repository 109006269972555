import React from 'react';

const Footer = () => {
  const copyrightStyles = {
    fontWeight: "bold",
    margin: "0",
  };
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };


  return (
    <footer style={{
        textAlign: "center",
        color: '#454444',
        fontSize: "16px",
        fontWeight:"bold",
        height: "5vh",
        width: "100%",
        backgroundColor: "#e3e3e3",
        position: "sticky",
        marginBottom: "0vh",
        padding: "10px",
    }}>
      <p style={copyrightStyles}>&copy; 2014-{getCurrentYear()} Mykids Ventures Private Limited.</p>
    </footer>
  );
};

export default Footer;
