import { TFetchingStatus } from "helper";
import { createReducer } from "typesafe-actions";
import produce from "immer";
import {
  TUserChild,
  TViewProfileActions,
  updateProfileUpdateAction,
  viewProfileFailureAction,
  viewProfileInitAction,
  viewProfileSuccessAction,
} from "actions/viewprofile";
import { updateProfileSuccessAction } from "actions/updateprofile";
import { WritableDraft } from "immer/dist/internal";
import { TUpdateProfile } from "./updateprofile";




export type TViewProfile = {
  length: number;
  personal_details_progression: number;
  total_percentage: number;
  personal_details_updated: number;
  personal_details_maxfields: number;
  contact_information_progression: number;
  contact_information_updated: number;
  contact_information_maxfields: number;
  pregnancy_information_progression: number;
  pregnancy_information_updated: number;
  pregnancy_information_maxdields: number;
  parenting_information_progression: number;
  parenting_information_updated: number;
  parenting_information_maxfields: number;
  parenting_information_progression_isValid: string;
  sk_user_id: string;
  full_name: string;
  alies_name: string;
  gender: string;
  c_code: string;
  email: string;
  mobile: string;
  dob: string;
  i_am: string;
  username_momnexus: string;
  profile_pic: string;
  gender_pic: string;
  cover_photo: string;
  pincode: string;
  stage_name: string;
  country_name: string;
  country_id: string;
  personal: string;
  mobiledata: string;
  parentingdata: string;
  due_date_parent: string;
  due_date_status: string;
  child_info: Array<TUserChild>;
  fetchingStatus: TFetchingStatus;
  error: Error | null;
  profile_pic_url: string;
  registered_date: string;
};

export const initialState: TViewProfile = {
  personal_details_progression: 0,
  total_percentage: 0,
  personal_details_updated: 0,
  personal_details_maxfields: 0,
  contact_information_progression: 0,
  contact_information_updated: 0,
  contact_information_maxfields: 0,
  pregnancy_information_progression: 0,
  pregnancy_information_updated: 0,
  pregnancy_information_maxdields: 0,
  parenting_information_progression: 0,
  parenting_information_updated: 0,
  parenting_information_maxfields: 0,
  parenting_information_progression_isValid: "",
  sk_user_id: "",
  full_name: "",
  alies_name: "",
  gender: "",
  c_code: "",
  email: "",
  mobile: "",
  dob: "",
  i_am: "",
  username_momnexus: "",
  profile_pic: "",
  gender_pic:"",
  cover_photo: "",
  pincode: "",
  stage_name: "",
  country_name: "",
  country_id: "",
  personal: "",
  mobiledata: "",
  parentingdata: "",
  due_date_parent: "",
  due_date_status: "",
  child_info: [],
  fetchingStatus: TFetchingStatus.Default,
  error: null,
  length: 0,
  profile_pic_url:"",
  registered_date:"",
};

export const viewProfileReducer = createReducer<
  TViewProfile,
  TViewProfileActions
>(initialState)
  .handleAction(viewProfileInitAction, (state) =>
    produce(state, (draftState) => {
      // console.log("init", state, draftState);
      draftState.fetchingStatus = TFetchingStatus.Loading;
      draftState.error = null;
    })
  )
  .handleAction(viewProfileSuccessAction, (state, { payload }) =>
    produce(state, (draftState) => {
      // console.log("rwegegergregregregeg"+  state);
      draftState.personal_details_progression =
        payload.personal_details_progression;
        draftState.total_percentage =
        payload.total_percentage;
      draftState.personal_details_updated = payload.personal_details_updated;
      draftState.personal_details_maxfields =
        payload.personal_details_maxfields;
      draftState.contact_information_progression =
        payload.contact_information_progression;
      draftState.contact_information_updated =
        payload.contact_information_updated;
      draftState.contact_information_maxfields =
        payload.contact_information_maxfields;
      draftState.pregnancy_information_progression =
        payload.pregnancy_information_progression;
      draftState.pregnancy_information_updated =
        payload.pregnancy_information_updated;
      draftState.pregnancy_information_maxdields =
        payload.pregnancy_information_maxdields;
      draftState.parenting_information_progression =
        payload.parenting_information_progression;
      draftState.parenting_information_updated =
        payload.parenting_information_updated;
      draftState.parenting_information_maxfields =
        payload.parenting_information_maxfields;
      draftState.parenting_information_progression_isValid =
        payload.parenting_information_progression_isValid;
      draftState.sk_user_id = payload.sk_user_id;
      draftState.full_name = payload.full_name;
      draftState.alies_name = payload.alies_name;
      draftState.gender = payload.gender;
      draftState.c_code = payload.c_code;
      draftState.email = payload.email;
      draftState.mobile = payload.mobile;
      draftState.dob = payload.dob;
      draftState.i_am = payload.i_am;
      draftState.username_momnexus = payload.username_momnexus;
      draftState.registered_date = payload.registered_date;
      draftState.profile_pic = payload.profile_pic;
      draftState.cover_photo = payload.cover_photo;
      draftState.pincode = payload.pincode;
      draftState.stage_name = payload.stage_name;
      draftState.country_name = payload.country_name;
      draftState.country_id = payload.country_id;
      draftState.personal = payload.personal;
      draftState.mobiledata = payload.mobiledata;
      draftState.parentingdata = payload.parentingdata;
      draftState.due_date_parent = payload.due_date_parent;
      draftState.due_date_status = payload.due_date_status;
      draftState.child_info = payload.child_info;
      draftState.gender_pic = payload.gender_pic;
      draftState.error = null;
      draftState.fetchingStatus = TFetchingStatus.Success;
    })
  )
  .handleAction(viewProfileFailureAction, (state, { payload }) =>
    produce(state, (draftState) => {
      // console.log("viewprofile");
      
      // console.log(state);
      
      draftState.fetchingStatus = TFetchingStatus.Failure;
      draftState.error = payload;
    })
  )
  .handleAction(updateProfileUpdateAction, (state, { payload }) =>
    produce(state, (draftState) => {
      // console.log("state",state);
      
      Object.entries<any>(payload).map(([field, values]) => {
        
        draftState[field as keyof TViewProfile] = values as never;
        // console.log("value",values);
      });
    })
  );
